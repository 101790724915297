import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { BufferedChangeset } from 'ember-changeset/types';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedMyAccountEditLegalIndividualRoute } from 'portal/routes/protected/my-account/edit-legal-individual';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedMyAccountEditLegalIndividual extends Controller {
    declare model: ModelFrom<ProtectedMyAccountEditLegalIndividualRoute>;

    @service
    declare router: RouterService;

    @action
    async onNext(changeset: BufferedChangeset) {
        await changeset.save();
        this.router.transitionTo('protected.my-account.my-titleholders');
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/my-account/edit-legal-individual': ProtectedMyAccountEditLegalIndividual;
    }
}
