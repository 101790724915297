import { Breadcrumb, BreadcrumbHint } from '@sentry/browser';

const maxPayloadSize = 100000;

export default function breadcrumbDecorator(breadcrumb: Breadcrumb, hint: BreadcrumbHint): Breadcrumb {
    if (breadcrumb.category === 'xhr') {
        const input = hint?.input;
        if (input?.length < maxPayloadSize) {
            breadcrumb.data!.payload = removeSensitiveData(input);
        } else {
            breadcrumb.data!.payload = `Payload exceeds maximum payload size of ${maxPayloadSize} (${input.length})`;
        }
    }
    return breadcrumb;
}

function removeSensitiveData(payload: string): string {
    try {
        const parsedPayload = JSON.parse(payload);
        return JSON.stringify(parsedPayload, replacer);
    } catch {
        return payload;
    }
}

const disallowedKeys = ['ssn','spouseSsn'];

function replacer(key: string, value: any): any {
    if (!disallowedKeys.includes(key)) {
        return value;
    } else {
        return undefined;
    }
}
