import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import DealSide from 'portal/services/deal-side';
import OnboardingQuestionRule, { OnboardingQuestionRuleId } from 'portal/models/onboarding-question-rule';
import Store from '@ember-data/store';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedDealRoute } from '../deal';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedBuyerTitleholderAndFinancingInformation extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare auth: AuthService;

    @service
    declare dealSide: DealSide;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Buyer Titleholder and Financing Information');
    }

    async model() {
        this.store.unloadAll('individualTitleholder');
        this.store.unloadAll('entityTitleholder');

        const user = await this.auth.getAuthenticatedUser();
        const deal = this.modelFor('protected.deal') as ModelFrom<ProtectedDealRoute>;
        const property = await deal.property;

        this.dealSide.initialize(deal.id);
        const creditLines = await property?.creditLinesForDealSide(this.dealSide.for(deal.id));
        const titleholders = await deal.titleholdersForSide(this.dealSide.for(deal.id));

        const legalEntities = await this.store.findAll('legalEntity', { reload: true });
        const legalIndividuals = await this.store.findAll('legalIndividual', {  reload: true });

        const onboardingQuestionRules = await this.store.query('onboardingQuestionRule', { deal_id: deal.id });
        const requestedMlsSurveyRule = onboardingQuestionRules.toArray().find(
            (onboardingQuestionRule: OnboardingQuestionRule) => {
                return onboardingQuestionRule.id == OnboardingQuestionRuleId.customer_requested_mls_survey;
            }
        );
        const requestedMlsSurveyApplies = requestedMlsSurveyRule?.applies ?? false;

        return {
            user,
            deal,
            creditLines,
            property,
            titleholders,
            legalEntities,
            legalIndividuals,
            requestedMlsSurveyApplies
        };
    }

    @action
    invalidateModel(): void {
        this.refresh();
    }
}
