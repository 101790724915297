import Component from '@glimmer/component';
import Deal, { DealStatuses } from 'portal/models/deal';
import Store from '@ember-data/store';
import { luxonDateFormat } from 'portal/helpers/luxon-date-format';
import { inject as service } from '@ember/service';

interface DealUltimateClosingDateArgs {
    deal: Deal;
    useOneLineFormat?: boolean;
}

export default class DealUltimateClosingDate extends Component<DealUltimateClosingDateArgs> {
    @service
    declare store: Store;

    declare ultimateClosingDate: string | undefined;

    constructor(owner: unknown, args: DealUltimateClosingDateArgs) {
        super(owner, args);

        const format = this.args.useOneLineFormat ? 'D' : "EEEE',' MMM d',' yyyy";
        this.ultimateClosingDate = luxonDateFormat([args.deal.ultimateClosingDate as Date, format]);
    }

    get isUltimateClosingDateVisible(): boolean | undefined {
        return this.args.deal.ultimateClosingDateVisible;
    }

    get closingDateLabel(): string {
        if (this.args.deal.status === DealStatuses.COMPLETE) {
            return 'Closing date';
        } else {
            return 'Estimated closing date';
        }
    }

}
