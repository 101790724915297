import { helper } from '@ember/component/helper';
import { isBlank } from '@ember/utils';

export function formatCurrency(params: [number]) {
    const [amount] = params;

    if (isBlank(amount)) {
        return '';
    }

    return amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
    });
}

export default helper(formatCurrency);
