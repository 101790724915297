import * as Sentry from '@sentry/ember';
import Component from '@glimmer/component';
import DwollaFundingSource from 'portal/models/dwolla-funding-source';
import ENV from 'portal/config/environment';
import { SeverityLevel } from '@sentry/types';
import { action } from '@ember/object';

interface InstantAccountVerificationArgs {
    dwollaFundingSource: DwollaFundingSource;
    onNext: (fundingSourceUrl: string) => void;
    onError: (error: any) => void;
}

export default class InstantAccountVerification extends Component<InstantAccountVerificationArgs> {
    @action
    onDidInsert(element: HTMLElement) {
        if (ENV.environment === 'test') {
            this.onDwollaLoaded();
        } else {
            const script = window.document.createElement('script');
            script.addEventListener('load', this.onDwollaLoaded.bind(this));
            script.src=ENV.DWOLLA_IAV_URL;
            element.parentElement!.prepend(script);
        }
    }

    onDwollaLoaded() {
        const dwolla = (window as any).dwolla;

        if (ENV.DWOLLA_STRATEGY === 'production') {
            dwolla?.configure('prod');
        } else {
            dwolla?.configure('sandbox');
        }

        dwolla?.iav.start(this.args.dwollaFundingSource!.iavToken, {
            container: 'iavContainer',
            stylesheets: [
                `${window.location.origin}/css/dwolla.css`
            ],
            microDeposits: 'false',
            fallbackToMicroDeposits: 'false'
        }, (err: any, res: {_links: {'funding-source': { href: string } } }) => {
            if (err) {
                Sentry.addBreadcrumb({
                    category: 'dwolla',
                    message: 'Dwolla error creating funding-source',
                    level: 'error' as SeverityLevel,
                    data: err
                });
                this.args.onError(err);
            } else {
                const fundingSourceUrl = res._links['funding-source'].href;
                const fundingSourceId = fundingSourceUrl.split('/').pop();
                this.args.onNext(fundingSourceId!);
            }
        });
    }
}
