import Component from '@glimmer/component';
import { BufferedChangeset } from 'ember-changeset/types';
import { action } from '@ember/object';

interface TeamInviteDialogArgs {
    changeset: BufferedChangeset;
    hideDialog: () => void;
}

export default class TeamInviteDialog extends Component<TeamInviteDialogArgs> {
    @action
    validate() {
        this.args.changeset.validate();
    }

    @action
    async onSave() {
        await this.args.changeset.save();
        this.args.hideDialog();
    }
}
