import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { ActionItemTemplateKeys } from 'portal/models/action-item';
import { BufferedChangeset } from 'ember-changeset/types';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedDealEarnestMoneyCheckRoute } from 'portal/routes/protected/deal/earnest-money/check';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class ProtectedDealEarnestMoneyCheck extends Controller {
    declare model: ModelFrom<ProtectedDealEarnestMoneyCheckRoute>;

    @service
    declare router: RouterService;

    @service
    declare store: Store;

    @task
    async onComplete(changeset: BufferedChangeset): Promise<void> {
        await changeset.save();
        const deal  = await this.model?.deal;
        const actionItems = await deal?.actionItems;
        const actionItem = actionItems?.find((actionItem) => [
            ActionItemTemplateKeys.BUYER_EARNEST_MONEY_METHOD,
            ActionItemTemplateKeys.ASSIGNOR_EARNEST_MONEY_METHOD
        ].includes(actionItem.templateKey as ActionItemTemplateKeys));
        if (actionItem){
            this.router.transitionTo('protected.deal.action-item-completion', actionItem.id);
        } else {
            this.router.transitionTo('protected.deal');
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'protected/deal/earnest-money/check': ProtectedDealEarnestMoneyCheck;
  }
}
