import Component from '@glimmer/component';
import IntakeParty, { intakePartyValidations } from 'portal/models/intake-party';
import Store from '@ember-data/store';
import lookupValidator from 'ember-changeset-validations';
import { BufferedChangeset } from 'ember-changeset/types';
import { Changeset } from 'ember-changeset';
import { UserRoleType } from 'portal/models/intake-user';
import { action } from '@ember/object';
import { capitalize } from '@ember/string';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface IntakeStartDealArgs {
  intakeUser: BufferedChangeset;
  parties: BufferedChangeset[];
  onNext: (intakeUser: BufferedChangeset, parties: BufferedChangeset[]) => void;
  disabled: boolean;
}

const primaryRoles = [UserRoleType.buyer, UserRoleType.seller, UserRoleType.assignor, UserRoleType.borrower] as string[];

export default class IntakeStartDeal extends Component<IntakeStartDealArgs> {
    @tracked
        showErrorBanner?: boolean = false;

    @tracked
        userRole?: string;

    @service
    declare store: Store;

    @action
    validate() {
        const intakeUser = this.args.intakeUser;
        intakeUser.validate();
        for (const party of this.args.parties) {
            party.validate();
        }
    }

    @action
    onUserRoleChange(value: string) {
        this.userRole = value;

        const intakeUser = this.args.intakeUser;
        const parties = this.args.parties;

        intakeUser.set('userRole', value);

        if (value === UserRoleType.lender) {
            intakeUser.set('representingRole',UserRoleType.buyer);
        } else {
            intakeUser.set('representingRole', null);
        }

        if (primaryRoles.includes(value)) {
            for (const party of parties) {
                party.set('userRole', null);

                if (primaryRoles.includes(party.get('representingRole'))) {
                    party.set('representingRole',value);
                }
            }
        } else {
            for (const party of parties) {
                (party.data as IntakeParty).deleteRecord();
            }
            parties.clear();
        }

        this.validate();
    }

    get userRoleDisplay(): string {
        const intakeUser = this.args.intakeUser;
        const userRole = intakeUser.get('userRole');

        if (userRole === UserRoleType.borrower) {
            return 'refinancer';
        } else {
            return userRole;
        }
    }

    get contactSubtitle(): string {
        return `Let us know who you are so we can add you to this deal as the ${this.userRoleDisplay}`;
    }

    get userRoleOptions() {
        const intakeUser = this.args.intakeUser;
        const userRole = capitalize(this.userRoleDisplay);
        return [
            { name: userRole, value: intakeUser.get('userRole') },
            { name: 'Real estate agent', value: UserRoleType.real_estate_agent },
            { name: `Power of attorney for ${userRole}`, value: UserRoleType.power_of_attorney }
        ];
    }

    @action
    addParty() {
        const intakeUser = this.args.intakeUser;
        const parties = this.args.parties;

        parties.pushObject(
            Changeset (
                this.store.createRecord('intake-party', {
                    intakeUser: intakeUser.data,
                    representingRole: intakeUser.userRole
                }),
                lookupValidator(intakePartyValidations),
                intakePartyValidations
            )
        );
        this.validate();
    }

    @action
    removeParty(partyChangeset: BufferedChangeset) {
        const parties = this.args.parties;

        parties.removeObject(partyChangeset);
        (partyChangeset.data as IntakeParty).deleteRecord();

        this.validate();
    }

    @action
    onIntakeContactInput(changeset: BufferedChangeset, key: string, value: any) {
        changeset.set(key, value);
        this.validate();
    }

    @action
    async onNext() {

        const parties = this.args.parties;
        const intakeUser = this.args.intakeUser;

        parties?.forEach((changeset) => {
            changeset.set('showErrorOnPristine', true);
        });
        intakeUser.set('showErrorOnPristine', true);

        if (intakeUser?.isInvalid || parties?.any((p) => p.isInvalid)) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
        }

        await this.args.onNext(intakeUser, parties);
    }
}
