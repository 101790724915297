import Controller from '@ember/controller';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedMyAccountPersonalDetailsRoute } from 'portal/routes/protected/my-account/personal-details';
import { userValidations } from 'portal/models/user';

export default class ProtectedMyAccountPersonalDetails extends Controller {
    declare model: ModelFrom<ProtectedMyAccountPersonalDetailsRoute>;

    validations = userValidations;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/my-account/personal-details': ProtectedMyAccountPersonalDetails;
    }
}
