import ActionItem from 'portal/models/action-item';
import Analytics from 'portal/services/analytics';
import BuyerWireActivity from 'portal/models/buyer-wire-activity';
import Component from '@glimmer/component';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import window from 'ember-window-mock';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

interface BuyerWireInstructionsArgs {
    actionItem: ActionItem;
}

export default class BuyerWireInstructions extends Component<BuyerWireInstructionsArgs> {
    @service
    declare store: Store;

    @service
        router!: RouterService;

    @service
    declare analytics: Analytics;

    @action
    async downloadDocument() {
        this.analytics.trackEvent('Buyer Wire Instructions Downloaded');
        const buyerWireActivity = this.args.actionItem as BuyerWireActivity;
        const documentDownloadRequest = this.store.createRecord(
            'document-download-request',
            {
                document: buyerWireActivity.document,
                deal: buyerWireActivity.deal
            }
        );

        // This needs to be instantiated before async behavior in order to not be blocked by Safari as a pop-up.
        // See https://stackoverflow.com/a/39387533
        const windowOpenReference = window.open();

        await documentDownloadRequest.save();

        const { url } = documentDownloadRequest;

        if (url && windowOpenReference) {
            windowOpenReference.location = url;
        }
    }

    @action
    onComplete() {
        if (!this.args.actionItem.hasCompleted) {
            this.args.actionItem.hasCompleted = true;
            this.args.actionItem.save();
        }

        this.router.transitionTo('protected.deal.action-item-completion', this.args.actionItem.id);
    }

}
