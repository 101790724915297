import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import Deal, { DealActorRoles } from 'portal/models/deal';
import Store from '@ember-data/store';
import User from 'portal/models/user';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedDealOnboardingRoute } from '../onboarding';
import { action } from '@ember/object';
import { isNone } from '@ember/utils';
import { inject as service } from '@ember/service';

export default class ProtectedDealOnboardingCpcNotaryIntermediary extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    @service
    declare auth: AuthService;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Onboarding / CPC Notary Intermediary');
    }

    async model(): Promise<{ deal: Deal, user: User, }> {
        const parentModel = this.modelFor(
            'protected.deal.onboarding'
        ) as ModelFrom<ProtectedDealOnboardingRoute>;

        const deal = await parentModel?.deal as Deal;
        const user = await this.auth.getAuthenticatedUser();

        return { deal, user };
    }

    async afterModel(model: ModelFrom<ProtectedDealOnboardingCpcNotaryIntermediary>): Promise<void> {
        const { deal } = model;

        if (deal.actorRole !== DealActorRoles.SELLER) return;
        const reissueRate = await deal.get('reissueRate');
        if (isNone(reissueRate)) {
            deal.set('reissueRate', this.store.createRecord('reissueRate'));
        }
    }

    @action
    async willTransition(): Promise<void> {
        const model = this.modelFor(
            this.routeName
        ) as ModelFrom<ProtectedDealOnboardingCpcNotaryIntermediary>;
        const reissueRate = await model.deal.get('reissueRate');
        reissueRate?.rollbackAttributes();
    }
}
