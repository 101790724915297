import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

interface ClosingRequestCompletionArgs {
    deal: Deal;
}

export default class ClosingRequestCompletion extends Component<ClosingRequestCompletionArgs> {
    @service
        router!: RouterService;

    @action
    onShowDealClick() {
        this.router.transitionTo('protected.deal');
    }

    @action
    onCompleteRemainingSigningsClick() {
        this.router.transitionTo('protected.deal.closing-request');
    }
}
