import BaseRoute from 'portal/routes/base-route';
import Deal from 'portal/models/deal';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';


export default class ProtectedDealBuyerWireInstructions extends BaseRoute {
    @service
    declare router: RouterService;

    async model({ action_item_id }: { action_item_id: string }) {
        if (!action_item_id){
            return {};
        }

        const deal = this.modelFor('protected.deal') as Deal;
        const actionItems = await deal.actionItems;
        const actionItem = actionItems?.find((actionItem) => actionItem.id === action_item_id);
        return {
            actionItem
        };
    }

}
