import { helper } from '@ember/component/helper';

export function formatPhoneNumber(params: [string]) {
    const [phoneNumber] = params;
    if (phoneNumber) {
        return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-‍$3');
    }
    return '';
}

export default helper(formatPhoneNumber);
