import Component from '@glimmer/component';
import RouterService from '@ember/routing/router-service';
import { BufferedChangeset } from 'ember-changeset/types';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export interface ProfileCommunicationPreferencesArgs {
    changeset: BufferedChangeset;
    onAfterSave?: () => Promise<void>;
    onCancel?: () => Promise<void>;
}

export default class ProfileCommunicationPreferences extends Component<ProfileCommunicationPreferencesArgs> {
    @service
    declare router: RouterService;

    @tracked
        saveSucceeded?: boolean = false;

    @tracked
        saveFailed?: boolean = false;

    @tracked
        showErrorBanner?: boolean = false;

    @action
    async cancel() {
        if (this.args.onCancel) {
            await this.args.onCancel?.();
        } else {
            this.router.transitionTo('protected.deals');
        }
    }

    @action
    async onSave() {
        this.saveFailed = false;
        this.saveSucceeded = false;

        this.args.changeset.set('emailPreference', this.args.changeset.emailPreference || 'always');
        this.args.changeset.set('portalMessagePreference', this.args.changeset.portalMessagePreference || 'always');
        this.args.changeset.set('messageNotificationEmailPreference', this.args.changeset.messageNotificationEmailPreference || 'always');
        this.args.changeset.set('phoneCallPreference', this.args.changeset.phoneCallPreference || 'only_when_necessary');
        this.args.changeset.set('textMessagePreference', this.args.changeset.textMessagePreference || 'only_when_necessary');
        this.args.changeset.set('showErrorOnPristine', true);

        if (this.args.changeset.isInvalid) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        try {
            await this.args.changeset.save();
            this.saveSucceeded = true;
            this.args.changeset.set('showErrorOnPristine', false);
            this.showErrorBanner = false;
        } catch (e) {
            this.saveFailed = true;
        }

        if (this.args.onAfterSave) {
            await this.args.onAfterSave?.();
        } else {
            window.scrollTo(0, 0);
        }
    }
}
