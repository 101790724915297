import AuthService from 'portal/services/auth';
import Component from '@glimmer/component';
import { BufferedChangeset } from 'ember-changeset/types';
import { Role } from 'portal/models/team';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export interface ProfileMyTeamArgs {
    changeset: BufferedChangeset;
    teamMemberChangesets: BufferedChangeset[];
    onAdd: () => void;
    onDelete: (teamMemberChangeset: BufferedChangeset) => void;
    onSave: () => void;
    isError: boolean;
}

export default class ProfileMyTeam extends Component<ProfileMyTeamArgs> {
    @action
    validate() {
        this.args.changeset.validate();
    }

    @service
        auth!: AuthService;

    @tracked
        needsAdminMember?: boolean = false;

    @tracked
        showErrorBanner?: boolean = false;

    get isUser() {
        return this.args.changeset.role === Role.user;
    }

    get adminMemberCount(){
        return this.args.teamMemberChangesets.filterBy('role', Role.admin).filterBy('pending', false).length;
    }

    get totalMemberCount(){
        return this.args.teamMemberChangesets.length;
    }

    get setNeedsAdminMember(){
        return this.totalMemberCount > 0 && this.adminMemberCount == 0;
    }

    @action
    onAdd(){
        this.args.onAdd();

        this.needsAdminMember = this.setNeedsAdminMember;
    }

    @action
    onDelete(teamMemberChangeset: BufferedChangeset){
        this.args.onDelete(teamMemberChangeset);

        this.needsAdminMember = this.setNeedsAdminMember;
    }

    @action
    onRoleChange(teamMemberChangeset: BufferedChangeset, event: any) {
        teamMemberChangeset.role = event;
        this.needsAdminMember = this.setNeedsAdminMember;
    }

    @action
    async onSave() {
        if (this.needsAdminMember || this.args.changeset.isInvalid) {
            this.args.changeset.set('showErrorOnPristine', true);
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        await this.args.onSave();
    }
}
