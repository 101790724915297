import { helper } from '@ember/component/helper';

export function querySelector(
    params: any[]
): ReturnType<Document['querySelector']> {
    if (params.length !== 1) {
        return null;
    }
    return document.querySelector(params[0]);
}

export default helper(querySelector);
