import Controller from '@ember/controller';
import GuidedAccountCreationSection,  { Section, Status } from 'portal/models/guided-account-creation-section';
import LegalEntityModel from 'portal/models/legal-entity';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'portal/utils/type-utils';
import {
    default as ProtectedAccountCreationFlowMyEntitiesRoute
} from 'portal/routes/protected/account-creation-flow/my-entities';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedAccountCreationFlowMyEntities extends Controller {
    declare model: ModelFrom<ProtectedAccountCreationFlowMyEntitiesRoute>;

    @service
    declare router: RouterService;

    @action
    async afterSave(): Promise<void> {
        await this.onComplete(Status.completed);
    }

    @action
    async cancel(): Promise<void> {
        await this.onComplete(Status.canceled);
    }

    @action
    onAddEntity(): void {
        this.router.transitionTo('protected.account-creation-flow.add-entity');
    }

    @action
    onEditEntity(legalEntity: LegalEntityModel): void {
        this.router.transitionTo(
            'protected.account-creation-flow.edit-entity',
            legalEntity
        );
    }

    async onComplete(status: Status): Promise<void> {
        const myEntitiesSection = this.model.guidedAccountCreationSections.findBy('section', Section.entity_details);

        if (myEntitiesSection) {
            myEntitiesSection.status = status;

            await myEntitiesSection.save();
        }

        const allSectionsFinished = this.model.guidedAccountCreationSections.every(
            (section: GuidedAccountCreationSection) => section.finished);

        if (allSectionsFinished) {
            this.router.transitionTo('protected.account-creation-flow.completed');
        } else {
            this.router.transitionTo('protected.account-creation-flow.home');
        }
    }

}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/account-creation-flow/my-entities': ProtectedAccountCreationFlowMyEntities;
    }
}
