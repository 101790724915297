import Deal from 'portal/models/deal';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { DealSides } from 'portal/services/deal-side';
import { oneLineAddress } from 'portal/models/basic-address';
import {
    validateFormat,
    validatePresence
} from 'ember-changeset-validations/validators';

export enum TitleholderType {
    individual = 'INDIVIDUAL',
    llc = 'LLC',
    trust = 'TRUST',
    cCorp = 'C_CORP',
    estate = 'ESTATE',
    llp = 'LLP'
}

export default class Titleholder extends Model {
    @attr('string')
        type?: TitleholderType;

    @attr('string')
        email?: string;

    @attr('string')
        phoneNumber?: string;

    @attr('string')
        street?: string;

    @attr('string')
        street2?: string;

    @attr('string')
        city?: string;

    @attr('string')
        state?: string;

    @attr('string')
        zipcode?: string;

    @attr('date')
        createdAt?: Date;

    @attr('date')
        updatedAt?: Date;

    @attr('string')
        taxId?: string;

    @attr('string')
        dealSide?: string;

    @belongsTo('deal', { inverse: 'titleholders' })
        deal?: AsyncBelongsTo<Deal>;

    get isCurrent() {
        return this.dealSide === DealSides.CURRENT;
    }

    get isFuture() {
        return this.dealSide === DealSides.FUTURE;
    }

    get oneLineTitleholderAddress(): string {
        return oneLineAddress({
            street: this.street,
            street2: this.street2,
            city: this.city,
            state: this.state,
            zipcode: this.zipcode
        });
    }
}

export const addressValidations = {
    street: validatePresence({
        presence: true,
        ignoreBlank: true,
        message: 'Address can’t be blank'
    }),
    city: validatePresence({
        presence: true
    }),
    state: [
        validatePresence({
            presence: true
        }),
        validateFormat({ regex: /^[A-Z]{2}$/ })
    ],
    zipcode: [
        validatePresence({
            presence: true,
            message: 'ZIP code can’t be blank'
        }),
        validateFormat({
            regex: /^[0-9]{5}(-[0-9]{4})?$/,
            message: 'ZIP code is invalid'
        })
    ]
};

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        titleholder: Titleholder;
    }
}
