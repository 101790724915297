import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedAccountCreationFlowEditEntity extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Edit Entity');
    }

    model({ legal_entity_id }: { legal_entity_id: string }) {
        return this.store.findRecord('legalEntity', legal_entity_id);
    }
}
