import AuthService from 'portal/services/auth';
import Component from '@glimmer/component';
import ENV from 'portal/config/environment';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { isBlank } from '@ember/utils';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class TopBar extends Component {
    @service
        router!: RouterService;

    @service
        auth!: AuthService;

    @tracked
        dropdownVisible = false;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    windowClickListener: (this: Window, e: MouseEvent) => any = () => {};
    uniqueId = `MyAccount-${guidFor(this)}`;

    get backgroundColor() {
        const deploymentName = ENV.DEPLOYMENT_NAME!;
        if (deploymentName.includes('local')) {
            return 'bg-red-700';
        }

        if (deploymentName.includes('staging')) {
            return 'bg-emerald-600';
        }

        return 'bg-onyx-5';
    }

    get imageUniqueId() {
        return `image-${this.uniqueId}`;
    }

    get shouldShowDealsLink() {
        return (
            this.router.currentRouteName !== 'protected.verify-email-address'
        );
    }

    get isMyAccountPage() {
        return this.router.currentRouteName?.startsWith('protected.my-account');
    }

    get isAllDeals() {
        return this.router.currentRouteName?.startsWith('protected.deals');
    }

    get isImpersonated() {
        return this.auth.isImpersonated();
    }

    get userName() {
        const user = this.auth.user;
        if (isBlank(user?.givenName) || isBlank(user?.familyName)) {
            return 'You are logged in as';
        } else {
            return `${user?.givenName} ${user?.familyName}`;
        }
    }

    @action
    toggle() {
        this.dropdownVisible = !this.dropdownVisible;
    }

    @action
    hide() {
        this.dropdownVisible = false;
    }

    @action
    logout() {
        this.auth.logout();
    }

    @action
    onAllDeals() {
        this.router.transitionTo('protected.deals');
    }

    @action
    onMyEntities() {
        this.router.transitionTo('protected.my-account.my-entities');
    }

    @action
    onCommunicationPreferences() {
        this.router.transitionTo('protected.my-account.communication-preferences');
    }

    @action
    onMyTitleholders() {
        this.router.transitionTo('protected.my-account.my-titleholders');
    }

    @action
    onMyTeam() {
        this.router.transitionTo('protected.my-account.team-overview');
    }

    @action
    onPersonalDetails() {
        this.router.transitionTo('protected.my-account.personal-details');
    }

    @action
    onEarnestTransactions() {
        this.router.transitionTo('protected.my-account.earnest-transactions');
    }

    @action
    onDidInsert() {
        this.windowClickListener = (e: MouseEvent) => {
            if (
                e.target &&
                !(e.target as HTMLElement).matches(`#${this.uniqueId}`) &&
                !(e.target as HTMLElement).matches(`#${this.imageUniqueId}`) &&
                !(e.target as HTMLElement).parentElement?.matches(
                    `#${this.uniqueId}`
                ) &&
                !(e.target as HTMLElement).parentElement?.matches(
                    `#${this.imageUniqueId}`
                )
            ) {
                this.hide();
            }
        };

        window.addEventListener('click', this.windowClickListener);
    }

    @action
    onWillDestroy() {
        window.removeEventListener('click', this.windowClickListener);
    }
}
