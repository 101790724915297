import Model, { attr } from '@ember-data/model';
import validateEmail from 'portal/validators/email';
import validatePhoneNumber from 'portal/validators/phone-number';
import { validatePresence } from 'ember-changeset-validations/validators';

export default class PowerOfAttorney extends Model {
    @attr('string')
        firstName?: string;

    @attr('string')
        lastName?: string;

    @attr('string')
        phoneNumber?: string;

    @attr('string')
        email?: string;
}

export const powerOfAttorneyValidations = {
    firstName: validatePresence({ presence: true, ignoreBlank: true }),
    lastName: validatePresence({ presence: true, ignoreBlank: true }),
    phoneNumber: [
        validatePhoneNumber('Phone number', { allowBlank: true })
    ],
    email: [
        validatePresence({ presence: true }),
        validateEmail('Email address')
    ]
};


// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'power-of-attorney': PowerOfAttorney;
  }
}
