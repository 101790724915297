import ActionItem, { ActionItemTemplateKeys } from 'portal/models/action-item';
import ArrayProxy from '@ember/array/proxy';
import Component from '@glimmer/component';
import CreateDealRequest, { CreateDealRequestType } from 'portal/models/create-deal-request';
import Deal from 'portal/models/deal';
import { CreateDealRequestConfirmationContext } from 'portal/models/create-deal-request-confirmation-context';

interface IntakeDealRequestConfirmationArgs {
    context: CreateDealRequestConfirmationContext;
    startOnboarding: (deal: Deal) => void;
}

export default class IntakeDealRequestConfirmation extends Component<IntakeDealRequestConfirmationArgs> {
    constructor(owner: unknown, args: IntakeDealRequestConfirmationArgs) {
        super(owner, args);

        if (this.args.context.createDealRequest?.requestType === CreateDealRequestType.buyer && this.onboardingAvailable) {
            this.args.startOnboarding(this.deal);
        }
    }

    get createDealRequest(): CreateDealRequest {
        return this.args.context.createDealRequest as CreateDealRequest;
    }

    get deal(): Deal {
        return this.args.context.deal as Deal;
    }

    get onboardingActivity(): ActionItem | undefined {
        if (!this.actionItems) return;

        if (this.isBuyer) {
            return this.actionItems.findBy('templateKey', ActionItemTemplateKeys.BUYER_TITLEHOLDER_AND_FINANCING_INFORMATION);
        }

        if (this.isSeller) {
            return this.actionItems.findBy('templateKey', ActionItemTemplateKeys.SELLER_TITLEHOLDER_DETAILS);
        }

        return;
    }

    get onboardingAvailable(): boolean {
        return !!this.onboardingActivity && !this.deal.representative!;
    }

    private get actionItems(): ArrayProxy<ActionItem> | undefined {
        return this.args.context.actionItems;
    }

    private get isBuyer(): boolean {
        return this.createDealRequest.requestType === CreateDealRequestType.buyer;
    }

    private get isSeller(): boolean {
        return this.createDealRequest.requestType === CreateDealRequestType.seller;
    }
}
