import Analytics from 'portal/services/analytics';
import Controller from '@ember/controller';
import DocumentModel, { DocumentTypes } from 'portal/models/document';
import EmporaDocumentUpload from 'portal/components/empora/document-upload/component';
import RouterService from '@ember/routing/router-service';
import { ActionItemTemplateKeys } from 'portal/models/action-item';
import { BufferedChangeset } from 'validated-changeset';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedProvideRentalInformationRoute } from 'portal/routes/protected/deal/provide-rental-information';
import { action } from '@ember/object';
import { rentalDocumentValidations } from 'portal/models/property';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';

export default class ProtectedProvideRentalInformation extends Controller {
    declare model: ModelFrom<ProtectedProvideRentalInformationRoute>;

    @service
    declare analytics: Analytics;

    @service
    declare router: RouterService;

    @tracked
        leaseDocuments?: Array<DocumentModel> = [];

    @tracked
        rentRollDocuments?: Array<DocumentModel> = [];

    @tracked
        showLeaseMissingError?: boolean = false;

    @tracked
        showRentRollMissingError?: boolean = false;

    @tracked
        showErrorBanner?: boolean = false;

    rentalDocumentValidations = rentalDocumentValidations;

    get disabledNext(): boolean {
        return (
            taskFor(this.saveLeaseDocument).isRunning ||
            taskFor(this.saveRentRollDocument).isRunning
        );
    }

    @action
    onDeleteLeaseDocument(document: DocumentModel) {
        this.leaseDocuments?.removeObject(document);
    }

    @action
    onDeleteRentRollDocument(document: DocumentModel) {
        this.rentRollDocuments?.removeObject(document);
    }

    @action
    async onLeaseDocumentUpload(document: DocumentModel, documentUpload: EmporaDocumentUpload) {
        try {
            await taskFor(this.saveLeaseDocument).perform(document);
        } catch (e) {
            return;
        } finally {
            documentUpload.clearDocument();
        }

        this.leaseDocuments?.pushObject(document);
        this.showLeaseMissingError = false;
        if (this.showErrorBanner) {
            this.showErrorBanner = this.rentRollDocuments?.length === 0;
        }
    }

    @action
    onLeaseDownload() {
        this.analytics.trackEvent('Provide rental information - Lease downloaded');
    }

    @action
    async onRentRollDocumentUpload(document: DocumentModel, documentUpload: EmporaDocumentUpload) {
        try {
            await taskFor(this.saveRentRollDocument).perform(document);
        } catch (e) {
            return;
        } finally {
            documentUpload.clearDocument();
        }

        this.rentRollDocuments?.pushObject(document);
        this.showRentRollMissingError = false;
        if (this.showErrorBanner) {
            this.showErrorBanner = this.leaseDocuments?.length === 0;
        }
    }

    @action
    onRentRollDownload() {
        this.analytics.trackEvent('Provide rental information - Rent roll downloaded');
    }

    @action
    async resetController(): Promise<void> {
        this.showErrorBanner = false;
        this.showLeaseMissingError = false;
        this.showRentRollMissingError = false;

        await this.model.property?.reload();

        const leaseDocuments = await this.model.property?.hasMany('leaseDocuments').reload();
        leaseDocuments.forEach((leaseDocument: DocumentModel) => leaseDocument.canDelete = false);
        this.leaseDocuments = leaseDocuments;

        const rentRollDocuments = await this.model.property?.hasMany('rentRollDocuments').reload();
        rentRollDocuments.forEach((leaseDocument: DocumentModel) => leaseDocument.canDelete = false);
        this.rentRollDocuments = rentRollDocuments;
    }

    @task
    async onSubmit(propertyChangeset: BufferedChangeset): Promise<void> {
        propertyChangeset.set('showErrorOnPristine', true);

        propertyChangeset.set('isRental', true);
        propertyChangeset.set('leaseDocuments', this.leaseDocuments);
        propertyChangeset.set('rentRollDocuments', this.rentRollDocuments);

        if (propertyChangeset?.isInvalid) {
            this.showErrorBanner = true;
            this.showLeaseMissingError = this.leaseDocuments?.length === 0;
            this.showRentRollMissingError = this.rentRollDocuments?.length === 0;
            window.scrollTo(0, 0);
            return;
        }

        await propertyChangeset.save();

        const actionItem = (await this.model.deal?.actionItems)?.find(
            (actionItem) => actionItem.templateKey === ActionItemTemplateKeys.PROVIDE_RENTAL_INFORMATION
        );

        if (actionItem) {
            actionItem.hasCompleted = true;
            await actionItem.save();
        }

        this.router.transitionTo('protected.deal.action-item-completion', actionItem?.id as string);
    }

    @task
    async saveLeaseDocument(doc: DocumentModel) {
        await this._saveDocument(doc, DocumentTypes.lease_document);
    }

    @task
    async saveRentRollDocument(doc: DocumentModel) {
        await this._saveDocument(doc, DocumentTypes.rent_roll_document);
    }

    // This function is wrapped by two different task definitions in order to render their errors separately.
    async _saveDocument(doc: DocumentModel, type: DocumentTypes) {
        doc.type = type;
        await doc.save();
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/deal/provide-rental-information': ProtectedProvideRentalInformation;
    }
}
