import Address from './address';
import Deal from './deal';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

export enum TaxAddressType {
    lender = 'Lender / Credit Line',
    forward = 'Forwarding Address',
    property = 'Property Address',
    other = 'Other'
}
export default class TaxAddress extends Model {
    @attr('string')
        street!: string;

    @attr('string')
        street2?: string | null;

    @attr('string')
        city!: string;

    @attr('string')
        state!: string;

    @attr('string')
        zipcode!: string;

    @attr('string')
        taxAddressType!: TaxAddressType;

    @belongsTo('deal')
    declare deal: AsyncBelongsTo<Deal>;

    @belongsTo('address')
    declare address: AsyncBelongsTo<Address>;
}

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        taxAddress: TaxAddress;
    }
}