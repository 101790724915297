import Deal from './deal';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

export enum ActionItemTemplateKeys {
    ASSIGNOR_EARNEST_MONEY_METHOD = 'assignor_earnest_money_method',
    ASSIGNOR_INFORMATION_AND_PAYMENT_INSTRUCTIONS = 'assignor_information_and_payment_instructions',
    BORROWER_ONBOARDING = 'borrower_onboarding',
    BUYER_CLOSING_PREFERENCES_AND_COVERAGES = 'buyer_closing_preferences_and_coverages',
    BUYER_CLOSING_REQUEST = 'buyer_closing_request',
    NON_SUBMITTER_CLOSING_REQUEST = 'non_submitter_closing_request',
    SELLER_CLOSING_REQUEST = 'seller_closing_request',
    SUBMITTER_CLOSING_REQUEST = 'submitter_closing_request',
    BUYER_EARNEST_MONEY_METHOD = 'buyer_earnest_money_method',
    BUYER_ONBOARDING = 'buyer_onboarding',
    BUYER_TITLEHOLDER_AND_FINANCING_INFORMATION = 'buyer_titleholder_and_financing_information',
    BUYER_WIRE_INSTRUCTIONS = 'buyer_wire_instructions',
    COMPLETE_TITLEHOLDER_INFORMATION = 'complete_titleholder_information',
    DOWNLOAD_EMPORA_WIRE_INSTRUCTIONS = 'download_empora_wire_instructions',
    PAYOFF_AUTHORIZATION = 'payoff_authorization',
    PROVIDE_RENTAL_INFORMATION = 'provide_rental_information',
    REVIEW_YOUR_TITLE_WORK = 'review_your_title_work',
    SELLER_CLOSING_PREFERENCES = 'seller_closing_preferences',
    SELLER_ONBOARDING = 'seller_onboarding',
    SELLER_PROPERTY_DETAILS = 'seller_property_details',
    SELLER_TITLEHOLDER_DETAILS = 'seller_titleholder_details',
    REVIEW_SETTLEMENT_STATEMENT = 'review_settlement_statement',
}

export enum ActionItemDescriptions {
    ASSIGNOR_EARNEST_MONEY_METHOD = 'Earnest money confirmation and collection for assignor',
    ASSIGNOR_INFORMATION_AND_PAYMENT_INSTRUCTIONS = 'Assignor information and payment instructions',
    BORROWER_AUTHORIZATION = 'Borrower authorization',
    BORROWER_ONBOARDING = 'Borrower onboarding',
    BUYER_CLOSING_PREFERENCES_AND_COVERAGES = 'Buyer closing preferences and coverages and stuff',
    BUYER_CLOSING_REQUEST = 'Schedule your closing',
    NON_SUBMITTER_CLOSING_REQUEST = 'Schedule your closing',
    SELLER_CLOSING_REQUEST = 'Schedule your closing',
    SUBMITTER_CLOSING_REQUEST = 'Schedule your closing',
    BUYER_EARNEST_MONEY_METHOD = 'Earnest money confirmation and collection for buyer',
    BUYER_ONBOARDING = 'Buyer onboarding',
    BUYER_TITLEHOLDER_AND_FINANCING_INFORMATION = 'Buyer titleholder and financing information',
    BUYER_WIRE_INSTRUCTIONS = 'Send buyer wire instructions',
    COMPLETE_TITLEHOLDER_INFORMATION = 'Complete titleholder information',
    PROVIDE_RENTAL_INFORMATION = 'Provide rental information',
    REVIEW_YOUR_TITLE_WORK = 'Review your title work',
    SELLER_AUTHORIZATION = 'Seller authorization',
    SELLER_CLOSING_PREFERENCES = 'Select your closing preferences',
    SELLER_ONBOARDING = 'Seller onboarding',
    SELLER_PROPERTY_DETAILS = 'Add your property and mortgage details',
    SELLER_TITLEHOLDER_DETAILS = 'Add seller titleholders',
    REVIEW_SETTLEMENT_STATEMENT = 'Review your settlement statement'
}

export default class ActionItem extends Model {
    @attr('string')
        description?: string;

    @attr('date')
        dueAt?: Date;

    @attr('date')
        startedAt?: Date;

    @attr('boolean')
        hasStarted?: boolean;

    @attr('date')
        completedAt?: Date;

    @attr('boolean')
        hasCompleted?: boolean;

    @attr('string')
        templateKey?: string;

    @belongsTo('deal')
        deal?: AsyncBelongsTo<Deal>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'action-item': ActionItem;
    }
}
