import ActionItem from 'portal/adapters/action-item';

export default class ClosingRequestActivity extends ActionItem {}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
    export default interface AdapterRegistry {
        'closing-request-activity': ClosingRequestActivity;
    }
}
