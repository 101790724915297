import ActionItem, { ActionItemDescriptions } from './action-item';
import PayoffAuthorization from './payoff-authorization';
import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

export default class BorrowerAuthorizationActivity extends ActionItem {
    @attr('string', { defaultValue: ActionItemDescriptions.BORROWER_AUTHORIZATION })
        name?: ActionItemDescriptions.BORROWER_AUTHORIZATION;

    @attr('string', { defaultValue: ActionItemDescriptions.BORROWER_AUTHORIZATION })
        description?: string;

    @belongsTo('payoff-authorization')
        payoffAuthorization?: AsyncBelongsTo<PayoffAuthorization>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'borrower-authorization-activity': BorrowerAuthorizationActivity;
    }
}
