import BaseRoute from 'portal/routes/base-route';
import EmporaServer from 'portal/services/empora-server';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/-private/transition';
import config from 'portal/config/environment';
import { inject as service } from '@ember/service';

export default class Surveys extends BaseRoute {
    @service
    declare router: RouterService;

    @service
    declare emporaServer: EmporaServer;

    async beforeModel(transition: Transition) {
        const { survey_type, deal_id } = transition.to.queryParams;

        const url = `/core/surveys?survey_type=${survey_type}&deal_id=${deal_id}`;

        const response = await this.emporaServer.fetchData(url);

        if (!response.ok) {
            this.router.replaceWith(config.rootURL);
        }
        else {
            const data = await response.json();
            this.navigateTo(data.survey_url);
        }
    }

    navigateTo(url: string) {
        window.location.assign(url);
    }
}
