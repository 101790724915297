import Component from '@glimmer/component';
import { BufferedChangeset } from 'ember-changeset/types';
import { action } from '@ember/object';

interface CreditLineArgs {
    changeset: BufferedChangeset;
    disabled?: boolean;
    onDelete: (creditLineChangeset: BufferedChangeset) => void;
}

export default class Lender extends Component<CreditLineArgs> {
    @action
    validate() {
        this.args.changeset.validate();
    }
}
