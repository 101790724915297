import Model, { AsyncBelongsTo, attr, belongsTo, hasMany } from '@ember-data/model';
import Team from './team';
import validateEmail from 'portal/validators/email';
import validateMarket from 'portal/validators/market';
import validatePhoneNumber from 'portal/validators/phone-number';
import validatePresenceWhen from 'portal/validators/presence-when';
import validatePresenceWhenFalse from 'portal/validators/presence-when-false';

import IntakeTeamMember from './intake-team-member';
import {
    validateFormat,
    validateInclusion,
    validateNumber,
    validatePresence
} from 'ember-changeset-validations/validators';

export enum UserRoleType {
    buyer = 'buyer',
    seller = 'seller',
    assignor = 'assignor',
    borrower = 'borrower',
    real_estate_agent = 'real_estate_agent',
    lender = 'lender',
    power_of_attorney = 'power_of_attorney'
}

export enum IntakeUserRequestSubtype {
    assignment = 'assignment',
    double_close = 'double_close',
    other = 'other',
    novation = 'novation'
}

export default class IntakeUser extends Model {

    @attr('string')
        street?: string;

    @attr('string')
        street2?: string;

    @attr('string')
        city?: string;

    @attr('string')
        state?: string;

    @attr('string')
        zipcode?: string;

    @attr('string')
        county?: string;

    @attr('string')
        userRole?: UserRoleType;

    @attr('string')
        firstName?: string;

    @attr('string')
        middleName?: string;

    @attr('string')
        lastName?: string;

    @attr('string')
        email?: string;

    @attr('string')
        phoneNumber?: string;

    @attr('string')
        phoneExtension?: string;

    @attr('string')
        companyName?: string;

    @attr('string')
        representingRole?: string;

    @attr('string')
        requestSubtype?: IntakeUserRequestSubtype;

    @belongsTo('team')
        team?: AsyncBelongsTo<Team>;

    @hasMany('intake-team-member')
        intakeTeamMembers?: IntakeTeamMember[];
}

export const intakeBuyerSellerValidations = {
    firstName: validatePresence({ presence: true }),
    lastName: validatePresence({ presence: true }),
    userRole: validatePresence({ presence: true, message: 'Deal role must be provided' }),
    email: [
        validateEmail('Email', { allowBlank: true }),
        validatePresenceWhenFalse({ presence: true, key: 'phoneNumber', message: 'Must provide an email or phone number' })
    ],
    phoneNumber: [
        validatePhoneNumber('Phone number', { allowBlank: true }),
        validatePresenceWhenFalse({ presence: true, key: 'email', message: 'Must provide a phone number or email' })
    ],
    phoneExtension: validateNumber({ allowBlank: true })
};

export const intakeUserValidations = Object.assign({}, intakeBuyerSellerValidations, {
    street: validatePresence({
        presence: true,
        message: 'Address can’t be blank'
    }),
    city: validatePresence({
        presence: true
    }),
    state: [
        validatePresence({
            presence: true
        }),
        validateFormat({ regex: /^[A-Z]{2}$/ }),
        validateMarket()
    ],
    zipcode: [
        validatePresence({
            presence: true,
            message: 'ZIP code can’t be blank'
        }),
        validateFormat({
            regex: /^[0-9]{5}(-[0-9]{4})?$/,
            message: 'ZIP code is invalid'
        })
    ],
    userRole: [
        validateInclusion({
            list: Object.values(UserRoleType),
            allowBlank: false
        })
    ],
    requestSubtype: [
        validatePresenceWhen({ presence: true, key: 'userRole', value: UserRoleType.assignor, message: 'Wholesale subtype can’t be blank' })
    ],
    representingRole: validateInclusion({ list: [UserRoleType.seller, UserRoleType.buyer], allowBlank: true })
});

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'intake-user': IntakeUser;
    }
}
