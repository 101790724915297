import AuthService from 'portal/services/auth';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class Login extends Controller {
    @service
        auth!: AuthService;

    @action
    login() {
        this.auth.login();
    }
}

declare module '@ember/controller' {
    interface Registry {
        login: Login;
    }
}
