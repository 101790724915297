import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface FeatureBannerArgs {
    bannerTitle: string;
    bannerText: string;
}

export default class FeatureBanner extends Component<FeatureBannerArgs> {
    @tracked
        bannerDismissed = false;

    @action
    closeBanner() {
        this.bannerDismissed = true;
    }
}
