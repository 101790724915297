import Component from '@glimmer/component';
import LegalEntityModel from 'portal/models/legal-entity';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

interface ProfileMyEntitiesArgs {
    legalEntities: LegalEntityModel[];
    onAddEntity?: () => void;
    onAfterSave?: () => Promise<void>;
    onCancel?: () => Promise<void>;
    onEditEntity?: (legalEntity: LegalEntityModel) => void;
}

export default class ProfileMyEntities extends Component<ProfileMyEntitiesArgs> {
    @service
        router!: RouterService;

    @action
    onAddEntity() {
        if (this.args.onAddEntity) {
            this.args.onAddEntity();
        } else {
            this.router.transitionTo('protected.my-account.add-entity');
        }
    }

    @action
    onEditEntity(legalEntity: LegalEntityModel) {
        if (this.args.onEditEntity) {
            this.args.onEditEntity(legalEntity);
        } else {
            this.router.transitionTo(
                'protected.my-account.edit-entity',
                legalEntity
            );
        }
    }

    get hasOnAfterSave(){
        return this.args.onAfterSave !== undefined;
    }

    get hasOnCancel(){
        return this.args.onCancel !== undefined;
    }
}
