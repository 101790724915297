/* eslint-disable ember/use-ember-data-rfc-395-imports */
import DS from 'ember-data';
import DocumentModel from './document';
import EntityTitleholderDesignatedSigner from './entity-titleholder-designated-signer';
import LegalEntityModel from './legal-entity';
import Titleholder from './titleholder';
import { AsyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';

export default class EntityTitleholder extends Titleholder {
    @attr('string')
        entityName?: string;

    @attr('string')
        paymentMethodPreference?: string;

    @belongsTo('legal-entity')
    declare existingLegalEntity:
        | DS.PromiseObject<LegalEntityModel>
        | LegalEntityModel
        | null;

    @hasMany('documents')
    declare entityDocuments:
        | DS.PromiseObject<DocumentModel>
        | DocumentModel[]
        | null;

    @hasMany('entity-titleholder-designated-signer')
        designatedSigners?: AsyncHasMany<EntityTitleholderDesignatedSigner>;

    get displayName(): string {
        return (this.existingLegalEntity as LegalEntityModel | undefined)?.get('entityName') || this.entityName || '';
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'entity-titleholder': EntityTitleholder;
    }
}
