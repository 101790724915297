import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { BufferedChangeset } from 'ember-changeset/types';
import { DealActorRoles } from 'portal/models/deal';
import { ModelFrom } from 'portal/utils/type-utils';
import {
    default as ProtectedDealOnboardingPersonalInformationRoute
} from 'portal/routes/protected/deal/onboarding/personal-information';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { userPersonalDetailsValidations } from 'portal/models/user';

export default class ProtectedDealOnboardingPersonalInformation extends Controller {
    declare model: ModelFrom<ProtectedDealOnboardingPersonalInformationRoute>;

    @service
    declare router: RouterService;

    validations = userPersonalDetailsValidations;

    @task
    async saveUser(changeset: BufferedChangeset): Promise<void> {
        if (changeset.isInvalid) {
            throw new Error(
                `User is invalid: ${JSON.stringify(changeset.errors)}`
            );
        }
        await changeset.save();
        const deal = await this.model?.deal;
        if (deal?.actorRole === DealActorRoles.SELLER) {
            this.router.transitionTo(
                'protected.deal.onboarding.property-information'
            );
        } else if (deal?.actorRole === DealActorRoles.BUYER) {
            this.router.transitionTo(
                'protected.deal.onboarding.lender-tax-address-info'
            );
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/deal/onboarding/personal-information': ProtectedDealOnboardingPersonalInformation;
    }
}
