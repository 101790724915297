import { isEmpty } from '@ember/utils';

export default function validateRoutingNumber(
    label: string,
    options: {
        allowBlank?: boolean
    } = {}
) {
    const FORMAT_REGEX = /^\d{9}$/;

    return (
        _key: string,
        newValue: unknown
    ): true | string => {
        if (options.allowBlank && isEmpty(newValue)) {
            return true;
        }

        if (!FORMAT_REGEX.test(newValue as string)) {
            return `${label} must be nine digits long`;
        }

        // See: https://en.wikipedia.org/wiki/ABA_routing_transit_number#Check_digit for details on this algorithm
        // This matches the server-side RoutingNumberValidator
        let total = 0;
        const digits = (newValue as string).split('').map(Number);

        for (let i = 0; i < digits.length; i += 3) {
            total += (digits[i] * 3) + (digits[i + 1] * 7) + digits[i + 2];
        }

        if (total === 0 || total % 10 !== 0) {
            return `${label} check digit is invalid`;
        }

        return true;
    };
}