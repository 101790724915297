import Controller from '@ember/controller';
import CreditLine, { IntendedFinancingTypes, creditLineValidations } from 'portal/models/credit-line';
import DealSide from 'portal/services/deal-side';
import EntityTitleholder from 'portal/models/entity-titleholder';
import IndividualTitleholder from 'portal/models/individual-titleholder';
import LegalEntity from 'portal/models/legal-entity';
import LegalIndividual from 'portal/models/legal-individual';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { ActionItemTemplateKeys } from 'portal/models/action-item';
import { BufferedChangeset, lookupValidator } from 'validated-changeset';
import { Changeset } from 'ember-changeset';
import { ModelFrom } from 'portal/utils/type-utils';
import {
    default as ProtectedBuyerTitleholderAndFinancingInformationRoute
} from 'portal/routes/protected/deal/buyer-titleholder-and-financing-information';
import { TitleholderType } from 'portal/models/titleholder';
import { action } from '@ember/object';
import { isNone } from '@ember/utils';
import { notifyPropertyChange } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { userValidations } from 'portal/models/user';

const ACTION_ITEM_VERSION = 12;

export default class ProtectedBuyerTitleholderAndFinancingInformation extends Controller {
    declare model: ModelFrom<ProtectedBuyerTitleholderAndFinancingInformationRoute>;

    @service
    declare router: RouterService;

    @service
    declare store: Store;

    @service
    declare dealSide: DealSide;

    @tracked
    declare addingTitleholder: boolean;

    @tracked
    declare editingTitleholder: IndividualTitleholder | EntityTitleholder | undefined;

    @tracked
    declare hasCreditLines: boolean;

    @tracked
    declare selectingTitleholder: boolean;

    @tracked
        availableLegalEntities = this.model.legalEntities as unknown as LegalEntity[];

    @tracked
        availableLegalIndividuals = this.model.legalIndividuals as unknown as LegalIndividual[];

    @tracked
        currentFinancingType = '';

    @tracked
        financingOptionSelected?: boolean;

    @tracked
        requestedMlsSurvey?: boolean;

    @tracked
        showErrorBanner?: boolean = false;

    @tracked
        showErrorOnPristine?: boolean = false;

    @tracked
        titleholderKnown?: boolean;

    userValidations = userValidations;

    private creditLineChangesetCache = new WeakMap<CreditLine, BufferedChangeset>();

    get creditLinesChangesets(): BufferedChangeset[] {
        return this.nonDeletedCreditLines.toArray().map((creditLine) => {
            let changeset = this.creditLineChangesetCache.get(creditLine);
            if (!changeset) {
                changeset = Changeset(creditLine,
                    lookupValidator(creditLineValidations),
                    creditLineValidations,
                    {
                        changesetKeys: Object.keys(CreditLine.attributes)
                    }
                );
                // eslint-disable-next-line ember/no-side-effects
                this.creditLineChangesetCache.set(creditLine, changeset);
            }
            return changeset;
        });
    }

    get nonDeletedCreditLines() {
        return this.model.creditLines?.filterBy('isDeleted', false) || [];
    }

    get performingTitleholderActions(): boolean {
        return this.addingTitleholder || this.selectingTitleholder || !isNone(this.editingTitleholder);
    }

    get titleholders() {
        return this.model.titleholders;
    }

    get showRequestedMlsSurvey(): boolean {
        return  this.model.requestedMlsSurveyApplies &&
                this.currentFinancingType != '' &&
                this.currentFinancingType != IntendedFinancingTypes.CONVENTIONAL &&
                (this.model.deal.actionItemVersion ?? 0) >= ACTION_ITEM_VERSION;
    }

    get showCommunicationPreferences(): boolean {
        return this.model.user.hasEmptyCommPreferences;
    }

    @action
    requestedMlsSurveyOnChange(value: boolean) {
        this.requestedMlsSurvey = value;

        for (const creditLine of this.nonDeletedCreditLines) {
            creditLine.requestedMlsSurvey = this.requestedMlsSurvey;
        }
    }

    @action
    creditLinesOnAddAnotherLender() {
        this.addCreditLine(this.currentFinancingType);
    }

    @action
    creditLinesOnChange(intendedFinancingType: string) {
        this.financingOptionSelected = true;
        this.hasCreditLines = intendedFinancingType !== IntendedFinancingTypes.CASH;

        if (this.hasCreditLines) {
            if (intendedFinancingType !== this.currentFinancingType) {
                if (intendedFinancingType === IntendedFinancingTypes.CONVENTIONAL) {
                    this.requestedMlsSurvey = undefined;

                    for (const creditLine of this.nonDeletedCreditLines) {
                        creditLine.requestedMlsSurvey = undefined;
                    }
                }

                if (this.currentFinancingType === IntendedFinancingTypes.CASH) {
                    this.deleteAllCreditLines();
                }

                if (this.nonDeletedCreditLines.length === 0) {
                    this.addCreditLine(intendedFinancingType);
                }

                if (this.nonDeletedCreditLines.length > 0) {
                    for (const creditLine of this.nonDeletedCreditLines) {
                        creditLine.intendedFinancingType = intendedFinancingType as IntendedFinancingTypes;
                    }
                }
            }
        } else {
            this.deleteAllCreditLines();
        }

        if (intendedFinancingType === IntendedFinancingTypes.CASH) {
            this.addCreditLine(intendedFinancingType);
        }

        this.currentFinancingType = intendedFinancingType;
    }

    @action
    creditLineOnDelete(creditLineChangeset: BufferedChangeset) {
        const creditLine = creditLineChangeset.data as CreditLine;
        this.creditLineChangesetCache.delete(creditLine);
        creditLine.deleteRecord();
    }

    @action
    removeAlreadyAddedTitleholder(titleholder: IndividualTitleholder | EntityTitleholder) {
        if (titleholder.type == TitleholderType.individual) {
            this.availableLegalIndividuals = this.availableLegalIndividuals.filter(
                (element) => element.email !== (titleholder as any).email
            );
        } else {
            this.availableLegalEntities = this.availableLegalEntities.filter(
                (element) => element.email != (titleholder as any).email
            );
        }
    }

    @action
    titleholderKnownOnChange(value: boolean) {
        this.titleholderKnown = value;
    }

    @action
    async addTitleholder(): Promise<void> {
        const legalEntities = this.model.legalEntities;
        const legalIndividuals = this.model.legalIndividuals;
        const totalAvailableTitleholders = (legalEntities.length as number) + (legalIndividuals.length as number);

        this.addingTitleholder = totalAvailableTitleholders == 0;
        this.selectingTitleholder = totalAvailableTitleholders > 0;
        window.scrollTo(0, 0);
    }

    @action
    async editTitleholder(titleholder: IndividualTitleholder | EntityTitleholder): Promise<void> {
        this.editingTitleholder = titleholder;
        window.scrollTo(0, 0);
    }

    @action
    async onCancel(): Promise<void> {
        this.addingTitleholder = false;
        this.editingTitleholder = undefined;
        this.selectingTitleholder = false;
        window.scrollTo(0, 0);
    }

    @task
    async onSubmit(userChangeset: BufferedChangeset): Promise<void> {
        this.showErrorOnPristine = true;
        this.creditLinesChangesets?.forEach((changeset) => {
            changeset.set('showErrorOnPristine', true);
        });
        userChangeset.set('showErrorOnPristine', true);

        if (isNone(this.hasCreditLines)) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        if (this.creditLinesChangesets?.any((creditlineChangeset) => creditlineChangeset.isInvalid)) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        if (isNone(this.titleholderKnown)) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        if (this.titleholderKnown && this.titleholders.length == 0) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        if (userChangeset.isInvalid) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        if (isNone(this.financingOptionSelected)) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        await userChangeset.save();

        this.creditLinesChangesets.map((creditLineChangeset) =>
            creditLineChangeset.execute()
        );

        const creditLines = this.model.creditLines;
        if (creditLines) {
            await Promise.all(
                creditLines.map((creditLine) => creditLine.save())
            );
        }

        const titleholdersToSave = this.titleholders.filter(
            (th) => th.hasDirtyAttributes || th.isDeleted
        ) as Array<IndividualTitleholder | EntityTitleholder>;

        for (const titleholderToSave of titleholdersToSave) {
            if (titleholderToSave instanceof IndividualTitleholder) {
                await titleholderToSave.save();
            } else {
                const savedEntityTitleholder = await titleholderToSave.save() as EntityTitleholder;
                const designatedSignersToSave = savedEntityTitleholder.designatedSigners?.toArray();
                if (designatedSignersToSave) {
                    await Promise.all(
                        designatedSignersToSave.map((designatedSignerToSave) => {
                            designatedSignerToSave.entityTitleholderId = savedEntityTitleholder.id;
                            designatedSignerToSave.save();
                        })
                    );
                }
            }
        }

        const actionItem = (await this.model.deal?.actionItems)?.find(
            (actionItem) => actionItem.templateKey === ActionItemTemplateKeys.BUYER_TITLEHOLDER_AND_FINANCING_INFORMATION
        );

        if (actionItem) {
            actionItem.hasCompleted = true;
            actionItem.save();
        }

        this.router.transitionTo('protected.deal.action-item-completion', actionItem?.id as string);
    }

    @action
    async removeTitleholder(titleholder: IndividualTitleholder | EntityTitleholder): Promise<void> {
        titleholder.deleteRecord();
        this.titleholders.removeObject(titleholder);

        if (titleholder.type == TitleholderType.individual) {
            const legalIndividualToMakeAvailable = this.model.legalIndividuals.toArray().find(
                (legalIndividual) => legalIndividual.email === (titleholder as any).email
            );
            this.availableLegalIndividuals.push(legalIndividualToMakeAvailable);
        } else {
            const legalEntityToMakeAvailable = this.model.legalEntities.toArray().find(
                (legalEntity) => legalEntity.email === (titleholder as any).email
            );
            this.availableLegalEntities.push(legalEntityToMakeAvailable);
            this.availableLegalEntities = this.availableLegalEntities.sortBy('entityName');
        }
    }

    @action
    async resetController(): Promise<void> {
        this.availableLegalEntities = Array.from(this.model.legalEntities.toArray()).sortBy('entityName');
        this.availableLegalIndividuals = Array.from(this.model.legalIndividuals.toArray());
        this.titleholderKnown = undefined;
        this.showErrorOnPristine = false;
        this.showErrorBanner = false;
        this.financingOptionSelected = undefined;
        this.currentFinancingType = '';
        this.hasCreditLines = false;
        this.requestedMlsSurvey = undefined;

        await this.onCancel();
    }

    @action
    async titleholderAdded(titleholder: IndividualTitleholder | EntityTitleholder): Promise<void> {
        this.removeAlreadyAddedTitleholder(titleholder);

        this.model.titleholders.addObject(titleholder);
        this.editingTitleholder = undefined;
        this.addingTitleholder = false;
        this.selectingTitleholder = false;
        window.scrollTo(0, 0);
    }

    @action
    async titleholderEdited(): Promise<void> {
        this.editingTitleholder = undefined;
        window.scrollTo(0, 0);
    }

    @action
    async titleholdersAdded(titleholders: (IndividualTitleholder | EntityTitleholder)[]): Promise<void> {
        for (const titleholder of titleholders) {
            this.removeAlreadyAddedTitleholder(titleholder);

            if (!this.model.titleholders.includes(titleholder)) {
                this.model.titleholders.addObject(titleholder);
            }
        }
        this.editingTitleholder = undefined;
        this.addingTitleholder = false;
        this.selectingTitleholder = false;
        window.scrollTo(0, 0);
    }

    addCreditLine(intendedFinancingType: string) {
        this.model.creditLines?.addObject(
            this.store.createRecord('credit-line', {
                accountName: intendedFinancingType == IntendedFinancingTypes.CASH ? IntendedFinancingTypes.CASH : null,
                intendedFinancingType: intendedFinancingType,
                requestedMlsSurvey: this.requestedMlsSurvey,
                dealSide: this.dealSide.for(this.model.deal.id),
                phoneNumber: intendedFinancingType == IntendedFinancingTypes.CASH ? '6146605503' : null,
                property: this.model.property
            })
        );
        notifyPropertyChange(this, 'creditLinesChangesets');
    }

    deleteAllCreditLines() {
        this.model.creditLines
            ?.toArray()
            .forEach((creditLine: CreditLine) => {
                this.creditLineChangesetCache.delete(creditLine);
                creditLine.deleteRecord();
            });
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/deal/buyer-titleholder-and-financing-information': ProtectedBuyerTitleholderAndFinancingInformation;
    }
}
