import DocumentModel from 'portal/models/document';
import FreestyleController from 'ember-freestyle/controllers/freestyle';
import Service, { inject as service } from '@ember/service';
import config from 'portal/config/environment';
import validatePhoneNumber from 'portal/validators/phone-number';
import { BufferedChangeset } from 'validated-changeset';
import { DateTime } from 'luxon';
import { EmporaDropdownOptions } from 'portal/components/empora/dropdown/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import {
    validateDate,
    validateFormat,
    validatePresence
} from 'ember-changeset-validations/validators';

class Person {
    @tracked
        firstName = 'Asuka';

    @tracked
        lastName = 'Langley';

    @tracked
        email = 'asuka@nerv.gov';

    @tracked
        phone = '(111) 111-1111';

    @tracked
        password = 'n0_p33k1nG';

    @tracked
        placeholder = '';
}

class Address {
    @tracked
        street = '1 One St.';

    @tracked
        street2 = '';

    @tracked
        city = 'Unoville';

    @tracked
        state = 'DE';

    @tracked
        zipcode = '11111';
}

export default class extends FreestyleController {
    @service
        emberFreestyle!: Service;

    /* BEGIN-FREESTYLE-USAGE fp--notes
    ### A few notes regarding freestyle-palette

    - Accepts a colorPalette POJO like the one found in the freestyle.js blueprint controller
    - Looks very nice

    And another thing...

    ###### Markdown note demonstrating prettified code

    ```
    import Ember from 'ember';

    export default Ember.Component.extend({
        // ...
        colorPalette: {
        'primary': {
            'name': 'cyan',
            'base': '#00bcd4'
        },
        'accent': {
            'name': 'amber',
            'base': '#ffc107'
        }
        }
        // ...
    });
    ```
    END-FREESTYLE-USAGE */

    get colorPalette() {
        const { colors } = config;
        return Object.keys(colors).reduce(
            (carry: { [key: string]: any }, colorName: string) => {
                const weights = colors[colorName];
                Object.keys(weights).forEach((weight: string) => {
                    carry[`${colorName}-${weight}`] = {
                        name: `${colorName}-${weight}`,
                        base: colors[colorName][weight as unknown as number]
                    };
                });
                return carry;
            },
            {}
        );
    }

    // Text
    textPerson = new Person();

    // Date
    dateAttrs = {
        dateValue: undefined
    };
    dateValidations = {
        dateValue: [validatePresence({ presence: true }), validateDate({})]
    };
    @action
    async validateChangeset(changeset: BufferedChangeset) {
        await changeset.validate();
    }

    // Button
    @action
    onButtonClicked() {
        alert('Thanks! :D');
    }

    // Radio
    @tracked
        radioValue: any = 'yes';

    @action
    onRadioChange(value: any) {
        this.radioValue = value;
    }

    // Checkbox
    @tracked
        checkboxValue = false;

    @action
    onCheckboxChange(value: boolean) {
        this.checkboxValue = value;
    }

    // Date Picker
    @tracked
        selectedDate?: DateTime;

    @action
    onDateSelected(date: DateTime) {
        this.selectedDate = date;
    }

    // Modal
    @tracked
        showModal = false;

    @action
    openModal() {
        this.showModal = true;
    }

    @action
    closeModal() {
        this.showModal = false;
    }

    // Dropdown
    dropdownOptions: EmporaDropdownOptions = [
        { value: 'option_1', name: 'Option 1' },
        { value: 'option_2', name: 'Option 2' },
        { value: 'option_3', name: 'Option 3' },
        { value: 'option_4', name: 'Option 4' },
        { value: 'option_5', name: 'Option 5, which has a very long name that runs underneath the chevron' }
    ];

    @tracked
        dropdownValue: any = '';

    @action
    onDropdownChange(value: any) {
        this.dropdownValue = value;
    }

    // Document Upload
    @tracked
        document?: DocumentModel;

    @action
    onDocumentUpload(doc: DocumentModel) {
        this.document = doc;
    }

    // Address
    @tracked
        address = new Address();

    // Phone number
    contactAttrs = {
        phoneNumber: ''
    };

    addressValidations = {
        street: validatePresence({
            presence: true,
            ignoreBlank: true,
            message: 'Address can’t be blank'
        }),
        city: validatePresence({
            presence: true
        }),
        state: [
            validatePresence({
                presence: true
            }),
            validateFormat({ regex: /^[A-Z]{2}$/ })
        ],
        zipcode: [
            validatePresence({
                presence: true,
                message: 'ZIP code can’t be blank'
            }),
            validateFormat({
                regex: /^[0-9]{5}(-[0-9]{4})?$/,
                message: 'ZIP code is invalid'
            })
        ]
    };

    phoneNumberValidations = {
        phoneNumber: validatePhoneNumber('Phone number', { allowBlank:true })
    };
}
