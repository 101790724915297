import Component from '@glimmer/component';
import DocumentModel from 'portal/models/document';
import ReissueRate from 'portal/models/reissue-rate';
import { action } from '@ember/object';

interface DealReissueRateArgs {
    reissueRate: ReissueRate
}

export default class DealReissueRate extends Component<DealReissueRateArgs> {
    @action
    async onDocumentUpload(doc: DocumentModel): Promise<void> {
        const currentDocument = await this.args.reissueRate.get('currentOwnersPolicyDocument');

        if (this.args.reissueRate.get('isNew') && currentDocument) {
            await currentDocument.destroyRecord();
        }

        this.args.reissueRate.set('currentOwnersPolicyDocument', doc);
    }
}
