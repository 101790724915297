/* eslint-disable ember/use-ember-data-rfc-395-imports */
import ApplicationAdapter from './application';

export default class Titleholder extends ApplicationAdapter {}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
    export default interface AdapterRegistry {
        titleholder: Titleholder;
    }
}
