import Model, {
    AsyncBelongsTo,
    attr,
    belongsTo
} from '@ember-data/model';
import Team from './team';
import { validateInclusion } from 'ember-changeset-validations/validators';
export default class TeamInvite extends Model {
    @attr('boolean', { allowNull: true })
        accepted?: boolean;

    @belongsTo('team')
        team?: AsyncBelongsTo<Team>;

}

export const teamInviteValidations = {
    accepted: validateInclusion({ list: [true, false] })
};

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        teamInvite: TeamInvite;
    }
}
