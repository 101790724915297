import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import DealSide from 'portal/services/deal-side';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedDealOnboardingRoute } from '../onboarding';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealOnboardingTitleholderPreference extends BaseRoute {
    @service
    declare router: RouterService;

    @service
    declare dealSide: DealSide;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Onboarding / Titleholder Preference');
    }

    async model() {
        const parentModel = this.modelFor(
            'protected.deal.onboarding'
        ) as ModelFrom<ProtectedDealOnboardingRoute>;
        return parentModel?.deal;
    }

    async afterModel(
        model: ModelFrom<ProtectedDealOnboardingTitleholderPreference>
    ) {
        const titleholder = await model?.latestTitleholder(this.dealSide.for(model.id));
        if (titleholder && !titleholder.isNew) {
            this.router.replaceWith(
                'protected.deal.onboarding.welcome'
            );
        }
    }
}
