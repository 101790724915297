import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Deal from 'portal/models/deal';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealClosingRequestCompletion extends BaseRoute {
    @service
    declare router: RouterService;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Closing Request Completion');
    }

    async model() {
        const deal = this.modelFor('protected.deal') as Deal;
        await deal.reload();
        return {
            deal
        };
    }
}
