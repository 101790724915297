import { DateTime } from 'luxon';
import { helper } from '@ember/component/helper';

export default helper(function (
    params: [DateTime, DateTime, DateTime, number]
) {

    const [rangeStart, rangeEnd, baseDate, selectedDay] = params;

    if (rangeStart && rangeEnd) {
        const compareDate = baseDate.set({ day: selectedDay });

        return (
            compareDate < rangeStart ||
            compareDate > rangeEnd
        );
    }

    return false;
});
