import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';



interface AddTeamOrUserDialogArgs {
    onNext: (selection: string) => void;
    onCancel: () => void;
}

export default class AddTeamOrUserDialog extends Component<AddTeamOrUserDialogArgs> {
    @tracked
        selection?: undefined | string;

    get isValid(){
        return this.selection != undefined;
    }

    @action
    setSelection(value: string){
        this.selection = value;
    }

}
