import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedMyAccountAddEntity extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Add Entity');
    }

    @action
    willTransition() {
        this.modelFor('protected.my-account.add-entity').rollbackAttributes();
    }

    async model() {
        return await this.store.createRecord('legalEntity', {
            wireInstruction: this.store.createRecord('legalEntityWireInstruction')
        });
    }
}
