import Component from '@glimmer/component';
import ENV from 'portal/config/environment';

export default class AccountCreationFlowTopBar extends Component {
    get backgroundColor() {
        const deploymentName = ENV.DEPLOYMENT_NAME;
        if (deploymentName.includes('local')) {
            return 'bg-red-700';
        }

        if (deploymentName.includes('staging')) {
            return 'bg-emerald-600';
        }

        return 'bg-onyx-5';
    }
}
