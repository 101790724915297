import Analytics from 'portal/services/analytics';
import Component from '@glimmer/component';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AccountCreationFlowCompleted extends Component {
    @service
    declare analytics: Analytics;

    @service
    declare router: RouterService;

    @action
    async onClick() {
        this.analytics.trackEvent('Account Creation Flow / Selected Submit a new deal');
        this.router.transitionTo('protected.deals');
    }
}
