import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedMyAccountLegalEntities extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed My titleholders');
    }

    async model() {
        this.store.unloadAll('legal-individual');
        return await this.store.findAll('legal-individual', {
            reload: true
        });
    }
}
