import { isEmpty } from '@ember/utils';

export default function validatePhoneNumber(label: string, options: { allowBlank?: boolean, when?: string; } = {}) {
    return (
        _key: string,
        newValue: unknown,
        _oldValue: unknown,
        changes: unknown,
        content: object
    ): true | string => {
        if (options.allowBlank && isEmpty(newValue)) {
            return true;
        }

        const when = options.when;
        if (!isEmpty(when)) {
            let whenValue = undefined;

            if (changes != undefined) {
                whenValue = (changes as { [key: string]: any })[when as string];
            }

            if (whenValue == undefined) {
                whenValue = (content as { [key: string]: any })[when as string];
            }

            if (!whenValue) {
                return true;
            }
        }

        if (/^[2-9]\d{2}[2-9]\d{6}$/.test(newValue as string)) {
            return true;
        } else {
            return `The ${label.toLowerCase()} entered must be a valid 10 digit phone number with no special characters`;
        }
    };
}
