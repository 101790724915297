import ApplicationSerializer from './application';

export default class IntakeUserSerializer extends ApplicationSerializer {
    attrs = {
        intakeTeamMembers: { embedded: 'always' }
    };
}

declare module 'ember-data/types/registries/serializer' {
    export default interface SerializerRegistry {
        intakeUser: IntakeUserSerializer;
    }
}
