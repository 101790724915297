import Deal from 'portal/models/deal';
import DwollaFundingSource from 'portal/models/dwolla-funding-source';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

export default class DwollaTransfer extends Model {
    @attr('number')
        amount!: number;

    @attr('string')
        status?: string;

    @attr('string')
        note?: string;

    @attr('date')
        transferDate?: Date;

    @attr('string')
        url?: string;

    @belongsTo('dwolla-funding-source')
        dwollaFundingSource!: AsyncBelongsTo<DwollaFundingSource>;

    @belongsTo('deal')
        deal!: AsyncBelongsTo<Deal>;

}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'dwolla-transfer': DwollaTransfer;
  }
}
