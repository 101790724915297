import Controller from '@ember/controller';
import DwollaFundingSource from 'portal/models/dwolla-funding-source';
import ProtectedDealEarnestMoneyFundingSourceSelectionRoute from 'portal/routes/protected/deal/earnest-money/funding-source-selection';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ProtectedDealEarnestMoneyFundingSourceSelection extends Controller {
    declare model: ModelFrom<ProtectedDealEarnestMoneyFundingSourceSelectionRoute>;

    @service
    declare router: RouterService;

    @tracked
    declare dwollaFundingSource?: DwollaFundingSource | boolean;

    @action
    onSelect(dwollaFundingSource: DwollaFundingSource) {
        this.dwollaFundingSource = dwollaFundingSource;
    }

    @action
    async onNext(): Promise<void> {
        const dwollaFundingSource = this.dwollaFundingSource!;
        if (dwollaFundingSource instanceof DwollaFundingSource) {
            this.router.transitionTo('protected.deal.earnest-money.portal-transfer', dwollaFundingSource.id);
        }  else {
            this.router.transitionTo('protected.deal.earnest-money.funding-source-creation');
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'protected/deal/earnest-money/funding-source-selection': ProtectedDealEarnestMoneyFundingSourceSelection;
  }
}
