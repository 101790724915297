import ActionCable from 'actioncable';
import ENV from 'portal/config/environment';
import Service from '@ember/service';

export default class WebSocketService extends Service {
    constructor(args: any) {
        super(args);

        this.cable = ActionCable.createConsumer(ENV.ACTION_CABLE_URL);
    }

    cable: ActionCable.Cable;

    createSubscription(channelName: string, received: (params: any) => void, channelNameParams?: Record<string, unknown>): void {
        let channelOptions = {
            channel: channelName
        };

        if (channelNameParams) {
            channelOptions = { ...channelOptions, ...channelNameParams };
        }

        this.cable.subscriptions.create(channelOptions, {
            connected: () => {
                console.log(`Connected to ${channelName}`);
            },

            disconnected: () => {
                console.log(`Disconnected from ${channelName}`);
            },

            rejected: () => {
                throw new Error(`Rejected connection to ${channelName}`);
            },

            received: received
        });
    }

    disconnect() {
        this.cable.disconnect();
    }
}

declare module '@ember/service' {
    interface Registry {
        webSocket: WebSocketService;
    }
}
