import Analytics from 'portal/services/analytics';
import Controller from '@ember/controller';
import ProtectedAccountCreationFlowUserTypeRoute from 'portal/routes/protected/account-creation-flow/user-type';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'portal/utils/type-utils';
import { Type } from 'portal/models/user';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedAccountCreationFlowUserType extends Controller {
    declare model: ModelFrom<ProtectedAccountCreationFlowUserTypeRoute>;
    @service
    declare analytics: Analytics;

    @service
    declare router: RouterService;

    @action
    async onClick(type: Type ) {
        this.model.type = type;
        this.model.save();

        this.analytics.trackEvent('Account Creation Flow / Selected User Type');
        if (type === Type.INVESTOR) {
            this.router.transitionTo('protected.account-creation-flow.home');
        }
        else {
            this.router.transitionTo('protected.deals');
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/account-creation-flow/user-type': ProtectedAccountCreationFlowUserType;
    }
}
