import Component from '@glimmer/component';
import { DateTime } from 'luxon';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface ClosingRequestSelectDateModalArgs {
    onDateSelected: (selectedDate: DateTime, allDaySelected: boolean) => void;
    onClose: () => void;
    allDayAllowed: boolean;
    allDaySelected: boolean;
    selectedDate: DateTime;
    rangeStart: DateTime;
    rangeEnd: DateTime;
}

export default class ClosingRequestSelectDateModal extends Component<ClosingRequestSelectDateModalArgs> {
    @tracked
        selectedDate: DateTime = this.args.selectedDate;

    @tracked
        disabledSelect = true;

    @tracked
        allDaySelected: boolean = this.args.allDaySelected;

    @tracked
        after2PMOnEndOfRange: boolean;

    constructor(owner: unknown, args: ClosingRequestSelectDateModalArgs) {
        super(owner, args);
        this.after2PMOnEndOfRange = this.selectedDateTimeOverThreshold();
    }

    selectedDateTimeOverThreshold(): boolean {
        if (!this.selectedDate) { return false; }

        const thresholdInET = DateTime.fromObject(
            {
                month: this.args.rangeEnd.month,
                day: this.args.rangeEnd.day,
                year: this.args.rangeEnd.year,
                hour: 14
            },
            { zone: 'America/New_York' }
        );
        return this.selectedDate >= thresholdInET;
    }

    @action
    onDateChosen(selectedDate: DateTime) {
        this.disabledSelect = false;
        this.selectedDate = selectedDate;
        this.after2PMOnEndOfRange = this.selectedDateTimeOverThreshold();
    }

    @action
    allDayClicked() {
        this.allDaySelected = !this.allDaySelected;
    }

    @action
    onSelect() {
        if (!this.selectedDate) { return; }

        this.args.onDateSelected(this.selectedDate, this.allDaySelected);
    }
}
