import Model, { attr } from '@ember-data/model';
import { validatePresence } from 'ember-changeset-validations/validators';

export default class Referral extends Model {
    @attr('boolean')
        referable!: boolean;

    @attr('string')
        referrerFirstName?: string;

    @attr('string')
        referrerLastName?: string;
}

export const referralValidations = Object.assign (
    {},
    {
        referrerFirstName: validatePresence({ presence: true, ignoreBlank: true, message: 'This field can\'t be blank.' }),
        referrerLastName: validatePresence({ presence: true, ignoreBlank: true, message: 'This field can\'t be blank.' })
    }
);

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        referral: Referral;
    }
}
