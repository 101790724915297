import { isEmpty } from '@ember/utils';

export default function validateEmail(
    label: string,
    options: { on?: string; allowBlank?: boolean, when?: string; } = {}
) {
    // This constant matches the regex used in Rails server to validate email addresses
    const EMAIL_VALIDATOR_REGEX =
        // eslint-disable-next-line max-len
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

    return (
        _key: string,
        newValue: unknown,
        _oldValue: unknown,
        changes: unknown,
        content: object
    ): true | string => {
        if (options.allowBlank && isEmpty(newValue)) {
            return true;
        }
        if (
            !isEmpty(options.on) &&
            isEmpty((content as { [key: string]: any })[options.on as string])
        ) {
            return true;
        }

        const when = options.when;
        if (!isEmpty(when)) {
            let whenValue = undefined;

            if (changes != undefined) {
                whenValue = (changes as { [key: string]: any })[when as string];
            }

            if (whenValue == undefined) {
                whenValue = (content as { [key: string]: any })[when as string];
            }

            if (!whenValue) {
                return true;
            }
        }

        if (
            !isEmpty(newValue) &&
            EMAIL_VALIDATOR_REGEX.test(newValue as string)
        ) {
            return true;
        } else {
            return `${label} must contain a valid email address`;
        }
    };
}
