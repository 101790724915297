import Controller from '@ember/controller';
import LegalEntityModel from 'portal/models/legal-entity';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealTitleholderInformationSelectEntity extends Controller {
    @service
    declare router: RouterService;

    @action
    onNext() {
        this.router.transitionTo('protected.deal.titleholder-information.confirm-entity-details');
    }

    @action
    onEdit(legalEntity: LegalEntityModel) {
        this.router.transitionTo('protected.deal.titleholder-information.edit-entity', legalEntity.id);
    }
}
