import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { BufferedChangeset } from 'validated-changeset';
import { DealActorRoles } from 'portal/models/deal';
import { ModelFrom } from 'portal/utils/type-utils';
import {
    default as ProtectedDealOnboardingCpcNotaryIntermediaryRoute
} from 'portal/routes/protected/deal/onboarding/cpc-notary-intermediary';
import {
    dealBuyerValidations,
    dealSellerValidations
} from 'portal/models/deal';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class ProtectedDealOnboardingCpcNotaryIntermediary extends Controller {
    declare model: ModelFrom<ProtectedDealOnboardingCpcNotaryIntermediaryRoute>;

    @service
    declare router: RouterService;

    get validations(): any {
        let validations;

        if (this.model?.deal?.actorRole === DealActorRoles.SELLER) {
            validations = dealSellerValidations;
        } else {
            validations = dealBuyerValidations;
        }

        return validations;
    }

    @task
    async save(dealChangeset: BufferedChangeset, userChangeset: BufferedChangeset): Promise<void> {
        if (dealChangeset.isInvalid) {
            throw new Error(
                `Deal is invalid: ${JSON.stringify(dealChangeset.errors)}`
            );
        }

        const reissueRate = await this.model.deal.reissueRate;
        const reissueRatePolicyDocument = await reissueRate?.currentOwnersPolicyDocument;
        if (reissueRatePolicyDocument?.isNew) {
            await reissueRatePolicyDocument?.save();
        }
        await reissueRate?.save();

        await dealChangeset.save();

        if (userChangeset.isDirty) {
            await userChangeset.save();
        }

        this.router.transitionTo('protected.deal.onboarding.review');
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/deal/onboarding/cpc-notary-intermediary': ProtectedDealOnboardingCpcNotaryIntermediary;
    }
}
