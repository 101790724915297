import ActionItem from 'portal/models/action-item';
import Analytics from 'portal/services/analytics';
import ArrayProxy from '@ember/array/proxy';
import BaseRoute from 'portal/routes/base-route';
import CreateDealRequest from 'portal/models/create-deal-request';
import Store from '@ember-data/store';
import {
    CreateDealRequestConfirmationContext,
    createDealRequestConfirmationContextLoaded,
    loadCreateDealRequestConfirmationContext
} from 'portal/models/create-deal-request-confirmation-context';
import { action } from '@ember/object';
import { pollWithTimeout } from 'portal/utils/poll-with-timeout';
import { inject as service } from '@ember/service';

const defaultMaxPollWaitTime = 10000;
const defaultPollInterval = 1000;
export const loadTimeExceededThresholdEvent = 'Deal Request Confirmation Exceeded Timeout Threshold';

export default class ProtectedIntakeDealRequestConfirmation extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Request Confirmation');
    }

    async model({ create_deal_request_id }: { create_deal_request_id: string }): Promise<CreateDealRequestConfirmationContext | undefined> {
        this.analytics.trackPage('Loading Deal Request Confirmation');

        return pollWithTimeout<CreateDealRequestConfirmationContext>({
            loadData: () => loadCreateDealRequestConfirmationContext(
                () => this.getCreateDealRequest(create_deal_request_id),
                (id) => this.getCreateActivityRequest(id)
            ),
            isValid: createDealRequestConfirmationContextLoaded,
            intervalMs: defaultPollInterval,
            timeoutMs: this.maxPollWaitTime(),
            onTimeout: () => this.analytics.trackPage(loadTimeExceededThresholdEvent)
        });
    }

    private maxPollWaitTime(): number {
        return defaultMaxPollWaitTime;
    }

    private async getCreateDealRequest(id: string): Promise<CreateDealRequest> {
        return await this.store.findRecord('createDealRequest', id, {
            include: 'deal,deal.actionItems'
        }) as CreateDealRequest;
    }

    private async getCreateActivityRequest(id: string): Promise<ArrayProxy<ActionItem>> {
        return await this.store.query('action-item', { deal_id: id });
    }
}
