import Component from '@glimmer/component';

interface DatePickerTimeButtonArgs {
    disabled: boolean;
    selected: boolean;
    hour: number;
}

export default class DatePickerTimeButton extends Component<DatePickerTimeButtonArgs> {
    get disabled() {
        return this.args.disabled || false;
    }

    get selected() {
        return this.args.selected || false;
    }

    baseCssClass =
        'date-picker__time-button flex flex-auto items-center justify-center rounded-md p-2 mb-4 focus:outline-none';

    get cssClass() {
        let cssClass = this.baseCssClass;

        if (!this.disabled) {
            cssClass = `border-2 ${cssClass}`;
        }

        if (this.selected) {
            cssClass = `bg-jade-90 text-white ${cssClass}`;
        }

        return cssClass;
    }
}
