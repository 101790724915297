import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface DealActionItemBaseArgs {}

export default class DealActionItemsBase extends Component<DealActionItemBaseArgs> {
    @tracked
        isOpen = false;

    @action
    toggleDrawer() {
        this.isOpen = !this.isOpen;
    }
}
