import Component from '@glimmer/component';
import DocumentModel from 'portal/models/document';
import EmporaDocumentUpload from 'portal/components/empora/document-upload/component';
import IntakeUser, { UserRoleType } from 'portal/models/intake-user';
import TeamMember from 'portal/models/team-member';
import { BufferedChangeset } from 'ember-changeset/types';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface IntakePurchaseContractArgs {
    changeset: BufferedChangeset;
    intakeUser: IntakeUser;
    activeTeamMembers: Array<TeamMember>;
    onNext: (changeset: BufferedChangeset, intakeUser: IntakeUser, selectedTeamMembers: TeamMember[]) => void;
    disabled: boolean;
}

export default class IntakePurchaseContract extends Component<IntakePurchaseContractArgs> {
    @tracked
        addTeam?: boolean = true;

    @tracked
        selectedTeamMembers: TeamMember[] = this.args.activeTeamMembers;

    @tracked
        showErrorBanner?: boolean = false;

    @tracked
        showTeamMembers?: boolean = true;

    @action
    validate() {
        this.args.changeset.validate();
    }

    @action
    onDocumentUpload(doc: DocumentModel, documentUpload: EmporaDocumentUpload) {
        const documents = this.args.changeset.get('documents') as DocumentModel[];

        documents.pushObject(doc);
        this.args.changeset.validate();
        documentUpload.clearDocument();
    }

    @action
    removeDocument(docToRemove: DocumentModel) {
        const documents = this.args.changeset.get(
            'documents'
        ) as DocumentModel[];

        documents.removeObject(docToRemove);
        docToRemove.rollbackAttributes();
        this.args.changeset.validate();
    }

    @action
    async onNext() {
        this.args.changeset.set('showErrorOnPristine', true);

        if (this.args.changeset.isInvalid) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        await this.args.onNext(this.args.changeset, this.args.intakeUser, this.selectedTeamMembers);
    }

    @action
    onAddTeam(){
        this.addTeam = !this.addTeam;

        if (this.addTeam) {
            this.selectedTeamMembers = this.args.activeTeamMembers;
        } else {
            this.selectedTeamMembers = [];
        }
    }

    @action
    onSelectTeamMember(teamMember: TeamMember) {
        if (this.selectedTeamMembers.includes(teamMember)){
            this.selectedTeamMembers = this.selectedTeamMembers.filter((selectedTeamMember) => selectedTeamMember != teamMember);
        } else {
            this.selectedTeamMembers.push(teamMember);
        }

        if (this.selectedTeamMembers.length > 0){
            this.addTeam = true;
        } else {
            this.addTeam = false;
        }
    }

    @action
    onToggleTeamMembers() {
        this.showTeamMembers = !this.showTeamMembers;
    }

    get isAssignor() {
        return this.args.intakeUser.userRole === UserRoleType.assignor;
    }

    get isBorrower() {
        return this.args.intakeUser.userRole === UserRoleType.borrower;
    }
}
