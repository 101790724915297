import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Store from '@ember-data/store';
import Transition from '@ember/routing/-private/transition';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedIntakeBuyerSeller extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Intake Buyer/Seller');
    }

    async model({ intake_user_id }: { intake_user_id: string }) {
        const intakeUser = this.store.findRecord('intakeUser', intake_user_id);

        return {
            buyers: [],
            sellers: [],
            intakeUser
        };
    }

    @action
    willTransition(transition: Transition) {
        if (!transition.to.name.includes(this.routeName)) {
            this.modelFor(this.routeName).buyers.forEach((buyer: any) => {
                buyer.data?.rollbackAttributes();
            });

            this.modelFor(this.routeName).sellers.forEach((seller: any) => {
                seller.data?.rollbackAttributes();
            });
        }
    }
}
