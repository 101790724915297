import Component from '@glimmer/component';
import LegalEntityModel from 'portal/models/legal-entity';
import { DealSides } from 'portal/services/deal-side';
import { action } from '@ember/object';
import { isNone } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

interface SelectEntityArgs {
    disabled?: boolean;
    legalEntities: Array<LegalEntityModel>;
    dealSide: DealSides,
    onNext: () => void;
    onAdd: () => void;
    onEdit?: (value: LegalEntityModel | null) => void;
    onSelect: (value: LegalEntityModel | null) => void;
}

export default class SelectEntity extends Component<SelectEntityArgs> {
    @tracked
        selectedEntity: LegalEntityModel | null = null;

    @tracked
        showErrorOnPristine?: boolean = false;

    @tracked
        showErrorBanner?: boolean = false;

    @action
    onChange(value: LegalEntityModel) {
        if (this.selectedEntity == value) {
            this.selectedEntity = null;
        } else {
            this.selectedEntity = value;
        }

        this.args.onSelect(this.selectedEntity);
    }

    @action
    onEdit(value: LegalEntityModel) {
        this.args.onEdit?.(value);
    }

    @action
    async onNext() {
        this.showErrorOnPristine = true;

        if (isNone(this.selectedEntity)) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        if (this.args.onNext) {
            this.args.onNext();
        }
    }
}
