import BaseRoute from 'portal/routes/base-route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class CatchUnrecognized extends BaseRoute {
    @service
    declare router: RouterService;

    redirect() {
        this.router.replaceWith('protected');
    }
}