import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import EntityTitleholder from 'portal/models/entity-titleholder';
import IndividualTitleholder, { individualTitleholderValidations } from 'portal/models/individual-titleholder';
import PowerOfAttorney, { powerOfAttorneyValidations } from 'portal/models/power-of-attorney';
import ReissueRate from 'portal/models/reissue-rate';
import Store from '@ember-data/store';
import lookupValidator from 'ember-changeset-validations';
import { BufferedChangeset } from 'ember-changeset/types';
import { Changeset } from 'ember-changeset';
import { isBlank } from '@ember/utils';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

interface DealSellerFinanceArgs {
    titleholders: Array<IndividualTitleholder | EntityTitleholder>;
    deal: Deal;
    reissueRate: ReissueRate;
    userChangeset: BufferedChangeset;
    reissueRateChangeset: BufferedChangeset;
    onSubmit: (userChangeset: BufferedChangeset) => Promise<void>;
    addTitleholder: () => Promise<void>;
    editTitleholder: (titleholder: IndividualTitleholder | EntityTitleholder) => Promise<void>;
    removeTitleholder: (titleholder: IndividualTitleholder | EntityTitleholder) => Promise<void>;
}

export default class DealSellerFinance extends Component<DealSellerFinanceArgs> {
    constructor(owner: unknown, args: DealSellerFinanceArgs) {
        super(owner, args);

        this.titleholderPoaChangesets = new Map();
        this.individualTitleholders.forEach((titleholder) => {
            let powerOfAttorney;
            if (!titleholder.isNew) {
                powerOfAttorney = titleholder.powerOfAttorney as PowerOfAttorney;
            } else {
                powerOfAttorney = this.store.createRecord('power-of-attorney', {});
            }

            this.titleholderPoaChangesets.set(
                Changeset(titleholder, lookupValidator(individualTitleholderValidations), individualTitleholderValidations),
                Changeset(powerOfAttorney, lookupValidator(powerOfAttorneyValidations), powerOfAttorneyValidations)
            );
        });

    }

    @tracked
    declare showErrorOnPristine: boolean;

    @tracked
    declare titleholderPoaChangesets: Map<BufferedChangeset, BufferedChangeset>;

    @service
    declare store: Store;

    showCommunicationPreferences: boolean = this.args.userChangeset.get('hasEmptyCommPreferences');

    get showUserInfo(): boolean {
        const { userChangeset } = this.args;

        if (isBlank(userChangeset.phoneNumber)) {
            return true;
        }
        return userChangeset.changes.some((change) => change.key === 'phoneNumber');
    }

    get hasTitleholder(): boolean {
        return this.args.titleholders.any((th) => !th.isDeleted);
    }

    get individualTitleholders(): Array<IndividualTitleholder> {
        return this.args.titleholders.filter((titleholder) => titleholder instanceof IndividualTitleholder) as Array<IndividualTitleholder>;

    }

    get relevantTitleholderPoaChangesets(): Map<BufferedChangeset, BufferedChangeset> {
        return new Map(
            Array.from(this.titleholderPoaChangesets).filter((titleholderPoaChangeset) => {
                return (titleholderPoaChangeset[0].data as IndividualTitleholder).isNew;
            })
        );
    }

    get powerOfAttorneysAreInvalid(): boolean {
        if (this.relevantTitleholderPoaChangesets.size === 0){
            return false;
        } else {
            return Array.from(this.relevantTitleholderPoaChangesets.entries()).some((titleholderPoa) => {
                if (titleholderPoa[0].hasPowerOfAttorney === false){
                    titleholderPoa[0].set('powerOfAttorney',  this.store.createRecord('power-of-attorney', {}));
                    return false;
                } else {
                    titleholderPoa[0].set('powerOfAttorney',  titleholderPoa[1]);
                    titleholderPoa[1].validate();
                    return titleholderPoa[1].isInvalid;
                }
            });
        }
    }

    @task
    async onSubmit(): Promise<void> {
        const { reissueRateChangeset, userChangeset } = this.args;
        await userChangeset.validate();
        await reissueRateChangeset.validate();

        if (
            userChangeset.isInvalid ||
            reissueRateChangeset.isInvalid ||
            !this.hasTitleholder ||
            this.powerOfAttorneysAreInvalid
        ) {
            this.showErrorOnPristine = true;
            userChangeset.showErrorOnPristine = true;
            reissueRateChangeset.showErrorOnPristine = true;
            Array.from(
                this.relevantTitleholderPoaChangesets.keys()
            ).forEach((titleholderChangeset) => titleholderChangeset.showErrorOnPristine = true);
            Array.from(this.relevantTitleholderPoaChangesets.values()).forEach((poaChangeset) => poaChangeset.showErrorOnPristine = true);
            window.scrollTo(0,0);
        } else {
            Array.from(this.relevantTitleholderPoaChangesets.keys()).forEach((titleholderChangeset) => titleholderChangeset.execute());
            Array.from(this.relevantTitleholderPoaChangesets.values()).forEach((poaChangeset) => poaChangeset.execute());

            await this.args.onSubmit(userChangeset);
        }
    }
}
