import Deal from './deal';
import Model, { AsyncBelongsTo, belongsTo } from '@ember-data/model';
import Team from './team';

export default class DealTeam extends Model {
    @belongsTo('deal')
        deal?: AsyncBelongsTo<Deal>;

    @belongsTo('team')
        team?: AsyncBelongsTo<Team>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'deal-team': DealTeam;
    }
}
