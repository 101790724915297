import Deal from './deal';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

export default class DocumentModel extends Model {
    @attr('string')
        name?: string;

    @attr('string')
        type?: string;

    @attr('string')
        path?: string;

    @attr('string')
        filetype?: string;

    @attr('boolean')
        canDelete?: boolean;

    @attr('date')
        uploadedAt?: Date;

    @belongsTo('deal')
        deal?: AsyncBelongsTo<Deal> | Deal;

    file?: File;
}

export enum DocumentTypes {
    entity_document = 'entity_document',
    lease_document = 'lease',
    other_lender_documents = 'other_lender_documents',
    purchase_contract = 'purchase_contract',
    rent_roll_document = 'rent_roll'
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        document: DocumentModel;
    }
}
