import { isEmpty } from '@ember/utils';

export default function validateTaxId(
    label: string | undefined,
    options: {
        allowBlank?: boolean;
        on?: string;
        when?: string;
        unless?: string;
    } = {}
) {
    return (
        _key: string,
        newValue: unknown,
        _oldValue: unknown,
        changes: unknown,
        content: object
    ): true | string => {
        if (options.allowBlank && isEmpty(newValue as string)) {
            return true;
        }

        if (
            !isEmpty(options.on) &&
            isEmpty((content as { [key: string]: any })[options.on as string])
        ) {
            return true;
        }

        if (
            !isEmpty(options.unless) &&
            !isEmpty((content as { [key: string]: any })[options.unless as string]) &&
            isEmpty(newValue)
        ) {
            return true;
        }

        const when = options.when;
        if (!isEmpty(when)) {
            let whenValue = undefined;

            if (changes != undefined) {
                whenValue = (changes as { [key: string]: any })[when as string];
            }

            if (whenValue == undefined) {
                whenValue = (content as { [key: string]: any })[when as string];
            }

            if (!whenValue) {
                return true;
            }
        }

        if (
            !isEmpty(newValue) &&
            (newValue as string).length === 9 &&
            /[0-9]{9}/.test(newValue as string)
        ) {
            return true;
        } else {
            if (label) {
                return `${label} must be 9 digits with no dashes`;
            } else {
                return `Must be 9 digits with no dashes`;
            }
        }
    };
}
