import ClearToCloseTitleItem from 'portal/models/clear-to-close-title-item';
import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import EmporaServer from 'portal/services/empora-server';
import Store from '@ember-data/store';
import TrackerEventModel, {
    TrackerEventStatus
} from 'portal/models/tracker-event';
import { htmlSafe } from '@ember/template';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';

export interface DealTrackerEventsArgs {
    deal: Deal;
}

export default class DealTrackerEvents extends Component<DealTrackerEventsArgs> {
    @service
    declare store: Store;

    @service
    declare emporaServer: EmporaServer;

    @tracked
        dealTrackerEvents: Array<TrackerEventModel> = [];

    @tracked
        clearToCloseTitleItems: ClearToCloseTitleItem[] = [];

    constructor(owner: unknown, args: DealTrackerEventsArgs) {
        super(owner, args);
        taskFor(this.getClearToCloseTitleItems).perform();
    }

    @task
    async getClearToCloseTitleItems() {
        this.clearToCloseTitleItems = (
            await this.store.query('clear-to-close-title-item', {
                deal_id: this.args.deal.id
            })
        ).toArray();
    }

    get isExternalCtcVisible() {
        return this.clearToCloseTitleItems?.length > 0;
    }

    get trackerEventStatus() {
        return TrackerEventStatus;
    }

    protected async queryTrackerEvents() {
        const trackerEvents = await this.store.query('tracker-event', {
            deal_id: this.args.deal.id
        });
        return trackerEvents?.toArray() || [];
    }

    @task
    async getTrackerEvents(): Promise<void> {
        this.dealTrackerEvents = await this.queryTrackerEvents();
    }

    get progressBarContainerPadding() {
        return 100 / (this.dealTrackerEvents.length * 2);
    }

    get totalCompleteTrackerEvents() {
        return this.dealTrackerEvents.filterBy(
            'status',
            TrackerEventStatus.COMPLETED
        ).length;
    }

    get progressBarWidth() {
        if (this.totalCompleteTrackerEvents === 0) {
            return 0;
        } else if (
            this.totalCompleteTrackerEvents === this.dealTrackerEvents.length
        ) {
            return 100;
        } else {
            const fullSegmentWidth = 100 / (this.dealTrackerEvents.length - 1);
            return (
                fullSegmentWidth *
                    Math.max(this.totalCompleteTrackerEvents - 1, 0) +
                fullSegmentWidth / 2
            );
        }
    }

    get progressBarContainerStyle() {
        return htmlSafe(
            `padding-left:${this.progressBarContainerPadding}%; padding-right:${this.progressBarContainerPadding}%`
        );
    }

    get progressBarStyle() {
        return htmlSafe(`width:${this.progressBarWidth}%`);
    }

    get currentEvent(){
        if (this.totalCompleteTrackerEvents === 0) {
            return null;
        } else {
            return this.dealTrackerEvents[this.totalCompleteTrackerEvents -1];
        }
    }

    get nextEvent(){
        if (this.totalCompleteTrackerEvents === this.dealTrackerEvents.length) {
            return null;
        } else {
            return this.dealTrackerEvents[this.totalCompleteTrackerEvents];
        }
    }

    get currentEventName(){
        if (this.currentEvent) {
            return this.currentEvent.name;
        } else {
            return 'Received';
        }
    }

    get nextEventName(){
        if (this.nextEvent) {
            return this.nextEvent.name;
        } else {
            return null;
        }
    }

}
