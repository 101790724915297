import DealModel from './deal';
import DocumentModel from './document';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

export default class DocumentDownloadRequest extends Model {
    @attr('string')
        url?: string;

    @attr('date')
        createdAt?: Date;

    @attr('date')
        updatedAt?: Date;

    @belongsTo('document')
        document?: AsyncBelongsTo<DocumentModel> | DocumentModel;

    @belongsTo('deal')
        deal?: AsyncBelongsTo<DealModel> | DealModel;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'document-download-request': DocumentDownloadRequest;
    }
}
