import Controller from '@ember/controller';
import GuidedAccountCreationSection,  { Section, Status } from 'portal/models/guided-account-creation-section';
import ProtectedAccountCreationFlowTeamOverviewRoute from 'portal/routes/protected/account-creation-flow/team-overview';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedAccountCreationFlowTeamOverview extends Controller {
    declare model: ModelFrom<ProtectedAccountCreationFlowTeamOverviewRoute>;

    @service
    declare router: RouterService;

    @action
    onEdit() {
        this.router.transitionTo('protected.account-creation-flow.my-team');
    }

    @action
    async afterSave(): Promise<void> {
        await this.onComplete(Status.completed);
    }

    @action
    async cancel(): Promise<void> {
        await this.onComplete(Status.canceled);
    }

    async onComplete(status: Status): Promise<void> {

        const teamDetailsSection = this.model.guidedAccountCreationSections.findBy('section', Section.team_details);

        if (teamDetailsSection) {
            teamDetailsSection.status = status;

            await teamDetailsSection.save();
        }

        const allSectionsFinished = this.model.guidedAccountCreationSections.every(
            (section: GuidedAccountCreationSection) => section.finished);

        if (allSectionsFinished) {
            this.router.transitionTo('protected.account-creation-flow.completed');
        } else {
            this.router.transitionTo('protected.account-creation-flow.home');
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/account-creation-flow/team-overview': ProtectedAccountCreationFlowTeamOverview;
    }
}
