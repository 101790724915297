import Helper from '@ember/component/helper';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';

interface Savable {
    save: () => Promise<void>;
}

export default class SaveTask extends Helper {
    @tracked
        model?: Savable;

    @task
    async saveModel() {
        await this.model?.save();
    }

    compute([model]: [Savable]) {
        this.model = model;
        return taskFor(this.saveModel);
    }
}
