import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import ProtectedDealOnboarding from '../onboarding';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealOnboardingReview extends BaseRoute {
    @service
    declare router: RouterService;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Onboarding / Review');
    }

    async model() {
        const parentModel = this.modelFor(
            'protected.deal.onboarding'
        ) as ModelFrom<ProtectedDealOnboarding>;

        if (!parentModel) {
            this.router.transitionTo('protected.deal');
            return;
        }

        parentModel.hasCompleted = true;
        await parentModel?.save();
        this.router.transitionTo('protected.deal.action-item-completion', parentModel.id);
    }
}
