import DealMessage from './deal-message';
import Document from './document';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import validatePresenceWhenFalse from 'portal/validators/presence-when-false';
import { validateInclusion } from 'ember-changeset-validations/validators';

export default class CommitmentTitleCommitmentReview extends Model {
    @attr('string')
        messageSent?: string;

    @attr('boolean', { allowNull: true })
        understoodByUser?: boolean;

    @belongsTo('document')
        document?: AsyncBelongsTo<Document> | Document;

    @belongsTo('deal-message')
        customerQuestion?: AsyncBelongsTo<DealMessage> | DealMessage;
}

export const commitmentReviewValidations = {
    understoodByUser: validateInclusion({ list: [true, false], message: 'Please select an option' }),
    customerQuestion: validatePresenceWhenFalse({ presence: true, key: 'understoodByUser', message: 'Please enter a follow up question' })
};

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
      'commitment-title-commitment-review': CommitmentTitleCommitmentReview;
    }
}