import BorrowerAuthorizationActivity from 'portal/models/borrower-authorization-activity';
import Component from '@glimmer/component';
import ENV from 'portal/config/environment';
import Hellosign from 'portal/services/hellosign';
import PayoffAuthorization from 'portal/models/payoff-authorization';
import RouterService from '@ember/routing/router-service';
import SellerAuthorizationActivity from 'portal/models/seller-authorization-activity';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

interface DealPayoffAuthorizationArgs {
    actionItem: SellerAuthorizationActivity | BorrowerAuthorizationActivity;
}

export default class DealPayoffAuthorization extends Component<DealPayoffAuthorizationArgs> {
    @service
        router!: RouterService;

    @service
        hellosign!: Hellosign;

    @task
    async fetchPayoffAuthorization(): Promise<PayoffAuthorization | undefined> {
        return await this.args.actionItem.payoffAuthorization;
    }

    @action
    async onSign() {
        const helloSignClient = this.hellosign.createClient();
        const payoffAuthorization = await taskFor(
            this.fetchPayoffAuthorization
        ).perform();

        helloSignClient.on('sign', async () => {
            const actionItem = this.args.actionItem;
            actionItem.hasCompleted = true;
            await actionItem.save();
            this.router.transitionTo('protected.deal.action-item-completion', this.args.actionItem.id);
        });

        if (payoffAuthorization?.embeddedSigningUrl) {
            helloSignClient.open(payoffAuthorization.embeddedSigningUrl, {
                skipDomainVerification: ENV.HELLOSIGN_SKIP_DOMAIN_VERIFICATION
            });
        }
    }
}
