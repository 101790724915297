import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { isPresent } from '@ember/utils';
// import validatePresenceWhenTrue from 'portal/validators/presence-when-true';
import { validatePresence } from 'ember-changeset-validations/validators';
// import { Changeset } from 'validated-changeset';
import DealMessage from './deal-message';
import DocumentModel from './document';
import ReviewSettlementStatementActivity from './review-settlement-statement-activity';

export default class SettlementStatementReviewRequest extends Model {
    @attr('boolean', { allowNull: true })
        approved?: boolean;

    @belongsTo('deal-message')
        message?: AsyncBelongsTo<DealMessage> | DealMessage;

    @belongsTo('review-settlement-statement-activity')
        activity?:
        | AsyncBelongsTo<ReviewSettlementStatementActivity>
        | ReviewSettlementStatementActivity;

    @belongsTo('document')
        document?: AsyncBelongsTo<DocumentModel> | DocumentModel;
}

export const validations = {
    approved: validatePresence({ presence: true }),
    message: (
        _key: string,
        newValue: unknown,
        _oldValue: unknown,
        changes: { approved?: boolean },
        content: SettlementStatementReviewRequest
    ) => {
        const approved = isPresent(changes.approved)
            ? changes.approved
            : content.approved;

        if (approved) {
            return newValue === null;
        } else {
            return newValue !== null;
        }
    }
};

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'settlement-statement-review-request': SettlementStatementReviewRequest;
    }
}
