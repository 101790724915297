import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import Store from '@ember-data/store';
import Team, { teamValidations } from 'portal/models/team';
import  TeamMember, { Role } from 'portal/models/team-member';
import lookupValidator from 'ember-changeset-validations';
import { Changeset } from 'ember-changeset';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedMyAccountMyTeam extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare auth: AuthService;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed My Team');
    }

    async model() {
        const user =  await this.auth.getAuthenticatedUser();
        const team = await user?.team;
        let refreshedTeam = null;
        if (team) {
            refreshedTeam = await this.store.findRecord('team', team.id, { reload: true });
        } else
        {
            refreshedTeam = this.store.createRecord('team', {});
        }

        const teamChangeset = Changeset(refreshedTeam, lookupValidator(teamValidations), teamValidations);

        let teamMembers = (await refreshedTeam.teamMembers || []).toArray();
        if (teamMembers.length == 0) {
            teamMembers = [
                this.store.createRecord(
                    'team-member',
                    {
                        role: Role.admin,
                        firstName: user.givenName,
                        lastName: user.familyName,
                        email: user.email,
                        phoneNumber: user.phoneNumber,
                        pending: false,
                        team: refreshedTeam
                    }
                )
            ];
        }

        return { teamChangeset, teamMembers };
    }

    @action
    willTransition() {
        const { teamChangeset } = this.modelFor(this.routeName) as ModelFrom<ProtectedMyAccountMyTeam>;
        (teamChangeset.data as Team).rollbackAttributes();

        const teamMembers = (teamChangeset.data as Team).teamMembers;
        teamMembers?.forEach((teamMember: TeamMember) => { teamMember.rollbackAttributes; });
        teamMembers?.filter((teamMember) => teamMember.isNew).forEach((teamMember: TeamMember) => { teamMember.deleteRecord(); });
    }
}
