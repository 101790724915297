import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedMyAccountTeamOverview extends BaseRoute {
    @service
    declare auth: AuthService;

    @service
    declare analytics: Analytics;

    @service
    declare store: Store;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Team Overview');
    }

    async model() {
        const user =  await this.auth.getAuthenticatedUser();
        const refreshedUser = await this.store.findRecord('user', user.id, { reload: true });
        const team = await refreshedUser?.team;
        await team?.teamMembers;
        return team;
    }
}
