import Component from '@glimmer/component';
import LegalEntityDesignatedSigner from 'portal/models/legal-entity-designated-signer';
import LegalEntityModel from 'portal/models/legal-entity';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface ReviewEntityDetailsArgs {
    legalEntities: LegalEntityModel[];
    onSave: () => void;
}

export default class ReviewEntityDetails extends Component<ReviewEntityDetailsArgs> {
    @service
    declare store: Store;

    @tracked
        legalEntities: LegalEntityModel[];

    @tracked
    declare legalEntityBeingEdited?: LegalEntityModel;

    @tracked
        showErrorBanner = false;

    constructor(owner: unknown, args: any) {
        super(owner, args);

        this.legalEntities = this.args.legalEntities;
        this.legalEntityBeingEdited = undefined;

        this.args.legalEntities.forEach((legalEntity) => {
            this.initializeDesignatedSignerSelection(legalEntity);
        });
    }

    @action
    onCancelEditLegalEntity() {
        this.showErrorBanner = false;
        this.legalEntityBeingEdited = undefined;
        window.scrollTo(0, 0);
    }

    @action
    onEditLegalEntity(legalEntity: LegalEntityModel) {
        this.showErrorBanner = false;
        this.legalEntityBeingEdited = legalEntity;
    }

    @action
    onSave() {
        if (this.isValid) {
            this.args.onSave();
        } else {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
        }
    }

    @action
    async onSaveLegalEntity() {
        const updatedLegalEntity = await this.store.findRecord(
            'legal-entity', this.legalEntityBeingEdited!.id, { reload: true }
        );

        this.initializeDesignatedSignerSelection(updatedLegalEntity);

        this.legalEntities = this.args.legalEntities.map((legalEntity) => {
            if (legalEntity.id === updatedLegalEntity.id) {
                return updatedLegalEntity;
            }
            return legalEntity;
        }) as LegalEntityModel[];

        this.legalEntityBeingEdited = undefined;

        window.scrollTo(0, 0);
    }

    @action
    onSelectDesignatedSigner(legalEntity: LegalEntityModel, designatedSigner: LegalEntityDesignatedSigner) {
        designatedSigner.selectedAsTitleholderSigner = !designatedSigner.selectedAsTitleholderSigner;

        if (designatedSigner.selectedAsTitleholderSigner) {
            legalEntity.designatedSignerSelectedForTitleholder = true;
        } else {
            if (!this.isDesignatedSignerSelectedForLegalEntity(legalEntity)) {
                legalEntity.designatedSignerSelectedForTitleholder = false;
            }
        }
    }

    get isDesignatedSignerSelectedForEachLegalEntity(): boolean {
        return this.legalEntities.every((legalEntity) => legalEntity.designatedSignerSelectedForTitleholder);
    }

    get isPrimaryDocumentUploadedForEachLegalEntity(): boolean {
        return !this.args.legalEntities.some((legalEntity) => !legalEntity.primaryDocument);
    }

    get isValid(): boolean {
        return this.isDesignatedSignerSelectedForEachLegalEntity && this.isPrimaryDocumentUploadedForEachLegalEntity;
    }

    isDesignatedSignerSelectedForLegalEntity(legalEntity: LegalEntityModel): boolean {
        return !!legalEntity.designatedSigners?.toArray().some((designatedSigner) => designatedSigner.selectedAsTitleholderSigner);
    }

    initializeDesignatedSignerSelection(legalEntity: LegalEntityModel) {
        legalEntity.designatedSignerSelectedForTitleholder = false;
        const legalEntityDesignatedSigners = legalEntity.designatedSigners?.toArray();
        if (legalEntityDesignatedSigners) {
            legalEntityDesignatedSigners.forEach(
                (designatedSigner) => designatedSigner.selectedAsTitleholderSigner = false
            );

            if (legalEntityDesignatedSigners.length === 1) {
                legalEntityDesignatedSigners[0].selectedAsTitleholderSigner = true;
                legalEntity.designatedSignerSelectedForTitleholder = true;
            }
        }
    }
}
