import ActionItem from 'portal/models/action-item';
import Component from '@glimmer/component';
import CreditLine from 'portal/models/credit-line';
import DealSide, { DealSides } from 'portal/services/deal-side';
import EntityTitleholder from 'portal/models/entity-titleholder';
import IndividualTitleholder from 'portal/models/individual-titleholder';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';

interface DealActionItemsBuyerTitleholderAndFinancingInformationArgs {
    actionItem: ActionItem;
    onRequestAChange: () => void;
    onStart: () => void;
}

export default class DealActionItemsBuyerTitleholderAndFinancingInformation extends
    Component<DealActionItemsBuyerTitleholderAndFinancingInformationArgs> {
    @service
        router!: RouterService;

    @service
    declare dealSide: DealSide;

    @service intl!: IntlService;

    @tracked
        titleholders: Array<IndividualTitleholder | EntityTitleholder>;

    @tracked
        creditLines: Array<CreditLine>;

    @tracked
        intendedFinancingType: string | undefined;

    constructor(owner: unknown, args: DealActionItemsBuyerTitleholderAndFinancingInformationArgs) {
        super(owner, args);

        this.titleholders = [];
        taskFor(this.loadTitleholders).perform();

        this.creditLines = [];
        this.intendedFinancingType = '';
        taskFor(this.loadCreditLines).perform();
    }

    @task
    async loadTitleholders(): Promise<void> {
        const deal = await this.args.actionItem.deal;
        if (deal != undefined) {
            this.titleholders = await deal.titleholdersForSide(DealSides.FUTURE);
        }
    }

    @task
    async loadCreditLines(): Promise<void> {
        const deal = await this.args.actionItem.deal;
        this.intendedFinancingType = deal?.intendedFinancingType;
        const property = await deal?.property;
        if (property != undefined) {
            this.creditLines = await property.creditLinesForDealSide(DealSides.FUTURE);
        }
    }

    get hasTitleholders(): boolean {
        return this.titleholders.length > 0;
    }

    get hasCreditLines(): boolean {
        return this.creditLines.length > 0;
    }
}
