import Model, {
    AsyncHasMany,
    attr,
    hasMany
} from '@ember-data/model';
import TeamMember from './team-member';
import { validatePresence } from 'ember-changeset-validations/validators';

export enum Role {
    user = 'user',
    admin = 'admin',
}
export default class Team extends Model {
    @attr('string')
        role?: Role;

    @attr('string')
        name?: string;

    @hasMany('team-member')
        teamMembers?: AsyncHasMany<TeamMember>;

    get activeTeamMemberCountDisplay(): string {
        const activeTeamMembers = this.teamMembers?.filterBy('pending', false);
        if (!activeTeamMembers){ return '0 members'; }
        if (activeTeamMembers.length == 1){ return '1 member'; }
        return `${activeTeamMembers.length} members`;
    }

    get totalTeamMemberCountDisplay(): string {
        const totalTeamMembers = this.teamMembers;
        if (!totalTeamMembers){ return '0 members'; }
        if (totalTeamMembers.length == 1){ return '1 member'; }
        return `${totalTeamMembers.length} members`;
    }
}

export const teamValidations = {
    name: validatePresence({
        presence: true,
        message: "Name can't be blank"
    })
};

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        team: Team;
    }
}
