import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Deal from 'portal/models/deal';
import EarnestMoneyDepositMethodRule from 'portal/models/earnest-money-deposit-method-rule';
import EarnestMoneyDetail from 'portal/models/earnest-money-detail';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealEarnestMoneySelection extends BaseRoute {
    @service
    declare analytics: Analytics;

    @service
    declare store: Store;

    async model(): Promise<
        {
            market: string | undefined,
            earnestMoneyDepositMethodRules: EarnestMoneyDepositMethodRule[],
            earnestMoneyDetail: EarnestMoneyDetail | undefined
        }
        > {
        const deal = this.modelFor('protected.deal') as Deal;
        const market = (await (await deal.property)?.address)?.state;
        const earnestMoneyDepositMethodRules = (await this.store.query('earnestMoneyDepositMethodRule', { deal_id: deal.id })).toArray();
        const earnestMoneyDetail = await deal.earnestMoneyDetail;
        return { market: market, earnestMoneyDepositMethodRules: earnestMoneyDepositMethodRules, earnestMoneyDetail: earnestMoneyDetail };
    }

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Earnest Money Selection');
    }

    @action
    error(): boolean {
        this.analytics.trackEvent('Error on Earnest Money Selection');
        return true;
    }
}
