import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface MlsSurveyArgs {
    onChange: (value: boolean) => void;
    mlsSurvey: boolean;
}

export default class MlsSurvey extends Component< MlsSurveyArgs>{
    @tracked
        mlsSurvey: boolean = this.args.mlsSurvey;


    @action
    onChange(value: boolean) {
        this.mlsSurvey = value;
        this.args.onChange(value);
    }
}