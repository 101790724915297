import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class EmailVerificationComplete extends Controller {
    @service
    declare router: RouterService;

    @tracked
        timer = 5;

    @action
    runTimer() {
        if (this.timer <= 0) {
            this.redirect();
            return;
        }

        later(() => {
            this.timer--;
            this.runTimer();
        }, 1000);
    }

    redirect() {
        this.router.transitionTo('protected.deals');
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'email-verification-complete': EmailVerificationComplete;
    }
}
