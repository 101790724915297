import Service from '@ember/service';
import Store from '@ember-data/store';
import { DealActorRoles } from 'portal/models/deal';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export enum DealSides {
  CURRENT = 'current',
  FUTURE = 'future'
}

interface ActorRoleSide {
    actorRole: DealActorRoles,
    side: DealSides
}
export default class DealSide extends Service {
    @service
        store!: Store;

    @tracked
    private map: Map<string, ActorRoleSide> = new Map();

    initialize(dealId: string): void {
        const deal = this.store.peekRecord('deal', dealId);
        const actorRole = deal?.actorRole?.toUpperCase() as DealActorRoles;

        const [startingSide] = this.availableDealSidesForRole(actorRole);

        this.map.set(dealId, {
            actorRole,
            side: startingSide
        });
    }

    for(dealId: string): DealSides | undefined {
        return this.map.get(dealId)?.side;
    }

    switchTo(dealId: string, newSide: DealSides): void {
        if (!this.map.get(dealId)) {
            this.initialize(dealId);
        }

        const { actorRole, side } = this.map.get(dealId) ?? {} as ActorRoleSide;

        if (!this.availableDealSidesForRole(actorRole).includes(newSide)) {
            throw new Error('Cannot switch sides for the current actor role');
        }

        if (side !== newSide) {
            this.map.set(dealId, {
                actorRole,
                side: newSide
            });
        }
    }

    availableDealSidesForRole(actorRole: DealActorRoles): DealSides[] {
        switch (actorRole) {
        case DealActorRoles.BUYER:
        case DealActorRoles.ASSIGNOR:
            return [DealSides.FUTURE];
        case DealActorRoles.SELLER:
            return [DealSides.CURRENT];
        case DealActorRoles.BORROWER:
            return [DealSides.CURRENT, DealSides.FUTURE];
        default:
            throw new Error('Unsupported deal actor role provided');
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'deal-side': DealSide;
  }
}
