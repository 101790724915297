import Helper from '@ember/component/helper';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class TransitionTo extends Helper {
    @service
    declare router: RouterService;

    compute([routeName]: string[]) {
        return () => this.router.transitionTo(routeName);
    }
}
