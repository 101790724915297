import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedMyAccountCommunicationPreferences extends BaseRoute {
    @service
    declare auth: AuthService;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Communication Preferences');
    }

    async model() {
        return await this.auth.getAuthenticatedUser();
    }
}
