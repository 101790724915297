import Model, { attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';
import { oneLineAddress } from 'portal/models/basic-address';
import { validateFormat, validatePresence } from 'ember-changeset-validations/validators';

export default class Address extends Model {
    @attr('string')
        street!: string;

    @attr('string')
        street2?: string | null;

    @attr('string')
        city!: string;

    @attr('string')
        state!: string;

    @attr('string')
        zipcode!: string;

    get hasStreet2(): boolean {
        return isPresent(this.street2);
    }

    get oneLine(): string {
        return oneLineAddress(this);
    }

}

export const signingAddressValidations = {
    street: validatePresence({
        presence: true,
        message: "Address can't be blank"
    }),
    city: validatePresence({
        presence: true
    }),
    state: validatePresence({
        presence: true,
        message: 'State must be selected'
    }),
    zipcode: [
        validatePresence({
            presence: true,
            message: "ZIP code can't be blank"
        }),
        validateFormat({
            allowBlank: true,
            regex: /^[0-9]{5}(-[0-9]{4})?$/,
            message: 'ZIP code is invalid'
        })
    ]
};

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        address: Address;
    }
}
