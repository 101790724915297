import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import CommitmentReviewActivity from 'portal/models/commitment-review-activity';
import Deal from '../deal';
import RouterService from '@ember/routing/router-service';
import { ActionItemTemplateKeys } from 'portal/models/action-item';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealCommitmentReview extends BaseRoute {
    @service
    declare router: RouterService;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal / Commitment Review');
    }

    async model() {
        const deal = this.modelFor('protected.deal') as ModelFrom<Deal>;
        const actionItems = await deal.actionItems;
        const actionItem = actionItems?.find((actionItem) => {
            return (
                actionItem.templateKey === ActionItemTemplateKeys.REVIEW_YOUR_TITLE_WORK &&
                !actionItem.hasCompleted
            );
        }) as CommitmentReviewActivity | undefined;

        const commitmentTitleCommitmentReview =
            await actionItem?.commitmentTitleCommitmentReview;

        return {
            actionItem,
            commitmentTitleCommitmentReview
        };
    }

    afterModel(model: ModelFrom<ProtectedDealCommitmentReview>) {
        if (
            model.actionItem === undefined ||
            model.commitmentTitleCommitmentReview === undefined
        ) {
            this.router.replaceWith('protected.deal');
        }
    }
}
