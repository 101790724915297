import Component from '@glimmer/component';
import LegalEntityModel, { LegalEntityType } from 'portal/models/legal-entity';
import LegalEntityWireInstruction from 'portal/models/legal-entity-wire-instruction';

interface ConfirmEntityDetailsArgs {
    legalEntity: LegalEntityModel;
    wireInstruction?: LegalEntityWireInstruction;
    wireInstructionsRequired?: boolean;
    primaryDocument: Document | undefined;
    disabled: boolean;
    onEdit: () => void;
    onSave: () => void;
}

export default class ConfirmEntityDetails extends Component<ConfirmEntityDetailsArgs> {
    get primaryDocumentDisplayTitle(): string {
        return LegalEntityModel.primaryDocumentDisplay(this.args.legalEntity.type as LegalEntityType).title;
    }

    get disabledSave(): boolean {
        return this.args.disabled || !this.args.primaryDocument || this.missingRequiredInstructions;
    }

    get disabledEdit(): boolean {
        return this.args.disabled;
    }

    get validWireInstructions() {
        return !!this.args.wireInstruction
            && !!this.args.wireInstruction?.bankName
            && !!this.args.wireInstruction?.accountNumber;
    }

    get showWireInstructions() {
        return this.validWireInstructions;
    }

    get missingRequiredInstructions(): boolean {
        return !!this.args.wireInstructionsRequired && !this.validWireInstructions;
    }
}
