import Analytics from 'portal/services/analytics';
import Component from '@glimmer/component';
import Property from 'portal/models/property';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { debounce } from 'underscore';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface AddressOption {
    text: string;
    dealId: string;
}

export default class PropertySearchRedirect extends Component {
    private debounceWaitTime = 300;

    @service
    declare analytics: Analytics;

    @service
        router!: RouterService;

    @service
        store!: Store;

    @tracked
        addressList: AddressOption[] = [];

    @tracked
        isFetchingProperties = false;

    @action
    async retrievePropertiesByAddress(query: string) {
        this.isFetchingProperties = true;
        this.debouncedLoadProperties(query);
    }

    @action
    onAddressSelected(option: AddressOption) {
        this.addressList = [];

        this.analytics.trackEvent('Deals / Property Search bar / Result Selected', { selectedAddress: option.text, dealId: option.dealId });

        this.router.transitionTo('protected.deal', option.dealId);
    }

    private debouncedLoadProperties = debounce(async (query: string) => {
        const properties = await this.store.query('property', { query });

        this.addressList = properties.toArray().map((p: Property): AddressOption => {
            return {
                text: p.address.get('oneLine')!,
                dealId: p.dealId
            };
        });
        this.isFetchingProperties = false;
        this.analytics.trackEvent('Deals / Property Search bar / Search Completed', { query, resultCount: this.addressList.length });
    }, this.debounceWaitTime);
}
