import Analytics from 'portal/services/analytics';
import Component from '@glimmer/component';
import EarnestMoneyCollectionActivity from 'portal/models/earnest-money-collection-activity';
import RouterService from '@ember/routing/router-service';
import { default as EarnestMoneyDetail, EarnestMoneyMethod } from 'portal/models/earnest-money-detail';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';

interface DealActionItemsEarnestMoneyCollectionArgs {
    actionItem: EarnestMoneyCollectionActivity;
    onStart: () => void;
}

export default class DealActionItemsEarnestMoneyCollection extends Component<DealActionItemsEarnestMoneyCollectionArgs> {
    @service
        router!: RouterService;

    @service
    declare analytics: Analytics;

    @tracked
    declare earnestMoneyDetail?: EarnestMoneyDetail;

    constructor(owner: unknown, args: DealActionItemsEarnestMoneyCollectionArgs) {
        super(owner, args);

        taskFor(this.loadEarnestMoneyDetail).perform();
    }

    @task
    async loadEarnestMoneyDetail(): Promise<void> {
        const deal = await this.args.actionItem.deal;
        const earnestMoneyDetail = await deal!.earnestMoneyDetail;

        this.earnestMoneyDetail = earnestMoneyDetail;
    }

    get checkOrWire(): boolean {
        return (
            [EarnestMoneyMethod.check, EarnestMoneyMethod.wire] as (EarnestMoneyMethod | undefined)[]
        ).includes(this.earnestMoneyDetail?.earnestMoneyMethod);
    }

    @action
    onDownload() {
        this.analytics.trackEvent('Earnest Money Document Downloaded');
    }
}
