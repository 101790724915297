import Controller from '@ember/controller';
import ProtectedDealEarnestMoneyPortalRoute from 'portal/routes/protected/deal/earnest-money/portal';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealEarnestMoneyPortal extends Controller {
    declare model: ModelFrom<ProtectedDealEarnestMoneyPortalRoute>;

    @service
    declare router: RouterService;

    @action
    async onNext(): Promise<void> {
        this.router.transitionTo('protected.deal.earnest-money.funding-source-selection');
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'protected/deal/earnest-money/portal': ProtectedDealEarnestMoneyPortal;
  }
}
