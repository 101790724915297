import Deal from './deal';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import titleize from 'portal/utils/titleize';

export enum OpsTeamUserRoles {
    CLIENT_SUCCESS_MANAGER = 'client_success_manager',
    PRODUCT_PROCESSOR = 'product_processor',
    TITLE_CURATIVE = 'title_curative',
    ESCROW_OFFICER = 'escrow_officer',
    DISBURSEMENT_SPECIALIST = 'disbursement_specialist',
    AUTHORIZED_SIGNATORY = 'authorized_signatory'
}

export default class OpsTeamUser extends Model {
    @attr('boolean')
        assigned!: boolean;

    @attr('string')
        email!: string;

    @attr('string')
        name?: string;

    @attr('string')
        phoneNumber?: string;

    @attr('string')
        role?: OpsTeamUserRoles;

    @belongsTo('deal')
        deal!: AsyncBelongsTo<Deal>;

    get initials() {
        if (this.name) {
            return ((this.name.split(' ')[0].charAt(0) || '') + this.name.split(' ')[1].charAt(0)).toUpperCase();
        }

        return undefined;
    }

    get firstName() {
        if (this.name) {
            return this.name.split(' ')[0];
        }

        return undefined;
    }

    get displayRole() {
        return titleize(this.role as string);
    }
}

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'ops-team-user': OpsTeamUser;
    }
}
