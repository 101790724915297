import Market from 'portal/services/market';
import { getOwner } from '@ember/application';

export default function validateMarket() {
    return async (
        _key: string,
        newValue: string,
        _oldValue: unknown,
        _changes: unknown,
        content: any
    ): Promise<true | string> => {

        const marketService: Market = getOwner(content).lookup('service:market');

        return marketService.loadMarkets().then((markets) => {
            const market = markets.findBy('state', newValue);
            if (market) {
                return true;
            }
            return 'Empora does not currently operate in this market.';
        });
    };
}
