import Component from '@glimmer/component';
import IndividualTitleholder from 'portal/models/individual-titleholder';
import { action } from '@ember/object';
import { isBlank } from '@ember/utils';

interface IndividualTitleholderSelectArgs {
    titleholder: IndividualTitleholder;
    showCheck: boolean;
    selected: boolean;
    showForwardingAddress: boolean;
    onSelect: (titleholder: IndividualTitleholder) => void;
    onDeselect: (titleholder: IndividualTitleholder) => void;
    onEdit: (titleholder: IndividualTitleholder) => void;
}

export default class IndividualTitleholderSelect extends Component<IndividualTitleholderSelectArgs> {
    get name(): string {
        const titleholder: IndividualTitleholder = this.args.titleholder;
        if (titleholder.isMarried) {
            return `${titleholder.firstName} ${titleholder.lastName} & ${titleholder.spouseFirstName} ${titleholder.spouseLastName}`;
        } else {
            return `${titleholder.firstName} ${titleholder.lastName}`;
        }
    }

    get forwardingAddress(): string {
        const titleholder: IndividualTitleholder = this.args.titleholder;
        const street = isBlank(titleholder.street2)
            ? titleholder.street
            : [titleholder.street, titleholder.street2].join(' ');
        const state_zipcode = [titleholder.state, titleholder.zipcode].join(' ');

        return [
            street,
            titleholder.city,
            state_zipcode
        ].join(', ');
    }

    @action
    selectClick() {
        const args = this.args;

        if (args.selected && args.onDeselect) {
            args.onDeselect(args.titleholder);
        }

        if (!args.selected && args.onSelect) {
            args.onSelect(args.titleholder);
        }
    }

    @action
    editClick() {
        const args = this.args;
        if (args.onEdit) {
            args.onEdit(args.titleholder);
        }
    }
}
