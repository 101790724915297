import Deal from './deal';
import MessageDocument from './message-document';
import Model, { AsyncBelongsTo, attr, belongsTo, hasMany } from '@ember-data/model';
import { htmlSafe } from '@ember/template';

export default class DealMessage extends Model {
    @attr('string')
        messageText?: string;

    @attr('date')
        createdAt?: Date;

    @attr('date')
        deletedAt?: Date;

    @attr('date')
        updatedAt?: Date;

    @attr('string')
        createdBy?: string;

    @attr('string')
        createdByInitials?: string;

    @attr('boolean')
        isAdminMessage?: boolean;

    @attr('boolean')
        isMyMessage?: boolean;

    @belongsTo('deal')
        deal!: AsyncBelongsTo<Deal>;

    @hasMany('message-document')
        messageDocuments?: MessageDocument[];

    get messageTextHtml() {
        return htmlSafe(this.messageText || '');
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'deal-message': DealMessage;
    }
}
