import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import ProtectedDealRoute from 'portal/routes/protected/deal';
import RouterService from '@ember/routing/router-service';
import { ActionItemTemplateKeys } from 'portal/models/action-item';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealTitleholderInformationComplete extends BaseRoute {
    @service
    declare router: RouterService;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Completed Titleholder Information');
    }

    async beforeModel() {
        const deal = this.modelFor(
            'protected.deal'
        ) as ModelFrom<ProtectedDealRoute>;

        const actionItem = (await deal.actionItems)?.findBy(
            'templateKey',
            ActionItemTemplateKeys.COMPLETE_TITLEHOLDER_INFORMATION
        );

        if (actionItem) {
            actionItem.hasCompleted = true;
            await actionItem.save();
            this.router.transitionTo('protected.deal.action-item-completion', actionItem.id);
        }

        this.router.replaceWith('protected.deal');
    }
}
