import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';


export default class Deal extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare auth: AuthService;

    @service
    declare router: RouterService;

    @service
    declare analytics: Analytics;

    afterModel(model: ModelFrom<Deal>) {
        this.analytics.trackPage('Show Deal', {
            deal_id: model.id
        });
    }

    model({ deal_id }: { deal_id: string }) {
        return this.store.findRecord('deal', deal_id, { reload: true });
    }

    @action
    error(): boolean {
        if (!this.modelFor('protected.deal')) {
            this.analytics.trackEvent('Error accessing deal');
            this.router.transitionTo('protected.deals');
        }

        return true;
    }
}

