import Model, { attr } from '@ember-data/model';

export enum TrackerEventStatus {
    COMPLETED = 'COMPLETED',
    NOT_COMPLETED = 'NOT_COMPLETED'
}

export enum TrackerEventName {
    DEAL_INTAKE_COMPLETED = 'Deal intake completed',
    TITLE_COMMITMENT_COMPLETED = 'Title commitment completed',
    CLOSING_CONFIRMED = 'Closing confirmed',
    ALL_PARTIES_SIGNED = 'All parties signed',
    FUNDING_COMPLETED = 'Funding completed',
    DEAL_RECORDED = 'Deal recorded'
}

export default class TrackerEvent extends Model {
    @attr('date')
        completedAt?: Date;

    @attr('string')
        name?: string;

    @attr('number')
        order?: number;

    @attr('string')
        status?: TrackerEventStatus;

    @attr('string')
        tooltip?: string;
}

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'tracker-event': TrackerEvent;
    }
}
