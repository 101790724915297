import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Deal from '../deal';
import DealSide, { DealSides } from 'portal/services/deal-side';
import Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
type Transition = ReturnType<RouterService['transitionTo']>;
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealTitleholderInformation extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare dealSide: DealSide;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Titleholder Information');
    }

    async model() {
        const deal = this.modelFor('protected.deal') as ModelFrom<Deal>;

        this.dealSide.switchTo(deal.id, DealSides.FUTURE);

        const legalEntities = (
            await this.store.findAll('legalEntity', {
                reload: true
            })
        ).toArray();

        return {
            legalEntities,
            deal
        };
    }

    @action
    async willTransition(transition: Transition) {
        if (
            !transition.to.name.includes(
                'protected.deal.titleholder-information'
            )
        ) {
            const deal = this.modelFor('protected.deal') as ModelFrom<Deal>;
            const titleholder = await deal.latestTitleholder(this.dealSide.for(deal.id));
            titleholder?.rollbackAttributes();
        }
    }
}
