import Deal from './deal';
import DocumentModel from './document';
import Model, {
    AsyncBelongsTo,
    attr,
    belongsTo
} from '@ember-data/model';
import { validateInclusion } from 'ember-changeset-validations/validators';

export default class ReissueRate extends Model {
    @attr('boolean')
        recentlyFinanced?: boolean;

    @attr('string')
        titleCompanyName?: string;

    @belongsTo('document')
        currentOwnersPolicyDocument?: AsyncBelongsTo<DocumentModel> | DocumentModel;

    @belongsTo('deal')
        deal?: AsyncBelongsTo<Deal> | Deal;
}

export const reissueRateValidations = {
    recentlyFinanced: validateInclusion({ list: [true, false] })
};

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'reissue-rate': ReissueRate;
  }
}
