import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedIndex extends BaseRoute {
    @service
    declare router: RouterService;

    @service
    declare analytics: Analytics;

    redirect() {
        this.router.replaceWith('protected.deals');
    }

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Index');
    }
}
