import Deal from './deal';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { DealSides } from 'portal/services/deal-side';

export default class DealsCloneTitleholderRequest extends Model {
    @attr('string')
        fromSide?: DealSides;

    @attr('string')
        toSide?: DealSides;

    @belongsTo('deal')
        deal?: AsyncBelongsTo<Deal>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'deals-clone-titleholder-request': DealsCloneTitleholderRequest;
  }
}
