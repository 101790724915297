import Component from '@glimmer/component';
import { DateTime } from 'luxon';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';

interface EmporaDateArgs {
    value?: Date;
    onInput?: (value: Date) => void;
}

export default class EmporaDate extends Component<EmporaDateArgs> {
    constructor(owner: unknown, args: EmporaDateArgs) {
        super(owner, args);
        if (this.args.value) {
            this.value = DateTime.fromJSDate(this.args.value)
                .toUTC()
                .toFormat('y-MM-dd');
        }
    }

    value?: string;

    @action
    onInput(value: string): void {
        if (!isEmpty(value) && this.args.onInput) {
            const [year, month, day] = value.split('-');
            this.args.onInput(
                DateTime.utc(
                    Number.parseInt(year),
                    Number.parseInt(month),
                    Number.parseInt(day)
                ).toJSDate()
            );
        } else if (this.args.onInput) {
            this.args.onInput(new Date(''));
        }
    }
}
