/* eslint-disable ember/use-ember-data-rfc-395-imports */
import ApplicationAdapter from './application';
import { ModelRegistry } from 'ember-cli-mirage';

export default class FeatureFlagAdapter extends ApplicationAdapter {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pathForType<K extends keyof ModelRegistry>(_modelName: K): string {
        return 'core/feature_flags';
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
    export default interface AdapterRegistry {
        'feature-flag': FeatureFlagAdapter;
    }
}
