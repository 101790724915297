import Model, { attr } from '@ember-data/model';
import fullName from 'portal/utils/full-name';
import { DealActorRoles } from './deal';

export default class CalendarAttendee extends Model {
    @attr('string')
        email?: string;

    @attr('string')
        entityName?: string;

    @attr('string')
        firstName?: string;

    @attr('string')
        middleName?: string;

    @attr('string')
        lastName?: string;

    @attr('string')
        suffix?: string;

    @attr('string')
        phoneNumber?: string;

    @attr('string')
        role?: DealActorRoles;

    @attr('string')
        individualTitleholderId?: string;

    @attr('string')
        entityTitleholderDesignatedSignerId?: string;

    get fullName(): string | undefined {
        return [
            fullName(this.firstName, this.middleName, this.lastName, this.suffix),
            this.entityName
        ].filter((s) => !!s).join(' for ');
    }
}