import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import DealSide from 'portal/services/deal-side';
import ProtectedDealRoute from 'portal/routes/protected/deal';
import RSVP from 'rsvp';
import Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
type Transition = ReturnType<RouterService['transitionTo']>;
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealOnboardingEntityTitleholder extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare dealSide: DealSide;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Onboarding / Entity Titleholder');
    }

    async model() {
        const deal = this.modelFor(
            'protected.deal'
        ) as ModelFrom<ProtectedDealRoute>;

        const titleholder = await deal.latestTitleholder(this.dealSide.for(deal.id));

        return RSVP.hash({
            titleholder,
            deal,
            newLegalEntity: this.store.createRecord('legalEntity', {
                wireInstruction: this.store.createRecord('legalEntityWireInstruction')
            }),
            wireInstructionsRequired: false
        });
    }

    @action
    willTransition(transition: Transition) {
        if (!transition.to.name.includes('protected.deal.onboarding.personal-information')) {
            this.modelFor(this.routeName).newLegalEntity.rollbackAttributes();
        }
    }
}
