import Address from 'portal/models/address';
import CreditLine from './credit-line';
import DocumentModel from 'portal/models/document';
import Model, {
    AsyncBelongsTo,
    AsyncHasMany,
    attr,
    belongsTo,
    hasMany
} from '@ember-data/model';
import validateEmail from 'portal/validators/email';
import validatePhoneNumber from 'portal/validators/phone-number';
import { DealSides } from 'portal/services/deal-side';
import { validateInclusion, validatePresence } from 'ember-changeset-validations/validators';

export default class Property extends Model {
    @attr('string')
        dealId!: string;

    @belongsTo('address')
        address!: AsyncBelongsTo<Address>;

    @attr('string')
        county?: string;

    @attr('boolean', { allowNull: true })
        hasCreditLines?: boolean;

    @attr('boolean', { allowNull: true })
        hasHoa?: boolean;

    @attr('boolean')
        hasTrashService?: boolean;

    @attr('boolean')
        hasWastewaterService?: boolean;

    @attr('boolean', { allowNull: true })
        isRental?: boolean;

    @attr('string')
        managementCompanyName?: string;

    @attr('string')
        managementCompanyEmail?: string;

    @attr('string')
        managementCompanyPhoneNumber?: string;

    @attr('string')
        trashCompany?: string;

    @attr('string')
        trashAccountNumber?: string;

    @attr('string')
        wastewaterCompany?: string;

    @attr('string')
        wastewaterAccountNumber?: string;

    @attr()
        parcelNumbers!: Array<string>;

    @hasMany('credit-line')
        creditLines?: AsyncHasMany<CreditLine>;

    @hasMany('document')
        leaseDocuments?: AsyncHasMany<DocumentModel>;

    @hasMany('document')
        rentRollDocuments?: AsyncHasMany<DocumentModel>;

    async creditLinesForDealSide(dealSide: DealSides | undefined): Promise<Array<CreditLine>> {
        await this.creditLines;

        return (this.creditLines ?? []).toArray().filter((creditLine) => {
            return creditLine?.dealSide === dealSide;
        });
    }
}

export const propertyValidations = {
    hasCreditLines: [validateInclusion({ message: 'Must specify whether or not there is a line of credit', list: [true, false] })],
    hasHoa: [validateInclusion({ message: 'Must specify whether or not there is an HOA', list: [true, false] })],
    managementCompanyEmail: [validateEmail('Email address', { allowBlank: true })],
    managementCompanyPhoneNumber: [validatePhoneNumber('Management company phone number', { allowBlank: true })]
};

export const sellerPropertyValidations = {
    hasCreditLines: [validateInclusion({ message: 'Must specify whether or not there is a line of credit', list: [true, false] })],
    hasHoa: [validateInclusion({ message: 'Must specify whether or not there is an HOA', list: [true, false] })],
    isRental: [validateInclusion({ message: 'Must specify whether or not this is a rental property', list: [true, false] })],
    managementCompanyEmail: [validateEmail('Email address', { allowBlank: true })],
    managementCompanyPhoneNumber: [validatePhoneNumber('Management company phone number', { allowBlank: true })]
};

export const rentalDocumentValidations = {
    leaseDocuments: validatePresence({ presence: true }),
    rentRollDocuments: validatePresence({ presence: true })
};

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        property: Property;
    }
}
