import ActionItem from 'portal/models/action-item';
import Component from '@glimmer/component';
import CreditLine from 'portal/models/credit-line';
import DealSide, { DealSides } from 'portal/services/deal-side';
import EntityTitleholder from 'portal/models/entity-titleholder';
import IndividualTitleholder from 'portal/models/individual-titleholder';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';

interface DealActionItemsBorrowerOnboardingArgs {
    actionItem: ActionItem;
    onRequestAChange: () => void;
    onStart: () => void;
}

export default class DealActionItemsBorrowerOnboarding extends Component<DealActionItemsBorrowerOnboardingArgs> {
    @service
        router!: RouterService;

    @service intl!: IntlService;

    @service
    declare dealSide: DealSide;

    @tracked
        cpcAcceptedBooleanValue?: boolean;

    @tracked
        creditLines: Array<CreditLine>;

    @tracked
        notaryPreference: string | undefined;

    @tracked
        titleholders: Array<IndividualTitleholder | EntityTitleholder>;

    constructor(owner: unknown, args: DealActionItemsBorrowerOnboardingArgs) {
        super(owner, args);

        taskFor(this.loadCpcAcceptedBooleanValue).perform();
        taskFor(this.loadNotaryPreference).perform();

        this.titleholders = [];
        taskFor(this.loadTitleholders).perform();

        this.creditLines = [];
        taskFor(this.loadCreditLines).perform();
    }

    @task
    async loadCpcAcceptedBooleanValue(): Promise<void> {
        const deal = await this.args.actionItem.deal;
        this.cpcAcceptedBooleanValue = await deal?.cpcAccepted;
    }

    @task
    async loadCreditLines(): Promise<void> {
        const deal = await this.args.actionItem.deal;
        const property = await deal?.property;
        if (property != undefined) {
            this.creditLines = await property.creditLinesForDealSide(DealSides.FUTURE);
        }
    }

    @task
    async loadNotaryPreference(): Promise<void> {
        const deal = await this.args.actionItem.deal;
        this.notaryPreference = deal?.notaryPreference;
    }

    @task
    async loadTitleholders(): Promise<void> {
        const deal = await this.args.actionItem.deal;
        if (deal != undefined) {
            this.titleholders = await deal.titleholdersForSide(DealSides.FUTURE);
        }
    }

    get hasCreditLines(): boolean {
        return this.creditLines.length > 0;
    }

    get hasTitleholders(): boolean {
        return this.titleholders.length > 0;
    }

    get notaryPreferenceDescription(): string {
        if (this.notaryPreference) {
            return this.intl.t(
                // eslint-disable-next-line max-len
                `components.deal.action_items.buyer_closing_preferences_and_coverages.notary_preference.${this.notaryPreference.toLowerCase()}`
            );
        }

        return this.intl.t('shared.not_selected');
    }
}
