import AuthBaseService from 'portal/services/auth-base';
import ENV from 'portal/config/environment';
import EmporaServer from 'portal/services/empora-server';
import Logger from 'portal/services/logger';
import { inject as service } from '@ember/service';

export default class Auth0MockService extends AuthBaseService {
    @service
        emporaServer!: EmporaServer;

    @service
        logger!: Logger;

    async getToken(): Promise<any> {
        return Promise.resolve(ENV.CLIENT_TEST_USER_AUTH0_ID);
    }

    async afterAuthentication(): Promise<void> {
        return await super.afterAuthentication();
    }

    async isAuthenticated(): Promise<boolean> {
        this.afterAuthentication();
        return Promise.resolve(true);
    }

    async isEmailVerified(): Promise<boolean> {
        return Promise.resolve(true);
    }

    async login(): Promise<void> {
        return Promise.resolve();
    }

    async logout(): Promise<void> {
        window.location.replace('https://www.emporatitle.com/');
    }
}

declare module '@ember/service' {
    interface Registry {
        'auth0-mock-service': Auth0MockService;
    }
}
