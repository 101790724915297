import Model, { attr } from '@ember-data/model';

export default class ActionItemLink extends Model {
    @attr('string')
        nextActionItemId?: string;
}

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        actionItemLink: ActionItemLink;
    }
}
