import Model, { attr } from '@ember-data/model';
import fullName from 'portal/utils/full-name';
import { validatePresence } from 'ember-changeset-validations/validators';

export default class LegalEntityDesignatedSigner extends Model {
    @attr('string')
        email?: string;

    @attr('string')
        firstName?: string;

    @attr('string')
        middleName?: string;

    @attr('string')
        lastName?: string;

    @attr('string')
        legalEntityId?: string;

    @attr('string')
        phoneNumber?: string;

    @attr('boolean')
        selectedAsTitleholderSigner?: boolean;

    @attr('string')
        title?: string;

    @attr('string')
        userProfileId?: string;

    get fullName(): string {
        return fullName(this.firstName, this.middleName, this.lastName);
    }

    get fullNameAndTitle(): string {
        return `${fullName(this.firstName, this.middleName, this.lastName)} - ${this.title}`.replace(/\s+/g, ' ').trim();
    }
}

export const legalEntityDesignatedSignerValidations = {
    legalEntityId: validatePresence({ presence: true }),
    title: validatePresence({ presence: true }),
    userProfileId: validatePresence({ presence: true })
};
