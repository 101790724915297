import Analytics from 'portal/services/analytics';
import ArrayProxy from '@ember/array/proxy';
import BaseRoute from 'portal/routes/base-route';
import Deal from 'portal/models/deal';
import DwollaFundingSource from 'portal/models/dwolla-funding-source';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealEarnestMoneyFundingSourceSelection extends BaseRoute {
    @service
    declare analytics: Analytics;

    @service
    declare store: Store;

    @service
    declare router: RouterService;

    async beforeModel() {
        this.store.unloadAll('dwolla-funding-source');
    }

    async model(): Promise<{deal: Deal, fundingSources: ArrayProxy<DwollaFundingSource>}> {
        const deal = await this.modelFor('protected.deal') as Deal;

        return {
            deal: deal!,
            fundingSources: await this.store.findAll('dwolla-funding-source')
        };
    }

    async afterModel(model: ModelFrom<ProtectedDealEarnestMoneyFundingSourceSelection>): Promise<void> {
        if (model.fundingSources.length == 0) {
            this.router.replaceWith('protected.deal.earnest-money.funding-source-creation');
        }
    }

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Earnest Money Funding Source Selection');
    }

    @action
    error(): boolean {
        this.analytics.trackEvent('Error on Earnest Money Funding Source Selection');
        return true;
    }
}
