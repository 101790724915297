import Component from '@glimmer/component';
import { DateTime } from 'luxon';

interface DatePickerDayButtonArgs {
    displayDate: DateTime;
    currentDate: DateTime;
    disableWeekends: boolean;
    day: number;
    disabled: boolean;
    selected: boolean;
}

export default class DatePickerDayButton extends Component<DatePickerDayButtonArgs> {
    get thisDate() {
        // set here returns a new DateTime
        // eslint-disable-next-line ember/no-side-effects
        return this.args.displayDate.set({ day: this.args.day });
    }

    get disabled() {
        if (this.args.disabled) {
            return true;
        }

        // Monday is weekday 1 and Sunday is 7
        if (this.args.disableWeekends && this.thisDate.weekday >= 6) {
            return true;
        }

        return (
            this.thisDate.startOf('day') < this.args.currentDate.startOf('day')
        );
    }

    get selected() {
        return this.args.selected || false;
    }

    baseCssClass =
        'date-picker__day-button h-10 w-10 rounded-full flex items-center justify-center focus:outline-none';

    get cssClass() {
        let cssClass = this.baseCssClass;

        if (!this.disabled) {
            cssClass = `border-2 ${cssClass}`;
        }

        if (this.selected) {
            cssClass = `bg-sapphire-100 border-sapphire-100 text-white ${cssClass}`;
        }

        return cssClass;
    }
}
