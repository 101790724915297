import Model, { attr } from '@ember-data/model';

export default class DwollaFundingSource extends Model {
    @attr('string')
        bankName?: string;

    @attr('string')
        bankAccountType?: string;

    @attr('string')
        name?: string;

    @attr('string')
        url?: string;

    @attr('string')
        iavToken?: string;

    @attr('string')
        publicToken?: string;

    @attr('string')
        plaidAccountId?: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'dwolla-funding-source': DwollaFundingSource;
  }
}
