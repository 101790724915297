import Analytics from 'portal/services/analytics';
import Controller from '@ember/controller';
import DocumentModel, { DocumentTypes } from 'portal/models/document';
import IntakeUser, { UserRoleType } from 'portal/models/intake-user';
import RSVP from 'rsvp';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import TeamMember from 'portal/models/team-member';
import { BufferedChangeset } from 'ember-changeset/types';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedIntakePurchaseContractRoute } from 'portal/routes/protected/intake/purchase-contract';
import { intakePurchaseContractValidations } from 'portal/models/intake-purchase-contract';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class ProtectedIntakePurchaseContract extends Controller {
    declare model: ModelFrom<ProtectedIntakePurchaseContractRoute>;

    validations = intakePurchaseContractValidations;

    @tracked
        running = false;

    @service
    declare analytics: Analytics;

    @service
    declare router: RouterService;

    @service
    declare store: Store;

    @task
    async onNext(
        changeset: BufferedChangeset,
        intakeUser: IntakeUser | BufferedChangeset,
        selectedTeamMembers: TeamMember[]
    ): Promise<void> {
        this.running = true;
        const documents = changeset.get('documents') as DocumentModel[];

        return RSVP.all(documents.map(async (document) => {
            if (intakeUser.userRole == UserRoleType.borrower) {
                document.type = DocumentTypes.other_lender_documents;
            } else {
                document.type = DocumentTypes.purchase_contract;
            }
            return await document.save();
        })).then(async (documents: any) => {
            changeset.set('documents', documents);
            changeset.set('intakeUser', intakeUser);
            const teamMembers = await this.model.team?.teamMembers || [];

            if (selectedTeamMembers.length > 0) {
                this.store.createRecord('intake-team-member', {
                    teamMember: teamMembers.find((teamMember: TeamMember) => teamMember.currentUser),
                    intakeUser: intakeUser
                });

                selectedTeamMembers.forEach((teamMember: TeamMember) => {
                    this.store.createRecord('intake-team-member', {
                        teamMember: teamMember,
                        intakeUser: intakeUser
                    });
                });
            } else if (this.model.team && this.model.activeTeamMembers.length == 0) {
                this.store.createRecord('intake-team-member', {
                    teamMember: teamMembers.find((teamMember: TeamMember) => teamMember.currentUser),
                    intakeUser: intakeUser
                });
            }

            const purchaseContract = await changeset.save();

            this.analytics.trackEvent('Intake / Purchase Contract / Next Clicked');
            this.router.transitionTo('protected.intake.deal-request-confirmation', purchaseContract.createDealRequestId);
            this.running = false;
        });
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/intake/purchase-contract': ProtectedIntakePurchaseContract;
    }
}
