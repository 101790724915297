import Model, {
    AsyncBelongsTo,
    attr,
    belongsTo
} from '@ember-data/model';
import User from './user';

export enum Section {
    personal_details = 'personal_details',
    communication_preferences = 'communication_preferences',
    entity_details = 'entity_details',
    team_details = 'team_details'
}

export enum Status {
    canceled = 'canceled',
    completed = 'completed'
}

export default class GuidedAccountCreationSection extends Model {
    @attr('string')
        section?: Section;

    @attr('string')
        status?: Status;

    @belongsTo('user')
        user?: AsyncBelongsTo<User>;

    get finished(): boolean {
        return (this.status == Status.completed || this.status == Status.canceled);
    }
}

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'guided-account-creation-section': GuidedAccountCreationSection;
    }
}
