import Component from '@glimmer/component';
import Deal, { DealActorRoles } from 'portal/models/deal';
import DealSide from 'portal/services/deal-side';
import EntityTitleholder from 'portal/models/entity-titleholder';
import IndividualTitleholder from 'portal/models/individual-titleholder';
import LegalEntity from 'portal/models/legal-entity';
import LegalIndividual from 'portal/models/legal-individual';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import User from 'portal/models/user';
import { TitleholderType } from 'portal/models/titleholder';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface SelectTitleholderArgs {
    deal: Deal;
    user: User;
    legalIndividuals: LegalIndividual[],
    legalEntities: LegalEntity[]
    onTitleholderSelect: (titleholders: (EntityTitleholder | IndividualTitleholder)[]) => Promise<void>;
    onCancel: () => Promise<void>;
}

export default class SelectTitleholder extends Component<SelectTitleholderArgs> {
    @service
    declare dealSide: DealSide;

    @service
    declare router: RouterService;

    @service
    declare store: Store;

    @tracked
    declare createTitleholderType: TitleholderType | undefined;

    @tracked
    declare editingTitleholder: IndividualTitleholder | EntityTitleholder | undefined;

    @tracked
    declare individualTitleholders: IndividualTitleholder[];

    @tracked
    declare reviewingTitleholder: boolean;

    @tracked
    declare selectedTitleholders: (IndividualTitleholder | LegalEntity)[];

    legalEntitiesBeingReviewed: LegalEntity[] = [];

    constructor(owner: unknown, args: SelectTitleholderArgs) {
        super(owner, args);

        const selectedTitleholders: (IndividualTitleholder | LegalEntity)[] = [];
        this.individualTitleholders = this.args.legalIndividuals.map((legalIndividual) => {
            const titleholder = this.store.createRecord('individual-titleholder', {
                deal: this.args.deal,
                dealSide: this.dealSide.for(this.args.deal.id),
                firstName: legalIndividual.firstName,
                middleName: legalIndividual.middleName,
                lastName: legalIndividual.lastName,
                suffix: legalIndividual.suffix,
                email: legalIndividual.email,
                phoneNumber: legalIndividual.phoneNumber,
                birthday: legalIndividual.birthday,
                taxId: legalIndividual.taxId,
                isMarried: legalIndividual.isMarried,
                street: legalIndividual.street,
                street2: legalIndividual.street2,
                city: legalIndividual.city,
                state: legalIndividual.state,
                zipcode: legalIndividual.zipcode,
                inviteToDeal: false,
                addToDealAsTitleholder: false
            }
            );

            if (titleholder.isMarried) {
                titleholder.spouseFirstName = legalIndividual.spouseFirstName;
                titleholder.spouseMiddleName = legalIndividual.spouseMiddleName;
                titleholder.spouseLastName = legalIndividual.spouseLastName;
                titleholder.spouseSuffix = legalIndividual.spouseSuffix;
                titleholder.spouseTaxId = legalIndividual.spouseTaxId;
                titleholder.spouseEmail = legalIndividual.spouseEmail;
            }

            if (legalIndividual.userId === this.args.user.id && legalIndividual.hasRequiredTitleholderAttributes) {
                if (this.args.deal.actorRole === DealActorRoles.BUYER || !!legalIndividual.taxId) {
                    selectedTitleholders.addObject(titleholder);
                }
            }

            return titleholder;
        });
        this.selectedTitleholders = selectedTitleholders;

        this.reviewingTitleholder = false;
    }

    get performingTitleholderActions(): boolean {
        return !!this.editingTitleholder || !!this.createTitleholderType || this.reviewingTitleholder;
    }

    get isBuyerActorRole() {
        return this.args.deal.actorRole === DealActorRoles.BUYER;
    }

    @action
    addEntity() {
        this.createTitleholderType = TitleholderType.llc;
    }

    @action
    addIndividual() {
        this.createTitleholderType = TitleholderType.individual;
    }

    @action
    editEntity(legalEntity: LegalEntity) {
        this.editingTitleholder = this.store.createRecord('entityTitleholder', {
            existingLegalEntity: legalEntity
        });
    }

    @action
    editIndividual(individual: IndividualTitleholder) {
        this.editingTitleholder = individual;
    }

    @action
    onCheck(titleholder: IndividualTitleholder | LegalEntity) {
        if (this.selectedTitleholders.includes(titleholder)) {
            this.selectedTitleholders.removeObject(titleholder);
        } else {
            this.selectedTitleholders.addObject(titleholder);
        }
    }

    @action
    onInviteChange(individualTitleholder: IndividualTitleholder) {
        individualTitleholder.inviteToDeal = !individualTitleholder.inviteToDeal;
    }

    @action
    onTitleholderReview() {
        this.legalEntitiesBeingReviewed = this.selectedTitleholders.filter((th) => th instanceof LegalEntity) as LegalEntity[];
        if (this.legalEntitiesBeingReviewed.length) {
            this.reviewingTitleholder = true;
        } else {
            this.onTitleholderSelect();
        }
    }

    @action
    onTitleholderSelect() {
        this.reviewingTitleholder = false;

        const deal = this.args.deal;
        this.args.onTitleholderSelect(
            this.selectedTitleholders.map((th) => {
                if (th instanceof LegalEntity) {
                    const entityTitleholder = this.store.createRecord('entityTitleholder', {
                        entityName: th.entityName,
                        taxId: th.taxId,
                        email: th.email,
                        phoneNumber: th.phoneNumber,
                        street: th.street,
                        street2: th.street2,
                        city: th.city,
                        state: th.state,
                        zipcode: th.zipcode,
                        dealSide: this.dealSide.for(deal.id),
                        deal: deal,
                        existingLegalEntity: th
                    });

                    const selectedDesignatedSigners = th.designatedSigners?.toArray().filter(
                        (designatedSigner) => designatedSigner.selectedAsTitleholderSigner
                    );
                    if (selectedDesignatedSigners) {
                        for (const selectedDesignatedSigner of selectedDesignatedSigners) {
                            const newEntityTitleholderDesignatedSigner = this.store.createRecord('entity-titleholder-designated-signer', {
                                email: selectedDesignatedSigner?.email,
                                firstName: selectedDesignatedSigner?.firstName,
                                middleName: selectedDesignatedSigner?.middleName,
                                lastName: selectedDesignatedSigner?.lastName,
                                legalEntityDesignatedSignerId: selectedDesignatedSigner?.id,
                                phoneNumber: selectedDesignatedSigner?.phoneNumber,
                                title: selectedDesignatedSigner?.title
                            });
                            entityTitleholder.designatedSigners?.pushObject(newEntityTitleholderDesignatedSigner);
                        }
                    }

                    return entityTitleholder;
                } else {
                    return th;
                }
            })
        );
    }

    @action
    titleholderAddEditCancel() {
        this.createTitleholderType = undefined;
        this.editingTitleholder = undefined;
    }

    @action
    titleholderAdded(titleholder: IndividualTitleholder | EntityTitleholder) {
        this.args.onTitleholderSelect([titleholder]);
    }

    @action
    titleholderEdited() {
        this.editingTitleholder = undefined;
    }
}
