import Deal from './deal';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

export enum Status {
    BLOCKED = 'blocked',
    CLEARED = 'cleared',
    COMPLETED = 'completed',
    IN_PROGRESS = 'in_progress',
    NEEDS_VERIFICATION = 'needs_verification',
    NOT_APPLICABLE = 'not_applicable',
    NOT_STARTED = 'not_started'
}

export enum CustomerStatus {
    BLOCKED = 'Blocked: Action Needed',
    CLEARED = 'Resolved',
    COMPLETED = 'Resolved',
    IN_PROGRESS = 'In Progress',
    NEEDS_VERIFICATION = 'In Progress',
    NOT_APPLICABLE = '',
    NOT_STARTED = 'Received'
}

export default class ClearToCloseTitleItem extends Model {
    @attr('string')
        name?: string;

    @attr('string')
        status?: string;

    @attr('string')
        customerName?: string;

    @attr('string')
        customerStatus?: string;

    @attr('string')
        customerExplanation?: string;

    @attr('string')
        customerSpecificTimeline?: string;

    @attr('boolean')
        blockedByPayoffAuth?: boolean;

    @belongsTo('deal')
        deal!: AsyncBelongsTo<Deal>;

    mayNeedMoreInformation(): boolean{
        return this.status === Status.BLOCKED || !!this.blockedByPayoffAuth;
    }

    isCompleted(): boolean{
        return this.status === Status.COMPLETED || this.status === Status.CLEARED;
    }

    get timelineDisplay(): string {
        if (this.isCompleted() || this.status === Status.BLOCKED) {
            return this.customerStatus!;
        }

        return this.customerSpecificTimeline!;
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'clear-to-close-title-item': ClearToCloseTitleItem;
    }
}
