import Controller from '@ember/controller';
import DwollaFundingSource from 'portal/models/dwolla-funding-source';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedPlaidOauthLandingRoute } from 'portal/routes/protected/plaid/oauth-landing';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export const IGNORED_PLAID_ERRORS = [ 'INSUFFICIENT_CREDENTIALS', 'INVALID_CREDENTIALS' ];

export default class ProtectedPlaidOauthLanding extends Controller {
    queryParams = ['oauth_state_id'];

    declare model: ModelFrom<ProtectedPlaidOauthLandingRoute>;

    @service
    declare router: RouterService;

    @action
    async onNext(fundingSource: DwollaFundingSource) {
        const deal = await this.model.deal;

        await fundingSource.save();
        await this.model.save();

        this.router.transitionTo('protected.deal.earnest-money.portal-transfer',deal.id, fundingSource.id);
    }

    @action
    async onError(err: any) {
        if (!IGNORED_PLAID_ERRORS.includes(err?.error_code)) {
            throw `Plaid exception creating new funding source: ${JSON.stringify(err)}`;
        }
    }

}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/plaid/oauth-landing': ProtectedPlaidOauthLanding;
    }
}
