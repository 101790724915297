import Controller from '@ember/controller';
import LegalEntityModel from 'portal/models/legal-entity';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealOnboardingSelectEntity extends Controller {
    @service
    declare router: RouterService;

    @action
    onNext() {
        this.router.transitionTo('protected.deal.onboarding.confirm-entity-details');
    }

    @action
    onAdd() {
        this.router.transitionTo('protected.deal.onboarding.entity-titleholder');
    }

    @action
    onEdit(legalEntity: LegalEntityModel) {
        this.router.transitionTo('protected.deal.onboarding.edit-entity', {
            legalEntity,
            wireInstructionsRequired: this.model.wireInstructionsRequired
        }
        );
    }
}
