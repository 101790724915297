import Controller from '@ember/controller';
import ProtectedDealTitleholderInformationNewIndividualRoute from 'portal/routes/protected/deal/titleholder-information/new-individual';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealTitleholderInformationNewIndividual extends Controller {
    declare model: ModelFrom<ProtectedDealTitleholderInformationNewIndividualRoute>;

    @service
    declare router: RouterService;

    @action
    async onNext() {
        this.router.transitionTo('protected.deal.titleholder-information.multiselect-individual-titleholder');
    }
}