import Address from './address';
import CalendarAppointment from './calendar-appointment';
import Model, { attr, belongsTo } from '@ember-data/model';
import { oneLineAddress } from 'portal/helpers/company-info';
import { validatePresence } from 'ember-changeset-validations/validators';

export enum SigningDetailSigningMethods {
    THIRD_PARTY_MOBILE = 'third_party_mobile',
    THIRD_PARTY_REMOTE_ONLINE_NOTARY = 'third_party_remote_online_notary',
    INTERNAL_MOBILE = 'internal_mobile',
    INTERNAL_REMOTE_ONLINE_NOTARY = 'internal_remote_online_notary',
    INTERNAL_IN_OFFICE = 'internal_in_office',
    HELLO_SIGN = 'hello_sign'
}

export enum LocationDisplays {
    HELLO_SIGN = 'Online through Dropbox Hello Sign',
    NOTARIZE = 'Online through Notarize'
}

export default class SigningDetail extends Model {
    @attr('string')
    declare dealId: string;

    @attr('string')
    declare signingMethod: SigningDetailSigningMethods;

    @attr('boolean')
    declare buyerSigning: boolean;

    @attr('boolean')
    declare sellerSigning: boolean;

    @belongsTo('address')
    declare signingLocation: Address;

    @belongsTo('calendar-appointment')
    declare appointment: CalendarAppointment;

    get displaySigningLocation(): string {
        switch (this.signingMethod) {
        case SigningDetailSigningMethods.THIRD_PARTY_MOBILE:
        case SigningDetailSigningMethods.INTERNAL_MOBILE:
            return this.signingLocation.get('oneLine');
        case SigningDetailSigningMethods.INTERNAL_IN_OFFICE:
            return oneLineAddress();
        case SigningDetailSigningMethods.THIRD_PARTY_REMOTE_ONLINE_NOTARY:
        case SigningDetailSigningMethods.INTERNAL_REMOTE_ONLINE_NOTARY:
            return LocationDisplays.NOTARIZE;
        case SigningDetailSigningMethods.HELLO_SIGN:
            return LocationDisplays.HELLO_SIGN;
        default:
            return '';
        }
    }
}

export const signingDetailValidations = {
    signingMethod: validatePresence({ presence: true, message: 'Must select a closing option' })
};

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        signingDetail: SigningDetail;
    }
}