import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import TaxAddress from 'portal/models/tax-address';
import { ActionItemTemplateKeys } from 'portal/models/action-item';
import { BufferedChangeset, lookupValidator } from 'validated-changeset';
import { Changeset } from 'ember-changeset';
import { ModelFrom } from 'portal/utils/type-utils';
import {
    default as ProtectedBuyerClosingPreferencesAndCoveragesRoute
} from 'portal/routes/protected/deal/onboarding/lender-tax-address-info';
import { action } from '@ember/object';
import { addressValidations } from 'portal/models/titleholder';
import { dealBuyerValidations } from 'portal/models/deal';
import { inject as service } from '@ember/service';

export default class ProtectedBuyerClosingPreferencesAndCoverages extends Controller {
    declare model: ModelFrom<ProtectedBuyerClosingPreferencesAndCoveragesRoute>;

    @service
    declare store: Store;

    @service
    declare router: RouterService;

    get hasCreditLines(): boolean {
        return (this.model.creditLines || []).length > 0;
    }

    get dealChangeset(): BufferedChangeset {
        return Changeset(this.model.deal, lookupValidator(dealBuyerValidations), dealBuyerValidations);
    }

    get taxAddressChangeset(): BufferedChangeset {
        const taxAddress = this.model.taxAddress || this.store.createRecord('tax-address', {
            street: '',
            street2: '',
            city: '',
            state: '',
            zipcode: '',
            taxAddressType: null,
            deal: this.model.deal
        });

        const changeset = Changeset(
            taxAddress,
            lookupValidator(addressValidations),
            addressValidations,
            {
                changesetKeys: Object.keys(TaxAddress.attributes)
            }
        );

        return changeset;
    }

    @action
    async onSubmit() {
        const actionItem = (await this.model.deal?.actionItems)?.find(
            (actionItem) => actionItem.templateKey === ActionItemTemplateKeys.BUYER_CLOSING_PREFERENCES_AND_COVERAGES
        );

        if (actionItem) {
            actionItem.hasCompleted = true;
            actionItem.save();
        }

        this.router.transitionTo('protected.deal.action-item-completion', actionItem?.id as string);
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/deal/buyer-closing-preferences-and-coverages': ProtectedBuyerClosingPreferencesAndCoverages;
    }
}
