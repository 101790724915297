import AuthService from 'portal/services/auth';
import Component from '@glimmer/component';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface MyAccountSidebarSidebarArgs {}

export default class MyAccountSidebarSidebar extends Component<MyAccountSidebarSidebarArgs> {
    @service
    declare auth: AuthService;

    @service
        router!: RouterService;

    @tracked
        horizontal?: boolean = false;

    get name() {
        const user = this.auth.user;
        if (user?.givenName && user?.familyName)
            return `${user?.givenName} ${user?.familyName}`;
        return '';
    }

    @action
    logout() {
        this.auth.logout();
    }

    @action
    onPersonalDetails() {
        this.router.transitionTo('protected.my-account.personal-details');
    }

    @action
    onMyEntities() {
        this.router.transitionTo('protected.my-account.my-entities');
    }

    @action
    onMyTeam() {
        this.router.transitionTo('protected.my-account.team-overview');
    }

    @action
    onEarnestTransactions() {
        this.router.transitionTo('protected.my-account.earnest-transactions');
    }

    @action
    onMyTitleholders() {
        this.router.transitionTo('protected.my-account.my-titleholders');
    }

    @action
    onCommunicationPreferences() {
        this.router.transitionTo('protected.my-account.communication-preferences');
    }
}
