import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import DealSide from 'portal/services/deal-side';
import { default as DealRoute } from 'portal/routes/protected/deal';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealOnboardingLenderTaxAddressInfo extends BaseRoute {
    @service
    declare dealSide: DealSide;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Onboarding / Lender and Tax Address Info');
    }

    async model() {
        const deal: ModelFrom<DealRoute> = this.modelFor('protected.deal');
        const property = await deal.property;
        const creditLines = await property?.creditLinesForDealSide(this.dealSide.for(deal.id));
        const titleholder = await deal?.latestTitleholder(this.dealSide.for(deal.id));
        const taxAddress = await deal.taxAddress;

        return {
            deal,
            property,
            titleholder,
            creditLines,
            taxAddress
        };
    }

    @action
    invalidateModel(): void {
        this.refresh();
    }
}
