import Analytics from 'portal/services/analytics';
import Component from '@glimmer/component';
import DocumentModel from 'portal/models/document';
import Store from '@ember-data/store';
import fileTypeList, { defaultValidFileTypes } from 'portal/utils/file-type-list';
import { UploadFile } from 'ember-file-upload';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface EmporaDocumentUploadArgs {
    validFiletypes?: string[];
    inputId?: string;
    isUploading?: boolean;
    onInputClick?: () => void;
    onUpload?: (
        doc: DocumentModel,
        DocumentUploadInstance: EmporaDocumentUpload
    ) => void;
    document?: DocumentModel;
}


export default class EmporaDocumentUpload extends Component<EmporaDocumentUploadArgs> {
    @service
    declare analytics: Analytics;

    @service
    declare store: Store;

    @tracked
        file?: File;

    @tracked
        document?: DocumentModel;

    constructor(owner: unknown, args: EmporaDocumentUploadArgs) {
        super(owner, args);
        this.document = args.document;
    }

    get validFiletypes() {
        return this.args.validFiletypes || defaultValidFileTypes();
    }

    get fileName(): string {
        if (!this.file) {
            return '';
        }
        return this.file.name.substr(0, this.file.name.lastIndexOf('.'));
    }

    get fileType(): string {
        if (!this.file) {
            return '';
        }
        return this.file.name.split('.').lastObject || '';
    }

    get fileTypeValid(): boolean {
        if (!this.file) {
            return false;
        }

        return this.validFiletypes.includes(this.fileType.toLowerCase());
    }

    get displayFileTypeInvalid(): boolean {
        return !this.fileTypeValid && !!this.file;
    }

    get displayFileSizeInvalid(): boolean {
        return !!this.file && this.file.size < 1;
    }

    get fileTypeList(): string {
        return fileTypeList(this.validFiletypes);
    }

    clearDocument() {
        this.file = undefined;
        this.document = undefined;
    }

    @action
    onInputClick() {
        this.args.onInputClick?.();
    }

    @action
    onDragAndDrop(uploadFile: UploadFile) {
        this.analytics.trackEvent('Document uploaded via drag and drop');
        this.createDocument(uploadFile.file as File);
    }

    @action
    onInputChange(e: Event) {
        const files = (e.target as HTMLInputElement).files;
        if (files === null) return;
        if (files.length === 0) return;

        this.analytics.trackEvent('Document uploaded via select a file to upload');
        this.createDocument(files[0] as File);

        // This is a bit of a hack to allow subsequent uploads of the same file name on a single page
        // the change event doesn't fire if a file with the same filename is uploaded twice even if they are different
        (e.target as HTMLInputElement).value = '';
    }

    createDocument(file: File) {
        this.file = file;

        this.document = this.store.createRecord('document', {
            filetype: `.${this.fileType}`,
            name: this.fileName,
            file: this.file
        });

        if (!this.fileTypeValid) return;

        if (file.size < 1) return;

        if (this.args.onUpload) {
            this.args.onUpload(this.document, this);
        }
    }
}
