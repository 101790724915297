import Component from '@glimmer/component';
import ReissueRate from 'portal/models/reissue-rate';
import { BufferedChangeset } from 'ember-changeset/types';
import { DealActorRoles } from 'portal/models/deal';
import { action } from '@ember/object';
import { isNone } from '@ember/utils';
import { street } from 'portal/helpers/company-info';
import { tracked } from '@glimmer/tracking';

interface CpcIntermediaryNotaryArgs {
    disabled?: boolean;
    dealChangeset: BufferedChangeset;
    userChangeset?: BufferedChangeset;
    onNext: () => void;
    actorRole: string;
    reissueRate?: ReissueRate;
}

export default class CpcIntermediaryNotary extends Component<CpcIntermediaryNotaryArgs> {
    showCommunicationPreferences: boolean = this.args.userChangeset?.get('hasEmptyCommPreferences')
                                                && this.args.actorRole === DealActorRoles.BORROWER;

    @tracked
        showErrorBanner?: boolean = false;

    @action
    validate(): void {
        this.args.dealChangeset.set('showErrorOnPristine', false);
        this.args.dealChangeset.validate();
    }

    @action
    onIntermediaryPreferenceChange(value: string): void {
        this.args.dealChangeset.set('intermediaryPreference', value);

        if (value !== 'YES') {
            this.args.dealChangeset.set('intermediaryPreferenceYes', false);
            this.args.dealChangeset.set('intermediaryPhoneNumber', null);
            this.args.dealChangeset.set('intermediaryName', null);
            this.args.dealChangeset.validate();
        } else if (value === 'YES') {
            this.args.dealChangeset.set('intermediaryPreferenceYes', true);
            this.args.dealChangeset.validate();
        }
    }

    get reissueRateInvalid(): boolean {
        if (this.args.actorRole !== DealActorRoles.SELLER) return false;
        return isNone(this.args.reissueRate?.get('recentlyFinanced'));
    }

    get disabledNext(): boolean {
        return !!this.args.disabled;
    }

    get inPersonCopy(): string {
        return `Meet in-person at our office located at ${street()}.`;
    }

    @action
    async onNext() {
        this.args.dealChangeset.set('showErrorOnPristine', true);

        if (this.args.dealChangeset?.isInvalid) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        await this.args.onNext();
    }
}
