import LegalIndividualWireInstruction from 'portal/models/legal-entity-wire-instruction';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import User from 'portal/models/user';

export default class LegalIndividual extends Model {
    @belongsTo('user')
        userProfile?: AsyncBelongsTo<User>;

    @attr('string')
        userId?: string;

    @attr('string')
        parentUserId?: string;

    @attr('boolean')
        usCitizen?: boolean;

    @attr('string')
        email?: string;

    @attr('string')
        phoneNumber?: string;

    @attr('string')
        phoneNumberType?: string;

    @attr('string')
        firstName?: string;

    @attr('string')
        middleName?: string;

    @attr('string')
        lastName?: string;

    @attr('string')
        suffix?: string;

    @attr('date')
        birthday?: Date;

    @attr('string')
        taxId?: string;

    @attr('boolean')
        isMarried?: boolean;

    @attr('string')
        spouseFirstName?: string;

    @attr('string')
        spouseMiddleName?: string;

    @attr('string')
        spouseLastName?: string;

    @attr('string')
        spouseSuffix?: string;

    @attr('string')
        spouseEmail?: string;

    @attr('date')
        spouseBirthday?: Date;

    @attr('string')
        spouseTaxId?: string;

    @attr('string')
        street?: string;

    @attr('string')
        street2?: string;

    @attr('string')
        city?: string;

    @attr('string')
        state?: string;

    @attr('string')
        zipcode?: string;

    @belongsTo('user')
        deal!: AsyncBelongsTo<User>;

    @belongsTo('legal-individual-wire-instruction')
        legalIndividualWireInstruction?: LegalIndividualWireInstruction;

    get hasRequiredTitleholderAttributes(): boolean {
        return (
            this.birthday !== null && this.birthday !== undefined &&
            this.street !== null && this.street !== undefined &&
            this.city !== null && this.city !== undefined &&
            this.zipcode !== null && this.zipcode !== undefined &&
            this.isMarried !== null && this.isMarried !== undefined
        );
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'legal-individual': LegalIndividual;
  }
}
