import ApplicationAdapter from 'portal/adapters/application';
import Service from '@ember/service';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';

export default class EmporaServer extends Service {
    @service
        store!: Store;

    private applicationAdapter = this.store.adapterFor(
        'application'
    ) as ApplicationAdapter;

    formatUrl(requestPath: string) {
        let url: string;
        if (requestPath.startsWith('http')) {
            url = requestPath;
        } else {
            url = `${this.applicationAdapter.host}/${this.applicationAdapter.namespace}${requestPath}`;
        }

        return url;
    }

    private get headers() {
        return this.applicationAdapter.headers;
    }

    async fetchData(requestPath: string, method = 'GET'): Promise<Response> {
        return fetch(this.formatUrl(requestPath), {
            method,
            headers: this.headers
        });
    }

    async getRequest(requestPath: string): Promise<Response> {
        return this.fetchData(requestPath, 'GET');
    }

    async patchRequest(requestPath: string): Promise<Response> {
        return this.fetchData(requestPath, 'PATCH');
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
    interface Registry {
        'empora-server': EmporaServer;
    }
}
