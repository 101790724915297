import CalendarAttendee from 'portal/models/calendar-attendee';
import Component from '@glimmer/component';
import Deal, { DealActorRoles } from 'portal/models/deal';
import EntityTitleholder from 'portal/models/entity-titleholder';
import IndividualTitleholder from 'portal/models/individual-titleholder';
import Store from '@ember-data/store';
import { AsyncHasMany } from '@ember-data/model';
import { BufferedChangeset } from 'ember-changeset/types';
import { DealSides } from 'portal/services/deal-side';
import { TitleholderType } from 'portal/models/titleholder';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';

interface ClosingRequestSignersArgs {
    deal: Deal;
    appointmentChangeset: BufferedChangeset;
}

export default class ClosingRequestSigners extends Component<ClosingRequestSignersArgs> {
    @service
    declare store: Store;

    @tracked
        availableSigners: CalendarAttendee[] = [];

    @tracked
        selectedSigners: CalendarAttendee[] = [];

    @tracked
        previousSigners: CalendarAttendee[] = [];

    constructor(owner: unknown, args: ClosingRequestSignersArgs) {
        super(owner, args);
        taskFor(this.loadTitleholders).perform();
    }

    @task
    async loadTitleholders(): Promise<void> {
        const deal = this.args.deal;
        const titleholders: Array<IndividualTitleholder | EntityTitleholder> = await this.fetchTitleholders();

        for (const titleholder of titleholders) {
            if (titleholder.type === TitleholderType.individual) {
                const individualTitleholder = titleholder as IndividualTitleholder;
                const signerAttributes = {
                    individualTitleholderId: individualTitleholder.id,
                    email: individualTitleholder.email,
                    phoneNumber: individualTitleholder.phoneNumber,
                    firstName: individualTitleholder.firstName,
                    lastName: individualTitleholder.lastName,
                    middleName: individualTitleholder.middleName,
                    suffix: individualTitleholder.suffix,
                    role: deal.actorRole
                };

                if (individualTitleholder.hasSigning) {
                    this.addPreviousSigner(signerAttributes);
                } else {
                    this.addAvailableSigner(signerAttributes);

                    if (individualTitleholder.isMarried) {
                        this.addAvailableSigner({
                            individualTitleholderId: individualTitleholder.id,
                            email: individualTitleholder.spouseEmail,
                            firstName: individualTitleholder.spouseFirstName,
                            lastName: individualTitleholder.spouseLastName,
                            middleName: individualTitleholder.spouseMiddleName,
                            suffix: individualTitleholder.spouseSuffix,
                            role: deal.actorRole
                        });
                    }
                }
            } else {
                const entityTitleholder = titleholder as EntityTitleholder;
                const designatedSigners = (await entityTitleholder.designatedSigners)!.toArray();

                for (const designatedSigner of designatedSigners) {
                    const signerAttributes = {
                        entityTitleholderDesignatedSignerId: designatedSigner.id,
                        entityName: entityTitleholder.entityName,
                        email: designatedSigner.email,
                        firstName: designatedSigner.firstName,
                        middleName: designatedSigner.middleName,
                        lastName: designatedSigner.lastName,
                        phoneNumber: designatedSigner.phoneNumber,
                        role: deal.actorRole
                    };

                    if (designatedSigner.hasSigning) {
                        this.addPreviousSigner(signerAttributes);
                    } else {
                        this.addAvailableSigner(signerAttributes);
                    }
                }
            }
        }

        if (this.availableSigners.length === 1) {
            this.onSignerCheck(this.availableSigners[0]);
        }
    }

    async reloadTitleholdersFromServer() {
        await (this.args.deal.titleholders as AsyncHasMany<EntityTitleholder | IndividualTitleholder>).reload();
    }

    async fetchTitleholders(): Promise<Array<IndividualTitleholder | EntityTitleholder>> {
        await this.reloadTitleholdersFromServer();

        switch (this.args.deal.actorRole) {
        case DealActorRoles.BUYER:
            return this.args.deal.titleholdersForSide(DealSides.FUTURE);
        case DealActorRoles.SELLER:
            return this.args.deal.titleholdersForSide(DealSides.CURRENT);
        default: {
            const titleholders = await this.args.deal.titleholders;
            return titleholders ? titleholders.toArray() : [];
        }
        }
    }

    addAvailableSigner(attributes: Record<string, any>) {
        const signer = this.store.createRecord('calendarAttendee', attributes);
        this.availableSigners.addObject(signer);
    }

    addPreviousSigner(attributes: Record<string, any>) {
        const signer = this.store.createRecord('calendarAttendee', attributes);
        this.previousSigners.addObject(signer);
    }

    @action
    onSignerCheck(signer: CalendarAttendee) {
        if (this.selectedSigners.includes(signer)) {
            this.selectedSigners.removeObject(signer);
        } else {
            this.selectedSigners.addObject(signer);
        }

        this.args.appointmentChangeset.set('attendees', this.selectedSigners);
    }

    get attendeeErrors() {
        return this.args.appointmentChangeset.errors.findBy('key', 'attendees')?.validation;
    }
}
