import * as Sentry from '@sentry/ember';
import ENV from 'portal/config/environment';
import Service from '@ember/service';
import { SeverityLevel } from '@sentry/types';
import { core, usAutocompletePro, usStreet } from 'smartystreets-javascript-sdk';

export default class AddressAutocomplete extends Service {
    lookupClient: core.Client<usAutocompletePro.Lookup, usAutocompletePro.Lookup>;
    streetClient: core.Client<usStreet.Lookup | core.Batch<usStreet.Lookup>, core.Batch<usStreet.Lookup>>;

    constructor(args: any) {
        super(args);

        const embeddedKey = ENV.SMARTY_EMBEDDED_KEY;
        const credentials = new core.SharedCredentials(embeddedKey);
        const clientBuilder = new core.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud']);
        this.lookupClient = clientBuilder.buildUsAutocompleteProClient();

        const streetClientBuilder = new core.ClientBuilder(credentials).withLicenses(['us-core-cloud']);
        this.streetClient = streetClientBuilder.buildUsStreetApiClient();
    }

    async getAutoCompleteSuggestions(search: string): Promise<any> {
        try {
            const lookup = new usAutocompletePro.Lookup(search);
            lookup.maxResults = 10;
            // @ts-ignore source does exist inside lookup object
            lookup.source = 'postal';

            return await this.lookupClient.send(lookup);
        } catch (err) {
            Sentry.addBreadcrumb({
                category: 'smarty',
                message: 'Smarty US Autocomplete Pro suggestion lookup error',
                level: 'error' as SeverityLevel,
                data: err
            });
            return null;
        }
    }

    async findCountyForSuggestion(suggestion: usAutocompletePro.Suggestion): Promise<any> {
        try {
            const lookup = new usStreet.Lookup();
            lookup.street = suggestion.streetLine;
            lookup.city = suggestion.city;
            lookup.state = suggestion.state;
            lookup.zipCode = suggestion.zipcode;
            lookup.maxCandidates = 1;

            const result = await this.streetClient.send(lookup);
            return result?.lookups?.[0]?.result?.[0]?.metadata?.countyName || null;
        } catch (err) {
            Sentry.addBreadcrumb({
                category: 'smarty',
                message: 'Smarty US Street address lookup error',
                level: 'error' as SeverityLevel,
                data: err
            });
            return null;
        }
    }
}
