import AuthService from 'portal/services/auth';
import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import WebSocketService from 'portal/services/web-socket';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface NavigationArgs {
    deal: Deal;
}

export default class Deals extends Component<NavigationArgs> {
    @service
    declare auth: AuthService;

    @service
    declare webSocket: WebSocketService;

    @tracked
        hasUnreadMessages = this.args.deal.hasUnreadMessages;

    @action
    async subscribeToUnreadMessageCount() {
        const currentUser = await this.auth.getAuthenticatedUser();

        this.webSocket.createSubscription(
            'Deals::Channels::UnreadMessageCountChannel',
            (unreadMessageCountUpdate: any) => { this.receivedUnreadMessageCountUpdate(unreadMessageCountUpdate); },
            { user_id: currentUser.id }
        );
    }

    receivedUnreadMessageCountUpdate(unreadMessageCountUpdate: any) {
        if (document.getElementById('sorted-and-filtered-deal-messages')) {
            this.hasUnreadMessages = false;
        } else {
            if (unreadMessageCountUpdate.deal_id === this.args.deal.id) {
                this.hasUnreadMessages = unreadMessageCountUpdate.unread_message_count > 0;
            }
        }
    }
}
