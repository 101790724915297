import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedDealEarnestMoneySelectionRoute } from 'portal/routes/protected/deal/earnest-money/selection';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealEarnestMoneySelection extends Controller {
    declare model: ModelFrom<ProtectedDealEarnestMoneySelectionRoute>;

    @service
    declare router: RouterService;

    @action
    async onNext(): Promise<void> {
        this.router.transitionTo(`protected.deal.earnest-money.${this.model!.earnestMoneyDetail?.earnestMoneyMethod}`);
    }

    @action
    async onMessages(): Promise<void> {
        this.router.transitionTo('protected.deal.index.messages');
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'protected/deal/earnest-money/selection': ProtectedDealEarnestMoneySelection;
  }
}
