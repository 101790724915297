import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import ProtectedDeal from 'portal/routes/protected/deal';
import Store from '@ember-data/store';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedSelectTitleholder extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare auth: AuthService;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Titleholder Information / Select Titleholder');
    }

    async model() {
        const user = await this.auth.getAuthenticatedUser();
        const deal = this.modelFor(
            'protected.deal'
        ) as ModelFrom<ProtectedDeal>;

        const legalEntities = await this.store.findAll('legalEntity', { reload: true });
        const legalIndividuals = await this.store.findAll('legalIndividual', {  reload: true });

        return {
            user,
            deal,
            legalEntities,
            legalIndividuals
        };
    }
}