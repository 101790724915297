import ActionItem from './action-item';
import SettlementStatementReviewRequest from './settlement-statement-review-request';
import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

export default class ReviewSettlementStatementActivity extends ActionItem {
    @attr('boolean')
        subsequentReview?: boolean;

    @belongsTo('settlement-statement-review-request')
        settlementStatementReviewRequest?:
        | AsyncBelongsTo<SettlementStatementReviewRequest>
        | SettlementStatementReviewRequest;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'review-settlement-statement-activity': ReviewSettlementStatementActivity;
    }
}
