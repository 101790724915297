/* eslint-disable ember/use-ember-data-rfc-395-imports */
import AuthService from 'portal/services/auth';
import DS from 'ember-data';
import ENV from 'portal/config/environment';
import JSONAPIAdapter from '@ember-data/adapter/json-api';
import ModelRegistry from 'ember-data/types/registries/model';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class ApplicationAdapter extends JSONAPIAdapter {
    @service
        auth!: AuthService;

    @service
        router!: RouterService;

    namespace = ENV.API_NAMESPACE;
    host = ENV.API_URL;

    get headers() {
        return {
            Authorization: `Bearer ${this.auth.token}`
        };
    }

    handleResponse(status: any, headers: any, payload: any, requestData: any) {
        if (status === 401) {
            this.router.transitionTo('/');
            return false;
        }

        return super.handleResponse(status,headers,payload,requestData);
    }

    pathForType<K extends keyof ModelRegistry>(modelName: K): string {
        if (modelName === 'me' || modelName === 'user') {
            return 'profile/users/me';
        } else if ((modelName as string).includes('intake')) {
            const modelPath = super.pathForType(modelName);
            const moduleModelPath = modelPath.replace(/intake-/, 'intake/');
            return moduleModelPath.replace(/-/g, '_');
        } else if ((modelName as string).includes('deals')) {
            const modelPath = super.pathForType(modelName);
            const moduleModelPath = modelPath.replace(/deals-/, 'deals/');
            return moduleModelPath.replace(/-/g, '_');
        } else {
            return super.pathForType(modelName).replace(/-/g, '_');
        }
    }

    buildURL<K extends keyof ModelRegistry>(
        modelName?: K,
        // eslint-disable-next-line @typescript-eslint/ban-types
        id?: string | any[] | {} | null,
        snapshot?: DS.Snapshot<K> | any[] | null,
        requestType?: string,
        // eslint-disable-next-line @typescript-eslint/ban-types
        query?: {}
    ): string {
        // This is done to handle the terms and conditions route
        if (modelName === 'user') id = null;
        return super.buildURL(modelName, id, snapshot, requestType, query);
    }
}

declare module 'ember-data/types/registries/adapter' {
    export default interface AdapterRegistry {
        application: ApplicationAdapter;
    }
}
