import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import Deal, { DealStatuses } from 'portal/models/deal';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedInProgress extends BaseRoute {
    @service
    declare analytics: Analytics;

    @service
    declare auth: AuthService;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deals / In Progress');
    }

    async model() {
        const currentUser = await this.auth.getAuthenticatedUser();
        const inProgressDeals = this.modelFor('protected.deals').inProgressDeals.filterBy('status', DealStatuses.IN_PROGRESS) as Deal[];

        return {
            currentUser,
            deals: inProgressDeals
        };
    }
}
