import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import type RouterService from '@ember/routing/router-service';
type Transition = ReturnType<RouterService['transitionTo']>;
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class Protected extends BaseRoute {
    @service
        auth!: AuthService;

    @service
    declare router: RouterService;

    @service
    declare store: Store;

    isEmailVerified?: boolean;

    async beforeModel(transition: Transition) {
        try {
            const isAuthenticated = await this.auth.isAuthenticated();
            if (!isAuthenticated) {
                this.auth.requireAuthentication(transition);
            }
            // eslint-disable-next-line no-empty
        } catch (error) {}

        if (transition.to.name == 'protected.verify-email-address'){
            return;
        }

        this.isEmailVerified = this.isEmailVerified || await this.auth.isEmailVerified();
        if (!this.isEmailVerified) {
            this.router.replaceWith('protected.verify-email-address', {
                queryParams: {
                    transition: transition.to.name,
                    inviteId: (transition.to.params) ? transition.to.params['invite_id'] : null,
                    singleUseToken: (transition.to.queryParams) ? transition.to.queryParams['single_use_token'] : null
                }
            });
            return;
        }

        const user = await this.auth.getAuthenticatedUser();

        if (transition.to.name == 'protected.account-creation-flow.user-type'){
            return;
        }

        if (user.guidedAccountCreationFlowStartedAt){
            return;
        }

        if (await user.team){
            return;
        }

        if (await user.teamInvite){
            return;
        }

        const deals = await this.store.findAll('deal');
        if (deals.toArray().length > 0) {
            return;
        }

        this.router.replaceWith('protected.account-creation-flow.user-type');
    }

    async model() {
        const user = await this.auth.getAuthenticatedUser();
        return await user?.teamInvite;
    }

    @action
    loading(transition: Transition) {
        /**
         * This checks and stops the nested loading template
         * from rendering when the app is first booting OR
         * transitioning from another loading state farther
         * up the route tree.
         */
        return !(transition.from === undefined || transition.from?.name === 'loading');
    }
}
