import ClearToCloseTitleItem, { CustomerStatus } from 'portal/models/clear-to-close-title-item';
import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import Store from '@ember-data/store';
import WebSocketService from 'portal/services/web-socket';
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface ClearToCloseTitleItemArgs {
    deal: Deal;
    clearToCloseTitleItem: ClearToCloseTitleItem;
    clearToCloseTitleItems: ClearToCloseTitleItem[];
    showTimelines: boolean;
}

export default class ClearToCloseTitleItemEntry extends Component<ClearToCloseTitleItemArgs> {
    @service
    declare store: Store;

    @tracked
        clearToCloseTitleItem: ClearToCloseTitleItem;

    @tracked
        isOpen = false;

    @service
    declare webSocket: WebSocketService;

    constructor(owner: unknown, args: ClearToCloseTitleItemArgs) {
        super(owner, args);
        this.clearToCloseTitleItem = this.args.clearToCloseTitleItem;

        this.webSocket.createSubscription(
            'AdminWorkflow::WorkflowUpdatesForDealChannel',
            (workflow: any) => { this.receivedWorkflowUpdate(workflow); },
            { deal_id: this.args.deal.id }
        );
    }

    receivedWorkflowUpdate(workflow: any) {
        if (workflow.data.id === this.clearToCloseTitleItem.id){
            const status = workflow.data.attributes.status as string;
            this.clearToCloseTitleItem.status = status;
            // @ts-ignore
            this.clearToCloseTitleItem.customerStatus = CustomerStatus[status.toUpperCase()];
        }
    }

    @action
    toggleDrawer() {
        this.isOpen = !this.isOpen;
    }

    get finalCustomerName(){
        const matchingItems = this.args.clearToCloseTitleItems.filter(
            (clearToCloseTitleItem) => clearToCloseTitleItem.customerName === this.clearToCloseTitleItem.customerName
        );
        if (matchingItems.length > 1){
            const index = matchingItems.indexOf(this.clearToCloseTitleItem);
            return `${this.clearToCloseTitleItem.customerName} (${index + 1})`;

        } else {
            return this.clearToCloseTitleItem.customerName;
        }
    }

    get role(){
        return this.args.deal.isBorrower ? 'Borrower' : 'Seller';
    }

    get customerExplanation() {
        let explanation = this.clearToCloseTitleItem.customerExplanation!;
        if (this.args.deal.isBorrower){
            explanation = explanation.replace(/Seller/g, 'Borrower').replace(/seller/g, 'borrower');
        }
        return htmlSafe(explanation);
    }

    get mayNeedMoreInformation(){
        return this.clearToCloseTitleItem.mayNeedMoreInformation();
    }

    get isCompleted(){
        return this.clearToCloseTitleItem.isCompleted();
    }

}
