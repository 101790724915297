import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { default as CommitmentReviewRoute } from 'portal/routes/protected/deal/commitment-review';
import { ModelFrom } from 'portal/utils/type-utils';
import { commitmentReviewValidations } from 'portal/models/commitment-title-commitment-review';
import { inject as service } from '@ember/service';

export default class ProtectedDealCommitmentReview extends Controller {
    declare model: ModelFrom<CommitmentReviewRoute>;

    @service
    declare router: RouterService;

    validations = commitmentReviewValidations;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/deal/commitment-review': ProtectedDealCommitmentReview;
    }
}
