import ActionItem, { ActionItemTemplateKeys } from 'portal/models/action-item';
import AuthService from 'portal/services/auth';
import Controller from '@ember/controller';
import Deal from 'portal/models/deal';
import DealSide from 'portal/services/deal-side';
import RouterService from '@ember/routing/router-service';
import window from 'ember-window-mock';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedDealOnboardingRoute } from 'portal/routes/protected/deal/onboarding';
import { TitleholderType } from 'portal/models/titleholder';
import { reactUrl } from 'portal/helpers/react-url';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

const ORDERED_SELLER_ONBOARDING_ACTIVITIES_TO_ROUTES = {
    [ActionItemTemplateKeys.SELLER_TITLEHOLDER_DETAILS]: 'protected.deal.onboarding.seller-titleholder-info',
    [ActionItemTemplateKeys.PAYOFF_AUTHORIZATION]: 'protected.deal.payoff-authorization',
    [ActionItemTemplateKeys.SELLER_PROPERTY_DETAILS]: 'protected.deal.onboarding.seller-property-information',
    [ActionItemTemplateKeys.SELLER_CLOSING_PREFERENCES]: 'protected.deal.onboarding.seller-closing-preferences'
};

const redirectActivityRoutesActionItemTemplateKeys = [
    ActionItemTemplateKeys.SELLER_CLOSING_PREFERENCES,
    ActionItemTemplateKeys.SELLER_PROPERTY_DETAILS
];

export default class ProtectedDealOnboardingWelcome extends Controller {
    declare model: ModelFrom<ProtectedDealOnboardingRoute>;

    @service
        auth!: AuthService;

    @service
    declare router: RouterService;

    @service
    declare dealSide: DealSide;

    @task
    async confirmAddress(): Promise<void> {
        const deal = await this.model?.deal;
        const user = await this.auth.getAuthenticatedUser();

        if (user.welcomeDealId) {
            const incompleteActionItems = deal?.actionItems?.toArray().filter((actionItem) => !actionItem.hasCompleted);
            const incompleteTemplateKeys = incompleteActionItems?.map((actionItem: ActionItem) => actionItem.templateKey);

            const nextIncompleteOnboardingActionItemTemplateKey =
                Object.keys(ORDERED_SELLER_ONBOARDING_ACTIVITIES_TO_ROUTES).find((templateKey: string) => (
                    incompleteTemplateKeys?.includes(templateKey)
                ));

            if (!nextIncompleteOnboardingActionItemTemplateKey) {
                this.router.transitionTo('protected.deals');
                return;
            }

            // Routing to payoff authorization requires the corresponding action item id
            if (nextIncompleteOnboardingActionItemTemplateKey === ActionItemTemplateKeys.PAYOFF_AUTHORIZATION) {
                const payoffAuthorizationActionItem = incompleteActionItems?.find((actionItem: ActionItem) => (
                    actionItem.templateKey === ActionItemTemplateKeys.PAYOFF_AUTHORIZATION
                ));

                this.router.transitionTo(
                    // @ts-ignore
                    ORDERED_SELLER_ONBOARDING_ACTIVITIES_TO_ROUTES[nextIncompleteOnboardingActionItemTemplateKey],
                    payoffAuthorizationActionItem!.id
                );
            } else {

                if (
                    redirectActivityRoutesActionItemTemplateKeys.includes(
                        nextIncompleteOnboardingActionItemTemplateKey as ActionItemTemplateKeys
                    )
                ) {
                    const redirectActionItem = incompleteActionItems?.find((actionItem: ActionItem) => (
                        actionItem.templateKey === nextIncompleteOnboardingActionItemTemplateKey
                    ));

                    const path = reactUrl(`deals/${deal?.id}/action-items/${redirectActionItem!.id}`);
                    const url = new URL(path);
                    window.location.assign(url);
                    return;
                }
                // @ts-ignore
                this.router.transitionTo(ORDERED_SELLER_ONBOARDING_ACTIVITIES_TO_ROUTES[nextIncompleteOnboardingActionItemTemplateKey]);
            }

        } else {
            this.model!.hasStarted = true;
            await this.model!.save();

            const titleholder = await deal?.latestTitleholder(this.dealSide.for(deal.id));

            const nextRouteForExistingIndividualTitleholder = titleholder?.isCurrent
                ? 'protected.deal.onboarding.property-information'
                : 'protected.deal.onboarding.cpc-notary-intermediary';

            const nextRouteForExistingEntityTitleholder = deal?.isBorrower
                ? 'protected.deal.onboarding.property-information'
                : 'protected.deal.onboarding.personal-information';

            if (this.model!.templateKey == ActionItemTemplateKeys.SELLER_TITLEHOLDER_DETAILS) {
                this.router.transitionTo('protected.deal.onboarding.seller-titleholder-info');
            } else if (this.model!.templateKey == ActionItemTemplateKeys.BUYER_TITLEHOLDER_AND_FINANCING_INFORMATION) {
                this.router.transitionTo('protected.deal.buyer-titleholder-and-financing-information', deal as Deal);
            } else {
                if (titleholder && !titleholder.isNew) {
                    if (titleholder.type?.toUpperCase() === TitleholderType.individual) {
                        this.router.transitionTo(nextRouteForExistingIndividualTitleholder);
                    } else {
                        this.router.transitionTo(nextRouteForExistingEntityTitleholder);
                    }
                } else {
                    this.router.transitionTo(
                        'protected.deal.onboarding.titleholder-preference'
                    );
                }
            }
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/deal/onboarding/welcome': ProtectedDealOnboardingWelcome;
    }
}
