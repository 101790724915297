import Analytics from 'portal/services/analytics';
import ApplicationAdapter from 'portal/adapters/application';
import BaseRoute from 'portal/routes/base-route';
import Deal from 'portal/models/deal';
import ENV from 'portal/config/environment';
import Invite from 'portal/models/invite';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class Invites extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare router: RouterService;

    @service
    declare analytics: Analytics;

    queryParams = {
        single_use_token: {
            refreshModel: true
        }
    };

    async model({
        invite_id,
        single_use_token: singleUseToken
    }: {
        [key: string]: any;
    }): Promise<Invite | null> {
        const invite = {
            data: {
                type: 'invites',
                id: invite_id,
                attributes: {
                    accepted: true
                }
            }
        };

        const applicationAdapter = this.store.adapterFor(
            'application'
        ) as ApplicationAdapter;

        const headers = new Headers(applicationAdapter.headers);
        headers.set('x-single-use-token', singleUseToken);
        headers.set('Content-Type', 'application/json');

        const url = `${ENV.API_URL}/${ENV.API_NAMESPACE}/deals/invites/${invite_id}`;
        try {
            const inviteResponse = await fetch(url, {
                method: 'PATCH',
                body: JSON.stringify(invite),
                headers
            });

            const data = await inviteResponse.json();
            this.store.pushPayload(data);

            const storeInvite = this.store.peekRecord('invite', invite_id);
            return storeInvite;
        } catch (error) {
            const storeInvite = await this.store.findRecord('invite', invite_id, { reload: true });
            this.router.replaceWith('protected.deal', (await storeInvite.deal).id);
            return null;
        }
    }

    async redirect(model: Invite) {
        const storeDealId = ((await model?.deal) as Deal).id;
        this.router.replaceWith('protected.deal.onboarding', storeDealId);
    }

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Invites');
    }
}
