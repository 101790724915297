import Deal from 'portal/models/deal';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

export default class PlaidLinkSession extends Model {
    @attr('string')
    declare linkToken: string;

    @attr('boolean')
    declare complete: boolean;

    @belongsTo('deal')
    declare deal: AsyncBelongsTo<Deal>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'plaid-link-session': PlaidLinkSession;
  }
}
