import CalendarAttendee from './calendar-attendee';
import Model, { attr, hasMany } from '@ember-data/model';
import { validateLength, validatePresence } from 'ember-changeset-validations/validators';

export default class CalendarAppointment extends Model {
    @attr('boolean')
    declare allDay: boolean;

    @attr('date')
    declare appointmentDate: Date;

    @attr('date')
    declare endTime?: Date;

    @attr('date')
    declare startTime?: Date;

    @hasMany('calendar-attendee')
        attendees?: CalendarAttendee[];
}

export const signingAppointmentValidations = {
    appointmentDate: validatePresence({ presence: true, message: 'Must select a closing date' }),
    attendees: [
        validateLength({ min: 1, message: 'Must select at least one signer' })
    ]
};