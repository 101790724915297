import AddressAutocomplete from 'portal/services/address-autocomplete';
import Component from '@glimmer/component';
import { BufferedChangeset } from 'ember-changeset/types';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { stateOptions } from 'portal/models/state';
import { tracked } from '@glimmer/tracking';
import { usAutocompletePro } from 'smartystreets-javascript-sdk';

interface EmporaAddressArgs {
    disabled?: boolean;
    title: string;
    subtitle: string;
    changeset: BufferedChangeset;
    includeCounty?: boolean;
}

export default class EmporaAddress extends Component<EmporaAddressArgs> {
    states = stateOptions;
    keyUpTimer: number | undefined;

    @service
        addressAutocomplete!: AddressAutocomplete;

    @tracked
        autoCompleteSuggestions: usAutocompletePro.Suggestion[] = [];

    @tracked
        showAutoCompleteMenu = false;

    @action
    onStreetKeyUp(value: string): void {
        if (value.length < 3) {
            this.showAutoCompleteMenu = false;
            return;
        }

        clearTimeout(this.keyUpTimer);
        this.keyUpTimer = setTimeout(async () => {
            if (value) {
                await this.showAutoCompleteSuggestions(value);
            } else {
                this.showAutoCompleteMenu = false;
            }
        }, 250) as unknown as number;
    }

    @action
    hideAutoCompleteMenu() {
        if (this.showAutoCompleteMenu) {
            this.showAutoCompleteMenu = false;
        }
    }

    @action
    async onAutoCompleteSuggestionClick(
        clickedSuggestion: usAutocompletePro.Suggestion
    ) {
        this.args.changeset.set('street', clickedSuggestion.streetLine);
        this.args.changeset.set('street2', clickedSuggestion.secondary);
        this.args.changeset.set('city', clickedSuggestion.city);
        this.args.changeset.set('state', clickedSuggestion.state);
        this.args.changeset.set('zipcode', clickedSuggestion.zipcode);

        const county = await this.addressAutocomplete.findCountyForSuggestion(clickedSuggestion);
        this.args.changeset.set('county', county);

        this.showAutoCompleteMenu = false;
    }

    @action
    validate() {
        if (this.args.changeset.validate) {
            this.args.changeset.validate();
        }
    }

    @action
    onWillDestroy() {
        clearTimeout(this.keyUpTimer);
    }

    async showAutoCompleteSuggestions(search: string) {
        const results =
            await this.addressAutocomplete.getAutoCompleteSuggestions(search);
        if (!results) {
            return;
        }
        this.buildAutoCompleteMenu(results.result);
        this.showAutoCompleteMenu = true;
    }

    buildAutoCompleteMenu(suggestions: usAutocompletePro.Suggestion[]) {
        this.autoCompleteSuggestions = suggestions;
        const autoCompleteMenu = document.getElementById(
            'us-autocomplete-pro-menu'
        ) as HTMLUListElement;

        // While not perfect, this should handle most cases where
        // the user clicks outside of the menu to close it
        document.addEventListener('click', (event) => {
            const withinBoundaries = event
                .composedPath()
                .includes(autoCompleteMenu);

            if (!withinBoundaries) {
                this.showAutoCompleteMenu = false;
            }
        });
    }
}
