import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Deal from 'portal/models/deal';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class PayoffAuthorization extends BaseRoute {
    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Payoff Authorization');
    }

    async model({ action_item_id }: { action_item_id: string }) {
        if (!action_item_id){
            return {};
        }
        const deal = this.modelFor('protected.deal') as Deal;
        const actionItems = await deal.actionItems;
        const actionItem = actionItems?.find((actionItem) => actionItem.id === action_item_id);
        return {
            deal,
            actionItem
        };
    }

}
