import Component from '@glimmer/component';
import DocumentModel from 'portal/models/document';
import EmporaDocumentUpload from 'portal/components/empora/document-upload/component';
import Store from '@ember-data/store';
import window from 'ember-window-mock';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';


interface DocumentRowArgs {
    document: DocumentModel;
    onDelete?: (document: DocumentModel) => Promise<void> | void;
    onDownload: (document: DocumentModel) => void; // Required so that parents are encouraged to log an analytics event
    onUpload?: (document: DocumentModel, documentUpload: EmporaDocumentUpload) => Promise<void> | void;
    required?: boolean;
}

export default class DocumentRow extends Component<DocumentRowArgs> {
    @service
    declare store: Store;

    @tracked
        isModalOpen = false;

    @tracked
        documentToDisplay = this.args.document;

    get isUploading(): boolean {
        return taskFor(this.replaceDocument).isRunning;
    }

    get documentDisplay(): string {
        return `${this.documentToDisplay.name}${this.documentToDisplay.filetype}`;
    }

    @action
    toggleConfirmationModal() {
        this.isModalOpen = !this.isModalOpen;
    }

    @action
    triggerFileUpload() {
        this.toggleConfirmationModal();
        document.getElementById(this.fileUploadInputId)?.click();
    }

    @task
    async downloadDocument(): Promise<void> {
        const documentDownloadRequest = this.store.createRecord('document-download-request', {
            document: this.args.document
        });

        // This needs to be instantiated before async behavior in order to not be blocked by Safari as a pop-up.
        // See https://stackoverflow.com/a/39387533
        const windowOpenReference = window.open();

        await documentDownloadRequest.save();

        const { url } = documentDownloadRequest;

        if (url && windowOpenReference) {
            windowOpenReference.location = url;
        }

        this.args.onDownload(this.args.document);
    }

    @task
    async deleteDocument(): Promise<void> {
        if (this.args.document.canDelete) {
            await this.args.document.destroyRecord();
            this.args.onDelete?.(this.args.document);
            this.toggleConfirmationModal();
        } else {
            throw new Error('Cannot delete document that cannot be deleted');
        }
    }

    @task
    async replaceDocument(document: DocumentModel, documentUpload: EmporaDocumentUpload): Promise<void> {
        if (this.args.document.canDelete) {
            this.documentToDisplay = document;
            await this.args.onUpload?.(document, documentUpload);
            await this.args.document.destroyRecord();
            await this.args.onDelete?.(this.args.document);
        } else {
            throw new Error('Cannot replace document that cannot be deleted');
        }
    }

    get fileUploadInputId(): string {
        return 'file-upload-' + this.args.document.id;
    }
}
