import Controller from '@ember/controller';

export default class ProtectedAccountCreationFlowCompleted extends Controller {}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/account-creation-flow/completed': ProtectedAccountCreationFlowCompleted;
    }
}
