import Service from '@ember/service';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';

export default class FeatureFlag extends Service {
    @service
        store!: Store;

    loadFeatureFlags(): Promise<any> {
        return this.store.findAll('featureFlag');
    }

    enabled(featureName: string): boolean {
        return this.store.peekRecord('featureFlag', featureName)?.enabled;
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
    interface Registry {
        'feature-flag': FeatureFlag;
    }
}
