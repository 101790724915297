import Deal from './deal';
import DocumentModel from './document';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import validateEmail from 'portal/validators/email';
import validatePhoneNumber from 'portal/validators/phone-number';
import {
    validateFormat,
    validateInclusion,
    validateNumber,
    validatePresence
} from 'ember-changeset-validations/validators';

export enum CreateDealRequestType {
    buyer = 'BUYER',
    seller = 'SELLER',
    assignment = 'ASSIGNMENT'
}

export enum CreateDealRequestSubtype {
    assignment = 'assignment',
    double_close = 'double_close',
    other = 'other',
    novation = 'novation'
}

export default class CreateDealRequest extends Model {
    @attr('string')
        requestSubtype?: CreateDealRequestSubtype;

    @attr('string')
        requestType?: CreateDealRequestType;

    @attr('string')
        firstName?: string;

    @attr('string')
        middleName?: string;

    @attr('string')
        lastName?: string;

    @attr('string')
        email?: string;

    @attr('string')
        phoneNumber?: string;

    @attr('number')
        purchasePrice?: number;

    @attr('number')
        earnestMoney?: number;

    @attr('string')
        street?: string;

    @attr('string')
        street2?: string;

    @attr('string')
        city?: string;

    @attr('string')
        state?: string;

    @attr('string')
        zipcode?: string;

    @belongsTo('document')
        document?: AsyncBelongsTo<DocumentModel>;

    @belongsTo('deal')
        deal?: AsyncBelongsTo<Deal>;
}

export const createDealRequestValidations = {
    street: validatePresence({
        presence: true,
        ignoreBlank: true,
        message: 'Address can’t be blank'
    }),
    city: validatePresence({
        presence: true
    }),
    state: [
        validatePresence({
            presence: true
        }),
        validateFormat({ regex: /^[A-Z]{2}$/ })
    ],
    zipcode: [
        validatePresence({
            presence: true,
            message: 'ZIP code can’t be blank'
        }),
        validateFormat({
            regex: /^[0-9]{5}(-[0-9]{4})?$/,
            message: 'ZIP code is invalid'
        })
    ],
    requestType: [
        validateInclusion({
            list: Object.values(CreateDealRequestType),
            allowBlank: true
        })
    ],
    email: [validateEmail('Email', { allowBlank: true })],
    phoneNumber: [validatePhoneNumber('Phone number', { allowBlank: true })],
    purchasePrice: validateNumber({ allowBlank: true, gte:0 }),
    earnestMoney: validateNumber({ allowBlank: true, gte:0 })
};

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'create-deal-request': CreateDealRequest;
    }
}
