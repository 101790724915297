import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealTitleholderInformationPreference extends Controller {
    @service
    declare router: RouterService;

    @service
    declare store: Store;

    @action
    async onIndividualSelected() {
        const legalIndividualsLength = (await this.store.findAll('legal-individual', { reload: true })).length;

        if (legalIndividualsLength > 0) {
            this.router.transitionTo('protected.deal.titleholder-information.multiselect-individual-titleholder');
        } else {
            this.router.transitionTo('protected.deal.titleholder-information.new-individual');
        }
    }
}