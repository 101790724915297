import Controller from '@ember/controller';
import CreditLine, {
    IntendedFinancingTypes,
    creditLineValidations
} from 'portal/models/credit-line';
import DealSide from 'portal/services/deal-side';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import TaxAddress from 'portal/models/tax-address';
import { BufferedChangeset, lookupValidator } from 'validated-changeset';
import { Changeset } from 'ember-changeset';
import { ModelFrom } from 'portal/utils/type-utils';
import {
    default as ProtectedDealOnboardingLenderTaxAddressInfoRoute
} from 'portal/routes/protected/deal/onboarding/lender-tax-address-info';
import { action } from '@ember/object';
import { addressValidations } from 'portal/models/titleholder';
import { isNone } from '@ember/utils';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export enum TaxAddressOptions {
    LENDER = 'Lender / Credit Line',
    FORWARD = 'Forwarding Address',
    PROPERTY = 'Property Address',
    OTHER = 'Other'
}

export default class ProtectedDealOnboardingLenderTaxAddressInfo extends Controller {
    declare model: ModelFrom<ProtectedDealOnboardingLenderTaxAddressInfoRoute>;

    @service
    declare router: RouterService;

    @service
    declare store: Store;

    @service
    declare dealSide: DealSide;

    @tracked
    declare hasCreditLines: boolean;

    @tracked
    declare taxAddressSelected: boolean;

    @tracked
    declare otherOptionSelected: boolean;

    @tracked
        showErrorOnPristine?: boolean = false;

    @tracked
        showErrorBanner?: boolean = false;

    @tracked
        financingOptionSelected?: boolean;

    declare taxAddress: TaxAddress;

    private currentFinancingType = '';

    private creditLineChangesetCache = new WeakMap<
        CreditLine,
        BufferedChangeset
    >();
    private taxAddressChangesetCache = new WeakMap<TaxAddress, BufferedChangeset>();

    get taxAddressChangeset(): BufferedChangeset {
        let changeset = this.taxAddressChangesetCache.get(this.taxAddress);
        if (!changeset) {
            // eslint-disable-next-line ember/no-side-effects
            this.taxAddress = this.store.createRecord('tax-address', {
                street: '',
                street2: '',
                city: '',
                state: '',
                zipcode: '',
                taxAddressType: null,
                deal: this.model.deal
            });
            changeset = Changeset(
                this.taxAddress,
                lookupValidator(addressValidations),
                addressValidations,
                {
                    changesetKeys: Object.keys(TaxAddress.attributes)
                }
            );
            // eslint-disable-next-line ember/no-side-effects
            this.taxAddressChangesetCache.set(this.taxAddress, changeset);
        }

        return changeset;
    }

    get nonDeletedCreditLines() {
        return this.model.creditLines?.filterBy('isDeleted', false) || [];
    }

    get propertyAddress() {
        return this.model.property?.address;
    }

    get creditLinesChangesets(): BufferedChangeset[] {
        return this.nonDeletedCreditLines.toArray().map((creditLine) => {
            let changeset = this.creditLineChangesetCache.get(creditLine);
            if (!changeset) {
                changeset = Changeset(
                    creditLine,
                    lookupValidator(creditLineValidations),
                    creditLineValidations,
                    {
                        changesetKeys: Object.keys(CreditLine.attributes)
                    }
                );
                // eslint-disable-next-line ember/no-side-effects
                this.creditLineChangesetCache.set(creditLine, changeset);
            }
            return changeset;
        });
    }

    get actorRole() {
        return this.model.deal.actorRole;
    }

    @action
    validate() {
        this.taxAddressChangeset.set('showErrorOnPristine', false);
        this.creditLinesChangesets?.forEach((changeset) => {
            changeset.set('showErrorOnPristine', false);
        });
    }

    @task
    async saveInformation(): Promise<void> {
        this.showErrorOnPristine = true;
        this.taxAddressChangeset.set('showErrorOnPristine', true);
        this.creditLinesChangesets?.forEach((changeset) => {
            changeset.set('showErrorOnPristine', true);
        });

        if (isNone(this.hasCreditLines) || isNone(this.taxAddressSelected)) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        if (this.creditLinesChangesets?.any((creditlineChangeset) => creditlineChangeset.isInvalid)) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        if (this.taxAddressChangeset.isInvalid) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        this.creditLinesChangesets.map((creditLineChangeset) =>
            creditLineChangeset.execute()
        );

        const creditLines = this.model.creditLines;
        if (creditLines) {
            await Promise.all(
                creditLines.map((creditLine) => creditLine.save())
            );
        }

        this.taxAddressChangeset.save();

        this.router.transitionTo('protected.deal.onboarding.cpc-notary-intermediary');
    }

    @action
    onTaxAddressSelection(option: TaxAddressOptions) {
        this.taxAddressSelected = true;
        this.otherOptionSelected = false;
        this.taxAddressChangeset.rollback();
        this.taxAddressChangeset.set('taxAddressType', option);

        switch (option){
        case TaxAddressOptions.FORWARD:
            this.setTaxAddressChangeset(this.model.titleholder);
            break;
        case TaxAddressOptions.PROPERTY:
            this.setTaxAddressChangeset(this.propertyAddress);
            break;
        case TaxAddressOptions.OTHER:
            this.otherOptionSelected = true;
            break;
        }
    }

    @action
    setTaxAddressChangeset(address: any) {
        this.taxAddressChangeset.set('street', address.get('street'));
        this.taxAddressChangeset.set('street2', address.get('street2'));
        this.taxAddressChangeset.set('city', address.get('city'));
        this.taxAddressChangeset.set('state', address.get('state'));
        this.taxAddressChangeset.set('zipcode', address.get('zipcode'));
    }

    @action
    creditLinesOnChange(intendedFinancingType: string) {
        this.financingOptionSelected = true;
        this.hasCreditLines = intendedFinancingType !== IntendedFinancingTypes.CASH;

        if (this.hasCreditLines) {
            if (intendedFinancingType !== this.currentFinancingType) {
                if (this.currentFinancingType === IntendedFinancingTypes.CASH) {
                    this.deleteAllCreditLines();
                }

                if (this.nonDeletedCreditLines.length === 0) {
                    this.addCreditLine(intendedFinancingType);
                }

                if (this.nonDeletedCreditLines.length > 0) {
                    for (const creditLine of this.nonDeletedCreditLines) {
                        creditLine.intendedFinancingType = intendedFinancingType as IntendedFinancingTypes;
                    }
                }
            }
        } else {
            this.deleteAllCreditLines();
        }

        if (intendedFinancingType === IntendedFinancingTypes.CASH) {
            this.addCreditLine(intendedFinancingType);
        }

        this.currentFinancingType = intendedFinancingType;
    }

    @action
    creditLinesOnAddAnotherLender() {
        this.addCreditLine(this.currentFinancingType);
    }

    @action
    creditLineOnDelete(creditLineChangeset: BufferedChangeset) {
        const creditLine = creditLineChangeset.data as CreditLine;
        this.creditLineChangesetCache.delete(creditLine);
        creditLine.deleteRecord();
    }

    addCreditLine(intendedFinancingType: string) {
        this.store.createRecord('credit-line', {
            accountName: intendedFinancingType == IntendedFinancingTypes.CASH ? IntendedFinancingTypes.CASH : null,
            intendedFinancingType: intendedFinancingType,
            dealSide: this.dealSide.for(this.model.deal.id),
            phoneNumber: intendedFinancingType == IntendedFinancingTypes.CASH ? '6146605503' : null,
            property: this.model.property
        });
        this.send('invalidateModel');
    }

    deleteAllCreditLines() {
        this.model.creditLines
            ?.toArray()
            .forEach((creditLine: CreditLine) => {
                this.creditLineChangesetCache.delete(creditLine);
                creditLine.deleteRecord();
            });
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/deal/onboarding/lender-tax-address-info': ProtectedDealOnboardingLenderTaxAddressInfo;
    }
}
