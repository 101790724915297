import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Deal from '../deal';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealProvideRentalInformation extends BaseRoute {
    @service
    declare router: RouterService;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Provide rental information');
    }

    async model() {
        const deal = this.modelFor('protected.deal') as ModelFrom<Deal>;
        const property = await deal.property;
        return {
            deal,
            property
        };
    }
}
