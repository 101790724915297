import Model, { attr } from '@ember-data/model';

export default class SigningMethod extends Model {
    @attr('string')
        key!: string;
    @attr('string')
        label!: string;
    @attr('string')
        title!: string;
    @attr('string')
        subtitle!: string;
    @attr('boolean')
        allDayEligible!: boolean;
    @attr('boolean')
        internalNotaryRequired!: boolean;
    @attr('boolean')
        externalNotaryRequired!: boolean;
    @attr('boolean')
        isMobile!: boolean;

    static canBeAllDay(signingMethod?: SigningMethod): boolean {
        return !!signingMethod?.allDayEligible;
    }

    static requiresLocation(signingMethod?: SigningMethod): boolean {
        return !!signingMethod?.isMobile;
    }
}
