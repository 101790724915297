import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import Store from '@ember-data/store';
import Transition from '@ember/routing/-private/transition';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedIntakeStartDeal extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare auth: AuthService;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Start Deal');
    }

    async model() {
        const user = await this.auth.getAuthenticatedUser();
        return {
            intakeUser: this.store.createRecord('intake-user', {
                firstName: user.givenName,
                middleName: user.middleName,
                lastName: user.familyName,
                email: user.email,
                phoneNumber: user.phoneNumber
            }),
            parties: []
        };
    }

    @action
    async willTransition(transition: Transition) {
        if (!transition.to.name.includes(this.routeName)) {
            const model = this.modelFor(this.routeName);
            model.intakeUser.rollbackAttributes();

            model.parties.forEach((p: any) => p.data?.rollbackAttributes());
        }
    }
}
