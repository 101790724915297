import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import PotentialSigningLocation from 'portal/models/potential-signing-location';
import Store from '@ember-data/store';
import { BufferedChangeset } from 'ember-changeset/types';
import { EmporaDropdownOptions } from 'portal/components/empora/dropdown/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';

interface ClosingRequestLocationArgs {
    deal: Deal;
    showSigningLocationSection: boolean;
    signingLocationChangeset: BufferedChangeset;
}

type OtherSigningLocation = {
    oneLine: string,
    id: string
};

const OTHER_SIGNING_LOCATION: OtherSigningLocation = {
    oneLine: 'Use a different location',
    id: 'other'
};

export default class ClosingRequestSigners extends Component<ClosingRequestLocationArgs> {
    @service
    declare store: Store;

    @tracked
        potentialSigningLocations: Array<PotentialSigningLocation | OtherSigningLocation> = [];

    @tracked
        selectedSigningLocation?: PotentialSigningLocation | OtherSigningLocation;

    @tracked
        usingOtherAddress = false;

    constructor(owner: unknown, args: ClosingRequestLocationArgs) {
        super(owner, args);
        taskFor(this.loadPotentialSigningLocations).perform();
    }

    @task
    async loadPotentialSigningLocations(): Promise<void>  {
        const potentialSigningLocations: Array<PotentialSigningLocation | OtherSigningLocation> = [];

        await this.store.query('potentialSigningLocation', { deal_id: this.args.deal.id }).then((result) => {
            const uniqueOneLines: string[] = [];

            result.toArray().forEach((potentialSigningLocation) => {
                if (!uniqueOneLines.includes(potentialSigningLocation.oneLine)) {
                    uniqueOneLines.push(potentialSigningLocation.oneLine);
                    potentialSigningLocations.push(potentialSigningLocation);
                }
            });
        });

        potentialSigningLocations.push(OTHER_SIGNING_LOCATION);

        this.potentialSigningLocations = potentialSigningLocations;
    }

    get signingLocationOptions(): EmporaDropdownOptions {
        return this.potentialSigningLocations.map((potentialSigningLocation) => ({
            name: potentialSigningLocation.oneLine,
            value: potentialSigningLocation.id
        }));
    }

    get showOtherAddressFields(): boolean {
        return this.selectedSigningLocation?.id === OTHER_SIGNING_LOCATION.id;
    }

    @action
    onLocationSelected(signingLocationId: string) {
        this.selectedSigningLocation = this.potentialSigningLocations.find(({ id }) => id === signingLocationId);

        if (!this.selectedSigningLocation || this.showOtherAddressFields) {
            this.args.signingLocationChangeset.rollback();
        } else {
            if (this.selectedSigningLocation) {
                ['street', 'street2', 'city', 'state', 'zipcode'].forEach((key: keyof PotentialSigningLocation) => {
                    this.args.signingLocationChangeset.set(key, (this.selectedSigningLocation as PotentialSigningLocation)?.get(key));
                });
            }
        }
    }
}