import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import DealSide from 'portal/services/deal-side';
import ProtectedDealRoute from 'portal/routes/protected/deal';
import RSVP from 'rsvp';
import Store from '@ember-data/store';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealTitleholderInformationNewEntity extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare dealSide: DealSide;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Titleholder Information / New Entity');
    }

    async model() {
        const deal = this.modelFor(
            'protected.deal'
        ) as ModelFrom<ProtectedDealRoute>;

        const titleholder = await deal.latestTitleholder(this.dealSide.for(deal.id));

        return RSVP.hash({
            deal,
            titleholder,
            newLegalEntity: this.store.createRecord('legalEntity')
        });
    }

    @action
    willTransition() {
        const { newLegalEntity } = this.modelFor(
            this.routeName
        ) as ModelFrom<ProtectedDealTitleholderInformationNewEntity>;
        newLegalEntity.rollbackAttributes();
    }
}
