import Component from '@glimmer/component';
import { BufferedChangeset } from 'validated-changeset';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface OnboardingPersonalInformationArgs {
    changeset: BufferedChangeset;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onNext: () => {};
}

export default class OnboardingPersonalInformation extends Component<OnboardingPersonalInformationArgs> {
    @tracked
        showErrorBanner?: boolean = false;

    @action
    async validate() {
        this.args.changeset.set('showErrorOnPristine', false);
        await this.args.changeset.validate();
    }

    @action
    async onNext() {
        this.args.changeset.set('showErrorOnPristine', true);

        if (this.args.changeset?.isInvalid) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        if (this.args.onNext) {
            this.args.onNext();
        }
    }
}
