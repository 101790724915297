/* eslint-disable ember/use-ember-data-rfc-395-imports */
import ApplicationAdapter from './application';
import DS from 'ember-data';
import DocumentModel from 'portal/models/document';
import Store from '@ember-data/store';
import { ModelRegistry } from 'ember-cli-mirage';

export default class DocumentAdapter extends ApplicationAdapter {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pathForType<K extends keyof ModelRegistry>(_modelName: K): string {
        return 'documents';
    }

    createRecord(store: Store, type: any, snapshot: DS.Snapshot) {
        const documentRecord = snapshot.record as DocumentModel;

        if (!documentRecord.file) {
            throw new Error('A new document must have a file attribute');
        }

        const documentUploadRequest = store.createRecord(
            'document-upload-request',
            {
                deal: documentRecord.deal
            }
        );

        return documentUploadRequest
            .save()
            .then((documentUploadRequest) => {
                const { uploadUrl, uploadHeaders, path } =
                    documentUploadRequest;
                documentRecord.path = path;

                return fetch(uploadUrl as string, {
                    method: 'PUT',
                    headers: Object.assign(uploadHeaders, {
                        'Content-Type': documentRecord.file?.type
                    }),
                    body: documentRecord.file
                });
            })
            .then((uploadResponse) => {
                if (!uploadResponse.ok) {
                    throw new Error(
                        'Upload was unsuccessful, please try again'
                    );
                }
                const newSnapshot = (
                    documentRecord as any
                )._internalModel.createSnapshot();
                return super.createRecord(store, type, newSnapshot);
            });
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
    export default interface AdapterRegistry {
        document: DocumentAdapter;
    }
}
