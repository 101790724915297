import Component from '@glimmer/component';
import EntityTitleholder from 'portal/models/entity-titleholder';
import IndividualTitleholder from 'portal/models/individual-titleholder';
import LegalEntity from 'portal/models/legal-entity';
import User from 'portal/models/user';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';

interface EditTitleholderArgs {
    user: User;
    titleholder: IndividualTitleholder | EntityTitleholder;
    onTitleholderEdit: () => Promise<void>;
    onCancel: () => Promise<void>;
}

export default class EditTitleholder extends Component<EditTitleholderArgs> {

    constructor(owner: unknown, args: EditTitleholderArgs) {
        super(owner, args);
        taskFor(this.loadLegalEntity).perform();
    }

    @tracked
    declare legalEntity: LegalEntity | null;

    @task
    async loadLegalEntity() {
        const titleholder = this.args.titleholder;
        if (titleholder instanceof EntityTitleholder) {
            this.legalEntity = await titleholder.existingLegalEntity;
        }
    }

    @action
    async onCancel() {
        if (!this.args.titleholder.isNew) {
            this.args.titleholder.rollbackAttributes();
        }
        return this.args.onCancel();
    }
}
