import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealTitleholderInformationEditIndividual extends Controller {
    @service
    declare router: RouterService;

    @action
    onNext() {
        this.router.transitionTo('protected.deal.titleholder-information.select-titleholder');
    }
}