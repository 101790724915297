import Component from '@glimmer/component';
import GuidedAccountCreationSection, { Section } from 'portal/models/guided-account-creation-section';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

interface AccountCreationFlowHomeArgs {
    guidedAccountCreationSections: GuidedAccountCreationSection[]
}


export default class AccountCreationFlowTopBar extends Component<AccountCreationFlowHomeArgs> {
    @service
        router!: RouterService;

    get communicationPreferencesFinished(){
        const communication_preferences = this.args.guidedAccountCreationSections.findBy('section', Section.communication_preferences);
        return communication_preferences?.finished;
    }

    get entityDetailsFinished(){
        const entity_details = this.args.guidedAccountCreationSections.findBy('section', Section.entity_details);
        return entity_details?.finished;
    }

    get teamDetailsFinished(){
        const team_details = this.args.guidedAccountCreationSections.findBy('section', Section.team_details);
        return team_details?.finished;
    }

    get personalDetailsFinished(){
        const personal_details = this.args.guidedAccountCreationSections.findBy('section', Section.personal_details);
        return personal_details?.finished;
    }

    @action
    onClickPersonalDetails() {
        this.router.transitionTo('protected.account-creation-flow.personal-details');
    }

    @action
    onClickCommunicationPreferences() {
        this.router.transitionTo('protected.account-creation-flow.communication-preferences');
    }

    @action
    onClickTeamDetails() {
        this.router.transitionTo('protected.account-creation-flow.team-overview');
    }

    @action
    onClickEntityDetails() {
        this.router.transitionTo('protected.account-creation-flow.my-entities');
    }
}
