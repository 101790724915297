import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { DealActorRoles } from 'portal/models/deal';
import { ModelFrom } from 'portal/utils/type-utils';
import {
    default as ProtectedEditEntityRoute
} from 'portal/routes/protected/deal/titleholder-information/edit-entity';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealTitleholderInformationEditEntity extends Controller {
    declare model: ModelFrom<ProtectedEditEntityRoute>;

    queryParams = ['confirming'];

    confirming = false;

    @service
    declare router: RouterService;

    @action
    onNext() {
        if (this.model.deal.actorRole === DealActorRoles.ASSIGNOR) {
            this.router.transitionTo('protected.deal.titleholder-information.select-titleholder');
        } else if (this.confirming) {
            this.router.transitionTo('protected.deal.titleholder-information.confirm-entity-details');
        } else {
            this.router.transitionTo('protected.deal.titleholder-information.select-entity');
        }
    }
}