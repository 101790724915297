import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';

export interface EmporaRadioGroupArgs {
    label?: string;
    name?: string;
    onChange?: (value: any) => void;
}

export default class EmporaRadioGroup extends Component<EmporaRadioGroupArgs> {
    get name() {
        return this.args.name || guidFor(this);
    }
}
