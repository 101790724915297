import Analytics from 'portal/services/analytics';
import Component from '@glimmer/component';
import User from 'portal/models/user';
import { BufferedChangeset } from 'ember-changeset/types';
import { Changeset } from 'ember-changeset';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

interface TermsConditionsDialogArgs {
    user: User;
}

export default class TermsConditionsDialog extends Component<TermsConditionsDialogArgs> {
    changeset: BufferedChangeset;

    @service
    declare analytics: Analytics;

    constructor(owner: unknown, args: TermsConditionsDialogArgs) {
        super(owner, args);
        this.changeset = Changeset(args.user);
    }

    @action
    async onChange() {
        this.analytics.trackEvent('Terms and Conditions / Continue Clicked');
        await this.changeset.save();
        return false;
    }
}
