import Actor, { actorValidations } from 'portal/models/actor';
import Analytics from 'portal/services/analytics';
import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import lookupValidator from 'ember-changeset-validations';
import { BufferedChangeset } from 'ember-changeset/types';
import { Changeset } from 'ember-changeset';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface AddUserDialogArgs {
    deal: Deal;
    onClose: () => void;
}

export default class AddUserDialog extends Component<AddUserDialogArgs> {
    actor!: Actor;

    constructor(owner: unknown, args: AddUserDialogArgs) {
        super(owner, args);
        this.actor = this.store.createRecord('actor', { deal: this.args.deal });

        this.changeset = Changeset(this.actor, lookupValidator(actorValidations), actorValidations);
    }

    @service
    declare analytics: Analytics;

    @service
    declare store: Store;

    @service
        router!: RouterService;

    @tracked
        changeset!: BufferedChangeset;

    @tracked
        showTeamPrompt = false;

    @action
    async onInvite() {
        await this.changeset.save();
        this.analytics.trackEvent('Deal User Invited');

        this.showTeamPrompt = true;
    }

    @action
    validate() {
        this.changeset.validate();
    }

    @action
    onGoToMyTeam() {
        this.router.transitionTo('protected.my-account.team-overview');
    }

    willDestroy() { // eslint-disable-line ember/require-super-in-lifecycle-hooks
        if (this.actor.get('isNew') && (!this.store.isDestroying && !this.store.isDestroyed)) {
            this.actor.deleteRecord();
        }
    }

}
