import BaseRoute from 'portal/routes/base-route';
import Deal from 'portal/models/deal';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';


export default class ProtectedDealActionItemCompletion extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare router: RouterService;

    async model({ action_item_id }: { action_item_id: string }) {
        if (!action_item_id) {
            return {};
        }

        const actionItemLink = await this.store.findRecord('action-item-link', action_item_id);
        const deal = this.modelFor('protected.deal') as Deal;
        await deal?.hasMany('actionItems')?.reload();
        const actionItems = (await deal.actionItems)?.sortBy('dueAt');

        const previousActivity = actionItems?.find((actionItem) => actionItem.id === action_item_id);
        let nextActivity = undefined;

        if (!previousActivity) return { previousActivity, nextActivity };

        nextActivity = actionItems?.find((actionItem) => actionItem.id == actionItemLink.nextActionItemId );
        nextActivity ||= actionItems?.filter((a) => !a.hasCompleted)?.sortBy('dueAt')[0];
        nextActivity ||= actionItems?.find((actionItem) => !actionItem.hasCompleted);

        if (previousActivity.id === nextActivity?.id){
            nextActivity = undefined;
        }

        return { previousActivity, nextActivity };
    }

}
