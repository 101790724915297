export function maskPhoneNumber(param: string) {
    const phoneNumber = param;

    if (phoneNumber) {
        const unmaskedPhoneNumber = phoneNumber.replace(/[^0-9]+/g, '');

        const unmaskedPhoneNumberLength = unmaskedPhoneNumber.length;

        if (unmaskedPhoneNumberLength == 0) {
            return '';
        }
        if (unmaskedPhoneNumberLength <= 3) {
            return '('.concat(unmaskedPhoneNumber);
        } else if (unmaskedPhoneNumberLength < 7) {
            const areaCode = unmaskedPhoneNumber.slice(0, 3);
            const remainingDigits = unmaskedPhoneNumber.slice(3, unmaskedPhoneNumberLength);
            return '('.concat(areaCode).concat(') ').concat(remainingDigits);
        } else {
            const areaCode = unmaskedPhoneNumber.slice(0, 3);
            const digitsBeforeDash = unmaskedPhoneNumber.slice(3, 6);
            const remainingDigits = unmaskedPhoneNumber.slice(6, 10);
            return '('.concat(areaCode).concat(') ').concat(digitsBeforeDash).concat('-').concat(remainingDigits);
        }
    }
    return '';
}