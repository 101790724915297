import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import DealSide from 'portal/services/deal-side';
import ProtectedDeal from 'portal/routes/protected/deal';
import RSVP from 'rsvp';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { ModelFrom } from 'portal/utils/type-utils';
import { TitleholderType } from 'portal/models/titleholder';
import { action } from '@ember/object';
import { isNone } from '@ember/utils';
import { inject as service } from '@ember/service';

export default class ProtectedDealTitleholderInformationSelectEntity extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare router: RouterService;

    @service
    declare dealSide: DealSide;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Titleholder Information / Select Entity');
    }

    async model() {
        const deal = this.modelFor(
            'protected.deal'
        ) as ModelFrom<ProtectedDeal>;
        const titleholder = await deal.latestTitleholder(this.dealSide.for(deal.id));
        return RSVP.hash({
            titleholder,
            deal,
            legalEntities: this.store.findAll('legalEntity', {
                reload: true
            })
        });
    }

    afterModel({
        titleholder
    }: ModelFrom<ProtectedDealTitleholderInformationSelectEntity>) {
        if (
            isNone(titleholder) ||
            titleholder.type?.toUpperCase() === TitleholderType.individual
        ) {
            this.router.replaceWith(
                'protected.deal.titleholder-information.preference'
            );
        }
    }
}
