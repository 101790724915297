import Model, { attr } from '@ember-data/model';

export default class Impression extends Model {
    @attr('string')
        impressionableType!: string;

    @attr('string')
        impressionableId!: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'impression': Impression;
    }
}
