import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Deal from 'portal/models/deal';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import lookupValidator from 'ember-changeset-validations';
import { Changeset } from 'ember-changeset';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { signingAddressValidations } from 'portal/models/address';
import { signingAppointmentValidations } from 'portal/models/calendar-appointment';
import { signingDetailValidations } from 'portal/models/signing-detail';

export default class ProtectedDealClosingRequest extends BaseRoute {
    @service
    declare router: RouterService;

    @service
    declare analytics: Analytics;

    @service
    declare store: Store;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Closing Request');
    }

    async model() {
        const deal = this.modelFor('protected.deal') as Deal;

        const appointment = this.store.createRecord('calendar-appointment');
        const signingLocation = this.store.createRecord('address');
        const signingDetail = this.store.createRecord('signing-detail', {
            dealId: deal?.id,
            appointment,
            signingLocation
        });

        return {
            deal,
            appointmentChangeset: Changeset(appointment, lookupValidator(signingAppointmentValidations), signingAppointmentValidations),
            signingLocationChangeset: Changeset(signingLocation, lookupValidator(signingAddressValidations), signingAddressValidations),
            signingDetailChangeset: Changeset(signingDetail, lookupValidator(signingDetailValidations), signingDetailValidations)
        };
    }
}