import Analytics from 'portal/services/analytics';
import ArrayProxy from '@ember/array/proxy';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import Deal from 'portal/models/deal';
import EntityTitleholder from 'portal/models/entity-titleholder';
import IndividualTitleholder from 'portal/models/individual-titleholder';
import LegalEntity from 'portal/models/legal-entity';
import LegalIndividual from 'portal/models/legal-individual';
import ReissueRate from 'portal/models/reissue-rate';
import Store from '@ember-data/store';
import User from 'portal/models/user';
import { DealSides } from 'portal/services/deal-side';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedDealOnboardingRoute } from '../onboarding';
import { action } from '@ember/object';
import { isNone } from '@ember/utils';
import { inject as service } from '@ember/service';

export default class ProtectedDealOnboardingSellerTitleholderInfo extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    @service
    declare auth: AuthService;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Onboarding / Seller Titleholder Info');
    }

    async model(): Promise<{
        deal: Deal,
        reissueRate: ReissueRate,
        user: User,
        titleholders: Array<IndividualTitleholder | EntityTitleholder>,
        legalEntities: ArrayProxy<LegalEntity>,
        legalIndividuals: ArrayProxy<LegalIndividual>
    }> {
        this.store.unloadAll('individualTitleholder');
        this.store.unloadAll('entityTitleholder');

        const parentModel = this.modelFor(
            'protected.deal.onboarding'
        ) as ModelFrom<ProtectedDealOnboardingRoute>;

        const deal = await parentModel?.deal as Deal;
        const user = await this.auth.getAuthenticatedUser();
        const titleholders = await deal.titleholdersForSide(DealSides.CURRENT);
        const legalEntities = await this.store.findAll('legalEntity', { reload: true });
        const legalIndividuals = await this.store.findAll('legalIndividual', {  reload: true });

        let reissueRate = await deal.get('reissueRate') as ReissueRate;
        if (isNone(reissueRate)) {
            reissueRate = this.store.createRecord('reissueRate', { deal: deal });
        }

        return { deal, reissueRate, user, titleholders, legalEntities, legalIndividuals };
    }
}
