import ActionItem from 'portal/adapters/action-item';

export default class ProvideRentalInformationActivity extends ActionItem {}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
    export default interface AdapterRegistry {
        'provide-rental-information-activity': ProvideRentalInformationActivity
    }
}
