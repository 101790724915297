import DocumentModel from './document';
import IntakeUser from './intake-user';
import Model, { AsyncBelongsTo, AsyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';
import { validateNumber } from 'ember-changeset-validations/validators';


export default class IntakePurchaseContract extends Model {
    @attr('string')
        additionalDetails?: string;

    @hasMany('document')
        documents?: AsyncHasMany<DocumentModel>;

    @attr('number')
        earnestMoneyAssignorAmount?: number;

    @attr('number')
        earnestMoneyBuyerAmount?: number;

    @attr('number')
        purchasePrice?: number;

    @belongsTo('intake-user')
        intakeUser?: AsyncBelongsTo<IntakeUser>;

    @attr('string')
        createDealRequestId?: string;
}

export const intakePurchaseContractValidations = {
    earnestMoneyBuyerAmount: validateNumber({ allowBlank: true, gte:0 }),
    purchasePrice: validateNumber({ allowBlank: false, gte:0 })
};

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'intake-purchase-contract': IntakePurchaseContract;
    }
}
