import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Deal from 'portal/models/deal';
import EarnestMoneyDetail from 'portal/models/earnest-money-detail';
import { BufferedChangeset } from 'ember-changeset/types';
import { Changeset } from 'ember-changeset';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealEarnestMoneyPortal extends BaseRoute {
    @service
    declare analytics: Analytics;

    async model(): Promise<{earnestMoneyDetail: EarnestMoneyDetail, acceptTos: BufferedChangeset}> {
        const deal = await this.modelFor('protected.deal') as Deal;
        return {
            earnestMoneyDetail: await deal.earnestMoneyDetail!,
            acceptTos: Changeset({ value: false })
        };
    }

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Earnest Money Portal');
    }

    @action
    error(): boolean {
        this.analytics.trackEvent('Error on Earnest Money Portal');
        return true;
    }
}
