import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import DealSide from 'portal/services/deal-side';
import EntityTitleholder from 'portal/models/entity-titleholder';
import ProtectedDealRoute from 'portal/routes/protected/deal';
import RSVP from 'rsvp';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealOnboardingSelectEntity extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare router: RouterService;

    @service
    declare dealSide: DealSide;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Onboarding / Select Entity');
    }

    async model() {
        const deal = this.modelFor(
            'protected.deal'
        ) as ModelFrom<ProtectedDealRoute>;
        const titleholder = await deal.latestTitleholder(this.dealSide.for(deal.id));
        return RSVP.hash({
            titleholder,
            deal,
            wireInstructionsRequired: false,
            legalEntities: this.store.findAll('legalEntity', {
                reload: true
            })
        });
    }

    async afterModel(model: ModelFrom<ProtectedDealOnboardingSelectEntity>) {
        const titleholder = await model?.titleholder;
        if (titleholder && !titleholder.isNew) {
            this.router.replaceWith(
                'protected.deal.onboarding.welcome'
            );
        }

        if (
            titleholder === undefined ||
            !(titleholder instanceof EntityTitleholder)
        ) {
            this.router.replaceWith(
                'protected.deal.onboarding.titleholder-preference'
            );
        }
    }
}
