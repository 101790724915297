import AuthService from 'portal/services/auth';
import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import Team from 'portal/models/team';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface DealMembersArgs {
    deal: Deal;
    showInviteTooltip: boolean;
}

export default class DealMembers extends Component<DealMembersArgs> {
    team?: Team;

    @service
        auth!: AuthService;

    @tracked
        addTeamOrUserDialogShown = false;

    @tracked
        addTeamDialogShown = false;

    @tracked
        addUserDialogShown = false;

    @tracked
        highlightTooltipShown = true;

    constructor(owner: unknown, args: DealMembersArgs) {
        super(owner, args);
        this.highlightTooltipShown = args.showInviteTooltip;
    }

    get actorsWithoutTeam() {
        return this.args.deal?.actors?.filter((actor) => !actor.teamName);
    }

    get actorsGroupedByTeams() {
        const result: any = {};

        this.args.deal?.actors?.forEach((actor) => {
            if (actor.teamName) {
                result[actor.teamName] = (result[actor.teamName] || []).concat(actor);
            }
        });

        return Object.entries(result);
    }

    @action
    showAddTeamOrUserDialog() {
        this.addTeamOrUserDialogShown = true;
    }

    @action
    hideAddTeamOrUserDialog() {
        this.addTeamOrUserDialogShown = false;
    }

    @action
    async showAddTeamDialog() {
        this.team = await this.auth.user?.team;
        this.addTeamDialogShown = true;
    }

    @action
    hideAddTeamDialog() {
        this.addTeamDialogShown = false;
    }

    @action
    showAddUserDialog() {
        this.addUserDialogShown = true;
    }

    @action
    hideAddUserDialog() {
        this.addUserDialogShown = false;
    }

    @action
    hideHighlightTooltip() {
        this.highlightTooltipShown = false;
    }

    @action
    onNext(selection: string){
        this.hideAddTeamOrUserDialog();

        if (selection === 'team') {
            this.showAddTeamDialog();
        } else {
            this.showAddUserDialog();
        }
    }

    @action
    async onInvite() {
        const team = await this.auth.user?.team;
        const teamMembers = await team?.teamMembers || [];

        if (team && teamMembers.length > 1) {
            this.showAddTeamOrUserDialog();
        } else {
            this.showAddUserDialog();
        }
    }
}
