import Component from '@glimmer/component';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import TeamMember, { Role, teamMemberValidations } from 'portal/models/team-member';
import lookupValidator from 'ember-changeset-validations';
import { BufferedChangeset } from 'ember-changeset/types';
import { Changeset } from 'ember-changeset';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export interface ProfileMyTeamWrapperArgs {
    teamChangeset: BufferedChangeset;
    teamMembers: TeamMember[];
    onAfterSave?: () => void;
}

export default class ProfileMyTeam extends Component<ProfileMyTeamWrapperArgs> {
    @service
    declare store: Store;

    @service
    declare router: RouterService;

    @tracked
        showAddTeamMemberDialog?: boolean = false;

    @tracked
        teamMemberChangesets = this.setTeamMemberChangesets;

    @tracked
        isError?: boolean = false;

    get nonDeletedTeamMembers() {
        return this.args.teamMembers?.filterBy('isDeleted', false) || [];
    }

    get setTeamMemberChangesets(): BufferedChangeset[] {
        return this.nonDeletedTeamMembers.toArray().map((teamMember) => {
            return Changeset(
                teamMember,
                lookupValidator(teamMemberValidations),
                teamMemberValidations,
                { changesetKeys: Object.keys(TeamMember.attributes) }
            );
        });
    }

    @action
    onAdd() {
        this.showAddTeamMemberDialog = true;
    }

    @action
    onDelete(teamMemberChangeset: BufferedChangeset) {
        this.teamMemberChangesets.forEach((changeset) => changeset.execute());
        const teamMember = teamMemberChangeset.data as TeamMember;
        teamMember.deleteRecord();
        this.teamMemberChangesets = this.setTeamMemberChangesets;
    }

    @action
    async onSave() {
        if (this.args.teamChangeset.isDirty) {
            await this.args.teamChangeset.save();
        }

        this.teamMemberChangesets.forEach((changeset) => changeset.execute());

        const activeTeamMembers = this.teamMemberChangesets.map((changeset) => { return changeset.data as TeamMember; });
        const newOrDirtyTeamMembers = activeTeamMembers.filter((teamMember) => teamMember.isNew || teamMember.hasDirtyAttributes);
        const deletedTeamMembers = this.args.teamMembers?.filter((teamMember) => teamMember.isDeleted);

        const adminPromises =
            newOrDirtyTeamMembers.filter((teamMember) => teamMember.role === Role.admin).map(async (teamMember) => await teamMember.save());
        const userPromises =
            newOrDirtyTeamMembers.filter((teamMember) => teamMember.role === Role.user).map(async (teamMember) => await teamMember.save());
        const deletedPromises =
            deletedTeamMembers.map(async (teamMember) => await teamMember.save());

        const promises = adminPromises.concat(userPromises).concat(deletedPromises);
        Promise.all(promises).then(
            () => {
                this.args.onAfterSave ? this.args.onAfterSave() : this.router.transitionTo('protected.my-account.team-overview');
            },
            () => { this.isError = true; }
        );
    }

    @action
    onClose(changeset: BufferedChangeset){
        const teamMember = changeset.data as TeamMember;
        teamMember.rollbackAttributes();
        this.showAddTeamMemberDialog = false;
    }

    @action
    onInvite(changeset: BufferedChangeset){
        changeset.execute();
        this.teamMemberChangesets = this.teamMemberChangesets.concat([changeset]);
        this.showAddTeamMemberDialog = false;
    }
}