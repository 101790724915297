import ActionItem from 'portal/models/action-item';
import Component from '@glimmer/component';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

interface DealActionItemsSellerTitleholderInformationArgs {
    actionItem: ActionItem;
}

export default class DealActionItemsSellerTitleholderInformation extends Component<DealActionItemsSellerTitleholderInformationArgs> {
    @service
        router!: RouterService;

    @action
    onNext() {
        this.router.transitionTo('protected.deal.onboarding.seller-titleholder-info');
    }
}
