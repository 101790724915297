import Model, { attr } from '@ember-data/model';
import validateConfirmation from 'portal/validators/confirmation';
import validateRoutingNumber from 'portal/validators/routing-number';
import {
    validateFormat,
    validatePresence
} from 'ember-changeset-validations/validators';

export default class LegalIndividualWireInstruction extends Model {
    @attr('string')
        legalIndividualId?: string;

    @attr('string')
        bankName?: string;

    @attr('string')
        routingNumber?: string;

    @attr('string')
        routingNumberConfirmation?: string;

    @attr('string')
        accountNumber?: string;

    @attr('string')
        accountNumberConfirmation?: string;

    @attr('string')
        instructions?: string;

    @attr('string')
        street?: string;

    @attr('string')
        street2?: string;

    @attr('string')
        city?: string;

    @attr('string')
        state?: string;

    @attr('string')
        zipcode?: string;
}

export const legalIndividualWireInstructionValidations = {
    bankName: validatePresence({
        presence: true,
        message: 'Bank name can’t be blank'
    }),
    routingNumber: [
        validatePresence({
            presence: true,
            message: 'Routing number can’t be blank'
        }),
        validateRoutingNumber('Routing number', { allowBlank: true })
    ],
    routingNumberConfirmation: [
        validatePresence({
            presence: true,
            message: 'Routing number confirmation can’t be blank'
        }),
        validateConfirmation({
            on: 'routingNumber',
            message: 'Routing numbers don’t match'
        })
    ],
    accountNumber: validatePresence({
        presence: true,
        message: 'Account number can’t be blank'
    }),
    accountNumberConfirmation: [
        validatePresence({
            presence: true,
            message: 'Account number confirmation can’t be blank'
        }),
        validateConfirmation({
            on: 'accountNumber',
            message: 'Account numbers don’t match'
        })
    ],
    street: validatePresence({
        presence: true,
        ignoreBlank: true,
        message: 'Address can’t be blank'
    }),
    city: validatePresence({
        presence: true
    }),
    state: [
        validatePresence({
            presence: true
        }),
        validateFormat({ regex: /^[A-Z]{2}$/ })
    ],
    zipcode: [
        validatePresence({
            presence: true,
            message: 'ZIP code can’t be blank'
        }),
        validateFormat({
            regex: /^[0-9]{5}(-[0-9]{4})?$/,
            message: 'ZIP code is invalid'
        })
    ]
};

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'legal-individual-wire-instruction': LegalIndividualWireInstruction;
  }
}