import Contact from 'portal/models/contact';
import DocumentModel from 'portal/models/document';
import LegalEntityDesignatedSigner from './legal-entity-designated-signer';
import LegalEntityWireInstruction from 'portal/models/legal-entity-wire-instruction';
import Model, {
    AsyncBelongsTo,
    AsyncHasMany,
    attr,
    belongsTo,
    hasMany
} from '@ember-data/model';
import User from 'portal/models/user';
import validateEmail from 'portal/validators/email';
import validatePhoneNumber from 'portal/validators/phone-number';
import validatePresenceWhenTrue from 'portal/validators/presence-when-true';
import validateTaxId from 'portal/validators/tax-id';
import { oneLineAddress } from 'portal/models/basic-address';
import {
    validateFormat,
    validateLength,
    validatePresence
} from 'ember-changeset-validations/validators';

export enum LegalEntityType {
    C_CORP = 'C_CORP',
    ESTATE = 'ESTATE',
    GOVERNMENT = 'GOVERNMENT',
    GUARDIANSHIP = 'GUARDIANSHIP',
    LLC = 'LLC',
    NON_PROFIT = 'NON_PROFIT',
    PARTNERSHIP = 'PARTNERSHIP',
    RECEIVERSHIP_CONSERVATORSHIP = 'RECEIVERSHIP_CONSERVATORSHIP',
    TRUST = 'TRUST',
}

export default class LegalEntityModel extends Model {
    @attr('string')
        type?: LegalEntityType;

    @belongsTo('contact')
        contact?: AsyncBelongsTo<Contact>;

    @attr('string')
        taxId?: string;

    @hasMany('document')
        primaryDocuments?: AsyncHasMany<DocumentModel>;

    @hasMany('document')
        otherDocuments?: AsyncHasMany<DocumentModel>;

    @hasMany('legal-entity-designated-signer')
        designatedSigners?: AsyncHasMany<LegalEntityDesignatedSigner>;

    @belongsTo('user')
        userProfile?: AsyncBelongsTo<User>;

    @belongsTo('legal-entity-wire-instruction')
        legalEntityWireInstruction?: LegalEntityWireInstruction;

    @attr('boolean')
        designatedSignerSelectedForTitleholder?: boolean;

    @attr('string')
        entityName?: string;

    @attr('string')
        email?: string;

    @attr('string')
        phoneNumber?: string;

    @attr('string')
        street?: string;

    @attr('string')
        street2?: string | null;

    @attr('string')
        city?: string;

    @attr('string')
        state?: string;

    @attr('string')
        zipcode?: string;

    get addressString(): string {
        return oneLineAddress(this);
    }

    get displayableTypeKey(): string {
        return LegalEntityModel.displayableTypeKey(this.type);
    }

    get primaryDocument(): DocumentModel | undefined {
        return this.primaryDocuments?.toArray()[0];
    }

    get primaryDocumentDisplayTitle(): string {
        return LegalEntityModel.primaryDocumentDisplay(this.type as LegalEntityType).title;
    }

    static displayableTypeKey(type: LegalEntityType | undefined): string {
        return `components.entity.legal_entity.types.${type?.toLowerCase()}`;
    }

    static primaryDocumentDisplay(type: LegalEntityType | undefined): { title: string, body: string, plural: boolean } {
        let title, body;
        let plural = false;

        switch (type) {
        case LegalEntityType.C_CORP:
        case LegalEntityType.NON_PROFIT: {
            title = 'Articles of Incorporation';
            plural = true;
            break;
        }
        case LegalEntityType.ESTATE: {
            title = 'estate documents'; // intentional lowercase, not a proper noun
            plural = true;
            body = 'Letters of Authority, Will, Inventory, or Probate Petition';
            break;
        }
        case LegalEntityType.GOVERNMENT: {
            title = 'Signature Authorization';
            break;
        }
        case LegalEntityType.GUARDIANSHIP: {
            title = 'Letters of Guardianship';
            plural = true;
            break;
        }
        case undefined:
        case LegalEntityType.LLC: {
            title = 'Operating Agreement';
            break;
        }
        case LegalEntityType.PARTNERSHIP: {
            title = 'Partnership Agreement';
            break;
        }
        case LegalEntityType.RECEIVERSHIP_CONSERVATORSHIP: {
            title = 'Letters of Conservatorship/Receivership';
            plural = true;
            break;
        }
        case LegalEntityType.TRUST: {
            title = 'trust documents'; // intentionally lowercase, not a proper noun
            plural = true;
            body = 'Certificate of Trust or Trust Agreement';
            break;
        }
        }

        return {
            title,
            body: body ?? title,
            plural
        };
    }

    static showOnCreate(type: LegalEntityType): boolean {
        const hiddenTypesOnCreate = [
            LegalEntityType.GOVERNMENT,
            LegalEntityType.GUARDIANSHIP,
            LegalEntityType.RECEIVERSHIP_CONSERVATORSHIP
        ];

        return !hiddenTypesOnCreate.includes(type);
    }
}

export const assignorLegalEntityValidations = {
    type: validatePresence({ presence: true }),
    entityName: validatePresence({ presence: true }),
    email: [
        validatePresence({ presence: true }),
        validateEmail('Email address')
    ],
    phoneNumber: [
        validatePresence({ presence: true }),
        validatePhoneNumber('Phone number')
    ]
};

export const legalEntityValidations = {
    type: validatePresence({ presence: true }),
    entityName: validatePresence({ presence: true }),
    email: [
        validatePresence({ presence: true }),
        validateEmail('Email address')
    ],
    phoneNumber: [
        validatePresence({ presence: true }),
        validatePhoneNumber('Phone number')
    ],
    taxId: [
        validateTaxId('EIN', { allowBlank: true })
    ],
    street: validatePresence({
        presence: true,
        on: ['city', 'state', 'zipcode'],
        message: 'Address can’t be blank'
    }),
    city: validatePresence({
        presence: true,
        on: 'street'
    }),
    state: validatePresence({
        presence: true,
        on: 'city',
        message: 'State must be selected'
    }),
    zipcode: [
        validatePresence({
            presence: true,
            on: 'state',
            message: 'ZIP code can’t be blank'
        }),
        validateFormat({
            allowBlank: true,
            regex: /^[0-9]{5}(-[0-9]{4})?$/,
            message: 'ZIP code is invalid'
        })
    ],
    primaryDocuments: validateLength({
        is: 1,
        message: 'Must provide one primary document'
    })
};

export const legalEntityValidationsWithTaxId = {
    ...legalEntityValidations,
    taxId: [
        validatePresenceWhenTrue({ presence: true, key: 'isNew' }),
        validateTaxId('EIN', { allowBlank: true })
    ]
};

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'legal-entity': LegalEntityModel;
    }
}
