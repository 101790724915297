import Model, { attr } from '@ember-data/model';
import fullName from 'portal/utils/full-name';

export default class TeamMembership extends Model {
    @attr('string')
        firstName?: string;

    @attr('string')
        middleName?: string;

    @attr('string')
        lastName?: string;

    @attr('string')
        email?: string;

    @attr('string')
        phoneNumber?: string;

    @attr('string')
        phoneNumberType?: string;

    @attr('string')
        role?: string;

    @attr('string')
        teamId?: string;

    @attr('string')
        userProfileId?: string;

    get teamUser(): string {
        let teamUser;
        if (this.email) {
            teamUser = `${fullName(this.firstName, this.middleName, this.lastName)} - ${this.email}`;
        } else {
            teamUser = `${fullName(this.firstName, this.middleName, this.lastName)}`;
        }
        return teamUser.replace(/\s+/g, ' ').trim();
    }
}
