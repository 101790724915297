import ActionItem, { ActionItemDescriptions } from './action-item';
import { attr } from '@ember-data/model';

export default class BuyerClosingPreferencesAndCoveragesActivity extends ActionItem {
    @attr('string', { defaultValue: ActionItemDescriptions.BUYER_CLOSING_PREFERENCES_AND_COVERAGES })
        name?: ActionItemDescriptions.BUYER_CLOSING_PREFERENCES_AND_COVERAGES;

    @attr('string', { defaultValue: ActionItemDescriptions.BUYER_CLOSING_PREFERENCES_AND_COVERAGES })
        description?: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'buyer-closing-preferences-and-coverages-activity': BuyerClosingPreferencesAndCoveragesActivity;
    }
}
