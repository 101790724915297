import Deal from './deal';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

export default class DocumentUploadRequest extends Model {
    @attr('string')
        uploadUrl?: string;

    @attr()
        uploadHeaders?: any;

    @attr('string')
        path?: string;

    @attr('date')
        createdAt?: Date;

    @attr('date')
        updatedAt?: Date;

    @belongsTo('deal')
        deal?: AsyncBelongsTo<Deal> | Deal;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'document-upload-request': DocumentUploadRequest;
    }
}
