import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Deal from 'portal/models/deal';
import DealSide, { DealSides } from 'portal/services/deal-side';
import ProtectedDealRoute from 'portal/routes/protected/deal';
import RouterService from '@ember/routing/router-service';
type Transition = ReturnType<RouterService['transitionTo']>;
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealOnboardingRefinancePacer extends BaseRoute {
    @service
    declare router: RouterService;

    @service
    declare dealSide: DealSide;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Onboarding / Refinance Pacer');
    }

    async model() {
        return this.modelFor('protected.deal') as Deal;
    }

    async afterModel(model: ModelFrom<ProtectedDealOnboardingRefinancePacer>) {
        const futureTitleholder = await model?.latestTitleholder(DealSides.FUTURE);

        if (futureTitleholder && !futureTitleholder.isNew) {
            this.router.replaceWith('protected.deal.onboarding.refinance-credit-lines');
        }
    }

    @action
    async willTransition(transition: Transition): Promise<void> {
        const deal = this.modelFor('protected.deal') as ModelFrom<ProtectedDealRoute>;

        const newDealSide = transition.to.name.includes('protected.deal.onboarding.titleholder-preference')
            ? DealSides.FUTURE
            : DealSides.CURRENT;

        this.dealSide.switchTo(deal.id, newDealSide);
    }
}
