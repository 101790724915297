import Controller from '@ember/controller';
import ProtectedDealOnboardingConfirmEntityDetailsRoute from 'portal/routes/protected/deal/onboarding/confirm-entity-details';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealOnboardingConfirmEntityDetails extends Controller {
    declare model: ModelFrom<ProtectedDealOnboardingConfirmEntityDetailsRoute>;

    @service
    declare router: RouterService;

    @action
    onNext() {
        const { titleholder, deal } = this.model;

        if (deal.isBorrower) {
            if (titleholder?.isCurrent) {
                this.router.transitionTo('protected.deal.onboarding.property-information');
            } else {
                this.router.transitionTo('protected.deal.onboarding.refinance-credit-lines');
            }
        } else {
            this.router.transitionTo('protected.deal.onboarding.personal-information');
        }
    }

    @action
    onEdit() {
        if (this.model.legalEntity) {
            this.router.transitionTo('protected.deal.onboarding.edit-entity', {
                legalEntity: this.model.legalEntity,
                wireInstructionsRequired: this.model.wireInstructionsRequired
            }, {
                queryParams: {
                    confirming: true
                }
            });
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'protected/deal/onboarding/confirm-entity-details': ProtectedDealOnboardingConfirmEntityDetails;
  }
}
