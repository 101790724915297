/* eslint-disable ember/use-ember-data-rfc-395-imports */
import ApplicationSerializer from './application';
import DS from 'ember-data';
import { ModelRegistry } from 'ember-cli-mirage';

export default class ReferralSerializer extends ApplicationSerializer {
    // eslint-disable-next-line @typescript-eslint/ban-types
    serialize(snapshot: DS.Snapshot<keyof ModelRegistry>, options: {}) {
        const json = super.serialize(snapshot, options) as {data: any};

        delete json.data.attributes.referable;

        return json;
    }
}
