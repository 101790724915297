import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealTitleholderInformationPreference extends BaseRoute {

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Titleholder Information Preference');
    }

}
