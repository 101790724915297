import Analytics from 'portal/services/analytics';
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import { BufferedChangeset } from 'ember-changeset/types';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { stateOptions } from 'portal/models/state';

interface WireInstructionArgs {
    name: string;
    changeset: BufferedChangeset;
    showErrors?: boolean;
    showAddress?: boolean;
}

export default class WireInstruction extends Component<WireInstructionArgs> {
    stateOptions = stateOptions;

    @service
    declare analytics: Analytics;

    @service
        intl!: IntlService;

    @action
    onRoutingNumberChanged(value: string) {
        this.args.changeset.set('routingNumber', value);
        this.args.changeset.validate('routingNumberConfirmation');
    }

    @action
    onRoutingNumberConfirmationChanged(value: string) {
        this.args.changeset.set('routingNumberConfirmation', value);
        this.args.changeset.validate('routingNumber');
    }

    @action
    onAccountNumberChanged(value: string) {
        this.args.changeset.set('accountNumber', value);
        this.args.changeset.validate('accountNumberConfirmation');
    }

    @action
    onAccountNumberConfirmationChanged(value: string) {
        this.args.changeset.set('accountNumberConfirmation', value);
        this.args.changeset.validate('accountNumber');
    }
}
