import Actor from 'portal/models/actor';
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';

interface DealMemberArgs {
    actor: Actor;
}

export default class DealMember extends Component<DealMemberArgs> {
    get hasName() {
        const actor = this.args.actor;
        return isPresent(actor.givenName) && isPresent(actor.familyName);
    }

    get email() {
        const actor = this.args.actor;
        return isPresent(actor.email) ? actor.email : 'Email unknown';
    }

    get initials() {
        const actor = this.args.actor;
        if (this.hasName) {
            return (actor.givenName!.substr(0,1) + actor.familyName!.substr(0,1)).toUpperCase();
        } else {
            return '';
        }
    }
}
