import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import SigningMethod from 'portal/models/signing-method';
import { BufferedChangeset } from 'ember-changeset/types';
import { DateTime } from 'luxon';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface ClosingRequestArgs {
    deal: Deal;
    appointmentChangeset: BufferedChangeset;
    signingLocationChangeset: BufferedChangeset;
    signingDetailChangeset: BufferedChangeset;
    onSubmit: () => void;
}

export default class ClosingRequest extends Component<ClosingRequestArgs> {
    @tracked
        signingMethods?: SigningMethod[];

    @tracked
        dateModalOpen = false;

    @tracked
        selectedSigningMethod?: SigningMethod;

    @tracked
        selectedDate?: DateTime;

    constructor(owner: unknown, args: ClosingRequestArgs) {
        super(owner, args);
        this.signingMethods = this.args.deal.signingMethods;
    }

    resetDateAndTimeSelections() {
        this.args.appointmentChangeset.set('appointmentDate', undefined);
        this.args.appointmentChangeset.set('allDay', undefined);
        this.args.appointmentChangeset.set('startTime', undefined);
        this.args.appointmentChangeset.set('endTime', undefined);
        this.selectedDate = undefined;
    }

    @action
    onSigningMethodSelected(selectedSigningMethodKey: string) {
        this.resetDateAndTimeSelections();

        this.selectedSigningMethod = this.signingMethods?.find(
            (signingMethod: SigningMethod) => signingMethod.key == selectedSigningMethodKey
        );
        this.args.signingDetailChangeset.set('signingMethod', this.selectedSigningMethod?.key);
    }

    @action
    onDateModalToggle() {
        this.dateModalOpen = !this.dateModalOpen;
    }

    @action
    onDateSelected(selectedDate: DateTime, allDaySelected: boolean) {
        this.selectedDate = selectedDate;
        const selectedJSDate = selectedDate.toJSDate();
        this.args.appointmentChangeset.set('appointmentDate', selectedJSDate);
        this.args.appointmentChangeset.set('allDay', allDaySelected);

        if (allDaySelected) {
            this.args.appointmentChangeset.set('startTime', undefined);
            this.args.appointmentChangeset.set('endTime', undefined);
        } else {
            const endTime = selectedDate.plus({ hours: 1 }).toJSDate();

            this.args.appointmentChangeset.set('startTime', selectedJSDate);
            this.args.appointmentChangeset.set('endTime', endTime);
        }

        this.onDateModalToggle();
    }

    get relevantChangesets(): BufferedChangeset[] {
        const changesets = [
            this.args.appointmentChangeset,
            this.args.signingDetailChangeset
        ];

        if (this.locationRequired) {
            changesets.push(this.args.signingLocationChangeset);
        }

        return changesets;
    }

    @action
    async onSubmitSigning(): Promise<void> {
        this.relevantChangesets.forEach((changeset) => {
            changeset.validate();
            changeset.set('showErrorOnPristine', true);
        });

        if (this.relevantChangesets?.any((changeset) => changeset.isInvalid)) {
            return;
        }

        if (!this.locationRequired) {
            this.args.signingDetailChangeset.set('signingLocation', undefined);
        }

        this.relevantChangesets.forEach((c) => c.execute());

        await this.args.signingDetailChangeset.save();

        this.args.onSubmit();
    }

    get selectedDateForDisplay() {
        const dateString = this.selectedDate?.toFormat('MMM d, yyyy');

        if (this.allDaySelected) {
            return dateString;
        } else {
            return dateString + ' at ' + this.selectedDate?.toFormat('t ZZZZ');
        }
    }

    get allDaySelected() {
        return this.args.appointmentChangeset.get('allDay');
    }

    get rangeStart() {
        if (!this.args.deal.signingDateRange) {
            return;
        }

        return DateTime.fromISO(this.args.deal.signingDateRange.start).startOf('day');
    }

    get rangeEnd() {
        if (!this.args.deal.signingDateRange) {
            return;
        }

        return DateTime.fromISO(this.args.deal.signingDateRange.end).endOf('day');
    }

    get locationRequired() {
        return SigningMethod.requiresLocation(this.selectedSigningMethod);
    }

    get allDayAllowed() {
        return SigningMethod.canBeAllDay(this.selectedSigningMethod);
    }
}
