import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Deal from 'portal/models/deal';
import DwollaFundingSource from 'portal/models/dwolla-funding-source';
import PlaidLinkSession from 'portal/models/plaid-link-session';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealEarnestMoneyFundingSourceCreation extends BaseRoute {
    @service
    declare analytics: Analytics;

    @service
    declare store: Store;

    async model(): Promise<DwollaFundingSource | PlaidLinkSession | undefined> {
        const deal = await this.modelFor('protected.deal') as Deal;
        return this.store.createRecord('plaid-link-session', {
            deal: deal
        }).save();
    }

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Earnest Money Funding Source Creation');
    }

    @action
    error(): boolean {
        this.analytics.trackEvent('Error on Earnest Money Funding Source Creation');
        return true;
    }
}
