import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import PlaidLinkSession from 'portal/models/plaid-link-session';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedPlaidOauthLanding extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Plaid Oauth Landing');
    }

    async model(): Promise<PlaidLinkSession> {
        const sessions = await this.store.query('plaid-link-session', { incomplete: true, last: true });

        if (sessions.length == 1) {
            return sessions.firstObject!;
        } else {
            throw 'Could not find previous Plaid Link Session';
        }
    }

    @action
    error(): boolean {
        this.analytics.trackEvent('Error on Plaid Oauth Landing');
        return true;
    }
}
