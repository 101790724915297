import ApplicationSerializer from './application';

export default class SigningDetailSerializer extends ApplicationSerializer {
    attrs = {
        appointment: { embedded: 'always' },
        signingLocation: { embedded: 'always' }
    };
}

declare module 'ember-data/types/registries/serializer' {
    export default interface SerializerRegistry {
        signingDetail: SigningDetailSerializer;
    }
}
