import Analytics from 'portal/services/analytics';
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import { BufferedChangeset } from 'ember-changeset/types';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { stateOptions } from 'portal/models/state';
import { tracked } from '@glimmer/tracking';

interface EntityLegalEntityWireInstructionArgs {
    entityName: string;
    entityAddress: string;
    changeset: BufferedChangeset;
    showErrors?: boolean;
    onSave?: () => void;
    onCancel?: () => void;
}

export default class EntityLegalEntityWireInstruction extends Component<EntityLegalEntityWireInstructionArgs> {
    stateOptions = stateOptions;

    constructor(owner: unknown, args: EntityLegalEntityWireInstructionArgs) {
        super(owner, args);

        this.showErrorOnPristine = args.showErrors ?? false;
    }

    @service
    declare analytics: Analytics;

    @service
        intl!: IntlService;

    @tracked
        showErrorOnPristine: boolean;

    @action
    onSave() {
        this.showErrorOnPristine = true;

        if (this.args.changeset?.isInvalid) {
            return;
        }

        this.analytics.trackPage('Edited Legal Entities / Wire Instructions', {
            legalEntityId: this.args.changeset.legalEntityId
        });

        this.args.onSave?.();
    }

    @action
    onCancel() {
        this.args.changeset.rollback();
        this.args.changeset.validate();
        this.args.onCancel?.();
    }

    @action
    onRoutingNumberChanged(value: string) {
        this.args.changeset.set('routingNumber', value);
        this.args.changeset.validate('routingNumberConfirmation');
    }

    @action
    onRoutingNumberConfirmationChanged(value: string) {
        this.args.changeset.set('routingNumberConfirmation', value);
        this.args.changeset.validate('routingNumber');
    }

    @action
    onAccountNumberChanged(value: string) {
        this.args.changeset.set('accountNumber', value);
        this.args.changeset.validate('accountNumberConfirmation');
    }

    @action
    onAccountNumberConfirmationChanged(value: string) {
        this.args.changeset.set('accountNumberConfirmation', value);
        this.args.changeset.validate('accountNumber');
    }
}
