import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealOnboardingEditEntity extends Controller {
    queryParams = ['confirming'];

    confirming = false;

    @service
    declare router: RouterService;

    @action
    onNext() {
        if (this.confirming) {
            this.router.transitionTo('protected.deal.onboarding.confirm-entity-details');
        } else {
            this.router.transitionTo('protected.deal.onboarding.select-entity');
        }
    }
}