import ENV from 'portal/config/environment';
import HelloSign from 'hellosign-embedded';
import Service from '@ember/service';

export default class Hellosign extends Service {
    createClient(): HelloSign {
        return new HelloSign({
            clientId: ENV.HELLOSIGN_CLIENT_ID
        });
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
    interface Registry {
        hellosign: Hellosign;
    }
}
