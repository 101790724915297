import ActionItem from 'portal/models/action-item';
import Address from 'portal/models/address';
import Component from '@glimmer/component';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';

interface DealActionItemsBuyerClosingPreferencesArgs {
    actionItem: ActionItem;
    onRequestAChange: () => void;
    onStart: () => void;
}

export default class DealActionItemsBuyerClosingPreferences extends Component<DealActionItemsBuyerClosingPreferencesArgs> {
    @service
        router!: RouterService;

    @service intl!: IntlService;

    @tracked
        rawTaxAddress: Address | undefined;

    @tracked
        cpcAcceptedBooleanValue?: boolean;

    @tracked
        notaryPreference: string | undefined;

    constructor(owner: unknown, args: DealActionItemsBuyerClosingPreferencesArgs) {
        super(owner, args);

        taskFor(this.loadTaxAddress).perform();
        taskFor(this.loadCpcAcceptedBooleanValue).perform();
        taskFor(this.loadNotaryPreference).perform();
    }

    @task
    async loadTaxAddress(): Promise<void> {
        const deal = await this.args.actionItem.deal;
        const taxAddress = await deal?.taxAddress;
        this.rawTaxAddress = await taxAddress?.address;
    }

    @task
    async loadCpcAcceptedBooleanValue(): Promise<void> {
        const deal = await this.args.actionItem.deal;
        this.cpcAcceptedBooleanValue = await deal?.cpcAccepted;
    }

    @task
    async loadNotaryPreference(): Promise<void> {
        const deal = await this.args.actionItem.deal;
        this.notaryPreference = await deal?.notaryPreference;
    }

    get notaryPreferenceDescription(): string {
        if (this.notaryPreference) {
            return this.intl.t(
                // eslint-disable-next-line max-len
                `components.deal.action_items.buyer_closing_preferences_and_coverages.notary_preference.${this.notaryPreference.toLowerCase()}`
            );
        }

        return this.intl.t('shared.not_selected');
    }

    get taxForwardingAddress(): string {
        if (this.rawTaxAddress == undefined) {
            return this.intl.t('components.deal.action_items.buyer_closing_preferences_and_coverages.to_my_lender_or_mortgage_company');
        } else {
            return this.rawTaxAddress.oneLine;
        }
    }
}
