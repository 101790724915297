import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import LegalIndividual from 'portal/models/legal-individual';
import ProtectedDealRoute from 'portal/routes/protected/deal';
import RSVP from 'rsvp';
import Store from '@ember-data/store';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealTitleholderInformationEditIndividual extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    @service
    declare auth: AuthService;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Titleholder Information / Edit Individual');
    }

    async model({ legal_individual_id }: { legal_individual_id: string }) {
        const user = await this.auth.getAuthenticatedUser();
        const deal = this.modelFor('protected.deal') as ModelFrom<ProtectedDealRoute>;
        const legalIndividual = await this.store.findRecord(
            'legalIndividual', legal_individual_id) as LegalIndividual;

        return RSVP.hash({
            user,
            deal,
            legalIndividual
        });
    }

}
