import Component from '@glimmer/component';
import Team from 'portal/models/team';

export interface ProfileTeamOverviewArgs {
    team: Team;
    onAfterSave?: () => Promise<void>;
    onCancel?: () => Promise<void>;
    onCreate: () => void;
    onEdit: () => void;
}


export default class ProfileTeamOverview extends Component<ProfileTeamOverviewArgs> {
    get hasOnAfterSave(){
        return this.args.onAfterSave !== undefined;
    }

    get hasOnCancel(){
        return this.args.onCancel !== undefined;
    }
}
