/* eslint-disable ember/use-ember-data-rfc-395-imports */
import ApplicationSerializer from './application';
import DS from 'ember-data';
import { ModelRegistry } from 'ember-cli-mirage';

export default class LegalIndividualSerializer extends ApplicationSerializer {
    // eslint-disable-next-line @typescript-eslint/ban-types
    serialize(snapshot: DS.Snapshot<keyof ModelRegistry>, options: {}) {
        const json = super.serialize(snapshot, options) as {data: any};
        const wireInstructionSnapshot = snapshot.belongsTo('legalIndividualWireInstruction');

        if (wireInstructionSnapshot) {
            const wireInstructionJson = super.serialize(wireInstructionSnapshot, options) as {data: any};

            json.data.attributes['wire-instruction-attributes'] = wireInstructionJson.data.attributes;
        }

        return json;
    }
}