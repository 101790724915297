import Component from '@glimmer/component';
import Store from '@ember-data/store';
import Team from 'portal/models/team';
import TeamMember, { Role, teamMemberValidations } from 'portal/models/team-member';
import lookupValidator from 'ember-changeset-validations';
import { BufferedChangeset } from 'ember-changeset/types';
import { Changeset } from 'ember-changeset';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface ProfileAddTeamMemberDialogArgs {
    onClose: (changeset: BufferedChangeset) => void;
    onInvite: (changeset: BufferedChangeset) => void;
    team: Team;
}

export default class ProfileAddTeamMemberDialog extends Component<ProfileAddTeamMemberDialogArgs>{
    constructor(owner: unknown, args: ProfileAddTeamMemberDialogArgs) {
        super(owner, args);
        this.teamMember = this.store.createRecord('teamMember', { team: this.args.team, pending: true });
        this.changeset = Changeset(this.teamMember, lookupValidator(teamMemberValidations), teamMemberValidations);
    }

    teamMember!: TeamMember;

    @service
    declare store: Store;

    @tracked
        changeset!: BufferedChangeset;

    get dropdownOptions() {
        return [
            { name: 'Admin', value: Role.admin },
            { name: 'User', value: Role.user }
        ];
    }

    @action
    validate() {
        this.changeset.validate();
    }

    @action
    async onInvite() {
        this.changeset.set('showErrorOnPristine', true);

        if (this.changeset.isInvalid) {
            return;
        }

        await this.args.onInvite(this.changeset);
    }
}
