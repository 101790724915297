import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedMyAccountLegalEntities extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    @service
    declare auth: AuthService;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Add My titleholders');
    }

    async model() {
        const user = await this.auth.getAuthenticatedUser();
        return {
            user,
            legalIndividual: this.store.createRecord('legal-individual')
        };
    }
}
