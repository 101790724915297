import AuthBaseService from 'portal/services/auth-base';
import EmporaServer from 'portal/services/empora-server';
import RouterService from '@ember/routing/router-service';
import window from 'ember-window-mock';
import { inject as service } from '@ember/service';

export default class MagicLoginService extends AuthBaseService {

    @service
        emporaServer!: EmporaServer;

    @service
    declare router: RouterService;

    private static readonly TOKEN_KEY = 'magic_token';

    hasToken(): boolean {
        return localStorage.getItem(MagicLoginService.TOKEN_KEY) !== null;
    }

    async getToken(): Promise<any> {
        const token = localStorage.getItem(MagicLoginService.TOKEN_KEY);
        if (token) {
            return 'magic:' + token;
        } else {
            return null;
        }
    }

    async deleteTokenForAuth0Signup(): Promise<void> {
        localStorage.removeItem(MagicLoginService.TOKEN_KEY);
        window.location.href = '/deals';
    }

    async afterAuthentication(): Promise<void> {
        this.setMagicToken();
        await super.afterAuthentication();
    }

    async isAuthenticated(): Promise<boolean> {
        if (this.user) {
            return true;
        }
        return false;
    }

    async isEmailVerified(): Promise<boolean> {
        return true;
    }

    async login(): Promise<void> {
        try {
            await this.afterAuthentication();
        } catch (e) {
            this.router.transitionTo('login');
        }
    }

    async logout(): Promise<void> {
        localStorage.removeItem(MagicLoginService.TOKEN_KEY);
        this.router.transitionTo('login');
    }

    get windowLocation(): string {
        return window.location.href;
    }

    setMagicToken() : void {
        const url = new URL(this.windowLocation);
        const token = url.searchParams.get('magic') || '';
        if (token) {
            localStorage.setItem(MagicLoginService.TOKEN_KEY, token);
        }
    }

}

declare module '@ember/service' {
    interface Registry {
        'magic-login-service': MagicLoginService;
    }
}
