import Controller from '@ember/controller';
import DwollaFundingSource from 'portal/models/dwolla-funding-source';
import RouterService from '@ember/routing/router-service';
import { IGNORED_PLAID_ERRORS } from 'portal/controllers/protected/plaid/oauth-landing';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealEarnestMoneyFundingSourceCreation extends Controller {
    @service
    declare router: RouterService;

    @action
    async onPlaidNext(fundingSource: DwollaFundingSource) {
        await fundingSource.save();
        await this.model.save();

        this.router.transitionTo('protected.deal.earnest-money.portal-transfer',fundingSource.id);
    }

    @action
    async onError(err: any) {
        if (!IGNORED_PLAID_ERRORS.includes(err?.error_code)) {
            throw `Plaid exception creating new funding source: ${JSON.stringify(err)}`;
        }
    }

}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'protected/deal/earnest-money/funding-source-creation': ProtectedDealEarnestMoneyFundingSourceCreation;
  }
}
