import Component from '@glimmer/component';

export interface EmporaSortHeaderArgs {
    label?: string;
    property?: string;
    sortProperty?: string;
    sortAsc?: string;
    onClick?: (value: any) => void;
}

export default class EmporaSortHeader extends Component<EmporaSortHeaderArgs> {
    get svgName() {
        if (this.args.property == this.args.sortProperty) {
            return this.args.sortAsc ? 'sort-down' : 'sort-up';
        } else {
            return 'sort-blank';
        }
    }

}
