import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Deal from '../deal';
import ReviewSettlementStatementActivity from 'portal/models/review-settlement-statement-activity';
import RouterService from '@ember/routing/router-service';
import { ActionItemTemplateKeys } from 'portal/models/action-item';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealSettlementStatementReview extends BaseRoute {
    @service
    declare router: RouterService;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal / Settlement Statement Review');
    }

    async model() {
        const deal = this.modelFor('protected.deal') as ModelFrom<Deal>;
        const actionItems = await deal.actionItems;
        const actionItem = actionItems?.find((item) => {
            return (
                item.templateKey === ActionItemTemplateKeys.REVIEW_SETTLEMENT_STATEMENT &&
                !item.hasCompleted
            );
        }) as ReviewSettlementStatementActivity | undefined;

        const settlementStatementReviewRequest =
            await actionItem?.settlementStatementReviewRequest;

        return {
            actionItem,
            settlementStatementReviewRequest
        };
    }

    afterModel(model: ModelFrom<ProtectedDealSettlementStatementReview>) {
        if (
            model.actionItem === undefined ||
            model.settlementStatementReviewRequest === undefined
        ) {
            this.router.replaceWith('protected.deal');
        }
    }
}
