import ActionItem, { ActionItemTemplateKeys } from 'portal/models/action-item';
import ArrayProxy from '@ember/array/proxy';
import CreateDealRequest, { CreateDealRequestType } from 'portal/models/create-deal-request';
import Deal from 'portal/models/deal';

export interface CreateDealRequestConfirmationContext {
    createDealRequest?: CreateDealRequest;
    deal?: Deal;
    actionItems?: ArrayProxy<ActionItem>;
}

export async function loadCreateDealRequestConfirmationContext(
    getCreateDealRequest: () => Promise<CreateDealRequest>,
    getActivitiesRequest: (id:  string) => Promise<ArrayProxy<ActionItem>>
):
    Promise<CreateDealRequestConfirmationContext | undefined> {
    const createDealRequest = await getCreateDealRequest();
    if (!createDealRequest) return {};

    let deal: Deal | undefined;
    try {
        deal = await createDealRequest.deal;
    } catch {
        return {
            createDealRequest
        };
    }

    let actionItems: ArrayProxy<ActionItem> | undefined;
    if (!!createDealRequest && !!deal && buyerOrSeller(createDealRequest)) {
        actionItems = await getActivitiesRequest(deal.id);
    }

    return {
        createDealRequest,
        deal: deal ? deal : undefined,
        actionItems: actionItems ? actionItems : undefined
    };
}

export function createDealRequestConfirmationContextLoaded(context: CreateDealRequestConfirmationContext): boolean {
    if (!context.createDealRequest) return false;
    if (!buyerOrSeller(context.createDealRequest)) return !!context.deal;

    const loaded = !!context.createDealRequest && !!context.deal && !!context.actionItems && context.actionItems.length as number > 0;
    if (!loaded) return false;

    if (context.createDealRequest.requestType === CreateDealRequestType.buyer &&
        context.actionItems?.map((a: ActionItem) => a.templateKey).includes(
            ActionItemTemplateKeys.BUYER_TITLEHOLDER_AND_FINANCING_INFORMATION
        )) {
        return true;
    }

    return !!(context.createDealRequest.requestType === CreateDealRequestType.seller &&
        context.actionItems?.map((a: ActionItem) => a.templateKey).includes(
            ActionItemTemplateKeys.SELLER_TITLEHOLDER_DETAILS
        )
    );
}

function buyerOrSeller(createDealRequest: CreateDealRequest) {
    const requestType = createDealRequest.get('requestType');
    return !!requestType && [CreateDealRequestType.buyer, CreateDealRequestType.seller].includes(requestType);
}
