import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import DealSide from 'portal/services/deal-side';
import EntityTitleholder from 'portal/models/entity-titleholder';
import IndividualTitleholder from 'portal/models/individual-titleholder';
import LegalEntity from 'portal/models/legal-entity';
import Store from '@ember-data/store';
import User from 'portal/models/user';
import { TitleholderType } from 'portal/models/titleholder';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface CreateTitleholderArgs {
    user: User;
    deal: Deal;
    type: TitleholderType | undefined;
    onTitleholderCreate: (titleholder: IndividualTitleholder | EntityTitleholder) => Promise<void>;
    onCancel: () => Promise<void>;
}

export default class CreateTitleholder extends Component<CreateTitleholderArgs> {
    @service
    declare store: Store;

    @service
    declare dealSide: DealSide;

    @tracked
    declare individualTitleholder: IndividualTitleholder | undefined;

    @tracked
    declare legalEntity: LegalEntity | undefined;

    @tracked
        showErrorOnPristine?: boolean = false;

    @tracked
        showErrorBanner?: boolean = false;

    @tracked
    declare radioTitleholderType: TitleholderType;

    constructor(owner: unknown, args: CreateTitleholderArgs) {
        super(owner, args);
        if (this.args.type) {
            this.createTitleholder();
        }
    }

    @action
    onTypeChange(type: TitleholderType) {
        this.radioTitleholderType = type;
    }

    get wireInstructionsRequired(): boolean {
        return false;
    }

    @action
    async createTitleholder(): Promise<void> {
        this.showErrorOnPristine = true;

        if (this.type == undefined) {
            this.showErrorBanner = true;
            return;
        }

        if (this.type == TitleholderType.individual) {
            let individualTitleholder;

            const deal = this.args.deal;
            const user = this.args.user;
            const dealSide = this.dealSide.for(deal.id);

            const titleholdersCount = (await deal.titleholdersForSide(dealSide)).length;
            const currentUserActor = await deal?.actors?.then((actors) => actors?.findBy('email', user.email));

            const powerOfAttorney = this.store.createRecord('power-of-attorney', {});

            if (!currentUserActor?.representative && titleholdersCount === 0) {
                individualTitleholder = this.store.createRecord(
                    'individualTitleholder',
                    {
                        deal,
                        dealSide,
                        firstName: user.givenName,
                        middleName: user.middleName,
                        lastName: user.familyName,
                        suffix: user.suffix,
                        email: user.email,
                        phoneNumber: user.phoneNumber,
                        birthday: user.birthday,
                        taxId: user.ssn,
                        isMarried: user.isMarried,
                        street: user.street,
                        street2: user.street2,
                        city: user.city,
                        state: user.state,
                        zipcode: user.zipcode,
                        inviteToDeal: false,
                        addToDealAsTitleholder: true,
                        powerOfAttorney
                    }
                );

                if (individualTitleholder.isMarried) {
                    individualTitleholder.spouseFirstName = user.spouseFirstName;
                    individualTitleholder.spouseMiddleName = user.spouseMiddleName;
                    individualTitleholder.spouseLastName = user.spouseLastName;
                    individualTitleholder.spouseSuffix = user.spouseSuffix;
                    individualTitleholder.spouseTaxId = user.spouseSsn;
                }
            }
            else {
                individualTitleholder = this.store.createRecord('individualTitleholder', { deal , dealSide, powerOfAttorney });
            }

            this.individualTitleholder = individualTitleholder;
        } else {
            this.legalEntity = this.store.createRecord('legalEntity', {
                wireInstruction: this.store.createRecord('legalEntityWireInstruction')
            });
        }
    }

    get type(): TitleholderType | undefined {
        return this.args.type || this.radioTitleholderType;
    }

    @action
    async onNext() {
        if (this.individualTitleholder) {
            this.args.onTitleholderCreate(this.individualTitleholder);
            this.individualTitleholder = undefined;
        } else if (this.legalEntity) {
            const deal = this.args.deal;

            const entityTitleholder = this.store.createRecord('entityTitleholder', {
                entityName: this.legalEntity.entityName,
                taxId: this.legalEntity.taxId,
                email: this.legalEntity.email,
                phoneNumber: this.legalEntity.phoneNumber,
                street: this.legalEntity.street,
                street2: this.legalEntity.street2,
                city: this.legalEntity.city,
                state: this.legalEntity.state,
                zipcode: this.legalEntity.zipcode,
                dealSide: this.dealSide.for(deal.id),
                deal: deal,
                existingLegalEntity: this.legalEntity
            });

            if (this.legalEntity.designatedSigners) {
                for (const legalEntityDesignatedSigner of this.legalEntity.designatedSigners.toArray()) {
                    const newEntityTitleholderDesignatedSigner = this.store.createRecord('entity-titleholder-designated-signer', {
                        email: legalEntityDesignatedSigner?.email,
                        firstName: legalEntityDesignatedSigner?.firstName,
                        middleName: legalEntityDesignatedSigner?.middleName,
                        lastName: legalEntityDesignatedSigner?.lastName,
                        legalEntityDesignatedSignerId: legalEntityDesignatedSigner?.id,
                        phoneNumber: legalEntityDesignatedSigner?.phoneNumber,
                        title: legalEntityDesignatedSigner?.title
                    });
                    entityTitleholder.designatedSigners?.pushObject(newEntityTitleholderDesignatedSigner);
                }
            }

            this.args.onTitleholderCreate(entityTitleholder);
        }
    }

    @action
    async onCancel() {
        if (this.legalEntity) {
            this.legalEntity.unloadRecord();
        }
        if (this.individualTitleholder) {
            this.individualTitleholder.unloadRecord();
        }
        this.args.onCancel();
    }
}
