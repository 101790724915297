import ApplicationInstance from '@ember/application/instance';

export function initialize(appInstance: ApplicationInstance): void {
    // rootElement is private but still accessible
    const rootElement: HTMLElement = document.querySelector(
        (appInstance as unknown as any).rootElement
    );

    const emporaModalContainer = document.createElement('div');
    emporaModalContainer.id = 'empora-modal__container';
    rootElement.appendChild(emporaModalContainer);
}

export default {
    initialize
};
