import Component from '@glimmer/component';
import { DateTime, Info } from 'luxon';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface DatePickerArgs {
    selectedDate?: DateTime;
    dateOnly: boolean;
    disableWeekends: boolean;
    rangeStart: DateTime;
    rangeEnd: DateTime;
    onDateSelected(date: DateTime): void;
}

export default class DatePicker extends Component<DatePickerArgs> {
    constructor(owner: unknown, args: DatePickerArgs) {
        super(owner, args);
        if (this.args.selectedDate) {
            this.displayDate = this.args.selectedDate;
            this.selectedDate = this.args.selectedDate;
        }
    }

    get weekdays(): string[] {
        const weekdays = [...Info.weekdaysFormat('short')];
        weekdays.unshift(weekdays.pop() as string);
        return weekdays;
    }

    get showTimeZone() {
        return false;
    }

    get disableWeekends() {
        return this.args.disableWeekends || false;
    }

    @tracked
        currentDate: DateTime = DateTime.local();

    get currentYear(): number {
        return this.currentDate.year;
    }

    get currentDayNumber(): number {
        return this.currentDate.day;
    }

    get currentMonthNumber(): number {
        return this.currentDate.month;
    }

    get currentMonth(): string {
        return this.currentDate.monthLong;
    }

    @tracked
        displayDate: DateTime = this.args.rangeStart?.startOf('day') || this.currentDate.startOf('day');

    get displayMonthStart(): DateTime {
        return this.displayDate.startOf('month');
    }

    get displayMonthEnd(): DateTime {
        return this.displayDate.endOf('month');
    }

    get displayMonthStartOffset(): number {
        return this.weekdays.indexOf(this.displayMonthStart.weekdayShort);
    }

    // So we can always fill 6 rows of the calendar
    get displayMonthEndOffset(): number {
        //   (7 Days a weeks * 6 rows)
        // - (Offset cells at start of month + the days in month)
        // = number of empty cells at end of month
        return 7 * 6 - (this.displayMonthStartOffset + this.displayDaysInMonth);
    }

    get displayMonthNumber(): number {
        return this.displayDate.month;
    }

    get displayMonth(): string {
        return this.displayDate.monthLong;
    }

    get displayDaysInMonth(): number {
        return this.displayDate.daysInMonth;
    }

    get displayYear(): number {
        return this.displayDate.year;
    }

    get displayTimeZone(): string {
        return this.displayDate.zoneName;
    }

    @tracked
        selectedDate: DateTime | null = null;

    get selectedDay(): string {
        return this.selectedDate ? `${this.selectedDate.day}` : '';
    }

    get selectedDayNumber(): number | null {
        return this.selectedDate ? this.selectedDate.day : null;
    }

    get selectedWeekday(): string {
        return this.selectedDate ? this.selectedDate.weekdayShort : '';
    }

    get selectedMonth(): string {
        return this.selectedDate ? this.selectedDate.monthShort : '';
    }

    get selectedMonthNumber(): number | null {
        return this.selectedDate ? this.selectedDate.month : null;
    }

    get selectedYear(): number | null {
        return this.selectedDate ? this.selectedDate.year : null;
    }

    get selectedHour(): number | null {
        return this.selectedDate ? this.selectedDate.hour : null;
    }

    get decrementMonthDisabled(): boolean {
        if (this.displayYear > this.currentYear) {
            return false;
        } else if (this.displayMonthNumber > this.currentMonthNumber) {
            return false;
        } else {
            return true;
        }
    }

    @action
    changeMonth(dir = 1) {
        this.displayDate = this.displayDate.plus({
            month: dir
        });
    }

    @action
    selectDate(day: number) {
        this.selectedDate = this.displayDate.set({ day });
        if (this.args.dateOnly && this.args.onDateSelected) {
            this.args.onDateSelected(this.selectedDate);
        }
    }

    @action
    selectHour(hour: number) {
        if (this.selectedDate) {
            this.selectedDate = this.selectedDate.set({ hour, minute: 0 });
            if (this.args.onDateSelected) {
                this.args.onDateSelected(this.selectedDate);
            }
        }
    }
}
