import Model, { attr } from '@ember-data/model';

export enum OnboardingQuestionRuleId {
    customer_requested_mls_survey = 'customer_requested_mls_survey',
}
export default class OnboardingQuestionRule extends Model {
    @attr('boolean')
        applies?: boolean;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'onboarding-question-rules': OnboardingQuestionRule;
    }
}
