import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Deal from 'portal/models/deal';
import DwollaTransfer from 'portal/models/dwolla-transfer';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealEarnestMoneyPortalTransfer extends BaseRoute {
    @service
    declare analytics: Analytics;

    @service
    declare store: Store;

    async model({ dwolla_funding_source_id }: { dwolla_funding_source_id: string }): Promise<DwollaTransfer> {
        const deal = await this.modelFor('protected.deal') as Deal;
        const earnestMoneyDetail = await deal.earnestMoneyDetail;
        const property = await deal.property;
        const address = await property?.address;
        const dwollaTransfer = this.store.createRecord('dwolla-transfer', {
            deal: deal,
            amount: earnestMoneyDetail?.earnestMoneyAmount,
            note: deal.name || address?.street,
            dwollaFundingSource: await this.store.findRecord('dwolla-funding-source', dwolla_funding_source_id)
        });

        return dwollaTransfer;
    }

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Earnest Money Portal Transfer');
    }

    @action
    error(): boolean {
        this.analytics.trackEvent('Error on Earnest Money Portal Transfer');
        return true;
    }
}
