import ApplicationSerializer from './application';

export default class IntakePurchaseContractSerializer extends ApplicationSerializer {
    attrs = {
        intakeUser: { embedded: 'always' }
    };
}

declare module 'ember-data/types/registries/serializer' {
    export default interface SerializerRegistry {
        intakePurchaseContract: IntakePurchaseContractSerializer;
    }
}
