import Controller from '@ember/controller';
import Route from 'portal/routes/protected/deal/titleholder-information/confirm-entity-details';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealTitleholderInformationConfirmEntityDetails extends Controller {
    declare model: ModelFrom<Route>;

    @service
    declare router: RouterService;

    @action
    onNext() {
        this.router.transitionTo('protected.deal.titleholder-information.complete');
    }

    @action
    onEdit() {
        if (this.model.legalEntity) {
            this.router.transitionTo('protected.deal.titleholder-information.edit-entity', this.model.legalEntity.id, {
                queryParams: {
                    confirming: true
                }
            });
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/deal/titleholder-information/confirm-entity-details': ProtectedDealTitleholderInformationConfirmEntityDetails;
    }
}
