import Component from '@glimmer/component';
import { BufferedChangeset } from 'ember-changeset/types';
import { action } from '@ember/object';

interface PowerOfAttorneyArgs {
    disabled: boolean;
    powerOfAttorneyChangeset: BufferedChangeset;
    titleholderChangeset: BufferedChangeset;
}

export default class PowerOfAttorney extends Component<PowerOfAttorneyArgs> {
    @action
    onHasPowerOfAttorneySelected(value: boolean) {
        this.args.titleholderChangeset.set('hasPowerOfAttorney', value);

        if (!value) {
            ['firstName', 'lastName', 'phoneNumber', 'email'].forEach((field) => this.args.powerOfAttorneyChangeset.set(field, null));
        }
    }
}
