import Component from '@glimmer/component';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';

interface EmporaButtonDropdownArgs {
    label: string;
    image: string;
    dropdownClass: string;
}

export default class EmporaButtonDropdown extends Component<EmporaButtonDropdownArgs> {
    @tracked
        dropdownVisible = false;
    uniqueId = `${this.args.label
        .replace(/\W/g, '-')
        .toLowerCase()}-${guidFor(this)}`;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    windowClickListener: (this: Window, e: MouseEvent) => any = () => {};

    @action
    toggle() {
        this.dropdownVisible = !this.dropdownVisible;
    }

    @action
    hide() {
        this.dropdownVisible = false;
    }

    get imageUniqueId() {
        return `image-${this.uniqueId}`;
    }

    @action
    onDidInsert() {
        this.windowClickListener = (e: MouseEvent) => {
            if (
                e.target &&
                !(e.target as HTMLElement).matches(`#${this.uniqueId}`) &&
                !(e.target as HTMLElement).matches(`#${this.imageUniqueId}`) &&
                !(e.target as HTMLElement).parentElement?.matches(
                    `#${this.uniqueId}`
                ) &&
                !(e.target as HTMLElement).parentElement?.matches(
                    `#${this.imageUniqueId}`
                )
            ) {
                this.hide();
            }
        };

        window.addEventListener('click', this.windowClickListener);
    }

    @action
    onWillDestroy() {
        window.removeEventListener('click', this.windowClickListener);
    }
}
