import Analytics from 'portal/services/analytics';
import Component from '@glimmer/component';
import DealMessage from 'portal/models/deal-message';
import RouterService from '@ember/routing/router-service';
import SettlementStatementReviewActivity from 'portal/models/review-settlement-statement-activity';
import SettlementStatementReviewRequest, {
    validations
} from 'portal/models/settlement-statement-review-request';
import Store from '@ember-data/store';
import { BufferedChangeset } from 'ember-changeset/types';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

interface DealSettlementStatementReviewArgs {
    actionItem: SettlementStatementReviewActivity;
    settlementStatementReviewRequest: SettlementStatementReviewRequest;
}

export default class DealSettlementStatementReview extends Component<DealSettlementStatementReviewArgs> {
    @service
    declare analytics: Analytics;

    @service
    declare store: Store;

    @service
    declare router: RouterService;

    @tracked
        hasDownloadedDocument = false;

    @tracked
        message: DealMessage;

    constructor(owner: unknown, args: DealSettlementStatementReviewArgs) {
        super(owner, args);
        this.message = this.store.createRecord('deal-message', {
            deal: args.actionItem.deal
        });
    }

    get validations() {
        return validations;
    }

    @action
    async validate(changeset: BufferedChangeset) {
        await changeset.validate();
    }

    @action
    async onChangeApproved(changeset: BufferedChangeset, value: boolean) {
        changeset.approved = value;
        if (value) {
            changeset.message = null;
        } else {
            changeset.message = this.message;
        }

        await changeset.validate();
    }

    @action
    onDownload() {
        this.hasDownloadedDocument = true;

        this.analytics.trackEvent('Settlement Statement Review Document Downloaded');
    }

    @task
    async submitRequest(changeset: BufferedChangeset) {
        if (changeset.approved) {
            await this.message.destroy();
        } else {
            await this.message.save();
        }

        await changeset.save();

        this.args.actionItem.hasCompleted = true;
        await this.args.actionItem.save();

        this.router.transitionTo('protected.deal.action-item-completion', this.args.actionItem.id);
    }
}
