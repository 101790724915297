import Component from '@glimmer/component';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';

export interface EmporaSearchOption {
    text: string;
    [key: string]: any;
}

export interface EmporaSearchArgs {
    placeholder: string;
    onSearch: (text: string) => void;
    dropdownOptions: EmporaSearchOption[]
    onOptionSelected: (result: EmporaSearchOption) => void;
    isFetchingResults: boolean;
    minCharacterLengthTrigger: number;
}

export default class EmporaSearch extends Component<EmporaSearchArgs> {
    @tracked
        inputText = '';

    @tracked
        isDropdownOpen = false;

    private optionSelected = false;

    private previousInputText = '';

    get minCharacterLengthTrigger() {
        return this.args.minCharacterLengthTrigger || 1;
    }

    get uniqueId() {
        return `${guidFor(this)}`;
    }

    get dropdownOptions() {
        return this.args.dropdownOptions || [];
    }

    @action
    onInputChange(text: string) {
        this.inputText = text.replace(/\s+/g, ' ').trimStart();

        if (this.previousInputText !== this.inputText) {
            this.previousInputText = this.inputText;
            this.optionSelected = false;

            if (this.isSearchTriggered) {
                this.args.onSearch(this.inputText);
            }
        }
    }

    get isSearchTriggered() {
        return this.inputText.length >= this.minCharacterLengthTrigger;
    }

    get displaySearchingMessage() {
        return this.isSearchTriggered && this.args.isFetchingResults;
    }

    get displayNoResultsMessage() {
        return this.isSearchTriggered && !this.args.isFetchingResults && this.dropdownOptions.length === 0;
    }

    get displayResultList() {
        return this.isSearchTriggered && !this.args.isFetchingResults && this.dropdownOptions.length > 0;
    }

    @action
    calculateDropdownState() {
        const hasContentToDisplay = this.displaySearchingMessage || this.displayNoResultsMessage || this.displayResultList;

        this.isDropdownOpen = !this.optionSelected && hasContentToDisplay;
    }

    @action
    closeDropdown() {
        this.isDropdownOpen = false;
    }

    @action
    onOptionSelected(option: EmporaSearchOption) {
        this.closeDropdown();
        this.inputText = option.text;
        this.optionSelected = true;

        this.args.onOptionSelected(option);
    }
}
