import Model, { attr } from '@ember-data/model';

export default class FeatureFlag extends Model {
    @attr('string')
        name?: string;

    @attr('string')
        description?: string;

    @attr('boolean')
        ab_test?: boolean;

    @attr('boolean')
        enabled?: boolean;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'feature-flag': FeatureFlag;
    }
}
