import Model, { attr } from '@ember-data/model';
import fullName from 'portal/utils/full-name';
import validateEmail from 'portal/validators/email';
import validatePhoneNumber from 'portal/validators/phone-number';
import { validatePresence } from 'ember-changeset-validations/validators';

export default class EntityTitleholderDesignatedSigner extends Model {
    @attr('string')
        email?: string;

    @attr('string')
        entityTitleholderId?: string;

    @attr('string')
        firstName?: string;

    @attr('string')
        middleName?: string;

    @attr('string')
        lastName?: string;

    @attr('string')
        legalEntityDesignatedSignerId?: string;

    @attr('string')
        phoneNumber?: string;

    @attr('string')
        title?: string;

    @attr('date')
        verifiedAt?: Date;

    @attr('string')
        verifiedByAuthAdminProfileId?: string;

    @attr('boolean')
        hasSigning?: boolean;

    get fullName(): string {
        return fullName(this.firstName, this.middleName, this.lastName);
    }

    get isVerified(): boolean {
        return !!this.verifiedByAuthAdminProfileId;
    }
}

export const entityTitleholderDesignatedSignerValidations = {
    entityTitleholderId: validatePresence({ presence: true }),
    legalEntityDesignatedSignerId: validatePresence({ presence: true }),
    email: validateEmail('Email address', { allowBlank: false }),
    firstName: validatePresence({ presence: true, ignoreBlank: true }),
    lastName: validatePresence({ presence: true, ignoreBlank: true }),
    phoneNumber: validatePhoneNumber('Phone number', { allowBlank: false }),
    title: validatePresence({ presence: true, ignoreBlank: true })
};
