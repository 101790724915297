import BaseRoute from 'portal/routes/base-route';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';

export default class TeamInvites extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare router: RouterService;

    queryParams = { accepted: { refreshModel: true } };

    async model({ team_invite_id, accepted: accepted }: { team_invite_id: string, accepted: string; }) {
        const teamInvite = await this.store.findRecord('team-invite', team_invite_id);

        if (teamInvite.accepted === true || teamInvite.accepted === false) {
            return teamInvite;
        }

        if (accepted === 'true') {
            teamInvite.accepted = true;
        } else if (accepted === 'false') {
            teamInvite.accepted = false;
        }

        return teamInvite;
    }

    async redirect() {
        this.router.replaceWith('protected.deals');
    }
}
