import DealMessage from './deal-message';
import DocumentModel from './document';
import Model, { AsyncBelongsTo, belongsTo } from '@ember-data/model';

export default class MessageDocument extends Model {
    @belongsTo('deal-message')
        message?: AsyncBelongsTo<DealMessage>;
    @belongsTo('document')
        document?: AsyncBelongsTo<DocumentModel>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'message-document': MessageDocument;
    }
}
