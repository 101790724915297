import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import Deal, { DealStatuses } from 'portal/models/deal';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedComplete extends BaseRoute {
    @service
    declare analytics: Analytics;

    @service
    declare auth: AuthService;

    @service
    declare store: Store;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Completed Deals');
    }

    async model() {
        const currentUser = this.modelFor('protected.deals').user;
        const parentCompleteDeals = await this.modelFor('protected.deals').completeDeals;
        const completedDeals: Deal[] = parentCompleteDeals.filterBy('status', DealStatuses.COMPLETE) as Deal[];

        return {
            currentUser,
            deals: completedDeals
        };
    }
}
