import Model, {
    AsyncBelongsTo,
    attr,
    belongsTo
} from '@ember-data/model';
import Team from './team';
import validateEmail from 'portal/validators/email';
import validatePhoneNumber from 'portal/validators/phone-number';
import { validateInclusion, validatePresence } from 'ember-changeset-validations/validators';

export enum Role {
    user = 'user',
    admin = 'admin',
}

export default class TeamMember extends Model {
    @attr('string')
        role?: Role;

    @attr('string')
        firstName?: string;

    @attr('string')
        lastName?: string;

    @attr('string')
        email?: string;

    @attr('string')
        phoneNumber?: string;

    @attr('boolean')
        pending?: boolean;

    @attr('boolean')
        currentUser?: boolean;

    @belongsTo('team')
        team?: AsyncBelongsTo<Team>;

    get displayName(): string | undefined {
        if (this.firstName && this.lastName) {
            return (this.firstName + ' ' + this.lastName);
        } else {
            return this.email;
        }
    }
}

export const teamMemberValidations = {
    firstName: validatePresence({ presence: true, ignoreBlank: true }),
    lastName: validatePresence({ presence: true, ignoreBlank: true }),
    email: [
        validatePresence({ presence: true }),
        validateEmail('Email address')
    ],
    phoneNumber: validatePhoneNumber('Phone number', { allowBlank: true }),
    role: validateInclusion({ list: Object.values(Role), allowBlank: false })
};

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        teamMember: TeamMember;
    }
}
