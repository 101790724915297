import Deal from 'portal/models/deal';
import DocumentModel from 'portal/models/document';
import DwollaFundingSource from 'portal/models/dwolla-funding-source';
import DwollaTransfer from 'portal/models/dwolla-transfer';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

export enum EarnestMoneyMethod {
    portal = 'portal',
    wire = 'wire',
    check = 'check',
    other = 'other'
}

export default class EarnestMoneyDetail extends Model {
    @attr('boolean', { allowNull: true })
        realtorHasCheck?: boolean;

    @attr('string')
        earnestMoneyMethod?: EarnestMoneyMethod;

    @attr('number')
        earnestMoneyAmount?: number;

    @attr('string')
        memo?: string;

    @attr('number')
        maxCheckAmount!: number;

    @belongsTo('deal')
        deal!: AsyncBelongsTo<Deal>;

    @belongsTo('document')
        instructionsDocument?: AsyncBelongsTo<DocumentModel>;

    @belongsTo('dwolla-transfer')
        dwollaTransfer?: AsyncBelongsTo<DwollaTransfer>;

    @belongsTo('dwolla-funding-source')
        dwollaFundingSource?: AsyncBelongsTo<DwollaFundingSource>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'earnest-money-detail': EarnestMoneyDetail;
  }
}
