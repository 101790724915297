import AuthBaseService from 'portal/services/auth-base';
import ENV from 'portal/config/environment';
import EmporaServer from 'portal/services/empora-server';
import Logger from 'portal/services/logger';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';

export default class Auth0Service extends AuthBaseService {
    @service
        emporaServer!: EmporaServer;

    @service
        logger!: Logger;

    private auth0?: Auth0Client;

    private async getClient(): Promise<Auth0Client> {
        if (isEmpty(this.auth0)) {
            const authResponse = await this.emporaServer.fetchData(
                '/profile/authorizer'
            );

            const data = await authResponse.json();
            const domain = new URL(data.authorization_server);
            this.auth0 = new Auth0Client({
                domain: domain.host as string,
                client_id: data.client_id as string,
                audience: data.audience as string
            });
        }
        return this.auth0!;
    }

    async getToken(): Promise<any> {
        const auth0 = await this.getClient();
        return auth0.getTokenSilently();
    }

    async afterAuthentication(): Promise<void> {
        const auth0 = await this.getClient();
        const results = await auth0!.handleRedirectCallback();

        const { lastAttemptedTransition, lastReferrer } = results.appState;
        if (!isEmpty(lastAttemptedTransition)) {
            this.lastAttemptedTransition = lastAttemptedTransition;
        }

        if (!isEmpty(lastReferrer)) {
            this.lastReferrer = lastReferrer;
        }

        await super.afterAuthentication();

        this.logger.log(`Bearer ${await auth0!.getTokenSilently()}`);
        this.logger.log(this.user);
    }

    async isAuthenticated(): Promise<boolean> {
        const auth0 = await this.getClient();
        return await auth0.isAuthenticated();
    }

    async isEmailVerified(): Promise<boolean> {
        const result = await this.emporaServer.fetchData('/profile/email_verified');
        if (result.ok) {
            const resultJson = await result.json();
            return resultJson['email_verified'];
        } else {
            throw await result.json();
        }
    }

    async login(params?: any): Promise<void> {
        const auth0 = await this.getClient();

        await auth0.loginWithRedirect({
            redirect_uri: `${location.origin}/callback`,
            appState: {
                lastAttemptedTransition: this.lastAttemptedTransition,
                lastReferrer: this.lastReferrer
            },
            ...params
        });
    }

    async logout(): Promise<void> {
        const auth0 = await this.getClient();
        auth0.logout({
            returnTo: `${ENV.REACT_APP_URL}/api/auth/logout`
        });
    }
}

declare module '@ember/service' {
    interface Registry {
        'auth0-service': Auth0Service;
    }
}
