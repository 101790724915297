import Address from 'portal/models/address';
import Component from '@glimmer/component';
import Property from 'portal/models/property';
import Titleholder from 'portal/models/titleholder';
import { BufferedChangeset } from 'ember-changeset/types';
import { TaxAddressType } from 'portal/models/tax-address';
import { action } from '@ember/object';
import { street } from 'portal/helpers/company-info';
import { tracked } from '@glimmer/tracking';

interface BuyerClosingPreferencesAndCoveragesArgs {
    cpcAvailable: boolean;
    dealChangeset: BufferedChangeset;
    disabled?: boolean;
    hasCreditLines: boolean;
    inPersonNotary?: boolean;
    onSubmit: () => void;
    property: Property;
    propertyAddress: Address,
    taxAddressChangeset: BufferedChangeset;
    titleholder: Titleholder;
}

export default class BuyerClosingPreferencesAndCoverages extends Component<BuyerClosingPreferencesAndCoveragesArgs> {
    @tracked
        showErrorBanner?: boolean = false;

    @tracked
        taxAddressSelected?: boolean = false;

    @tracked
        taxAddressOtherOptionSelected?: boolean = false;

    get disabledSubmit(): boolean {
        return !!this.args.disabled;
    }

    get inPersonCopy(): string {
        return `Meet in-person at our office located at ${street()}.`;
    }

    @action
    validate(): void {
        this.args.dealChangeset.set('showErrorOnPristine', false);
        this.args.taxAddressChangeset.set('showErrorOnPristine', false);
        this.args.dealChangeset.validate();
        this.args.taxAddressChangeset.validate();
    }

    @action
    async onTaxAddressSelection(option: string) {
        this.taxAddressSelected = true;
        this.args.taxAddressChangeset.rollback();
        this.args.taxAddressChangeset.set('taxAddressType', option);

        switch (option){
        case TaxAddressType.lender:
            this.taxAddressOtherOptionSelected = false;
            break;
        case TaxAddressType.forward:
            this.taxAddressOtherOptionSelected = false;
            this.setTaxAddressChangeset(this.args.titleholder);
            break;
        case TaxAddressType.property:
            this.taxAddressOtherOptionSelected = false;
            this.setTaxAddressChangeset(this.args.propertyAddress);
            break;
        case TaxAddressType.other:
            this.taxAddressOtherOptionSelected = true;
            break;
        }
    }

    @action
    setTaxAddressChangeset(address: any) {
        this.args.taxAddressChangeset.set('street', address.street);
        this.args.taxAddressChangeset.set('street2', address.street2);
        this.args.taxAddressChangeset.set('city', address.city);
        this.args.taxAddressChangeset.set('state', address.state);
        this.args.taxAddressChangeset.set('zipcode', address.zipcode);
    }

    @action
    async onSubmit() {
        this.args.dealChangeset.set('showErrorOnPristine', true);
        this.args.taxAddressChangeset.set('showErrorOnPristine', true);

        if (this.args.dealChangeset?.isInvalid || this.args.taxAddressChangeset?.isInvalid) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        await this.args.dealChangeset.save();
        this.args.taxAddressChangeset.set('deal', this.args.dealChangeset.data);
        await this.args.taxAddressChangeset.save();
        this.args.onSubmit();
    }
}
