export function street(){
    return '145 E Rich Street';
}

export function street2(){
    return 'Floor 4';
}

export function city(){
    return 'Columbus';
}

export function state(){
    return 'OH';
}

export function zipcode(){
    return '43215';
}

export function oneLineAddress(){
    return `${street()}, ${city()}, ${state()} ${zipcode()}`;
}