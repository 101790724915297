import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Deal from 'portal/models/deal';
import EarnestMoneyDetail from 'portal/models/earnest-money-detail';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealEarnestMoneyWire extends BaseRoute {
    @service
    declare analytics: Analytics;

    async model(): Promise<EarnestMoneyDetail | undefined> {
        const deal = this.modelFor('protected.deal') as Deal;
        return deal.earnestMoneyDetail;
    }

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Earnest Money Wire');
    }
}
