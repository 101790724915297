import ActionItem, { ActionItemDescriptions } from './action-item';
import PayoffAuthorization from './payoff-authorization';
import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

export default class SellerAuthorizationActivity extends ActionItem {
    @attr('string', { defaultValue: ActionItemDescriptions.SELLER_AUTHORIZATION })
        name?: ActionItemDescriptions.SELLER_AUTHORIZATION;

    @attr('string', { defaultValue: ActionItemDescriptions.SELLER_AUTHORIZATION })
        description?: string;

        @belongsTo('payoff-authorization')
            payoffAuthorization?: AsyncBelongsTo<PayoffAuthorization>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'seller-authorization-activity': SellerAuthorizationActivity;
    }
}
