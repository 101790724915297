import Address from 'portal/models/address';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

export default class Contact extends Model {
    @attr('string')
        entityName!: string;

    @attr('string')
        email!: string;

    @attr('string')
        phoneNumber!: string;

    @belongsTo('address')
        address!: AsyncBelongsTo<Address>;
}

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        contact: Contact;
    }
}
