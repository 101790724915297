import Controller from '@ember/controller';
import ProtectedAccountCreationFlowMyTeamRoute from 'portal/routes/protected/account-creation-flow/my-team';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedAccountCreationFlowMyTeam extends Controller {
    declare model: ModelFrom<ProtectedAccountCreationFlowMyTeamRoute>;

    @service
    declare router: RouterService;

    @action
    async onAfterSave( ) {
        this.router.transitionTo('protected.account-creation-flow.team-overview');
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/account-creation-flow/my-team': ProtectedAccountCreationFlowMyTeam;
    }
}
