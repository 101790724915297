import Component from '@glimmer/component';
import DocumentModel from 'portal/models/document';
import Store from '@ember-data/store';
import window from 'ember-window-mock';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

interface DocumentRowLegacyArgs {
  document: DocumentModel;
  onDelete?: (document: DocumentModel) => void;
  onDownload: (document: DocumentModel) => void; // Required so that parents are encouraged to log an analytics event
}

export default class DocumentRowLegacy extends Component<DocumentRowLegacyArgs> {
    @service
    declare store: Store;

    @tracked
        isModalOpen = false;

    @action
    toggleConfirmationModal() {
        this.isModalOpen = !this.isModalOpen;
    }

    @task
    async downloadDocument(): Promise<void> {
        const documentDownloadRequest = this.store.createRecord('document-download-request',{
            document: this.args.document
        });

        // This needs to be instantiated before async behavior in order to not be blocked by Safari as a pop-up.
        // See https://stackoverflow.com/a/39387533
        const windowOpenReference = window.open();

        await documentDownloadRequest.save();

        const { url } = documentDownloadRequest;

        if (url && windowOpenReference) {
            windowOpenReference.location = url;
        }

        this.args.onDownload(this.args.document);
    }

    @task
    async deleteDocument(): Promise<void> {
        if (this.args.document.canDelete) {
            await this.args.document.destroyRecord();
            if (this.args.onDelete) {
                this.args.onDelete(this.args.document);
            }
            this.toggleConfirmationModal();
        }
    }
}
