import Analytics from 'portal/services/analytics';
import Controller from '@ember/controller';
import Deal from 'portal/models/deal';
import RouterService from '@ember/routing/router-service';
import { CreateDealRequestType } from 'portal/models/create-deal-request';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedIntakeDealRequestConfirmationRoute } from 'portal/routes/protected/intake/deal-request-confirmation';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class ProtectedIntakeDealRequestConfirmation extends Controller {
    declare model: ModelFrom<ProtectedIntakeDealRequestConfirmationRoute>;

    @service
    declare analytics: Analytics;

    @service
    declare router: RouterService;

    @task
    async viewDeal(deal?: Deal) {
        this.analytics.trackEvent('Intake / Deal Request Confirmation / View Deal Clicked');
        if (!!deal && deal?.get('id')) {
            this.router.transitionTo('protected.deal', deal.get('id') as any, { queryParams: { invite:true } });
        } else {
            const router = this.router;
            later((function() {
                router.transitionTo('protected.deals');
            }), 2000);
        }
    }

    @task
    async startOnboarding(deal: Deal) {
        if (this.isBuyer) {
            this.router.transitionTo('protected.deal.buyer-titleholder-and-financing-information', deal);
        } else if (this.isSeller) {
            this.router.transitionTo('protected.deal.onboarding.seller-titleholder-info', deal);
        } else {
            this.router.transitionTo('protected.deal.onboarding', deal);
        }
    }

    private get isBuyer() {
        return this.model?.createDealRequest?.requestType === CreateDealRequestType.buyer;
    }

    private get isSeller() {
        return this.model?.createDealRequest?.requestType === CreateDealRequestType.seller;
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/intake/deal-request-confirmation': ProtectedIntakeDealRequestConfirmation;
    }
}
