import Component from '@glimmer/component';
import Deal, { DealActorRoles, DealStatuses } from 'portal/models/deal';
import FeatureFlag from 'portal/services/feature-flag';
import SigningDetail from 'portal/models/signing-detail';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface DealSigningAppointmentsArgs {
    deal: Deal;
}

export default class DealSigningAppointmentsDate extends Component<DealSigningAppointmentsArgs> {
    @service
    declare store: Store;

    @service
    declare featureFlag: FeatureFlag;

    @tracked
    declare sellerAppointment: SigningDetail;

    @tracked
    declare buyerAppointment: SigningDetail;

    @tracked
    declare signingAppointment: SigningDetail;

    constructor(owner: unknown, args: DealSigningAppointmentsArgs) {
        super(owner, args);
        if (this.shouldRender) {
            this.fetchSigningAppointments();
        }
    }

    @action
    async fetchSigningAppointments() {
        const signingDetails = await this.store.query('signing-detail', { deal_id: this.args.deal.id });

        if (this.args.deal.actorRole === DealActorRoles.ASSIGNOR) {
            this.sellerAppointment = signingDetails.find((signingDetail) => {
                return signingDetail.sellerSigning;
            });
            this.buyerAppointment = signingDetails.find((signingDetail) => {
                return signingDetail.buyerSigning;
            });
        } else {
            this.signingAppointment = signingDetails.firstObject;
        }
    }

    get shouldRender(): boolean {
        return this.args.deal.status === DealStatuses.IN_PROGRESS;
    }

    get buyerSigningLocation(): string {
        return this.buyerAppointment.displaySigningLocation;
    }

    get sellerSigningLocation(): string {
        return this.sellerAppointment.displaySigningLocation;
    }

    get signingLocation(): string {
        return this.signingAppointment.displaySigningLocation;
    }
}
