/* eslint-disable ember/use-ember-data-rfc-395-imports */
import DS from 'ember-data';
import JSONAPISerializer from '@ember-data/serializer/json-api';
import ModelRegistry from 'ember-data/types/registries/model';
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import { dasherize } from '@ember/string';

export default class Application extends JSONAPISerializer.extend(EmbeddedRecordsMixin) {
    isEmbeddedRecordsMixinCompatible = true;

    serializeBelongsTo(snapshot: DS.Snapshot<keyof ModelRegistry>, json: Record<string, any>, relationship: Record<string, any>) {
        super.serializeBelongsTo(snapshot, json, relationship);
        const key = dasherize(relationship.key);

        json.relationships = json.relationships ?? {};

        if (json[key]) {
            if (json[key]['data']) {
                json.relationships[key] = json[key];
                json.relationships[key]['data']['id'] = snapshot.belongsTo(relationship.key)?.id ?? '';
                json.relationships[key]['data']['type'] = relationship.type;
            } else {
                json.relationships[key] = {
                    data: {
                        id: snapshot.belongsTo(relationship.key)?.id ?? '',
                        type: relationship.type
                    }
                };
            }
        }

        delete json[key];
    }

    serializeHasMany(snapshot: DS.Snapshot<keyof ModelRegistry>, json: Record<string, any>, relationship: Record<string, any>) {
        super.serializeHasMany(snapshot, json, relationship);
        const key = dasherize(relationship.key);

        json.relationships = json.relationships ?? {};

        if (json[key]) {
            json.relationships[key] = json.relationships[key] ?? { data: [] };

            json[key].forEach((hash: Record<string, any>, index: number) => {
                json.relationships[key].data.push({
                    ...hash.data,
                    id: snapshot.hasMany(relationship.key)![index].id ?? '',
                    type: relationship.type
                });
            });
        }

        delete json[key];
    }
}

declare module 'ember-data/types/registries/serializer' {
    export default interface SerializerRegistry {
        application: Application;
    }
}
