import Analytics from 'portal/services/analytics';
import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedAccountCreationFlow extends Controller {
    @service
    declare analytics: Analytics;

    @service
    declare router: RouterService;

    @action
    async onSaveAndExit() {
        this.analytics.trackEvent('Account Creation Flow / Clicked Save and Exit');
        this.router.transitionTo('protected.deals');
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/account-creation-flow': ProtectedAccountCreationFlow;
    }
}
