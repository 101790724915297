import Analytics from 'portal/services/analytics';
import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { BufferedChangeset } from 'ember-changeset/types';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedIntakeStartDealRoute } from 'portal/routes/protected/intake/start-deal';
import { UserRoleType, intakeUserValidations } from 'portal/models/intake-user';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class ProtectedIntakeStartDeal extends Controller {
    declare model: ModelFrom<ProtectedIntakeStartDealRoute>;

    @service
    declare analytics: Analytics;

    @service
    declare router: RouterService;

    validations = intakeUserValidations;

    @task
    async onNext(intakeUser: BufferedChangeset, parties: BufferedChangeset[]): Promise<void> {

        if (intakeUser.isInvalid) {
            if (intakeUser.errors?.any((e) => e.key === 'state'
                && e.validation.includes('Empora does not currently operate in this market.'))) {
                if (intakeUser.get('state')) {
                    this.analytics.trackEvent(`Intake / Start Deal / Unsupported Market Selected: ${intakeUser.get('state')}`);
                }
            }
            return;
        }

        for (const party of parties) {
            if (party.isInvalid) {
                return;
            }
        }

        const intakeUserModel = await intakeUser.save();
        await Promise.all(parties.map((p) => p.save()));

        this.analytics.trackEvent('Intake / Start Deal / Next Clicked');

        if (intakeUserModel.userRole == UserRoleType.borrower) {
            this.router.transitionTo('protected.intake.purchase-contract', intakeUserModel.id);
        } else {
            this.router.transitionTo('protected.intake.buyer-seller', intakeUserModel.id);
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/intake/start-deal': ProtectedIntakeStartDeal;
    }
}
