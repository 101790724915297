import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import RSVP from 'rsvp';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { DealStatuses } from 'portal/models/deal';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDeals extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare auth: AuthService;

    @service
    declare router: RouterService;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deals');
    }

    async model() {
        const user = await this.auth.getAuthenticatedUser();
        const referral = this.store.findRecord('referral', user.id);
        const inProgressDeals = await this.store.query('deal', {
            status: DealStatuses.IN_PROGRESS
        });

        let completeDeals = [] as any;
        if (inProgressDeals.length === 0) {
            completeDeals = await this.store.query('deal', {
                status: DealStatuses.COMPLETE
            });
        }

        return RSVP.hash({
            referral,
            inProgressDeals: inProgressDeals.toArray(),
            completeDeals: completeDeals.toArray(),
            user
        });
    }

    afterModel(model: ModelFrom<ProtectedDeals>) {
        if (model.completeDeals.length === 0) {
            model.completeDeals = this.store.query('deal', {
                status: DealStatuses.COMPLETE
            });
        }
    }
}
