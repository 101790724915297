import Analytics from 'portal/services/analytics';
import Controller from '@ember/controller';
import IntakeUser, { UserRoleType } from 'portal/models/intake-user';
import RouterService from '@ember/routing/router-service';
import { BufferedChangeset } from 'ember-changeset/types';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedIntakeBuyerSellerRoute } from 'portal/routes/protected/intake/buyer-seller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class ProtectedIntakeBuyerSeller extends Controller {
    declare model: ModelFrom<ProtectedIntakeBuyerSellerRoute>;

    @service
    declare analytics: Analytics;

    @service
    declare router: RouterService;

    @task
    async onNext(
        changeset: BufferedChangeset,
        intakeUser: IntakeUser | BufferedChangeset
    ): Promise<void> {
        if (intakeUser.get('userRole') !== UserRoleType.buyer) {
            await Promise.all(changeset.get('buyers').map(async (buyer: BufferedChangeset) => {
                buyer.set('intakeUser', intakeUser);
                if (![UserRoleType.real_estate_agent,UserRoleType.lender,UserRoleType.power_of_attorney].includes(buyer.get('userRole'))) {
                    buyer.set('representing_role',null);
                }
                if (intakeUser.get('userRole') !== UserRoleType.assignor || buyer.isValid) {
                    await buyer.save();
                }
            } ));
        }

        if (intakeUser.get('userRole') !== UserRoleType.seller) {
            await Promise.all(changeset.get('sellers').map(async (seller: BufferedChangeset) => {
                seller.set('intakeUser', intakeUser);
                if (![UserRoleType.real_estate_agent,UserRoleType.lender,UserRoleType.power_of_attorney].includes(seller.get('userRole'))) {
                    seller.set('representing_role',null);
                }
                await seller.save();
            } ));
        }

        this.analytics.trackEvent('Intake / Buyer Seller / Next Clicked');

        this.router.transitionTo('protected.intake.purchase-contract', intakeUser.get('id'));
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/intake/buyer-seller': ProtectedIntakeBuyerSeller;
    }
}
