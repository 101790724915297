import Deal from 'portal/models/deal';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import validateEmail from 'portal/validators/email';
import { validatePresence } from 'ember-changeset-validations/validators';

export default class Actor extends Model {
    @attr('string')
        familyName?: string;

    @attr('string')
        givenName?: string;

    @attr('string')
        email?: string;

    @attr('boolean')
        inviteSent!: boolean;

    @attr('boolean')
        inviteAccepted!: boolean;

    @attr('string')
        teamName?: string;

    @attr('boolean')
        representative?: boolean;

    @belongsTo('deal')
        deal!: AsyncBelongsTo<Deal>;

    get invitePending(): boolean {
        return this.inviteSent && !this.inviteAccepted;
    }
}

export const actorValidations = {
    familyName: validatePresence({ presence: true, ignoreBlank: true, message: 'Last name must be provided' }),
    givenName: validatePresence({ presence: true, ignoreBlank: true, message: 'First name must be provided' }),
    email: [
        validatePresence({ presence: true }),
        validateEmail('Email address')
    ]
};

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'actor': Actor;
  }
}
