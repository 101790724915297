import IntakeUser, { UserRoleType } from './intake-user';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import validateEmail from 'portal/validators/email';
import validatePhoneNumber from 'portal/validators/phone-number';
import validatePresenceWhenFalse from 'portal/validators/presence-when-false';
import {
    validateNumber,
    validatePresence
} from 'ember-changeset-validations/validators';

export default class IntakeParty extends Model {
    @attr('string')
        firstName!: string;

    @attr('string')
        lastName!: string;

    @attr('string')
        email?: string;

    @attr('string')
        phoneNumber?: string;

    @attr('string')
        phoneExtension!: string;

    @attr('string')
        companyName!: string;

    @belongsTo('intake-user')
        intakeUser?: AsyncBelongsTo<IntakeUser>;

    @attr('string')
        userRole!: UserRoleType;

    @attr('string')
        representingRole!: UserRoleType;
}

export const intakePartyValidations = {
    firstName: validatePresence({ presence: true }),
    lastName: validatePresence({ presence: true }),
    userRole: validatePresence({ presence: true, message: 'Deal role must be provided' }),
    email: [
        validateEmail('Email', { allowBlank: true }),
        validatePresenceWhenFalse({ presence: true, key: 'phoneNumber', message: 'Must provide an email or phone number' })
    ],
    phoneNumber: [
        validatePhoneNumber('Phone number', { allowBlank: true }),
        validatePresenceWhenFalse({ presence: true, key: 'email', message: 'Must provide a phone number or email' })
    ],
    phoneExtension: validateNumber({ allowBlank: true })
};

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'intake-party': IntakeParty;
    }
}
