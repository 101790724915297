import { isEmpty } from '@ember/utils';
import { validatePresence } from 'ember-changeset-validations/validators';

export default function validatePresenceWhen(
    options: {
        presence: boolean;
        key?: string;
        value?: string;
        on?: string;
        message?: string;
        ignoreBlank?: boolean;
    } = {
        presence: true
    }
) {
    const checkKey = options.key;
    const checkValue = options.value;
    delete options.key;
    delete options.value;

    const parentValidator = validatePresence(options);

    return (
        key: string,
        newValue: unknown,
        oldValue: unknown,
        changes: unknown,
        content: object
    ): true | string => {
        if (isEmpty(checkKey)) {
            return "Key can't be blank";
        }

        if (isEmpty(checkValue)) {
            return "Value can't be blank";
        }

        if (!isEmpty(checkKey)) {
            let whenValue = undefined;

            if (changes != undefined) {
                whenValue = (changes as {[key: string]: any})[checkKey as string];
            }

            if (whenValue == undefined) {
                whenValue = (content as {[key: string]: any})[checkKey as string];
            }

            if (whenValue === checkValue) {
                return parentValidator(key, newValue, oldValue, changes, content);
            }
        }

        return true;
    };
}
