import Controller from '@ember/controller';
import ProtectedDealEarnestMoneyPortalTransferRoute from 'portal/routes/protected/deal/earnest-money/portal-transfer';
import RouterService from '@ember/routing/router-service';
import { ActionItemTemplateKeys } from 'portal/models/action-item';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

export default class ProtectedDealEarnestMoneyPortalTransfer extends Controller {
    declare model: ModelFrom<ProtectedDealEarnestMoneyPortalTransferRoute>;

    @service
    declare router: RouterService;

    @task
    async processTransfer(): Promise<void> {
        await this.model.save();
        const deal = await this.model.deal;
        const earnestMoneyDetail = await deal.earnestMoneyDetail!;
        const fundingSource = await this.model.dwollaFundingSource;

        earnestMoneyDetail.set('dwollaFundingSource', fundingSource);
        earnestMoneyDetail.set('dwollaTransfer', this.model);
        await earnestMoneyDetail.save();
    }

    @action
    async onNext(): Promise<void> {
        await taskFor(this.processTransfer).perform();
        const deal  = await this.model?.deal;
        const actionItems = await deal?.actionItems;
        const actionItem = actionItems?.find((actionItem) => [
            ActionItemTemplateKeys.BUYER_EARNEST_MONEY_METHOD,
            ActionItemTemplateKeys.ASSIGNOR_EARNEST_MONEY_METHOD
        ].includes(actionItem.templateKey as ActionItemTemplateKeys));
        if (actionItem){
            this.router.transitionTo('protected.deal.action-item-completion', actionItem.id);
        } else {
            this.router.transitionTo('protected.deal');
        }
    }

    @action
    async onCancel(): Promise<void> {
        this.router.transitionTo('protected.deal.earnest-money.selection');
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'protected/deal/earnest-money/portal-transfer': ProtectedDealEarnestMoneyPortalTransfer;
  }
}
