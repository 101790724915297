import AuthService from 'portal/services/auth';
import Controller from '@ember/controller';
import Deal from 'portal/models/deal';
import ProtectedDealNewIndexRoute from 'portal/routes/protected/deal/index';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import WebSocketService from 'portal/services/web-socket';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';

export default class ProtectedDealNewIndex extends Controller {
    declare model: ModelFrom<ProtectedDealNewIndexRoute>;

    ROUTES:{[key: string]: string} = {
        deal_tracker: 'my-deal',
        to_do: 'to-do',
        documents: 'documents',
        messages: 'messages',
        people: 'people'
    };

    @service
    declare auth: AuthService;

    @service intl!: IntlService;

    @service
        router!: RouterService;

    @service
    declare store: Store;

    @service
    declare webSocket: WebSocketService;

    @tracked
        currentSection = this.intl.t('components.deal.sections.deal_tracker');

    @tracked
        showNavigation = false;

    @tracked
        unreadMessageCount?: number;

    @tracked
        incompleteActivityCount?: number;

    @action
    onCancel() {
        this.showNavigation = false;
    }

    @action
    onNavigationClick() {
        this.showNavigation = true;
    }

    @action
    onNavigationSectionClick(section: string) {
        this.showNavigation = false;
        this.currentSection = this.intl.t(`components.deal.sections.${section}`);
        const route = `protected.deal.index.${this.ROUTES[section]}`;
        this.router.transitionTo(route);
    }

    @action
    async resetController() {
        this.currentSection = this.intl.t('components.deal.sections.deal_tracker');
        const refreshedDeal = await this.store.findRecord('deal', (this.model as Deal).id, { reload: true });
        this.unreadMessageCount = refreshedDeal.unreadMessageCount;
        this.incompleteActivityCount = refreshedDeal.incompleteActivityCount;
        this.showNavigation = false;
    }

    @action
    async subscribeToUnreadMessageCount() {
        const currentUser = await this.auth.getAuthenticatedUser();

        this.webSocket.createSubscription(
            'Deals::Channels::UnreadMessageCountChannel',
            (unreadMessageCountUpdate: any) => { this.receivedUnreadMessageCountUpdate(unreadMessageCountUpdate); },
            { user_id: currentUser.id }
        );
    }

    @action
    async subscribeToIncompleteActionItemCount() {
        const currentUser = await this.auth.getAuthenticatedUser();

        this.webSocket.createSubscription(
            'AdminWorkflow::IncompleteActionItemCountChannel',
            (incompleteActionItemCountUpdate: any) => { this.receivedIncompleteActionItemCountUpdate(incompleteActionItemCountUpdate); },
            { user_id: currentUser.id }
        );
    }

    receivedUnreadMessageCountUpdate(unreadMessageCountUpdate: any) {
        if (unreadMessageCountUpdate.deal_id === (this.model as Deal).id) {
            this.unreadMessageCount = unreadMessageCountUpdate.unread_message_count;
        }
    }

    receivedIncompleteActionItemCountUpdate(incompleteActionItemCountUpdate: any) {
        if (incompleteActionItemCountUpdate.deal_id === (this.model as Deal).id) {
            this.incompleteActivityCount = incompleteActionItemCountUpdate.incomplete_action_item_count;
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/deal/index': ProtectedDealNewIndex;
    }
}
