import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export interface EmporaRadioButtonArgs {
    onChange?: (value: any) => void;
    value?: any;
    name?: string;
}

export default class EmporaRadioButton extends Component<EmporaRadioButtonArgs> {
    @tracked
        input?: HTMLInputElement;

    get checked(): boolean {
        return this.input ? this.input.checked : false;
    }

    @action
    registerInput(element: HTMLInputElement) {
        this.input = element;
    }

    @action
    onChange() {
        if (this.args.onChange) {
            this.args.onChange(
                this.args.value !== undefined ? this.args.value : this.checked
            );
        }
    }
}
