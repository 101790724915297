/* eslint-disable ember/use-ember-data-rfc-395-imports */
import ActionItem, { ActionItemDescriptions } from './action-item';
import DS from 'ember-data';
import DocumentModel from './document';
import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

export default class BuyerWireActivity extends ActionItem {
    @attr('string', { defaultValue: ActionItemDescriptions.BUYER_WIRE_INSTRUCTIONS })
        name?: ActionItemDescriptions.BUYER_WIRE_INSTRUCTIONS;

    @attr('string', { defaultValue: ActionItemDescriptions.BUYER_WIRE_INSTRUCTIONS })
        description?: string;

    @belongsTo('document')
    declare document?:
        | AsyncBelongsTo<DocumentModel>
        | DS.PromiseObject<DocumentModel>
        | DocumentModel;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'buyer-wire-activity': BuyerWireActivity;
    }
}
