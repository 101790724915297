import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import DealSide from 'portal/services/deal-side';
import IndividualTitleholder from 'portal/models/individual-titleholder';
import ProtectedDealRoute from 'portal/routes/protected/deal';
import RSVP from 'rsvp';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealTitleholderInformationNewIndividual extends BaseRoute {
    @service
    declare router: RouterService;

    @service
    declare dealSide: DealSide;

    @service
    declare analytics: Analytics;

    @service
    declare auth: AuthService;


    @service
    declare store: Store;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Titleholder Information / New Individual');
    }

    async model() {
        const deal = this.modelFor(
            'protected.deal'
        ) as ModelFrom<ProtectedDealRoute>;

        const dealSide = this.dealSide.for(deal.id);
        const user = await this.auth.getAuthenticatedUser();

        const titleholdersCount = (await deal.titleholdersForSide(dealSide)).length;
        let titleholder;

        if (titleholdersCount === 0) {
            titleholder = this.store.createRecord(
                'individual-titleholder',
                {
                    deal,
                    dealSide,
                    firstName: user.givenName,
                    middleName: user.middleName,
                    lastName: user.familyName,
                    suffix: user.suffix,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                    birthday: user.birthday,
                    taxId: user.ssn,
                    isMarried: user.isMarried,
                    street: user.street,
                    street2: user.street2,
                    city: user.city,
                    state: user.state,
                    zipcode: user.zipcode,
                    inviteToDeal: false,
                    addToDealAsTitleholder: true,
                    userHasSsn: user.hasSsn
                }
            );

            if (titleholder.isMarried) {
                titleholder.spouseFirstName = user.spouseFirstName;
                titleholder.spouseMiddleName = user.spouseMiddleName;
                titleholder.spouseLastName = user.spouseLastName;
                titleholder.spouseSuffix = user.spouseSuffix;
                titleholder.spouseTaxId = user.spouseSsn;
                titleholder.spouseEmail = user.spouseEmail;
            }
        } else {
            titleholder = this.store.createRecord('individualTitleholder', {
                deal,
                dealSide,
                addToDealAsTitleholder: true,
                inviteToDeal: true
            });
        }

        return RSVP.hash({
            user,
            deal,
            titleholder
        });
    }

    afterModel(
        model: ModelFrom<ProtectedDealTitleholderInformationNewIndividual>
    ) {
        const titleholder = model.titleholder;

        if (titleholder === undefined || !(titleholder instanceof IndividualTitleholder)) {
            this.router.replaceWith(
                'protected.deal.titleholder-information.preference'
            );
        }
    }
}
