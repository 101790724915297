import ActionItem, {  ActionItemTemplateKeys } from 'portal/models/action-item';
import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Deal from 'portal/models/deal';
import DealSide from 'portal/services/deal-side';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

type Transition = ReturnType<RouterService['transitionTo']>;

const ALL_IN_ONE_ONBOARDING_ACTIVITIES = [
    ActionItemTemplateKeys.BUYER_ONBOARDING,
    ActionItemTemplateKeys.SELLER_ONBOARDING,
    ActionItemTemplateKeys.BORROWER_ONBOARDING
];

const CHUNKED_ONBOARDING_ACTIVITIES = [
    ActionItemTemplateKeys.SELLER_TITLEHOLDER_DETAILS,
    ActionItemTemplateKeys.BUYER_TITLEHOLDER_AND_FINANCING_INFORMATION
];


export default class ProtectedDealOnboarding extends BaseRoute {
    @service
    declare router: RouterService;

    @service
    declare dealSide: DealSide;

    @service
    declare analytics: Analytics;

    async model(): Promise<ActionItem | undefined> {
        const deal = this.modelFor('protected.deal') as Deal;
        const actionItems = await deal.actionItems;
        const onboardingActivity = actionItems?.find((actionItem) =>
            ALL_IN_ONE_ONBOARDING_ACTIVITIES.concat(CHUNKED_ONBOARDING_ACTIVITIES)
                .includes(actionItem.templateKey as ActionItemTemplateKeys)
        );
        return onboardingActivity;
    }

    async afterModel(): Promise<void> {
        const deal = this.modelFor('protected.deal') as Deal;
        this.dealSide.initialize(deal.id);
    }

    async redirect(
        model: ModelFrom<ProtectedDealOnboarding>,
        transition: Transition
    ): Promise<void> {
        if (!model || model.hasCompleted) {
            if (!CHUNKED_ONBOARDING_ACTIVITIES.includes(model?.templateKey as ActionItemTemplateKeys)) {
                this.router.replaceWith('protected.deal');
            }
        } else if (transition.to.name === 'protected.deal.onboarding.index') {
            this.router.replaceWith(
                'protected.deal.onboarding.welcome'
            );
        }
    }

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Onboarding');
    }
}
