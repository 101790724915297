import Controller from '@ember/controller';
import DealSide from 'portal/services/deal-side';
import LegalEntity from 'portal/models/legal-entity';
import LegalIndividual from 'portal/models/legal-individual';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { ModelFrom } from 'portal/utils/type-utils';
import {
    default as ProtectedSelectTitleholderRoute
} from 'portal/routes/protected/deal/titleholder-information/select-titleholder';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ProtectedSelectTitleholder extends Controller {
    declare model: ModelFrom<ProtectedSelectTitleholderRoute>;

    @service
    declare router: RouterService;

    @service
    declare store: Store;

    @service
    declare dealSide: DealSide;

    @tracked
        availableLegalEntities = this.model.legalEntities as unknown as LegalEntity[];

    @tracked
        availableLegalIndividuals = this.model.legalIndividuals as unknown as LegalIndividual[];


    @action
    async addIndividual(): Promise<void> {
        this.router.transitionTo('protected.deal.titleholder-information.new-individual');
    }

    @action
    async addEntity(): Promise<void> {
        this.router.transitionTo('protected.deal.titleholder-information.new-entity');
    }

    @action
    async editIndividual(legalIndividual: LegalIndividual): Promise<void> {
        this.router.transitionTo('protected.deal.titleholder-information.edit-individual', legalIndividual.id);
    }

    @action
    async editEntity(legalEntity: LegalEntity): Promise<void> {
        this.router.transitionTo('protected.deal.titleholder-information.edit-entity', legalEntity.id);
    }

    @action
    async individualSelect(legalIndividual: LegalIndividual): Promise<void> {
        const individualTitleholder = this.store.createRecord(
            'individual-titleholder',
            {
                deal: this.model.deal,
                dealSide: this.dealSide.for(this.model.deal.id),
                firstName: legalIndividual.firstName,
                middleName: legalIndividual.middleName,
                lastName: legalIndividual.lastName,
                suffix: legalIndividual.suffix,
                email: legalIndividual.email,
                phoneNumber: legalIndividual.phoneNumber,
                inviteToDeal: false,
                addToDealAsTitleholder: true,
                legalIndividual
            }
        );


        // TODO: This will be updated to go to the payment scene once that is complete
        console.log('individualTitleholder', individualTitleholder);
        // this.router.transitionTo('protected.deal.titleholder-information.new-individual');
    }

    @action
    async entitySelect(legalEntity: LegalEntity): Promise<void> {
        const entityTitleholder = this.store.createRecord('entityTitleholder', {
            entityName: legalEntity.entityName,
            email: legalEntity.email,
            phoneNumber: legalEntity.phoneNumber,
            dealSide: this.dealSide.for(this.model.deal.id),
            deal: this.model.deal,
            existingLegalEntity: legalEntity
        });

        if (legalEntity.designatedSigners) {
            for (const legalEntityDesignatedSigner of legalEntity.designatedSigners.toArray()) {
                const newEntityTitleholderDesignatedSigner = this.store.createRecord('entity-titleholder-designated-signer', {
                    email: legalEntityDesignatedSigner?.email,
                    firstName: legalEntityDesignatedSigner?.firstName,
                    middleName: legalEntityDesignatedSigner?.middleName,
                    lastName: legalEntityDesignatedSigner?.lastName,
                    legalEntityDesignatedSignerId: legalEntityDesignatedSigner?.id,
                    phoneNumber: legalEntityDesignatedSigner?.phoneNumber,
                    title: legalEntityDesignatedSigner?.title
                });
                entityTitleholder.designatedSigners?.pushObject(newEntityTitleholderDesignatedSigner);
            }
        }

        // TODO: This will be updated to go to the payment scene once that is complete
        console.log('entityTitleholder', entityTitleholder);
        // this.router.transitionTo('protected.deal.titleholder-information.new-individual');
    }
}