import ActionItem from './action-item';

export default class ProvideRentalInformationActivity extends ActionItem {}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'provide-rental-information-activity': ProvideRentalInformationActivity;
    }
}
