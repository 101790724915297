/* eslint-disable ember/use-ember-data-rfc-395-imports */
import DS from 'ember-data';
import LegalIndividualModel from './legal-individual';
import PowerOfAttorney from './power-of-attorney';
import Titleholder, {
    TitleholderType,
    addressValidations
} from './titleholder';
import validateEmail from 'portal/validators/email';
import validatePhoneNumber from 'portal/validators/phone-number';
import validatePresenceWhenFalse from 'portal/validators/presence-when-false';
import validatePresenceWhenTrue from 'portal/validators/presence-when-true';
import validateTaxId from 'portal/validators/tax-id';
import { attr, belongsTo } from '@ember-data/model';
import {
    validateDate,
    validatePresence
} from 'ember-changeset-validations/validators';

export default class IndividualTitleholder extends Titleholder {
    @attr('string', { defaultValue: TitleholderType.individual })
        type?: TitleholderType;

    @attr('string')
        firstName?: string;

    @attr('string')
        middleName?: string;

    @attr('string')
        lastName?: string;

    @attr('string')
        suffix?: string;

    @attr('boolean')
        isMarried?: boolean;

    @attr('string')
        spouseFirstName?: string;

    @attr('string')
        spouseMiddleName?: string;

    @attr('string')
        spouseLastName?: string;

    @attr('string')
        spouseSuffix?: string;

    @attr('string')
        spouseEmail?: string;

    @attr('date')
        birthday?: Date;

    @attr('date')
        spouseBirthday?: Date;

    @attr('string')
        spouseTaxId?: string;

    @attr('boolean')
        addToDealAsTitleholder?: boolean;

    @attr('boolean')
        inviteToDeal?: boolean;

    @attr('boolean')
        userHasSsn?: boolean;

    @attr('boolean')
        inviteSpouseToDeal?: boolean;

    @attr('boolean')
        titleholderHasTaxId?: boolean;

    @attr('boolean')
        hasSigning?: boolean;

    @attr('string')
        paymentMethodPreference?: string;

    @attr('boolean', { allowNull: true })
        hasPowerOfAttorney?: boolean | null;

    @belongsTo('power-of-attorney')
        powerOfAttorney?: PowerOfAttorney;

    @belongsTo('legal-individual')
    declare legalIndividual:
            | DS.PromiseObject<LegalIndividualModel>
            | LegalIndividualModel
            | null;

    get hasRequiredTitleholderAttributes(): boolean {
        return (
            this.birthday !== null && this.birthday !== undefined &&
            this.street !== null && this.street !== undefined &&
            this.city !== null && this.city !== undefined &&
            this.zipcode !== null && this.zipcode !== undefined &&
            this.isMarried !== null && this.isMarried !== undefined
        );
    }

    get hasSsn(): boolean {
        return !!(this.userHasSsn || this.titleholderHasTaxId);
    }

    get primaryName(): string {
        return [this.firstName, this.middleName, this.lastName].filter((x)=>x).join(' ').trim();
    }

    get displayName(): string {
        const primaryName = [this.firstName, this.middleName, this.lastName].filter((x)=>x).join(' ').trim();
        const spouseName = [this.spouseFirstName, this.spouseMiddleName, this.spouseLastName].filter((x)=>x).join(' ').trim();

        return [primaryName, spouseName].filter((x)=>x).join(' & ').trim();
    }
}

export const individualTitleholderValidations = Object.assign(
    {},
    addressValidations,
    {
        firstName: validatePresence({ presence: true, ignoreBlank: true }),
        lastName: validatePresence({ presence: true, ignoreBlank: true }),
        email: [
            validatePresence({ presence: true }),
            validateEmail('Email address')
        ],
        phoneNumber: [
            validatePhoneNumber('Phone number', { allowBlank: true })
        ],
        taxId: [
            validateTaxId(undefined, { unless: 'hasSsn' } )
        ],
        birthday: [validatePresence({ presence: true }), validateDate({
            message: 'Birthday must be a valid date, in the past, and after 1902',
            before: new Date(),
            after: new Date('1902-01-01')
        })],
        isMarried: validatePresence({ presence: true }),
        spouseFirstName: validatePresenceWhenTrue({
            presence: true,
            key: 'isMarried'
        }),
        spouseLastName: validatePresenceWhenTrue({
            presence: true,
            key: 'isMarried'
        }),
        spouseTaxId: [
            validateTaxId(undefined, { when: 'isMarried' })
        ],
        spouseEmail: [validateEmail('Email address', { allowBlank: true })]
    }
);

export const individualTitleholderValidationsWithTaxId = {
    ...individualTitleholderValidations,
    taxId: [
        validatePresenceWhenFalse({ presence: true, key: 'hasSsn', message: 'This field can\'t be blank.' }),
        validateTaxId(undefined, { unless: 'hasSsn' })
    ],
    spouseTaxId: [
        validatePresenceWhenTrue({ presence: true, key: 'isMarried', message: 'This field can\'t be blank.' }),
        validateTaxId(undefined, { when: 'isMarried' })
    ]
};

export const assignorIndividualTitleholderValidations = Object.assign(
    {},
    {
        firstName: validatePresence({ presence: true, ignoreBlank: true }),
        lastName: validatePresence({ presence: true, ignoreBlank: true }),
        email: [
            validatePresence({ presence: true }),
            validateEmail('Email address')
        ],
        phoneNumber: [
            validatePhoneNumber('Phone number', { allowBlank: true })
        ]
    }
);

export const assignorIndividualTitleholderValidationsWithTaxId = {
    ...assignorIndividualTitleholderValidations,
    taxId: [
        validatePresenceWhenFalse({ presence: true, key: 'hasSsn', message: 'This field can\'t be blank.' }),
        validateTaxId(undefined, { unless: 'hasSsn' })
    ]
};

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'individual-titleholder': IndividualTitleholder;
    }
}
