import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Store from '@ember-data/store';
import Transition from '@ember/routing/-private/transition';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedIntakePurchaseContract extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Intake Purchase Contract');
    }

    async model({ intake_user_id }: { intake_user_id: string }) {
        const intakeUser = await this.store.findRecord('intakeUser', intake_user_id);
        const team = await intakeUser.team;
        const teamMembers = await team?.hasMany('teamMembers')?.reload() || [];
        const intakePurchaseContract = this.store.createRecord('intake-purchase-contract');
        const activeTeamMembers = teamMembers.filterBy('pending', false).filterBy('currentUser', false);

        return {
            intakeUser,
            intakePurchaseContract,
            team,
            activeTeamMembers
        };
    }

    @action
    willTransition(transition: Transition) {
        if (!transition.to.name.includes(this.routeName)) {
            this.modelFor(this.routeName).intakePurchaseContract.documents.forEach((document: any) => {
                document.rollbackAttributes();
            });

            this.modelFor(this.routeName).intakePurchaseContract.rollbackAttributes();
        }
    }
}
