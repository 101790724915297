import IntakeUser from './intake-user';
import Model, { AsyncBelongsTo, belongsTo } from '@ember-data/model';
import TeamMember from './team-member';

export default class IntakeTeamMember extends Model {
    @belongsTo('team-member')
        teamMember?: AsyncBelongsTo<TeamMember>;

    @belongsTo('intake-user')
        intakeUser?: AsyncBelongsTo<IntakeUser>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'intake-team-member': IntakeTeamMember;
    }
}
