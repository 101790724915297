import { isEmpty } from '@ember/utils';
import { validatePresence } from 'ember-changeset-validations/validators';

export default function validatePresenceWhenTrue(
    options: {
        presence: boolean;
        key?: string;
        on?: string;
        message?: string;
    } = {
        presence: true
    }
) {
    const checkKey = options.key;
    delete options.key;

    const parentValidator = validatePresence(options);

    return (
        key: string,
        newValue: unknown,
        oldValue: unknown,
        changes: unknown,
        content: object
    ): true | string => {
        if (!isEmpty(checkKey)) {
            let whenValue = undefined;

            if (changes != undefined) {
                whenValue = (changes as { [key: string]: any })[
                    checkKey as string
                ];
            }

            if (whenValue == undefined) {
                whenValue = (content as { [key: string]: any })[
                    checkKey as string
                ];
            }

            if (!whenValue) {
                return true;
            }
        }

        return parentValidator(key, newValue, oldValue, changes, content);
    };
}
