import Component from '@glimmer/component';
import Store from '@ember-data/store';
import { BufferedChangeset } from 'ember-changeset/types';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface TaxAddressPreferenceArgs {
    changeset: BufferedChangeset;
    taxAddressSelected: boolean;
}

export default class TaxAddressPreference extends Component<TaxAddressPreferenceArgs> {
    @service
    declare store: Store;

    @tracked
    declare changeset: BufferedChangeset;

    @tracked
    declare taxAddressSelected: boolean;
}
