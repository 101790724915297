import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import Controller from '@ember/controller';
import ENV from 'portal/config/environment';
import EmporaServer from 'portal/services/empora-server';
import FeatureFlag from 'portal/services/feature-flag';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import download from 'downloadjs';
import { DateTime } from 'luxon';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const FEATURE_BANNER_IMPRESSIONABLE_TYPE = 'all_deals_feature_banner';
const FEATURE_BANNER_IMPRESSIONABLE_ID = '9834526f-3f72-40be-aca4-94251422df63';

export default class ProtectedDeals extends Controller {
    constructor(args: any) {
        super(args);

        this.shouldDisplayFeatureBanner();
    }

    @service
    declare analytics: Analytics;

    @service
    declare auth: AuthService;

    @service
    declare emporaServer: EmporaServer;

    @service
    declare store: Store;

    @service
    declare router: RouterService;

    @service
    declare featureFlag: FeatureFlag;

    @tracked
        displayFeatureBanner?: boolean = false;

    @action
    async exportDeals() {
        this.analytics.trackEvent('Deals / Export Clicked');
        const response = await this.emporaServer.fetchData('/deals/download');
        const responseJson = await response.text();
        download(responseJson, 'deals.csv', 'text/csv');
    }

    @action
    startDeal() {
        this.analytics.trackEvent('Deals / Submit Deal Clicked');
        this.router.transitionTo('protected.intake.start-deal');
    }

    @action
    onCommunicationPreferencesBannerClick() {
        this.analytics.trackEvent('Deals / Communication Preferences Banner Clicked');
        this.router.transitionTo('protected.my-account.communication-preferences');
    }

    async shouldDisplayFeatureBanner() {
        const impressions = await this.store.query('impression', {
            'impressionable_type': FEATURE_BANNER_IMPRESSIONABLE_TYPE,
            'impressionable_id': FEATURE_BANNER_IMPRESSIONABLE_ID
        });

        if ((impressions.length as number) > 0) return;

        const deals = await this.store.findAll('deal');
        if ((deals.length as number) < 5) return;

        await this.store.createRecord('impression', {
            impressionableType: FEATURE_BANNER_IMPRESSIONABLE_TYPE,
            impressionableId: FEATURE_BANNER_IMPRESSIONABLE_ID
        }).save();

        this.displayFeatureBanner = true;
    }

    get displayCustomerCommunicationBanner() {
        if (this.auth.user!.emailPreference) return false;
        if (this.auth.user!.portalMessagePreference) return false;
        if (this.auth.user!.phoneCallPreference) return false;
        if (this.auth.user!.textMessagePreference) return false;

        const featureLaunchDate = DateTime.fromFormat(ENV.CUSTOMER_FACING_COMMS_LAUNCH_DATE, 'MM/dd/yyyy');
        const featureLaunchCutoffDate = featureLaunchDate.plus({ day: ENV.CUSTOMER_FACING_COMMS_CUTOFF_DAYS });
        const userCreationDate = DateTime.fromJSDate(this.auth.user!.createdAt!);
        const userCutoffDate = userCreationDate.plus({ day: ENV.CUSTOMER_FACING_COMMS_CUTOFF_DAYS });

        return DateTime.now() < DateTime.max(featureLaunchCutoffDate, userCutoffDate);
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/deals': ProtectedDeals;
    }
}
