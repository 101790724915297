import ENV from 'portal/config/environment';
import Service from '@ember/service';

export default class Logger extends Service {
    log(...args: any[]) {
        if (ENV.environment !== 'production') {
            console.log(...args);
        }
    }
}

declare module '@ember/service' {
    interface Registry {
        logger: Logger;
    }
}
