import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedAccountCreationFlowHome extends BaseRoute {
    @service
    declare analytics: Analytics;

    @service
    declare store: Store;

    @action
    didTransition(): void {
        this.analytics.trackPage('Account Creation Flow / Visited Home');
    }

    async model() {
        return (await this.store.findAll('guided-account-creation-section')).toArray();
    }
}
