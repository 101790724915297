import { helper } from '@ember/component/helper';

function mask(params: [text: string]) {
    const [text] = params;
    if (!text) return '';
    if (text.length <= 4) return 'x'.repeat(text.length);

    return [
        'x'.repeat(text.length-4),
        '-',
        text.substring(text.length-4)
    ].join('');
}

export default helper(mask);
