import Component from '@glimmer/component';
import EntityTitleholder from 'portal/models/entity-titleholder';
import IndividualTitleholder from 'portal/models/individual-titleholder';

interface TitleholdersArgs {
    titleholders: Array<IndividualTitleholder | EntityTitleholder>;
    addTitleholder: () => Promise<void>;
    editTitleholder: (titleholder: IndividualTitleholder | EntityTitleholder) => Promise<void>;
    removeTitleholder: (titleholder: IndividualTitleholder | EntityTitleholder) => Promise<void>;
}

export default class Titleholders extends Component<TitleholdersArgs> {
    get hasTitleholder(): boolean {
        return this.args.titleholders.any((th) => !th.isDeleted);
    }
}
