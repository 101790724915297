import * as Sentry from '@sentry/ember';
import Component from '@glimmer/component';
import DwollaFundingSource from 'portal/models/dwolla-funding-source';
import ENV from 'portal/config/environment';
import PlaidLinkSession from 'portal/models/plaid-link-session';
import Store from '@ember-data/store';
import { SeverityLevel } from '@sentry/types';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface LinkArgs {
    plaidLinkSession: PlaidLinkSession;
    oauthRedirect: boolean;
    onNext: (fundingSource: DwollaFundingSource) => void;
    onError: (error: any) => void;
}

export default class Link extends Component<LinkArgs> {
    @service
    declare store: Store;

    @tracked
    declare creationPending: boolean;

    @tracked
    declare dwollaFundingSource: DwollaFundingSource;

    declare plaidCleanup: () => void;

    @action
    onDidInsert(element: HTMLElement) {
        this.creationPending = false;

        if (ENV.environment === 'test') {
            this.onLoaded();
        } else {
            const topDiv = element.closest('.deal-outlet');
            if (topDiv && window.getComputedStyle(topDiv).display === 'none') {
                return;
            }

            const script = window.document.createElement('script');
            script.addEventListener('load', this.onLoaded.bind(this));
            script.src=ENV.PLAID_LINK_URL;
            element.prepend(script);
        }
    }

    @action
    onWillDestroy() {
        if (this.plaidCleanup) {
            this.plaidCleanup();
        }
    }

    @action
    setName(name: string) {
        this.dwollaFundingSource.name = name;
    }

    @action
    onNext() {
        this.creationPending = true;
        this.args.onNext(this.dwollaFundingSource);
    }

    onLoaded() {
        const Plaid = (window as any).Plaid;

        const config: Record<string, any> = {
            token: this.args.plaidLinkSession!.linkToken,
            onSuccess: (publicToken: string, metadata: any) => {
                const account = metadata.accounts[0];
                const name = [metadata.institution.name,account.name,account.mask].filter((x)=>x).join(' - ');

                this.dwollaFundingSource = this.store.createRecord('dwolla-funding-source', {
                    publicToken: publicToken,
                    name: name,
                    plaidAccountId: account.id
                });
                this.args.plaidLinkSession.complete = true;
            },
            onExit: (err: any, metadata: any) => {
                if (err) {
                    Sentry.addBreadcrumb({
                        category: 'plaid',
                        message: 'Plaid user exited without linking account',
                        level: 'error' as SeverityLevel,
                        data: { err, metadata }
                    });
                    this.args.onError(err);
                }
            }
        };

        if (this.args.oauthRedirect) {
            config.receivedRedirectUri = window.location.href;
        }

        const { open, destroy } = Plaid.create(config);

        this.plaidCleanup = destroy;

        open();
    }
}
