import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import DealSide from 'portal/services/deal-side';
import EntityTitleholder from 'portal/models/entity-titleholder';
import ProtectedDealRoute from 'portal/routes/protected/deal';
import RSVP from 'rsvp';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealOnboardingConfirmEntityDetails extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare router: RouterService;

    @service
    declare dealSide: DealSide;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Onboarding / Confirm Entity Details');
    }

    async model() {
        const deal = this.modelFor('protected.deal') as ModelFrom<ProtectedDealRoute>;
        const titleholder = await deal.latestTitleholder(this.dealSide.for(deal.id)) as EntityTitleholder | undefined;
        const legalEntity = await titleholder?.existingLegalEntity;
        const wireInstruction = legalEntity?.legalEntityWireInstruction;
        const primaryDocument = legalEntity?.primaryDocuments?.toArray()?.[0];
        const wireInstructionsRequired = false;

        return RSVP.hash({
            titleholder,
            deal,
            legalEntity,
            wireInstruction,
            primaryDocument,
            wireInstructionsRequired
        });
    }

    afterModel(model: ModelFrom<ProtectedDealOnboardingConfirmEntityDetails>) {
        if (!model?.legalEntity) {
            this.router.replaceWith('protected.deal.onboarding.titleholder-preference');
        }
    }
}
