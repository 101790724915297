import Model, { attr } from '@ember-data/model';
import validateConfirmation from 'portal/validators/confirmation';
import validateRoutingNumber from 'portal/validators/routing-number';
import { validatePresence } from 'ember-changeset-validations/validators';

export default class LegalEntityWireInstruction extends Model {
    @attr('string')
        legalEntityId?: string;

    @attr('string')
        bankName?: string;

    @attr('string')
        routingNumber?: string;

    @attr('string')
        routingNumberConfirmation?: string;

    @attr('string')
        accountNumber?: string;

    @attr('string')
        accountNumberConfirmation?: string;

    @attr('string')
        instructions?: string;
}

export const legalEntityWireInstructionValidations = {
    bankName: validatePresence({
        presence: true,
        message: 'Bank name can’t be blank'
    }),
    routingNumber: [
        validatePresence({
            presence: true,
            message: 'Routing number can’t be blank'
        }),
        validateRoutingNumber('Routing number', { allowBlank: true })
    ],
    routingNumberConfirmation: [
        validatePresence({
            presence: true,
            message: 'Routing number confirmation can’t be blank'
        }),
        validateConfirmation({
            on: 'routingNumber',
            message: 'Routing numbers don’t match'
        })
    ],
    accountNumber: validatePresence({
        presence: true,
        message: 'Account number can’t be blank'
    }),
    accountNumberConfirmation: [
        validatePresence({
            presence: true,
            message: 'Account number confirmation can’t be blank'
        }),
        validateConfirmation({
            on: 'accountNumber',
            message: 'Account numbers don’t match'
        })
    ]
};

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'legal-entity-wire-instruction': LegalEntityWireInstruction;
  }
}
