import { later } from '@ember/runloop';

interface PollWithTimeoutParams<T> {
    loadData: () => Promise<T | undefined>;
    isValid: (data: T) => boolean;
    intervalMs: number;
    timeoutMs: number;
    onTimeout?: () => void;
}

export async function pollWithTimeout<T>(params: PollWithTimeoutParams<T>): Promise<T | undefined> {
    const endTime = Number(new Date()) + params.timeoutMs;
    const pollTimeRemainingMs = () => endTime - Number(new Date());
    const maxPollWaitTimeExceeded = () => pollTimeRemainingMs() <= 0;
    const timeout: () => Promise<null> = () => {
        return new Promise<null>((resolve) => later(() => resolve(null), pollTimeRemainingMs()));
    };
    let lastValue: T;

    const poll = async (resolve: (value: T | undefined) => void, reject: (reason?: any) => void) => {
        const data = await Promise.race([params.loadData(), timeout()]);
        const onTimeout = () => {
            if (params.onTimeout) params.onTimeout();
            resolve(lastValue);
        };

        if (data === null) {
            onTimeout();
            return;
        }

        if (params.isValid(data as T)) {
            resolve(data as T);
        } else if (!maxPollWaitTimeExceeded()) {
            lastValue = data as T;
            later(() => poll(resolve, reject), params.intervalMs);
        } else {
            onTimeout();
        }
    };

    return new Promise(poll);
}
