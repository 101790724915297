import Component from '@glimmer/component';
import RouterService from '@ember/routing/router-service';
import { BufferedChangeset } from 'ember-changeset/types';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export interface ProfilePersonalDetailsArgs {
    changeset: BufferedChangeset;
    onAfterSave?: () => Promise<void>;
    onCancel?: () => Promise<void>;
}

export default class ProfilePersonalDetails extends Component<ProfilePersonalDetailsArgs> {
    @tracked
        saveSucceeded?: boolean = false;

    @tracked
        saveFailed?: boolean = false;

    @tracked
        showErrorBanner?: boolean = false;

    @service
    declare router: RouterService;

    @action
    async cancel() {
        if (this.args.onCancel) {
            await this.args.onCancel?.();
        } else {
            this.router.transitionTo('protected.deals');
        }
    }

    @action
    onIsMarriedOnChange(value: boolean) {
        this.args.changeset.isMarried = value;

        if (value) {
            this.validate();
        } else {
            this.args.changeset.set('spouseFirstName', null);
            this.args.changeset.set('spouseMiddleName', null);
            this.args.changeset.set('spouseLastName', null);
            this.args.changeset.set('spouseSuffix', null);
            this.args.changeset.set('spouseSsn', null);
        }
    }

    @action
    onNoSsnChange(value: boolean) {
        this.args.changeset.noSsn = value;

        if (value) {
            this.args.changeset.ssn = null;
        }
    }

    @action
    async onSave() {
        this.saveFailed = false;
        this.saveSucceeded = false;

        this.args.changeset.set('showErrorOnPristine', true);

        if (this.args.changeset.isInvalid) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        try {
            await this.args.changeset.save();
            this.saveSucceeded = true;
            this.args.changeset.set('showErrorOnPristine', false);
            this.showErrorBanner = false;
        } catch (e) {
            this.saveFailed = true;
        }

        if (this.args.onAfterSave) {
            await this.args.onAfterSave?.();
        } else {
            window.scrollTo(0, 0);
        }
    }

    @action
    onSpouseNoSsnChange(value: boolean) {
        this.args.changeset.spouseNoSsn = value;

        if (value) {
            this.args.changeset.spouseSsn = null;
        }
    }

    @action
    validate() {
        this.args.changeset.validate();
    }

    get spouseSubtitle(): string {
        return 'Please enter your spouse’s information below';
    }
}
