/*global module require*/
const colors = require('tailwindcss/colors');

const emporaColors = require('../config/colors.js');

module.exports = {
    content: [
        './app/**/*.{ts,js,hbs,css}'
    ],
    theme: {
        extend: {
            zIndex: {
                '-1': '-1'
            },
            listStyleType: {
                square: 'square'
            },
            minHeight: {
                50: '50px'
            },
            boxShadow: {
                empora: '0 0 2px 2px #9670FF'
            },
            maxWidth: {
                xl: '37.5rem'
            },
            outlineOffset: {
                '-1': '-1px',
                '-2': '-2px',
                '-4': '-4px'
            }
        },
        colors: Object.assign(
            {},
            {
                transparent: 'transparent',
                current: 'currentColor',

                black: colors.black,
                white: colors.white,
                gray: colors.gray,
                red: colors.red,
                yellow: colors.amber,
                emerald: colors.emerald,
                blue: colors.blue,
                indigo: colors.indigo,
                purple: colors.violet,
                pink: colors.pink
            },
            emporaColors
        ),
        fontFamily: {
            sans: [
                '"Red Hat Display"',
                '"Red Hat Display Italic"'
            ],
            serif: [
                'tiempos-headline-regular',
                'tiempos-headline-regular-italic',
                'tiempos-headline-medium-italic',
                'tiempos-headline-medium',
                'tiempos-headline-semibold-italic',
                'tiempos-headline-semibold',
                'ui-serif',
                'Georgia',
                'Cambria',
                '"Times New Roman"',
                'Times',
                'serif'
            ]
        },
        fontWeight: {
            normal: '400',
            medium: '500',
            semibold: '600',
            bold: '700',
            extrabold: '800',
            black: '900'
        }
    },
    plugins: []
};
