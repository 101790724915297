import Component from '@glimmer/component';
import Deal, { DealActorRoles } from 'portal/models/deal';
import DealSide from 'portal/services/deal-side';
import LegalEntity from 'portal/models/legal-entity';
import LegalIndividual from 'portal/models/legal-individual';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import User from 'portal/models/user';
import { action } from '@ember/object';
import { isNone } from '@ember/utils';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface SelectTitleholderArgs {
    deal: Deal;
    user: User;
    legalIndividuals: LegalIndividual[];
    legalEntities: LegalEntity[];
    onAddEntity: () => void;
    onAddIndividual: () => void;
    onEditIndividual: (value: LegalIndividual) => void;
    onEditEntity: (value: LegalEntity) => void;
    onEntitySelect: (value: LegalEntity) => void;
    onIndividualSelect: (value: LegalIndividual) => void;
}

export default class SelectTitleholder extends Component<SelectTitleholderArgs> {
    @service
    declare dealSide: DealSide;

    @service
    declare router: RouterService;

    @service
    declare store: Store;

    @tracked
    declare selectedTitleholder: (LegalIndividual | LegalEntity | null);

    @tracked
        showErrorOnPristine?: boolean = false;

    @tracked
        showErrorBanner?: boolean = false;

    get titleTextFromDealRole() {
        return this.args.deal.actorRole === DealActorRoles.SELLER ? 'owners' : 'titleholders';
    }

    @action
    onCheck(value: LegalIndividual | LegalEntity) {
        this.selectedTitleholder = this.selectedTitleholder == value ? null : value;
    }

    @action
    onNext() {
        this.showErrorOnPristine = true;

        if (isNone(this.selectedTitleholder)) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        if (this.selectedTitleholder instanceof LegalIndividual) {
            this.args.onIndividualSelect(this.selectedTitleholder);
        } else {
            this.args.onEntitySelect(this.selectedTitleholder);
        }

    }
}
