import AuthService from 'portal/services/auth';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class ProtectedDeal extends Controller {
    @service
        auth!: AuthService;
}

declare module '@ember/controller' {
    interface Registry {
        dashboard: ProtectedDeal;
    }
}
