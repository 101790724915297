import Analytics from 'portal/services/analytics';
import ClearToCloseTitleItem from 'portal/models/clear-to-close-title-item';
import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface TrackYourTitleWorkArgs {
    deal: Deal;
    clearToCloseTitleItems: ClearToCloseTitleItem[];
}

const MINIMUM_VERSION_FOR_TIMELINES = 26;

export default class TrackYourTitleWork extends Component<TrackYourTitleWorkArgs> {
    @service
    declare analytics: Analytics;

    @service
    declare store: Store;

    @tracked
        isOpen: boolean;

    constructor(owner: unknown, args: TrackYourTitleWorkArgs) {
        super(owner, args);

        this.isOpen = this.completedCount < this.args.clearToCloseTitleItems.length;
    }

    @action
    toggleDrawer() {
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
            this.analytics.trackEvent('Track your title work - Expanded');
        }
    }

    get payoffAuthMessage(){
        if (this.args.deal.isBorrower){
            return 'One or more of our items require that a Borrower Authorization be filled out by the borrower.';
        } else {
            return 'One or more of our items require that a Seller Authorization be filled out by the seller.';
        }
    }

    get blockedByPayoffAuth(){
        return this.args.clearToCloseTitleItems.any(
            (clearToCloseTitleItem) => clearToCloseTitleItem.blockedByPayoffAuth!
        );
    }

    get completedCount(){
        return this.args.clearToCloseTitleItems.filter(
            (clearToCloseTitleItem) => clearToCloseTitleItem.isCompleted()
        ).length;
    }

    get showTimelines() {
        return (this.args.deal.actionItemVersion ?? 0) >= MINIMUM_VERSION_FOR_TIMELINES;
    }

}
