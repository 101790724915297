import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import Property from './property';
import validateEmail from 'portal/validators/email';
import validatePhoneNumber from 'portal/validators/phone-number';
import { DealSides } from 'portal/services/deal-side';
import { validateNumber, validatePresence } from 'ember-changeset-validations/validators';

export enum IntendedFinancingTypes {
    CASH = 'Cash',
    CONVENTIONAL = 'Conventional',
    HARD = 'Hard',
    OTHER = 'Other',
    PRIVATE = 'Private'
}

export default class CreditLine extends Model {
    @attr('string')
        accountEmail?: string;

    @attr('string')
        accountNumber?: string;

    @attr('string')
        accountName?: string;

    @attr('string')
        intendedFinancingType?: IntendedFinancingTypes;

    @attr('boolean', { allowNull: true })
        requestedMlsSurvey?: boolean;

    @attr('string')
        contactName?: string;

    @attr('string')
        dealSide?: DealSides;

    @attr('number')
        loanAmount?: number;

    @attr('string')
        phoneNumber?: string;

    @belongsTo('property')
        property?: AsyncBelongsTo<Property>;
}

export const creditLineValidations = {
    accountName: validatePresence({
        presence: true,
        message: "Bank name can't be blank"
    }),
    phoneNumber: [
        validatePresence({ presence: true }),
        validatePhoneNumber('Phone number', { allowBlank: true })
    ],
    accountEmail: [validateEmail('Email', { allowBlank: true })],
    loanAmount: validateNumber({ allowBlank: true })
};

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'credit-line': CreditLine;
    }
}
