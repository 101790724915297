import Actor from 'portal/models/actor';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface DealTeamArgs {
    teamName: string;
    actors: Actor[];
}

export default class DealTeam extends Component<DealTeamArgs> {
    @tracked
        showTeamMembers?: boolean = false;

    @action
    toggleShowTeamMembers() {
        this.showTeamMembers = !this.showTeamMembers;
    }
}
