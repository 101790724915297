import { isPresent } from '@ember/utils';

export interface BasicAddress {
    street?: string;
    street2?: string | null;
    city?: string;
    state?: string;
    zipcode?: string;
}

export function oneLineAddress(address: BasicAddress): string {
    return separated([
        separated([
            separated([address.street, address.street2], ' '),
            separated([address.city, address.state], ', ')
        ], ', '),
        address.zipcode
    ], ' ');
}

function separated(parts: (string | null | undefined)[], separator: string): string {
    return parts.filter((p) => isPresent(p)).join(separator);
}
