import Component from '@glimmer/component';
import ENV from 'portal/config/environment';
import EarnestMoneyDepositMethodRule, {
    EarnestMoneyDepositMethodRuleId,
    EarnestMoneyDepositMethodRuleValue
} from 'portal/models/earnest-money-deposit-method-rule';
import { default as EarnestMoneyDetail, EarnestMoneyMethod } from 'portal/models/earnest-money-detail';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface EarnestMoneySelectionArgs {
    market: string,
    earnestMoneyDepositMethodRules: EarnestMoneyDepositMethodRule[],
    earnestMoneyDetail: EarnestMoneyDetail;
    onNext: () => unknown;
}

export default class EarnestMoneySelection extends Component<EarnestMoneySelectionArgs> {
    @tracked
        selectedMethod?: EarnestMoneyMethod = this.args.earnestMoneyDetail.earnestMoneyMethod;

    @tracked
        loading?: boolean;


    get canUsePortal(): boolean {
        if (!ENV.PLAID_ENABLED) {
            return false;
        }

        if (!this.portalAvailableInMarket){
            return false;
        }

        const earnestMoneyAmount = this.args.earnestMoneyDetail.earnestMoneyAmount;
        return earnestMoneyAmount != undefined && earnestMoneyAmount <= 5000;
    }

    get portalAvailableInMarket(): boolean {
        const portalRule = this.args.earnestMoneyDepositMethodRules.find(
            (earnestMoneyDepositMethodRule: EarnestMoneyDepositMethodRule) => {
                return earnestMoneyDepositMethodRule.id == EarnestMoneyDepositMethodRuleId.portal;
            }
        );

        return portalRule?.value === EarnestMoneyDepositMethodRuleValue.enabled;
    }

    get hidePortal(): boolean {
        const portalRule = this.args.earnestMoneyDepositMethodRules.find(
            (earnestMoneyDepositMethodRule: EarnestMoneyDepositMethodRule) => {
                return earnestMoneyDepositMethodRule.id == EarnestMoneyDepositMethodRuleId.portal;
            }
        );

        return portalRule?.value == EarnestMoneyDepositMethodRuleValue.hidden;
    }

    get canUseCheck(): boolean {
        if (!this.checkAvailableInMarket) {
            return false;
        }

        const earnestMoneyDetail = this.args.earnestMoneyDetail;
        const earnestMoneyAmount = earnestMoneyDetail.earnestMoneyAmount;
        return earnestMoneyAmount != undefined && earnestMoneyAmount < earnestMoneyDetail.maxCheckAmount;
    }

    get checkAvailableInMarket(): boolean {
        const checkRule = this.args.earnestMoneyDepositMethodRules.find(
            (earnestMoneyDepositMethodRule: EarnestMoneyDepositMethodRule) => {
                return earnestMoneyDepositMethodRule.id == EarnestMoneyDepositMethodRuleId.check;
            }
        );

        return checkRule?.value === EarnestMoneyDepositMethodRuleValue.enabled;
    }

    get hideCheck(): boolean {
        const checkRule = this.args.earnestMoneyDepositMethodRules.find(
            (earnestMoneyDepositMethodRule: EarnestMoneyDepositMethodRule) => {
                return earnestMoneyDepositMethodRule.id == EarnestMoneyDepositMethodRuleId.check;
            }
        );

        return checkRule?.value == EarnestMoneyDepositMethodRuleValue.hidden;
    }

    get maxCheckAmountDisplay(): string {
        return '$'.concat(this.args.earnestMoneyDetail.maxCheckAmount.toLocaleString('en-US'));
    }

    get earnestMoneyAmount(): string | undefined {
        return this.args.earnestMoneyDetail?.earnestMoneyAmount?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });
    }

    @action
    selectMethod(method: EarnestMoneyMethod) {
        this.selectedMethod = method;
    }

    @action
    async onNext() {
        const earnestMoneyDetail = this.args.earnestMoneyDetail;
        earnestMoneyDetail.earnestMoneyMethod = this.selectedMethod;
        this.loading=true;
        await earnestMoneyDetail.save();
        this.loading=false;
        this.args.onNext();
    }

}
