import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedDealClosingRequestRoute } from 'portal/routes/protected/deal/closing-request';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealClosingRequest extends Controller {
    declare model: ModelFrom<ProtectedDealClosingRequestRoute>;

    @service
    declare router: RouterService;

    @action
    async onSubmit() {
        this.router.transitionTo('protected.deal.closing-request-completion');
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/deal/closing-request': ProtectedDealClosingRequest;
    }
}
