import Controller from '@ember/controller';
import CreditLine, { creditLineValidations } from 'portal/models/credit-line';
import DealSide from 'portal/services/deal-side';
import RefinanceCreditLinesRoute from 'portal/routes/protected/deal/onboarding/refinance-credit-lines';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { BufferedChangeset, lookupValidator } from 'validated-changeset';
import { Changeset } from 'ember-changeset';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class ProtectedDealOnboardingRefinanceCreditLines extends Controller {
    declare model: ModelFrom<RefinanceCreditLinesRoute>;

    @service
    declare router: RouterService;

    @service
    declare store: Store;

    @service
    declare dealSide: DealSide;

    @tracked
        showErrorBanner?: boolean = false;

    private changesetCache = new WeakMap<
        CreditLine,
        BufferedChangeset
    >();

    get nonDeletedCreditLines() {
        return this.model.creditLines?.filterBy('isDeleted', false) || [];
    }

    get changesets(): BufferedChangeset[] {
        return this.nonDeletedCreditLines.toArray().map((creditLine) => {
            let changeset = this.changesetCache.get(creditLine);
            if (!changeset) {
                changeset = Changeset(
                    creditLine,
                    lookupValidator(creditLineValidations),
                    creditLineValidations,
                    { changesetKeys: Object.keys(CreditLine.attributes) }
                );
                // eslint-disable-next-line ember/no-side-effects
                this.changesetCache.set(creditLine, changeset);
            }
            return changeset;
        });
    }

    @task
    async saveCreditLines(): Promise<void> {
        this.changesets?.forEach((changeset) => {
            changeset.set('showErrorOnPristine', true);
        });

        if (this.changesets.any((c) => c.isInvalid)) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        this.changesets.map((c) => c.execute());
        this.model.creditLines?.map(async (creditLine) => await creditLine.save());

        this.router.transitionTo('protected.deal.onboarding.cpc-notary-intermediary');
    }

    @action
    addAnother() {
        this.store.createRecord('credit-line', {
            property: this.model.property,
            dealSide: this.dealSide.for(this.model.deal.id)
        });
        this.send('invalidateModel');
    }

    @action
    onDelete(creditLineChangeset: BufferedChangeset) {
        const creditLine = creditLineChangeset.data as CreditLine;
        this.changesetCache.delete(creditLine);
        creditLine.deleteRecord();
    }

    @action
    validate() {
        this.changesets?.forEach((changeset) => {
            changeset.set('showErrorOnPristine', false);
            changeset.validate();
        });
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/deal/onboarding/refinance-credit-lines': ProtectedDealOnboardingRefinanceCreditLines;
    }
}
