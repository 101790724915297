import Deal from './deal';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

export default class PayoffAuthorization extends Model {
    @belongsTo('deal')
        deal?: AsyncBelongsTo<Deal>;

    @attr('string')
        embeddedSigningUrl?: string;
}

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'payoff-authorization': PayoffAuthorization;
    }
}
