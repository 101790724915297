import Controller from '@ember/controller';
import ProtectedDealOnboardingEntityTitleholderRoute from 'portal/routes/protected/deal/onboarding/entity-titleholder';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealOnboardingEntityTitleholder extends Controller {
    declare model: ModelFrom<ProtectedDealOnboardingEntityTitleholderRoute>;

    @service
    declare router: RouterService;

    @action
    async onNext() {
        const { titleholder, deal } = this.model;

        if (deal.isBorrower) {
            if (titleholder?.isCurrent) {
                this.router.transitionTo('protected.deal.onboarding.property-information');
            } else {
                this.router.transitionTo('protected.deal.onboarding.refinance-credit-lines');
            }
        } else {
            this.router.transitionTo('protected.deal.onboarding.personal-information');
        }
    }
}