import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import Store from '@ember-data/store';
import Team from 'portal/models/team';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
interface AddTeamDialogArgs {
    deal: Deal;
    team: Team;
    onClose: () => void;
}

export default class AddTeamDialog extends Component<AddTeamDialogArgs> {
    @service
    declare store: Store;

    @tracked
        isTeamChecked?: boolean = true;

    @action
    toggleIsTeamChecked() {
        this.isTeamChecked = !this.isTeamChecked;
    }

    @action
    async onAdd(){
        await this.store.createRecord('deal-team', { deal: this.args.deal, team: this.args.team }).save();
        await this.args.deal.hasMany('actors').reload();
        this.args.onClose();
    }
}
