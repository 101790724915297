import Component from '@glimmer/component';
import EarnestMoneyDetail from 'portal/models/earnest-money-detail';
import Store from '@ember-data/store';
import window from 'ember-window-mock';
import { BufferedChangeset } from 'ember-changeset/types';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

interface EarnestMoneyCheckArgs {
    earnestMoneyDetail: EarnestMoneyDetail;
    changeset: BufferedChangeset;
    onComplete?: () => void;
}

export default class EarnestMoneyCheck extends Component<EarnestMoneyCheckArgs> {
    @service
    declare store: Store;

    @action
    onRealtorHasCheckChange(value: string) {
        this.args.changeset.set('realtorHasCheck', value);
        this.args.changeset.validate();
    }

    @task
    async downloadDocument(): Promise<void> {
        const documentDownloadRequest = this.store.createRecord(
            'document-download-request',
            {
                document: await this.args.earnestMoneyDetail.instructionsDocument,
                deal: this.args.earnestMoneyDetail.deal
            }
        );

        // This needs to be instantiated before async behavior in order to not be blocked by Safari as a pop-up.
        // See https://stackoverflow.com/a/39387533
        const windowOpenReference = window.open();

        await documentDownloadRequest.save();

        const { url } = documentDownloadRequest;

        if (url && windowOpenReference) {
            windowOpenReference.location = url;
        }
    }

    get earnestMoneyAmount(): string | undefined {
        return this.args.earnestMoneyDetail?.earnestMoneyAmount?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });
    }
}
