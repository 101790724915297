import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';


export default class Callback extends BaseRoute {
    @service
    declare auth: AuthService;

    @service
    declare store: Store;

    @service
    declare router: RouterService;

    async beforeModel() {
        try {
            await this.auth.afterAuthentication();
            if (isEmpty(this.auth.getLastAttemptedTransition())) {
                this.router.replaceWith('/deals');
            }
        } catch (error) {
            console.log(error);
            this.auth.logout();
        }
    }
}
