import Model, { attr } from '@ember-data/model';

export enum EarnestMoneyDepositMethodRuleId {
    check = 'check',
    wire = 'wire',
    portal = 'portal'
}

export enum EarnestMoneyDepositMethodRuleValue {
    disabled = 'disabled',
    enabled = 'enabled',
    hidden = 'hidden'
}

export default class EarnestMoneyDepositMethodRule extends Model {
    @attr('string')
        value?: EarnestMoneyDepositMethodRuleValue;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'earnest-money-deposit-method-rules': EarnestMoneyDepositMethodRule;
    }
}
