import AuthService from 'portal/services/auth';
import RouterService from '@ember/routing/router-service';
import Service from '@ember/service';
import User from 'portal/models/user';
import { Metrics as EmberMetrics } from 'ember-metrics';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';

export enum AnalyticsProviders {
    GOOGLE_ANALYTICS = 'GoogleAnalytics',
    MIXPANEL = 'Mixpanel'
}

export default class Analytics extends Service {
    @service
    declare auth: AuthService;

    @service
    declare router: RouterService;

    @service
    declare metrics: EmberMetrics;

    identify(user: User): void {
        this.metrics.identify(AnalyticsProviders.MIXPANEL, {
            distinctId: user.id,
            '$email': user.email,
            '$name': user.fullName
        });
    }

    trackPage(event: string, props: Record<string, unknown> = {}): void {
        next(() => {
            const url = this.router.currentURL;

            this.metrics.trackPage(AnalyticsProviders.GOOGLE_ANALYTICS, {
                page: url,
                title: event,
                ...props
            });

            this.metrics.trackPage(AnalyticsProviders.MIXPANEL, {
                event,
                unique_id: this.auth.user?.email,
                ...props
            });
        });
    }

    trackEvent(event: string, props: Record<string, unknown> = {}): void {
        next(() => {
            const url = this.router.currentURL;

            this.metrics.trackEvent(AnalyticsProviders.GOOGLE_ANALYTICS, {
                page: url,
                title: event,
                eventCategory: 'button',
                eventAction: 'click',
                ...props
            });

            this.metrics.trackEvent(AnalyticsProviders.MIXPANEL, {
                event,
                unique_id: this.auth.user?.email,
                ...props
            });
        });
    }
}

declare module '@ember/service' {
    interface Registry {
        mixpanel: Analytics;
    }
}
