import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import DealSide from 'portal/services/deal-side';
import ProtectedDealRoute from 'portal/routes/protected/deal';
import RSVP from 'rsvp';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import Transition from '@ember/routing/-private/transition';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealTitleholderInformationMultiselectIndividualTitleholder extends BaseRoute {
    @service
    declare router: RouterService;

    @service
    declare dealSide: DealSide;

    @service
    declare analytics: Analytics;

    @service
    declare auth: AuthService;


    @service
    declare store: Store;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Titleholder Information / Multiselect Individual Titleholder');
    }

    async model() {
        const deal = this.modelFor(
            'protected.deal'
        ) as ModelFrom<ProtectedDealRoute>;

        const legalIndividuals = await this.store.findAll('legal-individual');
        const dealSide = this.dealSide.for(deal.id);
        const user = await this.auth.getAuthenticatedUser();

        let initialTitleholder;
        let titleholders = (await deal.titleholdersForSide(dealSide)).toArray();

        if (titleholders?.length === 0 && legalIndividuals.length > 0) {
            titleholders = legalIndividuals.map((legalIndividual) => {
                const titleholder = this.store.createRecord(
                    'individual-titleholder',
                    {
                        deal,
                        dealSide,
                        firstName: legalIndividual.firstName,
                        middleName: legalIndividual.middleName,
                        lastName: legalIndividual.lastName,
                        suffix: legalIndividual.suffix,
                        email: legalIndividual.email,
                        phoneNumber: legalIndividual.phoneNumber,
                        birthday: legalIndividual.birthday,
                        taxId: legalIndividual.taxId,
                        isMarried: legalIndividual.isMarried,
                        street: legalIndividual.street,
                        street2: legalIndividual.street2,
                        city: legalIndividual.city,
                        state: legalIndividual.state,
                        zipcode: legalIndividual.zipcode,
                        inviteToDeal: false,
                        addToDealAsTitleholder: false,
                        legalIndividual
                    }
                );

                if (titleholder.isMarried) {
                    titleholder.spouseFirstName = legalIndividual.spouseFirstName;
                    titleholder.spouseMiddleName = legalIndividual.spouseMiddleName;
                    titleholder.spouseLastName = legalIndividual.spouseLastName;
                    titleholder.spouseSuffix = legalIndividual.spouseSuffix;
                    titleholder.spouseTaxId = legalIndividual.spouseTaxId;
                    titleholder.spouseEmail = legalIndividual.spouseEmail;
                }

                if (legalIndividual.userId === user.id) {
                    titleholder.addToDealAsTitleholder = true;
                    initialTitleholder = titleholder;
                }

                return titleholder;
            });
        } else {
            initialTitleholder = await deal.latestTitleholder(dealSide);
        }

        return RSVP.hash({
            deal,
            titleholders,
            initialTitleholder
        });
    }

    @action
    async willTransition(transition: Transition) {
        if (
            transition.to.name.includes(
                'protected.deal.titleholder-information.multiselect-individual-titleholder'
            ) ||
            transition.to.name.includes(
                'protected.deal.titleholder-information.new-individual'
            )
        ) {
            return;
        } else {
            this.modelFor(this.routeName).titleholders.forEach((titleholder: any) => {
                titleholder.rollbackAttributes();
            });
        }
    }
}
