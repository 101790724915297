import ActionItem from 'portal/adapters/action-item';

export default class BuyerTitleholderAndFinancingInformationActivity extends ActionItem {}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
    export default interface AdapterRegistry {
        'buyer-titleholder-and-financing-information-activity': BuyerTitleholderAndFinancingInformationActivity
    }
}
