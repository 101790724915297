import * as Sentry from '@sentry/ember';
import Application from '@ember/application';
import Resolver from 'ember-resolver';
import breadcrumbDecorator from 'portal/utils/breadcrumb-decorator';
import config from 'portal/config/environment';
import loadInitializers from 'ember-load-initializers';
import { BrowserOptions } from '@sentry/browser';

const browserOptions = {
    beforeBreadcrumb: breadcrumbDecorator,
    ignoreErrors: [
        /Network request failed/
    ]
} as BrowserOptions;
Sentry.InitSentryForEmber(browserOptions);

export default class App extends Application {
    modulePrefix = config.modulePrefix;
    podModulePrefix = config.podModulePrefix;
    Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
