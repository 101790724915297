import ActionItem from 'portal/adapters/action-item';

export default class AssignorInformationAndPaymentInstructionsActivity extends ActionItem {}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
    export default interface AdapterRegistry {
        'assignor-information-and-payment-instructions-activity': AssignorInformationAndPaymentInstructionsActivity;
    }
}
