import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import DealRoute from 'portal/routes/protected/deal';
import DealSide, { DealSides } from 'portal/services/deal-side';
import Store from '@ember-data/store';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
export default class ProtectedDealOnboardingRefinanceCreditLines extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare dealSide: DealSide;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Onboarding / Refinance Credit Lines');
    }

    async model() {
        const deal: ModelFrom<DealRoute> = this.modelFor('protected.deal');
        this.dealSide.switchTo(deal.id, DealSides.FUTURE);

        const property = await deal.property;
        const activeDealSide = this.dealSide.for(deal.id);

        const creditLines = await property?.creditLinesForDealSide(activeDealSide);

        if (creditLines?.length === 0) {
            creditLines.push(
                this.store.createRecord('credit-line', {
                    property,
                    dealSide: activeDealSide
                })
            );
        }

        return {
            deal,
            property,
            creditLines
        };
    }

    @action
    invalidateModel(): void {
        this.refresh();
    }
}
