import ActionItem, { ActionItemTemplateKeys } from 'portal/models/action-item';
import Controller from '@ember/controller';
import EntityTitleholder from 'portal/models/entity-titleholder';
import IndividualTitleholder from 'portal/models/individual-titleholder';
import LegalEntity from 'portal/models/legal-entity';
import LegalIndividual from 'portal/models/legal-individual';
import ReissueRate, { reissueRateValidations } from 'portal/models/reissue-rate';
import RouterService from '@ember/routing/router-service';
import { BufferedChangeset } from 'ember-changeset/types';
import { ModelFrom } from 'portal/utils/type-utils';
import {
    default as ProtectedDealOnboardingSellerTitleholderInfoRoute
} from 'portal/routes/protected/deal/onboarding/seller-titleholder-info';
import { TitleholderType } from 'portal/models/titleholder';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { userPhoneNumberOptionalValidations } from 'portal/models/user';

export default class ProtectedDealOnboardingSellerTitleholderInfo extends Controller {
    declare model: ModelFrom<ProtectedDealOnboardingSellerTitleholderInfoRoute>;

    @service
    declare router: RouterService;

    @tracked
    declare addingTitleholder: boolean;

    @tracked
    declare editingTitleholder: IndividualTitleholder | EntityTitleholder | undefined;

    @tracked
    declare selectingTitleholder: boolean;

    @tracked
        availableLegalEntities = this.model.legalEntities as unknown as LegalEntity[];

    @tracked
        availableLegalIndividuals = this.model.legalIndividuals as unknown as LegalIndividual[];

    reissueRateValidations = reissueRateValidations;
    userValidations = userPhoneNumberOptionalValidations;

    get titleholders() {
        return this.model.titleholders;
    }

    @action
    async addTitleholder(): Promise<void> {
        const legalEntities = this.model.legalEntities;
        const legalIndividuals = this.model.legalIndividuals;
        const totalAvailableTitleholders = (legalEntities.length as number) + (legalIndividuals.length as number);

        this.addingTitleholder = totalAvailableTitleholders == 0;
        this.selectingTitleholder = totalAvailableTitleholders > 0;
        window.scrollTo(0, 0);
    }

    @action
    async editTitleholder(titleholder: IndividualTitleholder | EntityTitleholder): Promise<void> {
        this.editingTitleholder = titleholder;
        window.scrollTo(0, 0);
    }

    @action
    async onCancel(): Promise<void> {
        this.addingTitleholder = false;
        this.editingTitleholder = undefined;
        this.selectingTitleholder = false;
        window.scrollTo(0, 0);
    }

    @action
    async onSubmit(userChangeset: BufferedChangeset): Promise<void> {
        const { deal, reissueRate, titleholders } = this.model;

        const reissueRatePolicyDocument = await reissueRate?.currentOwnersPolicyDocument;
        if (reissueRatePolicyDocument?.hasDirtyAttributes) {
            await reissueRatePolicyDocument?.save();
        }
        if ((reissueRate as ReissueRate).hasDirtyAttributes) {
            await reissueRate?.save();
        }
        if (userChangeset.isDirty) {
            await userChangeset.save();
        }

        const titleholdersToSave = titleholders.filter(
            (th) => th.hasDirtyAttributes || th.isDeleted
        ) as Array<IndividualTitleholder | EntityTitleholder>;

        for (const titleholderToSave of titleholdersToSave) {
            if (titleholderToSave instanceof IndividualTitleholder) {
                await titleholderToSave.save();
            } else {
                const savedEntityTitleholder = await titleholderToSave.save() as EntityTitleholder;
                const designatedSignersToSave = savedEntityTitleholder.designatedSigners?.toArray();
                if (designatedSignersToSave) {
                    await Promise.all(
                        designatedSignersToSave.map((designatedSignerToSave) => {
                            designatedSignerToSave.entityTitleholderId = savedEntityTitleholder.id;
                            designatedSignerToSave.save();
                        })
                    );
                }
            }
        }

        const actionItems = await deal.actionItems;
        const actionItem = actionItems?.find((a: ActionItem) =>
            a.templateKey == ActionItemTemplateKeys.SELLER_TITLEHOLDER_DETAILS
        );

        if (actionItem) {
            actionItem.hasCompleted = true;
            await actionItem.save();
            this.router.transitionTo('protected.deal.action-item-completion', actionItem.id);
        } else {
            this.router.transitionTo('protected.deals');
        }
    }

    @action
    async resetController(): Promise<void> {
        this.availableLegalEntities = Array.from(this.model.legalEntities.toArray()).sortBy('entityName');
        this.availableLegalIndividuals = Array.from(this.model.legalIndividuals.toArray());

        await this.onCancel();
    }

    @action
    async removeTitleholder(titleholder: IndividualTitleholder | EntityTitleholder): Promise<void> {
        titleholder.deleteRecord();
        this.titleholders.removeObject(titleholder);

        if (titleholder.type == TitleholderType.individual) {
            const legalIndividualToMakeAvailable = this.model.legalIndividuals.toArray().find(
                (legalIndividual) => legalIndividual.email === (titleholder as any).email
            );
            if (legalIndividualToMakeAvailable) {
                this.availableLegalIndividuals.push(legalIndividualToMakeAvailable);
            }
        } else {
            const legalEntityToMakeAvailable = this.model.legalEntities.toArray().find(
                (legalEntity) => legalEntity.email === (titleholder as any).email
            );
            if (legalEntityToMakeAvailable) {
                this.availableLegalEntities.push(legalEntityToMakeAvailable);
            }
            this.availableLegalEntities = this.availableLegalEntities.sortBy('entityName');
        }
    }

    @action
    async titleholderAdded(titleholder: IndividualTitleholder | EntityTitleholder): Promise<void> {
        await this.titleholdersAdded([titleholder]);
        window.scrollTo(0, 0);
    }

    @action
    async titleholderEdited(): Promise<void> {
        this.editingTitleholder = undefined;
        window.scrollTo(0, 0);
    }

    @action
    async titleholdersAdded(titleholders: (IndividualTitleholder | EntityTitleholder)[]): Promise<void> {
        for (const titleholder of titleholders) {
            if (titleholder.type == TitleholderType.individual) {
                this.availableLegalIndividuals = this.availableLegalIndividuals.filter(
                    (element) => !(element.firstName == (titleholder as any).firstName && element.lastName == (titleholder as any).lastName)
                );
            } else {
                this.availableLegalEntities = this.availableLegalEntities.filter(
                    (element) => element.entityName != (titleholder as any).entityName
                );
            }

            this.removeAlreadyAddedTitleholder(titleholder);

            if (!this.model.titleholders.includes(titleholder)) {
                this.model.titleholders.addObject(titleholder);
            }
        }
        this.editingTitleholder = undefined;
        this.addingTitleholder = false;
        this.selectingTitleholder = false;
        window.scrollTo(0, 0);

    }

    removeAlreadyAddedTitleholder(titleholder: IndividualTitleholder | EntityTitleholder) {
        if (titleholder.type == TitleholderType.individual) {
            this.availableLegalIndividuals = this.availableLegalIndividuals.filter(
                (element) => element.email !== (titleholder as any).email
            );
        } else {
            this.availableLegalEntities = this.availableLegalEntities.filter(
                (element) => element.email != (titleholder as any).email
            );
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'protected/deal/onboarding/seller-titleholder-info': ProtectedDealOnboardingSellerTitleholderInfo;
  }
}
