import Deal from 'portal/models/deal';
import Model, { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import User from 'portal/models/user';

export default class Invite extends Model {
    @attr('boolean')
        accepted!: boolean;

    @attr('string')
        greetingName!: string;

    @attr('string')
        email!: string;

    @belongsTo('user')
        inviter!: AsyncBelongsTo<User>;

    @belongsTo('deal')
        deal!: AsyncBelongsTo<Deal>;

    @belongsTo('user')
        invitee!: AsyncBelongsTo<User>;
}

declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        invite: Invite;
    }
}
