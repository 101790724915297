export default {
    time: {
        hhmmss: {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }
    },
    date: {
        hhmmss: {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }
    },
    number: {
        compact: { notation: 'compact' },
        EUR: {
            style: 'currency',
            currency: 'EUR'
        },
        USD: {
            style: 'currency',
            currency: 'USD'
        }
    }
};
