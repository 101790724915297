import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import ProtectedDealRoute from 'portal/routes/protected/deal';
import RSVP from 'rsvp';
import Store from '@ember-data/store';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealOnboardingEditEntity extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Onboarding / Edit Entity');
    }

    async model({ legal_entity_id }: {legal_entity_id: string}) {
        const deal = this.modelFor('protected.deal') as ModelFrom<ProtectedDealRoute>;
        const legalEntity = this.store.findRecord('legalEntity', legal_entity_id);

        return RSVP.hash({
            deal,
            legalEntity,
            wireInstructionsRequired: false
        });
    }
}
