import Controller from '@ember/controller';
import CreditLine, {
    creditLineValidations
} from 'portal/models/credit-line';
import DealSide from 'portal/services/deal-side';
import Property, { propertyValidations, sellerPropertyValidations } from 'portal/models/property';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { BufferedChangeset, lookupValidator } from 'validated-changeset';
import { Changeset } from 'ember-changeset';
import { DealActorRoles } from 'portal/models/deal';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as PropertyInformationRoute } from 'portal/routes/protected/deal/onboarding/property-information';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class ProtectedDealOnboardingPropertyInformation extends Controller {
    declare model: ModelFrom<PropertyInformationRoute>;

    @service
    declare router: RouterService;

    @service
    declare store: Store;

    @service
    declare dealSide: DealSide;

    private creditLineChangesetCache = new WeakMap<
        CreditLine,
        BufferedChangeset
    >();
    private propertyChangesetCache = new WeakMap<Property, BufferedChangeset>();

    get propertyChangeset(): BufferedChangeset | undefined {
        if (!this.model.property) return undefined;

        const validations = this.actorRole === DealActorRoles.SELLER ? sellerPropertyValidations : propertyValidations;

        let changeset = this.propertyChangesetCache.get(this.model.property);
        if (!changeset) {
            changeset = Changeset(
                this.model.property,
                lookupValidator(validations),
                validations,
                {
                    changesetKeys: Object.keys(Property.attributes)
                }
            );
            // eslint-disable-next-line ember/no-side-effects
            this.propertyChangesetCache.set(this.model.property, changeset);
        }
        return changeset;
    }

    get nonDeletedCreditLines() {
        return this.model.creditLines?.filterBy('isDeleted', false) || [];
    }

    get creditLinesChangesets(): BufferedChangeset[] {
        return this.nonDeletedCreditLines.toArray().map((creditLine) => {
            let changeset = this.creditLineChangesetCache.get(creditLine);
            if (!changeset) {
                changeset = Changeset(
                    creditLine,
                    lookupValidator(creditLineValidations),
                    creditLineValidations,
                    {
                        changesetKeys: Object.keys(CreditLine.attributes)
                    }
                );
                // eslint-disable-next-line ember/no-side-effects
                this.creditLineChangesetCache.set(creditLine, changeset);
            }
            return changeset;
        });
    }

    get actorRole() {
        return this.model.deal.actorRole;
    }

    @task
    async saveProperty(): Promise<void> {
        if (!this.propertyChangeset || this.propertyChangeset?.isInvalid)
            throw new Error(
                `Property is invalid: ${JSON.stringify(
                    this.propertyChangeset?.errors
                )}`
            );
        if (
            this.creditLinesChangesets?.any(
                (creditlineChangeset) => creditlineChangeset.isInvalid
            )
        )
            throw new Error(
                `CreditLine is invalid: ${JSON.stringify(
                    this.creditLinesChangesets.map(
                        (creditLineChangeset) => creditLineChangeset?.errors
                    )
                )}`
            );
        this.creditLinesChangesets.map((creditLineChangeset) =>
            creditLineChangeset.execute()
        );
        const creditLines = await this.model.creditLines;
        if (creditLines) {
            await Promise.all(
                creditLines.map((creditLine) => creditLine.save())
            );
        }
        await this.propertyChangeset.save();

        await this.onNext();
    }

    async onNext() {
        if (this.actorRole === DealActorRoles.BORROWER) {
            this.router.transitionTo('protected.deal.onboarding.refinance-pacer');
        } else {
            this.router.transitionTo('protected.deal.onboarding.cpc-notary-intermediary');
        }
    }

    @action
    creditLinesOnChange(value: boolean) {
        if (value) {
            this.creditLinesAddAnother();
        } else {
            this.model.creditLines
                ?.toArray()
                .forEach((creditLine: CreditLine) => {
                    this.creditLineChangesetCache.delete(creditLine);
                    creditLine.deleteRecord();
                });
        }
    }

    @action
    creditLinesAddAnother() {
        this.store.createRecord('credit-line', {
            property: this.model.property,
            dealSide: this.dealSide.for(this.model.deal.id)
        });
        this.send('invalidateModel');
    }

    @action
    creditLineOnDelete(creditLineChangeset: BufferedChangeset) {
        const creditLine = creditLineChangeset.data as CreditLine;
        this.creditLineChangesetCache.delete(creditLine);
        creditLine.deleteRecord();
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/deal/onboarding/property-information': ProtectedDealOnboardingPropertyInformation;
    }
}
