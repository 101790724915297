import AuthService from 'portal/services/auth';
import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import DealSide, { DealSides } from 'portal/services/deal-side';
import EntityTitleholder from 'portal/models/entity-titleholder';
import IndividualTitleholder from 'portal/models/individual-titleholder';
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { isNone } from '@ember/utils';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

interface TitleholderTypeArgs {
    disabled?: boolean;
    deal: Deal;
    onIndividualSelected?: () => unknown;
    onEntitySelected?: (value: EntityTitleholder) => unknown;
    onCloneSelected?: () => unknown;
    showSkip?: boolean;
    onSkipSelected?: () => unknown;
}

export enum TitleholderTypeOptions {
    SAME = 'SAME',
    INDIVIDUAL = 'INDIVIDUAL',
    ENTITY = 'ENTITY',
    SKIP = 'SKIP'
}

export default class TitleholderType extends Component<TitleholderTypeArgs> {
    @service
    declare auth: AuthService;

    @service
    declare store: Store;

    @service
    declare dealSide: DealSide;

    @tracked
        titleholder?: IndividualTitleholder | EntityTitleholder;

    @tracked
        titleholderType?: TitleholderTypeOptions | undefined = undefined;

    @tracked
        showErrorOnPristine?: boolean = false;

    @tracked
        showErrorBanner?: boolean = false;

    get actorRole() {
        return this.args.deal.actorRole;
    }

    get activeDealSide() {
        return this.dealSide.for(this.args.deal.id);
    }

    get showSkip() {
        return isNone(this.args.showSkip) || this.args.showSkip;
    }

    @action
    async onChange(value: TitleholderTypeOptions) {
        this.titleholderType = value;
    }

    @task
    async onGetStarted() {
        this.showErrorOnPristine = true;

        if (isNone(this.titleholderType)) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        if (this.titleholder?.isNew) {
            this.titleholder.rollbackAttributes();
            this.titleholder = undefined;
        }

        switch (this.titleholderType) {
        case TitleholderTypeOptions.INDIVIDUAL: {
            this.args.onIndividualSelected?.();
            break;
        }
        case TitleholderTypeOptions.ENTITY: {
            this.titleholder = this.store.createRecord('entity-titleholder', {
                deal: this.args.deal,
                dealSide: this.activeDealSide
            });
            this.args.onEntitySelected?.(this.titleholder);
            break;
        }
        case TitleholderTypeOptions.SAME: {
            this.store.createRecord('deals-clone-titleholder-request', {
                deal: this.args.deal,
                fromSide: DealSides.CURRENT,
                toSide: DealSides.FUTURE
            }).save();
            this.args.onCloneSelected?.();
            break;
        }
        case TitleholderTypeOptions.SKIP: {
            this.args.onSkipSelected?.();
            break;
        }
        }
    }
}
