import Controller from '@ember/controller';
import GuidedAccountCreationSection,  { Section, Status } from 'portal/models/guided-account-creation-section';
import RouterService from '@ember/routing/router-service';
import { ModelFrom } from 'portal/utils/type-utils';
import {
    default as ProtectedAccountCreationFlowPersonalDetailsRoute
} from 'portal/routes/protected/account-creation-flow/personal-details';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { userValidations } from 'portal/models/user';

export default class ProtectedAccountCreationFlowPersonalDetails extends Controller {
    declare model: ModelFrom<ProtectedAccountCreationFlowPersonalDetailsRoute>;

    validations = userValidations;

    @service
    declare router: RouterService;

    @action
    async afterSave(): Promise<void> {
        await this.onComplete(Status.completed);
    }

    @action
    async cancel(): Promise<void> {
        await this.onComplete(Status.canceled);
    }

    async onComplete(status: Status): Promise<void> {

        const personalDetailsSection = this.model.guidedAccountCreationSections.findBy('section', Section.personal_details);

        if (personalDetailsSection) {
            personalDetailsSection.status = status;

            await personalDetailsSection.save();
        }

        const allSectionsFinished = this.model.guidedAccountCreationSections.every(
            (section: GuidedAccountCreationSection) => section.finished);

        if (allSectionsFinished) {
            this.router.transitionTo('protected.account-creation-flow.completed');
        } else {
            this.router.transitionTo('protected.account-creation-flow.home');
        }
    }

}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/account-creation-flow/personal-details': ProtectedAccountCreationFlowPersonalDetails;
    }
}
