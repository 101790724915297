import Component from '@glimmer/component';
import DwollaTransfer from 'portal/models/dwolla-transfer';

interface MyAccountEarnestTransactionsArgs {
    dwollaTransfers: DwollaTransfer[];
}

export default class MyAccountEarnestTransactions extends Component<MyAccountEarnestTransactionsArgs> {

    get transfersSortedByDate() {
        return this.args.dwollaTransfers.sortBy('transferDate').reverse();
    }

}
