import Analytics from 'portal/services/analytics';
import Component from '@glimmer/component';
import DocumentModel, { DocumentTypes } from 'portal/models/document';
import EmporaDocumentUpload from 'portal/components/empora/document-upload/component';
import { BufferedChangeset } from 'ember-changeset/types';
import { DealActorRoles } from 'portal/models/deal';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';

interface PropertyInformationArgs {
    actorRole: DealActorRoles,
    creditLineOnDelete: (creditLineChangeset: BufferedChangeset) => void;
    creditLinesChangesets: BufferedChangeset[];
    creditLinesOnChange: (value: boolean) => void;
    creditLinesOnClick: () => void;
    disabled: boolean;
    onNext: () => void;
    propertyChangeset: BufferedChangeset;
}

export default class PropertyInformation extends Component<PropertyInformationArgs> {
    @service
    declare analytics: Analytics;

    @tracked
        hasHoa?: boolean;

    @tracked
        hasTrashService?: boolean;

    @tracked
        hasWastewaterService?: boolean;

    @tracked
        hasCreditLines?: boolean;

    @tracked
        isRental?: boolean;

    @tracked
        leaseDocuments?: Array<DocumentModel> = [];

    @tracked
        rentRollDocuments?: Array<DocumentModel> = [];

    @tracked
        showErrorBanner?: boolean = false;

    constructor(owner: unknown, args: PropertyInformationArgs) {
        super(owner, args);

        if (args.creditLinesChangesets?.length > 0) {
            this.hasCreditLines = true;
        }
    }

    get disableFields(): boolean {
        return (
            this.args.disabled ||
            taskFor(this.saveLeaseDocument).isRunning ||
            taskFor(this.saveRentRollDocument).isRunning
        );
    }

    get disabledNext(): boolean {
        return (
            this.args.disabled ||
            taskFor(this.saveLeaseDocument).isRunning ||
            taskFor(this.saveRentRollDocument).isRunning
        );
    }

    get requiresCreditLineAnswer() {
        return true;
    }

    get requiresHoaAnswer() {
        return true;
    }

    get requiresRentalAnswer() {
        return this.args.actorRole === DealActorRoles.SELLER;
    }

    @action
    creditLinesOnChange(value: boolean) {
        this.hasCreditLines = value;
        this.args.propertyChangeset.hasCreditLines = value;
        this.args.creditLinesOnChange(value);
    }

    @action
    hoaOnChange(value: boolean) {
        this.hasHoa = value;

        this.args.propertyChangeset.hasHoa = value;
        if (!this.hasHoa) {
            this.args.propertyChangeset.set('managementCompanyEmail', null);
            this.args.propertyChangeset.set('managementCompanyName', null);
            this.args.propertyChangeset.set('managementCompanyPhoneNumber', null);
        }
    }

    @action
    onDeleteLeaseDocument(document: DocumentModel) {
        this.leaseDocuments?.removeObject(document);
    }

    @action
    onDeleteRentRollDocument(document: DocumentModel) {
        this.rentRollDocuments?.removeObject(document);
    }

    @action
    async onLeaseDocumentUpload(document: DocumentModel, documentUpload: EmporaDocumentUpload) {
        try {
            await taskFor(this.saveLeaseDocument).perform(document);
        } catch (e) {
            return;
        } finally {
            documentUpload.clearDocument();
        }

        this.leaseDocuments?.pushObject(document);
    }

    @action
    onLeaseDownload() {
        this.analytics.trackEvent('Property Information - Lease downloaded');
    }

    @action
    async onRentRollDocumentUpload(document: DocumentModel, documentUpload: EmporaDocumentUpload) {
        try {
            await taskFor(this.saveRentRollDocument).perform(document);
        } catch (e) {
            return;
        } finally {
            documentUpload.clearDocument();
        }

        this.rentRollDocuments?.pushObject(document);
    }

    @action
    onRentRollDownload() {
        this.analytics.trackEvent('Property Information - Rent roll downloaded');
    }

    @action
    async onNext() {
        this.args.propertyChangeset.set('showErrorOnPristine', true);
        this.args.creditLinesChangesets?.forEach((changeset) => {
            changeset.set('showErrorOnPristine', true);
        });

        this.args.propertyChangeset.set('leaseDocuments', this.leaseDocuments);
        this.args.propertyChangeset.set('rentRollDocuments', this.rentRollDocuments);

        if (this.args.propertyChangeset?.isInvalid) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        if (this.args.creditLinesChangesets?.any((creditlineChangeset) => creditlineChangeset.isInvalid)) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        await this.args.onNext();
    }

    @action
    rentalOnChange(value: boolean) {
        this.isRental = value;
        this.args.propertyChangeset.isRental = value;
    }

    @action
    trashServiceOnChange(value: boolean) {
        this.hasTrashService = value;

        this.args.propertyChangeset.hasTrashService = value;
        if (!this.hasTrashService) {
            this.args.propertyChangeset.set('trashCompany', null);
            this.args.propertyChangeset.set('trashAccountNumber', null);
        }
    }

    @action
    validate() {
        this.args.propertyChangeset.set('showErrorOnPristine', false);
        this.args.creditLinesChangesets?.forEach((changeset) => {
            changeset.set('showErrorOnPristine', false);
        });

        this.args.propertyChangeset.validate();
    }

    @action
    wastewaterServiceOnChange(value: boolean) {
        this.hasWastewaterService = value;

        this.args.propertyChangeset.hasWastewaterService = value;
        if (!this.hasWastewaterService) {
            this.args.propertyChangeset.set('wastewaterCompany', null);
            this.args.propertyChangeset.set('wastewaterAccountNumber', null);
        }
    }

    @task
    async saveLeaseDocument(doc: DocumentModel) {
        await this._saveDocument(doc, DocumentTypes.lease_document);
    }

    @task
    async saveRentRollDocument(doc: DocumentModel) {
        await this._saveDocument(doc, DocumentTypes.rent_roll_document);
    }

    // This function is wrapped by two different task definitions in order to render their errors separately.
    async _saveDocument(doc: DocumentModel, type: DocumentTypes) {
        doc.type = type;
        await doc.save();
    }
}
