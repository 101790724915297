import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import BaseRoute from 'portal/routes/base-route';
import Store from '@ember-data/store';
import Transition from '@ember/routing/-private/transition';
import lookupValidator from 'ember-changeset-validations';
import { Changeset } from 'ember-changeset';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { individualTitleholderValidations } from 'portal/models/individual-titleholder';
import { inject as service } from '@ember/service';

export default class ProtectedMyAccountEditLegalIndividual extends BaseRoute {
    @service
    declare analytics: Analytics;

    @service
    declare store: Store;

    @service
    declare auth: AuthService;

    @action
    didTransition() {
        this.analytics.trackPage('Viewed Edit Legal Individual');
    }

    @action
    async willTransition(transition: Transition) {
        const legalIndividual = await (this.modelFor(
            this.routeName
        ) as ModelFrom<ProtectedMyAccountEditLegalIndividual>).legalIndividual;

        const legalIndividualChangeset = Changeset(
            legalIndividual,
            lookupValidator(individualTitleholderValidations),
            individualTitleholderValidations
        );

        legalIndividualChangeset.validate();
        if (legalIndividualChangeset.isInvalid){
            legalIndividual.rollbackAttributes();
            return;
        }

        if (
            transition.to.name.includes(
                'protected.my-account.my-titleholders'
            ) ||
            transition.to.name.includes(
                'protected.my-account.edit-individual'
            )
        ) {
            return;
        } else {
            legalIndividual.rollbackAttributes();
        }

    }

    async model({ legal_individual_id }: { legal_individual_id: string }) {
        const user = await this.auth.getAuthenticatedUser();

        const legalIndividual = await this.store.findRecord('legal-individual', legal_individual_id);

        return {
            user,
            legalIndividual
        };
    }
}
