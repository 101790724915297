import AuthService from 'portal/services/auth';
import Component from '@glimmer/component';
import { BufferedChangeset } from 'ember-changeset/types';
import { Role } from 'portal/models/team';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export interface ProfileMyTeamArgs {
    teamChangeset: BufferedChangeset;
    teamMemberChangeset: BufferedChangeset;
    onDelete: (teamMemberChangeset: BufferedChangeset) => void;
    onRoleChange(teamMemberChangeset:  BufferedChangeset): () => void;
}

export default class ProfileMyTeam extends Component<ProfileMyTeamArgs> {
    @service
        auth!: AuthService;

    @tracked
        isDeleteDialogOpen?: boolean = false;

    get isUser() {
        return this.args.teamChangeset.role === Role.user;
    }

    get dropdownOptions() {
        return [
            { name: 'Admin', value: Role.admin },
            { name: 'User', value: Role.user }
        ];
    }

    @action
    showDeleteDialog() {
        this.isDeleteDialogOpen = true;
    }

    @action
    hideDeleteDialog() {
        this.isDeleteDialogOpen = false;
    }

    @action
    onDelete(teamMemberChangeset: BufferedChangeset){
        this.args.onDelete(teamMemberChangeset);

        this.hideDeleteDialog();
    }
}
