import Controller from '@ember/controller';
import IndividualTitleholder from 'portal/models/individual-titleholder';
import ProtectedDealOnboardingIndividualMultiselectTitleholder
    from 'portal/routes/protected/deal/onboarding/individual-multiselect-titleholder';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealIndividualMultiselectTitleholder extends Controller {
    declare model: ModelFrom<ProtectedDealOnboardingIndividualMultiselectTitleholder>;

    @service
    declare router: RouterService;

    @service
    declare store: Store;

    @action
    async onNext(individualTitleHolders: Array<IndividualTitleholder>) {
        await Promise.all(individualTitleHolders.map(async (titleholder: IndividualTitleholder) => {
            if (titleholder.addToDealAsTitleholder) {
                await titleholder.save();
            }
        }));

        const { initialTitleholder, deal } = this.model;

        if (initialTitleholder?.isCurrent) {
            this.router.transitionTo('protected.deal.onboarding.property-information');
        } else if (deal.isBorrower) {
            this.router.transitionTo('protected.deal.onboarding.refinance-credit-lines');
        } else {
            this.router.transitionTo('protected.deal.onboarding.lender-tax-address-info');
        }
    }

    @action
    onAdd() {
        this.router.transitionTo('protected.deal.onboarding.individual-titleholder');
    }
}