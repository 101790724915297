import Component from '@glimmer/component';
import { action } from '@ember/object';
import { maskPhoneNumber } from 'portal/helpers/mask-phone-number';
import { tracked } from '@glimmer/tracking';
import { unmaskPhoneNumber } from 'portal/helpers/unmask-phone-number';

interface EmporaPhoneNumberArgs {
    value?: string;
    onInput?: (value: string) => void;
}

export default class EmporaPhoneNumber extends Component<EmporaPhoneNumberArgs> {
    constructor(owner: unknown, args: EmporaPhoneNumberArgs) {
        super(owner, args);
        if (this.args.value) {
            this.value = maskPhoneNumber(this.args.value);
        } else {
            this.value = '';
        }
    }

    @tracked
        value?: string;

    @action
    onInput(value: string): void {
        this.value = maskPhoneNumber(value);
        if (this.args.onInput) {
            this.args.onInput(unmaskPhoneNumber(this.value));
        }
    }
}
