import Component from '@glimmer/component';

export default class EmporaModal extends Component {
    constructor(owner: unknown, args: any) {
        super(owner, args);

        document.addEventListener('keydown', this.focusTabs);
    }

    willDestroy() {
        document.removeEventListener('keydown', this.focusTabs);
        super.willDestroy();
    }

    focusTabs(e: KeyboardEvent) {
        if (e.code !== 'Tab') { return; }

        const focusableElements = Array.from(
            document.querySelectorAll(`
                #empora-modal__container a[href], 
                #empora-modal__container button:not([disabled]), 
                #empora-modal__container input:not([disabled]),
                #empora-modal__container select:not([disabled]), 
                #empora-modal__container textarea:not([disabled])
                `)
        ) as HTMLButtonElement[];

        e.preventDefault();
        if (focusableElements.length === 0) { return; }

        const [firstFocusableElement] = focusableElements;
        const [lastFocusableElement] = focusableElements.slice(-1);
        const currentlyFocusedIndex = focusableElements.indexOf(document.activeElement as HTMLButtonElement);

        if (currentlyFocusedIndex === -1) {
            firstFocusableElement?.focus();
            return;
        }

        if (e.shiftKey) {
            if (document.activeElement === firstFocusableElement) {
                lastFocusableElement!.focus();
            } else {
                focusableElements[currentlyFocusedIndex -1]?.focus();
            }
        } else {
            if (document.activeElement === lastFocusableElement) {
                firstFocusableElement!.focus();
            } else {
                focusableElements[currentlyFocusedIndex + 1]?.focus();
            }
        }
    }
}