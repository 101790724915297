/* eslint-disable ember/use-ember-data-rfc-395-imports */
import ApplicationAdapter from './application';
import { ModelRegistry } from 'ember-cli-mirage';

export default class DealTeamAdapter extends ApplicationAdapter {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pathForType<K extends keyof ModelRegistry>(_modelName: K): string {
        return 'deals/teams';
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'deal-team': DealTeamAdapter;
  }
}
