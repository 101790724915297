import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import RouterService from '@ember/routing/router-service';
import User from 'portal/models/user';
import WebSocketService from 'portal/services/web-socket';
import { TrackerEventStatus } from 'portal/models/tracker-event';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface DealArgs {
    currentUser: User,
    deals: Deal[];
}

export default class Deals extends Component<DealArgs> {
    @service
        router!: RouterService;

    @service
    declare webSocket: WebSocketService;

    @tracked
        initialDeals: Deal[] = Array.from(this.args.deals);

    @tracked
        sortedDeals: Deal[] = this.initialDeals;

    @tracked
        sortAsc = true;

    @tracked
        sortProperty = 'sortableUltimateClosingDate';

    dropDownOptions = [
        { name: 'Address', value: 'description' },
        { name: 'Status', value: 'trackerEventsCompleted' },
        { name: 'Role', value: 'prettyActorRole' },
        { name: 'Open date', value: 'sortableOpenDate' },
        { name: 'Closing date', value: 'sortableUltimateClosingDate' },
        { name: 'Outstanding tasks', value: 'incompleteActivityCount' }
    ];

    constructor(owner: unknown, args: DealArgs) {
        super(owner, args);
        this.sortedDeals = this.initialDeals.sortBy(this.sortProperty);

        this.webSocket.createSubscription(
            'Deals::Channels::UnreadMessageCountChannel',
            (unreadMessageCountUpdate: any) => { this.receivedUnreadMessageCountUpdate(unreadMessageCountUpdate); },
            { user_id: this.args.currentUser.id }
        );
    }

    @action
    goToDeal(deal: Deal) {
        this.router.transitionTo('protected.deal', deal);
    }

    @action
    sortDeals(property: string) {
        this.sortedDeals = this.args.deals.sortBy(property);
        if (property === this.sortProperty) {
            this.sortAsc = !this.sortAsc;
        } else {
            this.sortAsc = true;
        }
        this.sortProperty = property;
        if (!this.sortAsc) {
            this.sortedDeals = this.sortedDeals.reverse();
        }
    }

    @action
    svgName(property: string) {
        if (property == this.sortProperty) {
            return this.sortAsc ? 'sort-down' : 'sort-up';
        } else {
            return 'sort-blank';
        }
    }

    get trackerEventStatus() {
        return TrackerEventStatus;
    }

    receivedUnreadMessageCountUpdate(unreadMessageCountUpdate: any) {
        const dealToUpdate = this.initialDeals.find((deal) => deal.id === unreadMessageCountUpdate.deal_id);
        if (dealToUpdate) {
            dealToUpdate.unreadMessageCount = unreadMessageCountUpdate.unread_message_count;
            this.sortedDeals = this.initialDeals;
        }
    }
}
