import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { ActionItemTemplateKeys } from 'portal/models/action-item';
import { ModelFrom } from 'portal/utils/type-utils';
import { default as ProtectedDealEarnestMoneyWireRoute } from 'portal/routes/protected/deal/earnest-money/wire';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealEarnestMoneyWire extends Controller {
    declare model: ModelFrom<ProtectedDealEarnestMoneyWireRoute>;

    @service
    declare router: RouterService;

    @action
    async onComplete(): Promise<void> {
        const deal  = await this.model?.deal;
        const actionItems = await deal?.actionItems;
        const actionItem = actionItems?.find((actionItem) => [
            ActionItemTemplateKeys.BUYER_EARNEST_MONEY_METHOD,
            ActionItemTemplateKeys.ASSIGNOR_EARNEST_MONEY_METHOD
        ].includes(actionItem.templateKey as ActionItemTemplateKeys));
        if (actionItem){
            this.router.transitionTo('protected.deal.action-item-completion', actionItem.id);
        } else {
            this.router.transitionTo('protected.deal');
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'protected/deal/earnest-money/wire': ProtectedDealEarnestMoneyWire;
  }
}
