import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import Component from '@glimmer/component';
import DealSide, { DealSides } from 'portal/services/deal-side';
import DocumentModel, { DocumentTypes } from 'portal/models/document';
import EmporaDocumentUpload from 'portal/components/empora/document-upload/component';
import EmporaServer from 'portal/services/empora-server';
import LegalEntityDesignatedSigner from 'portal/models/legal-entity-designated-signer';
import LegalEntityModel, {
    LegalEntityType,
    assignorLegalEntityValidations,
    legalEntityValidations,
    legalEntityValidationsWithTaxId
} from 'portal/models/legal-entity';
import LegalEntityWireInstruction, {
    legalEntityWireInstructionValidations
} from 'portal/models/legal-entity-wire-instruction';
import Store from '@ember-data/store';
import Team from 'portal/models/team';
import TeamMembership from 'portal/models/team-membership';
import User from 'portal/models/user';
import fileTypeList from 'portal/utils/file-type-list';
import { BufferedChangeset } from 'ember-changeset/types';
import { Changeset } from 'ember-changeset';
import { DealActorRoles } from 'portal/models/deal';
import { action } from '@ember/object';
import { lookupValidator } from 'validated-changeset';
import { oneLineAddress } from 'portal/models/basic-address';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';

interface LegalEntityArgs {
    actorRole?: string;
    dealId?: string;
    disabled?: boolean;
    legalEntity: LegalEntityModel;
    wireInstructionsRequired?: boolean;
    onNext: (legalEntity: LegalEntityModel) => void;
    onCancel?: () => void;
}

export default class LegalEntity extends Component<LegalEntityArgs> {
    @service
    declare analytics: Analytics;

    @service
    declare auth: AuthService;

    @service
    declare dealSide: DealSide;

    @service
    declare emporaServer: EmporaServer;

    @service intl!: IntlService;

    @service
    declare store: Store;

    @tracked
        addSignerDisabled = false;

    @tracked
        changeset?: BufferedChangeset;

    @tracked
        designatedSignerBeingEdited?: LegalEntityDesignatedSigner;

    @tracked
        designatedSignerBeingRemoved?: LegalEntityDesignatedSigner;

    @tracked
        hasEngagedWithPrimaryDocumentInput?: boolean;

    @tracked
        isRemoveDesignedSignerModelOpen = false;

    @tracked
        designatedSigners?: LegalEntityDesignatedSigner[];

    @tracked
        newDesignatedSignerTitle?: string;

    @tracked
        newDesignatedSignerUserProfileId?: string;

    @tracked
        showAddDesignatedSignerErrorBanner = false;

    @tracked
        showAddDesignatedSignersForm = false;

    @tracked
        showEditDesignatedSignerErrorBanner = false;

    @tracked
        showEditDesignatedSignersForm = false;

    @tracked
        showErrorBanner?: boolean = false;

    @tracked
        showErrorOnPristine?: boolean = false;

    @tracked
        showWireInstructionErrors = false;

    @tracked
        showWireInstructionForm = false;

    @tracked
        team?: Team;

    @tracked
        teamMemberships: TeamMembership[] = [];

    @tracked
        wireInstructionChangeset?: BufferedChangeset;

    currentUser?: User;

    constructor(owner: unknown, args: LegalEntityArgs) {
        super(owner, args);

        this.changeset?.set('showErrorOnPristine', false);

        if (this.args.legalEntity.id) {
            this.designatedSigners = this.args.legalEntity.designatedSigners?.toArray();
        } else {
            this.designatedSigners = [];
        }
    }

    @action
    async fetchTeamMemberships() {
        this.currentUser = await this.auth.getAuthenticatedUser();
        const teamMemberships = await this.store.query('team-membership', {
            user_profile_id: this.currentUser.id
        });
        this.teamMemberships = teamMemberships.toArray();
        if (this.teamMemberships.length) {
            this.team = await this.store.findRecord('team', this.teamMemberships[0].teamId!);
        }
    }

    @action
    async onOtherDocumentUpload(document: DocumentModel, documentUpload: EmporaDocumentUpload) {
        try {
            await taskFor(this.saveOtherDocument).perform(document);
        } catch (e) {
            return;
        } finally {
            documentUpload.clearDocument();
        }

        this.args.legalEntity.otherDocuments?.pushObject(document);
    }

    @action
    async onPrimaryDocumentUpload(document: DocumentModel, documentUpload: EmporaDocumentUpload) {
        try {
            await taskFor(this.savePrimaryDocument).perform(document);
        } catch (e) {
            return;
        } finally {
            documentUpload.clearDocument();
        }

        this.args.legalEntity.primaryDocuments?.popObject();
        this.args.legalEntity.primaryDocuments?.pushObject(document);
        this.changeset?.validate();
    }

    @action
    async save() {
        this.changeset?.set('showErrorOnPristine', true);
        this.changeset?.validate();

        if (this.shouldSaveWireInstructions && this.args.actorRole !== DealActorRoles.ASSIGNOR) {
            this.wireInstructionChangeset?.validate();
        }

        if (this.changeset?.isInvalid || (this.shouldSaveWireInstructions && this.wireInstructionChangeset?.isInvalid)) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            if (this.wireInstructionChangeset?.isInvalid) {
                this.showWireInstructionForm = true;
                this.showWireInstructionErrors = true;
            }
            return;
        }

        await taskFor(this.saveLegalEntity).perform();
        const savedLegalEntity = await this.store.findRecord(
            'legal-entity', this.changeset?.get('id'), { reload: true }
        );
        this.args.onNext?.(savedLegalEntity);
    }

    @action
    async saveWireInstruction() {
        this.wireInstructionChangeset?.execute();
        this.showWireInstructionForm = false;
    }

    @action
    async validateAndRegister(changeset: BufferedChangeset) {
        this.changeset = changeset;
        const wireInstruction = changeset.get('legalEntityWireInstruction')?.content ?? {};
        this.wireInstructionChangeset = Changeset(
            wireInstruction as LegalEntityWireInstruction,
            lookupValidator(legalEntityWireInstructionValidations),
            legalEntityWireInstructionValidations
        );
        this.wireInstructionChangeset?.validate();
        await this.args.legalEntity.primaryDocuments;
        await this.args.legalEntity.otherDocuments;
        await this.args.legalEntity.legalEntityWireInstruction;
        changeset?.validate();
    }

    @action
    onAddDesignatedSigner() {
        this.showAddDesignatedSignerErrorBanner = false;
        this.newDesignatedSignerTitle = this.newDesignatedSignerTitle?.trim();
        if (!this.newDesignatedSignerUserProfileId || !this.newDesignatedSignerTitle) {
            this.showAddDesignatedSignerErrorBanner = true;
            return;
        }

        let designatedSigner;
        const selectedTeamMembership = this.teamMemberships.find(
            (teamMembership) => teamMembership.userProfileId === this.newDesignatedSignerUserProfileId
        );
        if (selectedTeamMembership) {
            designatedSigner = this.store.createRecord('legal-entity-designated-signer', {
                email: selectedTeamMembership?.email,
                firstName: selectedTeamMembership?.firstName,
                middleName: selectedTeamMembership?.middleName,
                lastName: selectedTeamMembership?.lastName,
                phoneNumber: selectedTeamMembership?.phoneNumber,
                title: this.newDesignatedSignerTitle,
                userProfileId: selectedTeamMembership?.userProfileId
            });
        } else {
            designatedSigner = this.store.createRecord('legal-entity-designated-signer', {
                email: this.currentUser?.email,
                firstName: this.currentUser?.givenName,
                middleName: this.currentUser?.middleName,
                lastName: this.currentUser?.familyName,
                phoneNumber: this.currentUser?.phoneNumber,
                title: this.newDesignatedSignerTitle,
                userProfileId: this.currentUser?.id
            });
        }
        this.designatedSigners?.pushObject(designatedSigner);

        this.addSignerDisabled = this.availableTeamMembershipsForDesignatedSigners.length === 0;

        this.toggleAddDesignatedSignersForm();
    }

    @action
    onDownload() {
        this.analytics.trackEvent('Legal Entity Document Downloaded');
    }

    @action
    onPrimaryDocumentInputClick() {
        this.hasEngagedWithPrimaryDocumentInput = true;
    }

    @action
    onRemoveNewDesignatedSigner() {
        if (this.designatedSignerBeingRemoved!.get('isNew')) {
            this.designatedSigners?.removeObject(this.designatedSignerBeingRemoved!);
        } else {
            this.designatedSignerBeingRemoved!.deleteRecord();
        }
        this.addSignerDisabled = false;
        this.toggleRemoveDesignatedSignerConfirmationModal();
    }

    @action
    onUpdateDesignatedSigner() {
        this.showEditDesignatedSignerErrorBanner = false;
        this.newDesignatedSignerTitle = this.newDesignatedSignerTitle?.trim();
        if (this.newDesignatedSignerTitle) {
            this.designatedSignerBeingEdited!.title = this.newDesignatedSignerTitle;
            this.toggleEditDesignatedSignersForm();
        } else {
            this.showEditDesignatedSignerErrorBanner = true;
        }
    }

    @action
    setNewDesignatedSignerTitle(title: string): void {
        this.newDesignatedSignerTitle = title;
    }

    @action
    setNewDesignatedSignerUserProfileId(userProfileId: string): void {
        this.newDesignatedSignerUserProfileId = userProfileId;
    }

    @action
    toggleAddDesignatedSignersForm() {
        this.showAddDesignatedSignersForm = !this.showAddDesignatedSignersForm;
        if (this.showAddDesignatedSignersForm) {
            this.newDesignatedSignerUserProfileId = undefined;
            this.newDesignatedSignerTitle = undefined;
        }
    }

    @action
    toggleEditDesignatedSignersForm(designatedSignerBeingEdited?: LegalEntityDesignatedSigner) {
        this.showEditDesignatedSignersForm = !this.showEditDesignatedSignersForm;
        if (this.showEditDesignatedSignersForm) {
            this.designatedSignerBeingEdited = designatedSignerBeingEdited;
            this.newDesignatedSignerTitle = this.designatedSignerBeingEdited?.title;
        }
    }

    @action
    toggleRemoveDesignatedSignerConfirmationModal(designatedSignerBeingRemoved?: LegalEntityDesignatedSigner) {
        this.designatedSignerBeingRemoved = designatedSignerBeingRemoved;
        this.isRemoveDesignedSignerModelOpen = !this.isRemoveDesignedSignerModelOpen;
    }

    @action
    toggleWireInstructionForm() {
        this.showWireInstructionForm = !this.showWireInstructionForm;
    }

    @task
    async saveLegalEntity(): Promise<any> {
        this.args.legalEntity.set(
            'legalEntityWireInstruction',
            this.shouldSaveWireInstructions ?
                this.store.createRecord('legalEntityWireInstruction', this.wireInstructionChangeset?.data) : null
        );
        await this.changeset?.save();

        if (this.designatedSigners?.length) {
            const legalEntityId = this.changeset?.get('id');
            for (const designatedSigner of this.designatedSigners) {
                if (designatedSigner.get('isDeleted')) {
                    await designatedSigner.destroyRecord();
                } else {
                    if (designatedSigner.get('isNew')) {
                        designatedSigner.legalEntityId = legalEntityId;
                    }
                    await designatedSigner.save();
                }
            }
        }
    }

    @task
    async saveOtherDocument(doc: DocumentModel) {
        await this._saveDocument(doc);
    }

    @task
    async savePrimaryDocument(doc: DocumentModel) {
        await this._saveDocument(doc);
    }

    get addressString(): string {
        return oneLineAddress({
            street: this.changeset?.get('street'),
            street2: this.changeset?.get('street2'),
            city: this.changeset?.get('city'),
            state: this.changeset?.get('state'),
            zipcode: this.changeset?.get('zipcode')
        });
    }

    get availableTeamMembershipsForDesignatedSigners() {
        const designatedSigners = this.designatedSigners?.toArray().filter((designatedSigner) => {
            return !designatedSigner.get('isDeleted');
        });

        const designatedSignerUserProfileIds = designatedSigners?.map(
            (designatedSigner) => designatedSigner.userProfileId
        );

        const filteredTeamMemberships = this.teamMemberships.filter((teamMembership) => {
            return !designatedSignerUserProfileIds?.includes(teamMembership.userProfileId);
        });

        const currentUserTeamMembership = filteredTeamMemberships.find(
            (teamMembership) => teamMembership.userProfileId === this.currentUser?.id
        );

        let availableTeamMemberships: TeamMembership[] = [];
        if (currentUserTeamMembership) {
            availableTeamMemberships.push(currentUserTeamMembership);
            filteredTeamMemberships.forEach((teamMembership) => {
                if (teamMembership.id !== currentUserTeamMembership.id) {
                    availableTeamMemberships.push(teamMembership);
                }
            });
        } else {
            availableTeamMemberships = filteredTeamMemberships;
        }

        return availableTeamMemberships;
    }

    get newDesignatedSignerUserOptions() {
        if (this.teamMemberships.length > 0) {
            return this.availableTeamMembershipsForDesignatedSigners.map((teamMembership) => {
                let name: string;
                if (teamMembership.userProfileId === this.currentUser?.id) {
                    name = `${this.intl.t('shared.myself')}, ${teamMembership.teamUser}`;
                } else {
                    name = teamMembership.teamUser;
                }
                return {
                    name,
                    value: teamMembership.userProfileId
                };
            });
        }

        return [
            {
                name: `${this.intl.t('shared.myself')}, ${this.currentUser?.fullNameWithEmail}`,
                value: this.currentUser?.id
            }
        ];
    }

    get disableFields(): boolean {
        return (
            !!this.args.disabled &&
            taskFor(this.savePrimaryDocument).isRunning ||
            taskFor(this.saveOtherDocument).isRunning ||
            taskFor(this.saveLegalEntity).isRunning
        );
    }

    get disabledNext(): boolean {
        return (
            this.showWireInstructionForm ||
            this.args.disabled ||
            taskFor(this.savePrimaryDocument).isRunning ||
            taskFor(this.saveOtherDocument).isRunning ||
            taskFor(this.saveLegalEntity).isRunning
        );
    }

    get designatedSignerEmptyOptionLabel(): string {
        if (this.teamMemberships.length > 0) {
            return this.intl.t('components.entity.legal_entity.designated_signers.select_user_from_team', { teamName: this.team?.name });
        } else {
            return this.intl.t('components.entity.legal_entity.designated_signers.select_user');
        }
    }

    get fileTypeList() {
        return fileTypeList();
    }

    get legalEntityTypeOptions() {
        return Object.values(LegalEntityType)
            .filter((value) => !!this.args.legalEntity.id || LegalEntityModel.showOnCreate(value))
            .map((value) => ({ value, name: this.intl.t(LegalEntityModel.displayableTypeKey(value)) }));
    }

    get primaryDocumentDisplay() {
        return LegalEntityModel.primaryDocumentDisplay(this.changeset?.type as LegalEntityType);
    }

    get showAddSignerButton() {
        return !this.showAddDesignatedSignersForm && !this.showEditDesignatedSignersForm;
    }

    get showWireSummary() {
        if (this.showWireInstructionForm) return false;
        if (!this.wireInstructionChangeset) return false;
        return !!this.wireInstructionChangeset.get('bankName') &&
            !!this.wireInstructionChangeset.get('accountNumber');
    }

    get showAddWireInstructionButton() {
        if (this.showWireInstructionForm) return false;
        return !this.showWireSummary;
    }

    get shouldSaveWireInstructions(): boolean {
        return this.args.wireInstructionsRequired || !!this.wireInstructionChangeset?.isDirty;
    }

    get validations(): any {
        if (this.args.actorRole === DealActorRoles.ASSIGNOR) {
            return assignorLegalEntityValidations;
        }
        if (this.args.dealId && this.dealSide.for(this.args.dealId) === DealSides.CURRENT) {
            return legalEntityValidationsWithTaxId;
        }

        return legalEntityValidations;
    }

    // This function is wrapped by two different task definitions in order to render their errors separately.
    async _saveDocument(doc: DocumentModel) {
        doc.type = DocumentTypes.entity_document;
        await doc.save();
    }
}
