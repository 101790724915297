import Analytics from 'portal/services/analytics';
import BaseRoute from 'portal/routes/base-route';
import LegalEntityModel from 'portal/models/legal-entity';
import ProtectedDealRoute from 'portal/routes/protected/deal';
import Store from '@ember-data/store';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealTitleholderInformationEditEntity extends BaseRoute {
    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    @action
    didTransition(): void {
        this.analytics.trackPage('Viewed Deal Titleholder Information / Edit Entity');
    }

    async model({ legal_entity_id }: {legal_entity_id: string}) {
        const deal = this.modelFor('protected.deal') as ModelFrom<ProtectedDealRoute>;
        const legalEntity = await this.store.findRecord('legalEntity', legal_entity_id) as LegalEntityModel;

        return {
            deal,
            legalEntity
        };
    }
}
