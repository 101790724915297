/* eslint-disable ember/use-ember-data-rfc-395-imports */
import ApplicationSerializer from './application';

export default class IndividualTitleholderSerializer extends ApplicationSerializer {
    attrs = {
        powerOfAttorney: { embedded: 'always' }
    };
}

declare module 'ember-data/types/registries/serializer' {
    export default interface SerializerRegistry {
        individualTitleholder: IndividualTitleholderSerializer;
    }
}
