import Analytics from './analytics';
import ApplicationAdapter from 'portal/adapters/application';
import ENV from 'portal/config/environment';
import EmporaServer from 'portal/services/empora-server';
import FeatureFlag from 'portal/services/feature-flag';
import RouteInfo from '@ember/routing/-private/route-info';
import RouteInfoWithAttributes from '@ember/routing/-private/route-info-with-attributes';
import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import User from 'portal/models/user';
import window from 'ember-window-mock';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

type Transition = ReturnType<RouterService['transitionTo']>;

export default abstract class AuthBaseService extends Service {
    @service
    declare analytics: Analytics;

    @service
    declare emporaServer: EmporaServer;

    @service
    declare router: RouterService;

    @service
    declare store: Store;

    @service
    declare featureFlag: FeatureFlag;

    @tracked
        lastAttemptedTransition?: string;
    @tracked
        lastReferrer = '';

    @tracked
    private loggedInUser?: User;

    @tracked
        user?: User;

    @tracked
        token?: any;

    abstract getToken(): Promise<any>;

    abstract isAuthenticated(): Promise<boolean>;
    abstract isEmailVerified(): Promise<boolean>;

    requireAuthentication(transition: Transition) {
        const { queryParams } = transition.to;

        const loginParams: any = {};

        if (queryParams.showSignupScreen == 'true') {
            loginParams.screen_hint = 'signup';
            delete queryParams.showSignupScreen;
        }
        if (queryParams.prefillEmail) {
            loginParams.login_hint = queryParams.prefillEmail;
            delete queryParams.prefillEmail;
        }

        const paramArray: Array<{ [key: string]: string | undefined }> = [];
        let currentTransitionPart: RouteInfo | RouteInfoWithAttributes | null =
            transition.to;
        while (currentTransitionPart !== null) {
            if (
                !isEmpty(currentTransitionPart.paramNames) &&
                currentTransitionPart.params !== undefined
            ) {
                paramArray.unshift(currentTransitionPart.params);
            }
            currentTransitionPart = currentTransitionPart.parent;
        }

        let url = '';
        if (!isEmpty(paramArray)) {
            // @ts-ignore
            url = this.router.urlFor(transition.to.name, ...paramArray, {
                queryParams
            });
        } else {
            url = this.router.urlFor(transition.to.name, { queryParams });
        }

        this.lastAttemptedTransition = url;
        this.lastReferrer = window.document.referrer;
        transition.abort();
        this.login(loginParams);
    }

    abstract login(params?: any): Promise<void>;
    abstract logout(): Promise<void>;

    async afterAuthentication(): Promise<void> {
        this.token = await this.getToken();
        this.user = await this.getAuthenticatedUser();
        this.loggedInUser = (await this.user.impersonator) || this.user;

        this.analytics.identify(this.loggedInUser);
        await this.featureFlag.loadFeatureFlags();
        if (this.lastAttemptedTransition !== undefined) {
            const referrerHost = this.lastReferrer.trim() === '' ? undefined : new URL('/', this.lastReferrer).host;
            const newPortalHost = new URL('/', ENV.REACT_APP_URL).host;
            const wasReferredFromNewPortal = referrerHost === newPortalHost;
            if (this.user.welcomeDealId && !wasReferredFromNewPortal) {
                this.router.replaceWith('protected.deal.onboarding.welcome', this.user.welcomeDealId);
            } else {
                this.router.replaceWith(this.lastAttemptedTransition);
            }
        }
    }

    async getAuthenticatedUser(): Promise<User> {
        const applicationAdapter = this.store.adapterFor(
            'application'
        ) as ApplicationAdapter;
        const result = await this.emporaServer.fetchData(
            applicationAdapter.buildURL('me')
        );

        if (result.status == 401) {
            this.logout();
        }

        const resultJson = await result.json();
        this.store.pushPayload(resultJson);
        const meId = resultJson.data.id;
        return this.store.peekRecord('user', meId) as User;

    }

    isImpersonated(): boolean {
        return !!this.user && this.loggedInUser?.id !== this.user?.id;
    }
}

declare module '@ember/service' {
    interface Registry {
        'auth-base-service': AuthBaseService;
    }
}
