import ActionItem from './action-item';
import CommitmentTitleCommitmentReview from './commitment-title-commitment-review';
import { AsyncBelongsTo, belongsTo } from '@ember-data/model';

export default class CommitmentReviewActivity extends ActionItem {
    @belongsTo('commitment-title-commitment-review')
        commitmentTitleCommitmentReview?: AsyncBelongsTo<CommitmentTitleCommitmentReview> | CommitmentTitleCommitmentReview;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'commitment-review-activity': CommitmentReviewActivity;
    }
}
