import Component from '@glimmer/component';
import IntakeParty from 'portal/models/intake-party';
import IntakeUser, { UserRoleType,  intakeBuyerSellerValidations } from 'portal/models/intake-user';
import Store from '@ember-data/store';
import lookupValidator from 'ember-changeset-validations';
import { BufferedChangeset } from 'ember-changeset/types';
import { Changeset } from 'ember-changeset';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface IntakeBuyerSellerArgs {
  changeset: BufferedChangeset;
  intakeUser: IntakeUser;
  onNext: (changeset: BufferedChangeset, intakeUser: IntakeUser) => void;
  disabled: boolean;
}

export enum BuyerUserRole {
    buyer = 'buyer',
    lender = 'lender',
    no = 'no',
    other_representative = 'other_representative',
    power_of_attorney = 'power_of_attorney',
    real_estate_agent = 'real_estate_agent'
}

export enum SellerUserRole {
    lender = 'lender',
    other_representative = 'other_representative',
    power_of_attorney = 'power_of_attorney',
    real_estate_agent = 'real_estate_agent',
    seller = 'seller'
}

export default class IntakeBuyerSeller extends Component<IntakeBuyerSellerArgs> {
    @service
    declare store: Store;

    @tracked
    declare buyerUserRole: BuyerUserRole;

    @tracked
    declare sellerUserRole: SellerUserRole;

    @tracked
        showErrorBanner?: boolean = false;

    @action
    didInsert() {
        const intakeUser = this.args.intakeUser;

        if (intakeUser.get('userRole') === UserRoleType.real_estate_agent && intakeUser.get('representingRole') === 'buyer') {
            this.setBuyerUserRole(BuyerUserRole.buyer);
        } else {
            this.setBuyerUserRole(BuyerUserRole.no);
        }

        if (intakeUser.get('userRole') !== UserRoleType.seller) {
            this.setSellerUserRole(SellerUserRole.seller);
        }
    }

    @action
    validate() {
        this.args.changeset.get('buyers').forEach((buyer: BufferedChangeset) => {
            buyer.validate();
        });
        this.args.changeset.get('sellers').forEach((seller: BufferedChangeset) => {
            seller.validate();
        });
    }

    @action
    setBuyerUserRole(buyerUserRole: BuyerUserRole) {
        this.buyerUserRole= buyerUserRole;
        const changeset = this.args.changeset;

        if (buyerUserRole === BuyerUserRole.no) {
            const buyers = changeset.get('buyers');
            if (buyers.length > 0) {
                for (const buyer of buyers) {
                    buyer.data.deleteRecord();
                }
                this.args.changeset.set('buyers',[]);
            }
        } else {
            if (changeset.get('buyers').length === 0) {
                this.addBuyer();
            }
            changeset.get('buyers').firstObject.set('userRole', buyerUserRole);
        }

        this.validate();
    }

    @action
    setSellerUserRole(sellerUserRole: SellerUserRole) {
        this.sellerUserRole= sellerUserRole;
        const changeset = this.args.changeset;

        if (changeset.get('sellers').length === 0) {
            this.addSeller();
        }
        changeset.get('sellers').firstObject.set('userRole', sellerUserRole);

        this.validate();
    }

    get buyerContactSubtitle() {
        let who = 'their';
        const buyerUserRole = this.buyerUserRole;

        if (buyerUserRole === BuyerUserRole.buyer) {
            who = 'the buyer’s';
        } else if (buyerUserRole === BuyerUserRole.real_estate_agent) {
            who = 'the real estate agent’s';
        }

        return `Okay, give us ${who} contact information`;
    }

    get sellerContactSubtitle() {
        let who = 'their';
        const sellerUserRole = this.sellerUserRole;

        if (sellerUserRole === SellerUserRole.seller) {
            who = 'the seller’s';
        } else if (sellerUserRole === SellerUserRole.real_estate_agent) {
            who = 'the real estate agent’s';
        }

        return `Okay, give us ${who} contact information`;
    }

    get buyerUserRoleOptions() {
        return [
            { name: 'Buyer', value: BuyerUserRole.buyer },
            { name: 'Real estate agent', value: BuyerUserRole.real_estate_agent },
            { name: 'Power of attorney for Buyer', value: BuyerUserRole.power_of_attorney },
            { name: 'Other buyer point of contact', value: BuyerUserRole.other_representative }
        ];
    }

    get sellerUserRoleOptions() {
        return [
            { name: 'Seller', value: SellerUserRole.seller },
            { name: 'Real estate agent', value: SellerUserRole.real_estate_agent },
            { name: 'Power of attorney for Seller', value: SellerUserRole.power_of_attorney },
            { name: 'Other seller point of contact', value: SellerUserRole.other_representative }
        ];
    }

    @action
    addSeller() {
        const newSeller = Changeset(
            this.store.createRecord('intake-party',{
                representingRole: UserRoleType.seller
            }),
            lookupValidator(intakeBuyerSellerValidations),
            intakeBuyerSellerValidations
        );
        const changeset = this.args.changeset;
        const newSellers = changeset.get('sellers').concat(newSeller);
        changeset.set('sellers',newSellers);
        this.validate();
    }

    @action
    removeSeller(sellerChangeset: BufferedChangeset) {
        const changeset = this.args.changeset;
        const newSellers = changeset.get('sellers').filter( (s: BufferedChangeset) => s !== sellerChangeset);
        changeset.set('sellers',newSellers);
        (sellerChangeset.data as IntakeParty).deleteRecord();
        this.validate();
    }

    @action
    addBuyer() {
        const newBuyer = Changeset(
            this.store.createRecord('intake-party', {
                representingRole: UserRoleType.buyer
            }),
            lookupValidator(intakeBuyerSellerValidations),
            intakeBuyerSellerValidations
        );
        const changeset = this.args.changeset;
        const newBuyers = changeset.get('buyers').concat(newBuyer);
        changeset.set('buyers',newBuyers);
        this.validate();
    }

    @action
    removeBuyer(buyerChangeset: BufferedChangeset) {
        const changeset = this.args.changeset;
        const newBuyers = changeset.get('buyers').filter( (b: BufferedChangeset) => b !== buyerChangeset);
        changeset.set('buyers',newBuyers);
        (buyerChangeset.data as IntakeParty).deleteRecord();
        this.validate();
    }

    @action
    onIntakeContactInput(changeset: BufferedChangeset, key: string, value: any) {
        changeset.set(key, value);
        this.validate();
    }

    @action
    async onNext() {
        const changeset = this.args.changeset;
        const intakeUser = this.args.intakeUser;
        const sellers = changeset.get('sellers');
        const buyers = changeset.get('buyers');

        const isInvalid = sellers.any((seller: BufferedChangeset) => seller.isInvalid) ||
                            buyers.any((buyer: BufferedChangeset) => buyer.isInvalid);

        sellers?.forEach((seller: BufferedChangeset) => {
            seller.set('showErrorOnPristine', true);
        });
        buyers?.forEach((buyer: BufferedChangeset) => {
            buyer.set('showErrorOnPristine', true);
        });

        if (isInvalid) {
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        await this.args.onNext(changeset, intakeUser);
    }
}
