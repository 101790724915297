import Controller from '@ember/controller';
import IndividualTitleholder from 'portal/models/individual-titleholder';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealTitleholderInformationMultiselectIndividualTitleholder extends Controller {
    @service
    declare router: RouterService;

    @action
    async onNext(individualTitleHolders: Array<IndividualTitleholder>) {
        await Promise.all(individualTitleHolders.map(async (titleholder: IndividualTitleholder) => {
            if (titleholder.addToDealAsTitleholder) {
                await titleholder.save();
            }
        }));

        this.router.transitionTo('protected.deal.titleholder-information.complete');
    }

    @action
    onAdd() {
        this.router.transitionTo('protected.deal.titleholder-information.new-individual');
    }
}