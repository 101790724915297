import ActionItem from './action-item';

export default class ReviewSettlementStatementActivity extends ActionItem {}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
    export default interface AdapterRegistry {
        'review-settlement-statement-review-activity': ReviewSettlementStatementActivity;
    }
}
