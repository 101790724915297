import Component from '@glimmer/component';
import LegalIndividual from 'portal/models/legal-individual';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

interface MyAccountMyTitleholdersArgs {
    legalIndividuals: LegalIndividual[];
}

export default class MyAccountMyTitleholders extends Component<MyAccountMyTitleholdersArgs> {
    @service
        router!: RouterService;

    @tracked
        isModalOpen = false;

    @action
    onAddIndividual() {
        this.router.transitionTo('protected.my-account.add-legal-individual');
    }

    @action
    onEditIndividual(legalIndividual: LegalIndividual) {
        this.router.transitionTo(
            'protected.my-account.edit-legal-individual',
            legalIndividual.id
        );
    }

    @task
    async deleteIndividual(legalIndividual: LegalIndividual): Promise<void> {
        await legalIndividual.destroyRecord();
        this.toggleConfirmationModal();
    }

    @action
    toggleConfirmationModal() {
        this.isModalOpen = !this.isModalOpen;
    }
}
