import Analytics from 'portal/services/analytics';
import CommitmentReviewActivity from 'portal/models/commitment-review-activity';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

interface DealActionItemsCommitmentReviewArgs {
    actionItem: CommitmentReviewActivity;
    onStart: () => void;
}

export default class DealActionItemsCommitmentReview extends Component<DealActionItemsCommitmentReviewArgs> {
    @service
    declare analytics: Analytics;

    @action
    onDownload() {
        this.analytics.trackEvent('Deal - Title Commitment Document Downloaded');
    }
}
