import Controller from '@ember/controller';
import ProtectedDealOnboardingTitleholderPreferenceRoute from 'portal/routes/protected/deal/onboarding/titleholder-preference';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { ModelFrom } from 'portal/utils/type-utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProtectedDealOnboardingTitleholderPreference extends Controller {
    declare model: ModelFrom<ProtectedDealOnboardingTitleholderPreferenceRoute>;

    @service
    declare router: RouterService;

    @service
    declare store: Store;

    @action
    async onIndividualSelected() {
        const legalIndividualsLength = (await this.store.findAll('legal-individual', { reload: true })).length;

        if (legalIndividualsLength > 0) {
            this.router.transitionTo('protected.deal.onboarding.individual-multiselect-titleholder');
        } else {
            this.router.transitionTo('protected.deal.onboarding.individual-titleholder');
        }
    }

    @action
    onEntitySelected() {
        this.router.transitionTo('protected.deal.onboarding.select-entity');
    }

    @action
    onSkipSelected() {
        if (this.model?.isBorrower) {
            this.router.transitionTo('protected.deal.onboarding.refinance-credit-lines');
        } else {
            this.router.transitionTo('protected.deal.onboarding.personal-information');
        }
    }
}