import Auth0MockService from 'portal/services/auth0-mock';
import Auth0Service from 'portal/services/auth0';
import AuthBaseService from 'portal/services/auth-base';
import ENV from 'portal/config/environment';
import MagicLoginService from 'portal/services/magic-login';
import RouterService from '@ember/routing/router-service';
import Service from '@ember/service';
type Transition = ReturnType<RouterService['transitionTo']>;
import User from 'portal/models/user';
import { inject as service } from '@ember/service';

export default class AuthService extends Service {

    @service
    declare auth0: Auth0Service;

    @service
    declare auth0Mock: Auth0MockService;

    @service
    declare magicLogin: MagicLoginService;

    @service
    declare router: RouterService;

    constructor(args: any){
        super(args);
    }

    requireAuthentication(transition: Transition) {
        this.getAuthService().requireAuthentication(transition);
    }

    async afterAuthentication(): Promise<void> {
        return await this.getAuthService().afterAuthentication();
    }

    get token() {
        return this.getAuthService().token;
    }

    async isEmailVerified(): Promise<boolean> {
        return await this.getAuthService().isEmailVerified();
    }

    async isAuthenticated(): Promise<boolean> {
        return await this.getAuthService().isAuthenticated();
    }

    isImpersonated(): boolean {
        return this.getAuthService().isImpersonated();
    }

    async login(): Promise<void> {
        return await this.getAuthService().login();
    }

    async logout(): Promise<void> {
        return await this.getAuthService().logout();
    }

    getAuthenticatedUser(): Promise<User> {
        return this.getAuthService().getAuthenticatedUser();
    }

    get user(): User | undefined {
        return this.getAuthService().user;
    }

    set user(user: User | undefined) {
        this.getAuthService().user = user;
    }

    getLastAttemptedTransition(){
        return this.getAuthService().lastAttemptedTransition;
    }

    getAuthService(): AuthBaseService
    {
        if (this.magicLogin.hasToken() || new URL(this.windowLocation).searchParams.get('magic')) {
            return this.magicLogin;
        } else if (ENV.MOCK_AUTH0_IN_PORTAL == 'true') {
            return this.auth0Mock;
        } else {
            return this.auth0;
        }
    }

    get windowLocation(): string {
        return window.location.href;
    }

}

declare module '@ember/service' {
    interface Registry {
        auth: AuthService;
    }
}
