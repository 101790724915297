import ActionItem, { ActionItemDescriptions } from './action-item';
import { attr } from '@ember-data/model';

export default class BuyerTitleholderAndFinancingInformationActivity extends ActionItem {
    @attr('string', { defaultValue: ActionItemDescriptions.BUYER_TITLEHOLDER_AND_FINANCING_INFORMATION })
        name?: ActionItemDescriptions.BUYER_TITLEHOLDER_AND_FINANCING_INFORMATION;

    @attr('string', { defaultValue: ActionItemDescriptions.BUYER_TITLEHOLDER_AND_FINANCING_INFORMATION })
        description?: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'buyer-titleholder-and-financing-information-activity': BuyerTitleholderAndFinancingInformationActivity;
    }
}
