import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import evValidateConfirmation from 'ember-validators/confirmation';

// The validateConfirmation() function from ember-changeset-validations merges the given `content` object
// with the `changes` object, but if `content` is a Model instance, the attributes are nested and end up
// not being top-level keys. See https://github.com/poteto/ember-changeset-validations/issues/137#issuecomment-1183397001
// for more context.

export default function validateConfirmation(options = {}) {
    return (
        key: string,
        newValue: unknown,
        _oldValue: unknown,
        changes: unknown,
        content: object = {}) => {
        let model;

        if ('_internalModel' in content) {
            const contentHash = (content as any)._internalModel.createSnapshot().attributes();
            model = Object.assign({}, contentHash, changes);
        } else {
            model = Object.assign({}, content, changes);
        }

        const result = evValidateConfirmation(newValue, options, model, key);
        return result === true ? true : buildMessage(key, result);
    };
}
