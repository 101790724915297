import Analytics from 'portal/services/analytics';
import Component from '@glimmer/component';
import Referral, { referralValidations } from 'portal/models/referral';
import Store from '@ember-data/store';
import lookupValidator from 'ember-changeset-validations';
import { Changeset } from 'ember-changeset';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';

interface BannerArgs {
    referral: Referral;
}

export default class Banner extends Component<BannerArgs> {
    displayBanner = this.args.referral.referable;
    changeset = Changeset(
        this.args.referral,
        lookupValidator(referralValidations),
        referralValidations
    );

    @tracked submittedBanner = false;

    @service
    declare store: Store;

    @service
    declare analytics: Analytics;

    @task
    async saveReferral(): Promise<void> {
        this.changeset.validate();

        if (this.changeset.isInvalid){
            this.changeset.set('showErrorOnPristine', true);
            this.args.referral.rollbackAttributes();
            return;
        }

        if (this.changeset.isValid) {
            this.analytics.trackEvent('Referral Form / Submit Clicked');
            await this.changeset.save();

            this.submittedBanner = true;
        }
    }

    @action
    async onSubmit() {
        await taskFor(this.saveReferral).perform();
    }
}
