import ArrayProxy from '@ember/array/proxy';
import MarketModel from 'portal/models/market';
import Service from '@ember/service';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';

export default class Market extends Service {
    @service
        store!: Store;

    private cachedMarkets: MarketModel[] = [];

    loadMarkets(): Promise<any> {
        if (this.cachedMarkets.length > 0) {
            return Promise.resolve(this.cachedMarkets);
        } else {
            return this.store.findAll('market').then((markets: ArrayProxy<any>) => {
                this.cachedMarkets = markets.toArray();
                return this.cachedMarkets;
            });
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
    interface Registry {
        'market': Market;
    }
}

