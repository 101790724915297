import AuthService from 'portal/services/auth';
import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import { inject as service } from '@ember/service';

interface OnboardingWelcomeArgs {
    deal: Deal;
    onConfirm: () => void;
    disabled: boolean;
}

export default class OnboardingWelcome extends Component<OnboardingWelcomeArgs> {
    @service
        auth!: AuthService;
}
