import Analytics from 'portal/services/analytics';
import CommitmentReviewActivity from 'portal/models/commitment-review-activity';
import Component from '@glimmer/component';
import DealMessage from 'portal/models/deal-message';
import RouterService from '@ember/routing/router-service';
import Store from '@ember-data/store';
import { BufferedChangeset } from 'ember-changeset/types';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface CommitmentCommitmentReviewArgs {
  changeset: BufferedChangeset
  actionItem: CommitmentReviewActivity
}

export default class CommitmentCommitmentReview extends Component<CommitmentCommitmentReviewArgs> {
    @service
    declare analytics: Analytics;

    @service
    declare store: Store;

    @service
    declare router: RouterService;

    @tracked
        message: DealMessage;

    @tracked
        showQuestionError = false;

    @tracked
        showUnderstoodByUserError = false;

    constructor(owner: unknown, args: CommitmentCommitmentReviewArgs) {
        super(owner, args);
        this.message = this.store.createRecord('deal-message', {
            deal: args.actionItem.deal
        });
    }

    @action
    onUnderstoodByUserChange(value: boolean) {
        this.args.changeset.set('understoodByUser', value);

        if (this.args.changeset.get('understoodByUser')) {
            this.showQuestionError = false;
        } else {
            this.showQuestionError = true;
        }

        this.args.changeset.validate();
    }

    @action
    onDownload() {
        this.analytics.trackEvent('Commitment Review - Title Commitment Document Downloaded');
    }

    @action
    async onSubmit() {
        if (this.message.messageText) {
            this.args.changeset.set('customerQuestion', this.message);
            this.showQuestionError = false;
        }

        this.args.changeset.validate();

        if (this.args.changeset.isInvalid) {
            this.showUnderstoodByUserError = true;
            return;
        }

        if (this.message.messageText) {
            await this.message.save();
        } else {
            this.message.destroy();
        }

        await this.args.changeset.save();

        this.args.actionItem.hasCompleted = true;
        await this.args.actionItem.save();

        this.router.transitionTo('protected.deal');
    }
}
