import { helper } from '@ember/component/helper';

export function formatAddressAsOneLine(
    params: [string, string, string, string, string]
) {
    const [street, street2, city, state, zipcode] = params;

    if (street && city && state && zipcode) {
        const address = [];
        address.push(street);
        if (street2 && street2.length > 0) {
            address.push(street2);
        }
        const state_zipcode = state + ' ' + zipcode;
        address.push(city, state_zipcode);
        return address.join(', ');
    }
    return '';
}

export default helper(formatAddressAsOneLine);
