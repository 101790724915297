import Analytics from 'portal/services/analytics';
import AuthService from 'portal/services/auth';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ProtectedVerifyEmailAddress extends Controller {
    queryParams = ['dealId', 'inviteId', 'singleUseToken', 'transition'];

    @tracked
        dealId?: string;

    @tracked
        inviteId?: string;

    @tracked
        singleUseToken?: string;

    @tracked
        transition!: string;

    @tracked
        emailNotVerifiedYet?: boolean = false;

    @service
        auth!: AuthService;

    @service
    declare analytics: Analytics;

    @action
    async verified() {
        const isEmailVerified = await this.auth.isEmailVerified();
        this.analytics.trackEvent('Verified Email Address Clicked', { isEmailVerified });

        if (isEmailVerified) {
            const user = await this.auth.getAuthenticatedUser();

            if (this.dealId) {
                this.replaceRoute(this.transition, this.dealId);
            } else if (this.transition == 'protected.invites') {
                this.replaceRoute(this.transition, this.inviteId, { queryParams: { single_use_token: this.singleUseToken } });
            } else if (user.welcomeDealId) {
                this.replaceRoute('protected.deal.onboarding.welcome', user.welcomeDealId);
            } else {
                this.replaceRoute(this.transition);
            }
        } else {
            this.emailNotVerifiedYet = true;
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'protected/verify-email-address': ProtectedVerifyEmailAddress;
    }
}
